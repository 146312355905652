import React from "react";

import { styled } from "@mui/material";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledSelect = styled("select")`
  padding: 0.5em 1em;
  margin: 0.4em 0;
  border: none;
  outline: none;
  background-color: transparent;
`;

const options = [10, 25, 50, 75, 100];

export default function HistogramSlicer(props) {
  const strings = useLocalizedStrings();
  return (
    <StyledSelect {...props}>
      {options.map((o) => (
        <option key={o} value={o}>
          {strings.formatString(strings.histogram_slicer_dropdown_fmt, o)}
        </option>
      ))}
    </StyledSelect>
  );
}
