export function sessionsHistogramParser(
  histogram,
  selectedSession,
  maxBars = 50
) {
  histogram.sort((a, b) => b.y - a.y);
  if (histogram.length <= maxBars) {
    return histogram;
  }

  const pickPercent = maxBars / histogram.length;

  const reducedArray = [];
  for (let i = 0; i < histogram.length; i++) {
    const rl = reducedArray.length;
    if (histogram[i].x === selectedSession) {
      if (rl !== maxBars) {
        reducedArray.push(histogram[i]);
      } else {
        reducedArray[rl - 1] = histogram[i];
      }

      continue;
    }

    const curr = Math.ceil(pickPercent * i);
    const prev = Math.ceil(pickPercent * (i - 1));

    if (prev === curr || rl === maxBars) {
      continue;
    }

    reducedArray.push(histogram[i]);
  }

  return reducedArray;
}
