import React, { useMemo } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";

import SessionsAPI from "api/sessions";

import BigMessage from "components/ui/BigMessage";

import { timeFormatter } from "utils/time-fmt";

import { useReportedFetch } from "hooks/http";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledAlertPage = styled("div")`
  width: auto;
  font-family: inherit;
  display: grid;
  grid-template-rows: min-content 1fr;
  .alert-details-container {
    padding: 1rem;
    display: grid;
    grid-template-rows: min-content 1fr;
    gap: 1em;
    th {
      text-transform: uppercase;
      font-weight: bold;
    }
    .alert-arbitrary-details-row {
      th {
        padding-right: 1em;
        &:after {
          content: ":";
        }
      }
    }
  }
`;
const columns = () => [
  {
    id: "transport",
    label: "Transport",
    render: (v) => v.transport?.toUpperCase(),
    style: {
      width: "12em",
    },
  },
  {
    id: "details",
    label: "Details",
    render: (v) => (
      <table>
        <tbody>
          {Object.entries(v.details).map((d) => (
            <tr key={d[0]} className="alert-arbitrary-details-row">
              <th>{d[0]}</th>
              <td>{d[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ),
  },
];

export default function AlertDetails({ scenario, session }) {
  const strings = useLocalizedStrings();

  const [[sessionDetails]] = useReportedFetch(
    SessionsAPI.getSessionUrl({ session, scenario }).url,
    [scenario, session]
  );

  const alertInfo = useMemo(() => {
    if (!sessionDetails) {
      return [];
    }
    return sessionDetails.alert_info.map((d) => {
      const { transport, ...details } = d;
      return {
        transport,
        details,
      };
    });
  }, [sessionDetails]);

  if (!sessionDetails) {
    return null;
  }

  if (!sessionDetails.alerted || !sessionDetails.time_alerted) {
    return (
      <StyledAlertPage>
        <BigMessage>{strings.sessiondetails_alert_no_alerted}</BigMessage>
      </StyledAlertPage>
    );
  }

  return (
    <StyledAlertPage>
      <div className="alert-details-container">
        <h2>
          {strings.formatString(
            strings.sessiondetails_alert_title,
            timeFormatter(new Date(sessionDetails.time_alerted))
          )}
        </h2>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns(strings).map((c) => (
                  <TableCell key={c.id} style={c.style}>
                    {c.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {alertInfo.map((a, idx) => (
                <TableRow key={idx}>
                  {columns(strings).map((c, idx) => (
                    <TableCell key={idx}>{c.render(a)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </StyledAlertPage>
  );
}
