import { useContext } from "react";

import { useFeaturesQuery } from "api/features";

import { LoginStatusContext } from "contexts/LoginStatusContext";

import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";

export default function FeatureFlagsWrapper({ children }) {
  const { user } = useContext(LoginStatusContext);
  const enabled = !!user?.id && !user?.super_user;
  const { isPending } = useFeaturesQuery({ enabled });

  if (enabled && isPending) {
    return <PageLoadingSpinner />;
  }

  return <>{children}</>;
}
