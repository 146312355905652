import { useCallback } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";

import AuditAPI from "api/audit";

import StatusIcon from "components/ui/StatusIcon";

import { formatIntervalObject, timeFormatter } from "utils/time-fmt";

import { useReportedFetch } from "hooks/http";
import useBackendEvents from "hooks/useBackendEvents";
import useScenarioJobState from "hooks/useScenarioJobState";

const StyledBundleVersion = styled("p")(() => ({
  "& .source-version": {
    fontFamily: "inherit",
    opacity: 0.7,
    marginLeft: "0.5em",
    fontStyle: "italic",
    "&::before": {
      content: '"["',
    },
    "&::after": {
      content: '"]"',
    },
  },
}));

const audit_columns = [
  {
    id: "icon",
    label: null,
    render: (a, isRunning) => (
      <StatusIcon
        status={a.completed ? "success" : isRunning ? "active" : "warning"}
      />
    ),
  },
  {
    id: "time",
    label: "Time",
    render: (a) => timeFormatter(new Date(a.time)),
  },
  {
    id: "duration",
    label: "Duration",
    render: (a) => formatIntervalObject(a.duration),
  },
  {
    id: "type",
    label: "Type",
    render: (a) => (a.extra?.class ? a.extra.class.toUpperCase() : null),
  },
  {
    id: "source",
    label: "Source",
    render: (a) => (
      <StyledBundleVersion>
        {a?.extra?.source?.toUpperCase()}
        {a?.extra?.version?.connector && (
          <span className="source-version">
            ver.{a?.extra?.version?.connector}
          </span>
        )}
      </StyledBundleVersion>
    ),
  },
  {
    id: "events",
    label: "Events",
    render: (a) =>
      a.completed && a.extra && a.extra.num_events !== undefined
        ? a.extra.num_events.toLocaleString()
        : null,
  },
  {
    id: "keps",
    label: "Speed (keps)",
    render: (a) =>
      a.completed && a.extra && a.extra.keps ? `${a.extra.keps}` : null,
  },
  {
    id: "messages",
    label: "Messages",
    render: (a) => <code style={{ whiteSpace: "pre-wrap" }}>{a.messages}</code>,
  },
];

const auditListBackendEventsOfInterest = ["audit", "jobs"];

export default function AuditListView({ scenario, style }) {
  const [backendEvent] = useBackendEvents(auditListBackendEventsOfInterest, [
    scenario,
  ]);

  const { scenarioJob } = useScenarioJobState(scenario);

  const isFreshAudit = useCallback(
    (a) => (!scenarioJob ? false : scenarioJob.run_id === a.run_id),
    [scenarioJob]
  );

  const [audits] = useReportedFetch(AuditAPI.getUrl(scenario, 50).url, [
    scenario,
    backendEvent,
  ]);

  return (
    <TableContainer style={style}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {audit_columns.map((c) => (
              <TableCell key={c.id} title={c.help} variant="head">
                {c.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {audits.map((a) => (
            <TableRow hover key={a.id}>
              {audit_columns.map((c) => (
                <TableCell key={c.id} align={c.align} variant="body">
                  {c.render(a, isFreshAudit(a))}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
