import { useContext } from "react";

import { Link } from "react-router-dom";

import { useClustersGetUsersHistogramQuery } from "api/clusters";

import { ScenariosContext } from "contexts/ScenariosContext";

import HtmlTooltip from "components/ui/HtmlTooltip";
import InformationStrip from "components/ui/InformationStrip";
import InformationStripItem from "components/ui/InformationStripItem";

import { timeFormatter } from "utils/time-fmt";

import useLocalizedStrings from "hooks/useLocalizedStrings";

export function ProfileInfo({ profileId, profile }) {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);

  const { data: users } = useClustersGetUsersHistogramQuery({
    scenarioId: selectedScenario.id,
    cluster: profileId,
  });

  return (
    <InformationStrip className="profile-info-strip">
      <InformationStripItem
        label={strings.profiledetails_infostrip_label_created}
      >
        {timeFormatter(new Date(profile?.created))}
      </InformationStripItem>

      <InformationStripItem
        label={strings.profiledetails_navbar_tab_profile_id}
      >
        {`#${profile?.id}`}
      </InformationStripItem>

      {selectedScenario?.scoring_system === "multi-user" ? (
        <InformationStripItem
          label={strings.profiledetails_infostrip_label_user}
        >
          {profile?.app_user_id?.length > 20 ? (
            <HtmlTooltip title={profile?.app_user_id}>
              <Link
                to={`/scenarios/${selectedScenario.id}/users?uid=${profile?.app_user_id}`}
              >
                {`${profile?.app_user_id.slice(0, 20)}...`}
              </Link>
            </HtmlTooltip>
          ) : (
            <Link
              to={`/scenarios/${selectedScenario.id}/users?uid=${profile?.app_user_id}`}
            >
              {profile?.app_user_id}
            </Link>
          )}
        </InformationStripItem>
      ) : (
        <InformationStripItem label={"users"}>
          {users ? Object.keys(users).length : "..."}
        </InformationStripItem>
      )}

      <InformationStripItem label={strings.profiledetails_infostrip_label_size}>
        <Link
          to={`/scenarios/${selectedScenario.id}/sessions?cluster=${profileId}`}
        >
          {profile?.size}
        </Link>
      </InformationStripItem>

      <InformationStripItem
        label={strings.profiledetails_infostrip_label_average}
      >
        {profile?.average}
      </InformationStripItem>

      <InformationStripItem
        label={strings.profiledetails_infostrip_label_stddev}
      >
        {profile?.deviation}
      </InformationStripItem>
    </InformationStrip>
  );
}
