import { useEffect, useMemo, useRef, useState } from "react";

import { useTheme } from "@mui/material";
import { Chart, registerables } from "chart.js";
import { useHistory } from "react-router-dom";

import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";

import { parseRiskColor } from "utils/parseRiskColor";
import { formatInterval, timeFormatter, timeInterval } from "utils/time-fmt";

import useLocalizedStrings from "hooks/useLocalizedStrings";

Chart.register(...registerables);

export function UserSessionsGraph({
  isPending,
  start,
  end,
  selectedId,
  sessionsData,
}) {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const isMountedRef = useRef(false);
  const [redirectState, setRedirectState] = useState("");
  const strings = useLocalizedStrings();

  const theme = useTheme();
  const { push } = useHistory();

  const formatAnnotation = (annotation) => {
    if (!annotation) {
      return [`Evaluation: N/A`];
    }

    if (annotation.length < 15) {
      return [`Evaluation: ${annotation}`];
    }

    let indexToSplit = annotation.indexOf(" ", 15);

    let firstPart = annotation.substring(0, indexToSplit);
    let secondPart = annotation.substring(indexToSplit).trim();

    if (annotation.length > 50) {
      secondPart = secondPart.substring(0, 35) + "...";
    }

    return [`Evaluation: ${firstPart}`, secondPart];
  };

  const graphData = useMemo(() => {
    const data = sessionsData.reduce((acc, s) => {
      const scenario = s.scenario_name;
      if (!acc[scenario]) {
        acc[scenario] = { label: scenario, data: [] };
      }

      const formattedAnnotation = formatAnnotation(s.annotation);

      const tooltip = [
        `Start: ${timeFormatter(new Date(s.start))}`,
        `Duration: ${formatInterval(timeInterval(s.start, s.end))}`,
        `Length: ${s.num_events}`,
        `Risk: ${s.whole_risk < 0 ? "N/A" : `${s.whole_risk}%`}`,
        `Sensitivity: ${Math.abs(Math.min(+s.num_sensitive_events, 0))}`,
        `${strings.profile}: ${s?.cluster !== "-1" ? s?.cluster : "N/A"}`,
      ];

      acc[scenario].data.push({
        tooltip: [...tooltip, ...formattedAnnotation],
        x: [s.start, s.end],
        y: scenario,
        color: parseRiskColor(s.whole_risk),
        currentSession: s.id === selectedId,
        scenario_id: s.scenario_id,
        app_session_id: s.app_session_id,
      });
      return acc;
    }, {});
    return Object.values(data);
  }, [selectedId, sessionsData, strings?.profile]);

  useEffect(() => {
    if (!graphData?.length || isPending) {
      return;
    }

    chartRef.current = new Chart(canvasRef.current, {
      type: "bar",
      options: {
        onClick: (_, elements) => {
          if (elements.length === 0) {
            return null;
          }
          const scenarioID = elements[0]?.element.$context.raw.scenario_id;
          const appSessionID = elements[0]?.element.$context.raw.app_session_id;

          setRedirectState(
            `/scenarios/${scenarioID}/sessions/${appSessionID}/session-summary`
          );
        },
        indexAxis: "y",
        datasets: {
          bar: {
            barThickness: 25,
            borderSkipped: false,
          },
        },
        plugins: {
          legend: {
            display: false,
            events: [],
          },
          tooltip: {
            titleColor: "black",
            bodyColor: "black",
            bodySpacing: 6,
            padding: 10,
            titleMarginBottom: 10,
            titleFont: {
              size: 14,
            },
            bodyFont: {
              size: 14,
            },
            backgroundColor: theme.palette.grey.light,
            intersect: false,
            displayColors: true,
            callbacks: {
              label: function (ctx) {
                return ctx?.raw.tooltip;
              },
            },
          },
        },

        elements: {
          bar: {
            backgroundColor: (ctx) => ctx.raw.color,
            borderWidth: (ctx) => (ctx.raw.currentSession ? 2 : 1),
            borderColor: (ctx) => (ctx.raw.currentSession ? "blue" : 0),
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          xAxis2: {
            type: "time",
            min: start,
            max: end,
            ticks: {
              stepSize: 3,
            },
            border: {
              display: false,
              z: 2,
            },
            grid: {
              display: true,
              drawTicks: false,
              drawOnChartArea: true,
            },
            time: {
              unit: "hour",
              displayFormats: {
                hour: "HH:mm",
              },
            },
          },
          x: {
            type: "time",
            min: start,
            max: end,
            grid: {
              display: true,
              lineWidth: 1,
              drawOnChartArea: false,
            },
            time: {
              unit: "day",
              displayFormats: {
                day: "MMM DD, YYYY",
              },
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
      },
      data: {
        datasets: graphData,
      },
    });

    isMountedRef.current = true;

    return () => {
      chartRef?.current?.destroy();
      isMountedRef.current = false;
    };
  }, [graphData, start, end, isPending, theme.palette.grey.light]);

  useEffect(() => {
    if (isMountedRef.current === true && redirectState !== "") {
      push(redirectState);
    }
  }, [push, redirectState]);

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  return (
    <div
      style={{
        padding: "50px",
        height: "100%",
      }}
    >
      <canvas ref={canvasRef} />
    </div>
  );
}
