import { useCallback } from "react";

import { useStore } from "store";

export const useDataAnalysisColumns = () => {
  const getAnalysisColumns = useStore(
    useCallback((state) => state.getAnalysisColumns, [])
  );
  const columns = useStore(useCallback((state) => state.analysisColumns, []));

  return {
    getAnalysisColumns,
    columns,
  };
};
