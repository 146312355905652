import { styled } from "@mui/material";

const TagsViewStyled = styled("div")`
  --transition-speed: 100ms;
  --border-color: #aaa;
  position: relative;
  width: 100%;
  height: auto;

  button {
    transition: transform var(--transition-speed) ease;
    margin-left: 0;
    margin-right: 0.5em;
    border: 1px solid var(--border-color);
    background-color: white;
    &:hover {
      background-color: white;
    }
  }

  ul {
    display: inline;
    line-height: 1.7em;
    list-style: none;
    li {
      & > * {
        display: inline-block;
        transition: all var(--transition-speed) ease-in-out;
      }
      .tag-name {
        font-weight: bold;
        padding-right: 16px;
        min-width: 0;
      }
      &.action .tag-name {
        color: steelblue;
      }
    }
  }

  &.collapsed {
    ul {
      li {
        display: inline;
        margin: 0;
        .tag-name {
          padding-right: 0 !important;
          &::after {
            content: "=";
            color: #555;
          }
        }
        .tag-value {
          &:after {
            content: ",";
            padding-right: 0.5em !important;
          }
          &:last-child:after {
            content: ";";
          }
        }
      }
    }
  }

  &:not(.collapsed) {
    button {
      transform: rotate(-180deg);
    }
    ul {
      li {
        margin-left: 1em;
        padding-left: 1em;
        border-left: 1px solid var(--border-color);

        & > .tag-name {
          min-width: 13em;
        }
      }
      li:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

export default TagsViewStyled;
