import { useCallback, useEffect, useRef } from "react";

import { v4 } from "uuid";

export default function useCustomEvent(name, listener) {
  const id = useRef(v4());
  const win = useRef(window);

  const listenerImpl = useCallback(
    (e) => {
      if (listener && e.detail.id !== id.current) {
        listener(e);
      }
    },
    [listener]
  );

  useEffect(() => {
    const currentWindow = win.current;
    if (!currentWindow) {
      return;
    }
    currentWindow.addEventListener(name, listenerImpl);
    return () => currentWindow.removeEventListener(name, listenerImpl);
  }, [name, listenerImpl]);

  const raise = useCallback(
    (details) => {
      win.current?.dispatchEvent(
        new CustomEvent(name, { detail: { ...details, id: id.current } })
      );
    },
    [name]
  );

  return [raise];
}
