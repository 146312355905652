import { useEffect, useRef, useState } from "react";

export const useInView = (
  options = { toggleOnHidden: false, rootMargin: "10px", enabled: true }
) => {
  const target = useRef(null);
  const [inView, setInView] = useState(false);
  const [mounted, setMounted] = useState(false);

  //Wait for any animations to complete e.g. scenario sidebar slide
  useEffect(() => {
    const timeout = setTimeout(() => setMounted(true), 100);
    return () => {
      clearTimeout(timeout);
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    const enabled = options?.enabled ?? true;
    if (!mounted || !enabled) {
      return;
    }
    const container = target.current;
    if (!container) {
      return;
    }

    const callbackFunction = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setInView(true);
      } else if (options?.toggleOnHidden) {
        setInView(false);
      }
    };

    const obs = new IntersectionObserver(callbackFunction, {
      root: null,
      rootMargin: options?.rootMargin || "10px",
      threshold: 0,
    });

    obs.observe(container);

    return () => {
      obs.unobserve(container);
    };
  }, [
    options?.toggleOnHidden,
    options?.enabled,
    options?.rootMargin,
    target,
    mounted,
  ]);

  return {
    inView,
    target,
  };
};
