import React from "react";

import useScenarioJobState from "hooks/useScenarioJobState";

import StatusIcon from "./StatusIcon";

export default function ActiveJobIndicator({ scenario, ...rest }) {
  const { isRunning } = useScenarioJobState(scenario);

  if (!isRunning) {
    return null;
  }

  return (
    <div
      style={{
        alignItems: "center",
        display: "inline-flex",
        transform: "scale(.8)",
      }}
    >
      <StatusIcon status="active" {...rest} />
    </div>
  );
}
