import { styled } from "@mui/material";

const StyledChartWrapper = styled("div")`
  width: 100%;
  max-width: 70vw;
  height: 100%;
  max-height: 60vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 3em 1fr;
  gap: 0.5em;
  .toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
    .MuiFormGroup-root {
      position: relative;
      border: 1px solid #ddd;
      padding: 0 1em;
      border-radius: 10px;
      &:before {
        content: attr(label);
        position: absolute;
        font-size: 70%;
        text-transform: uppercase;
        transform: translateY(-1.5em) translateX(-1.5em);
        letter-spacing: 2px;
        opacity: 0.5;
      }
    }
  }
`;

export default StyledChartWrapper;
