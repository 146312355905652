import { useCallback } from "react";

import { useStore } from "store";

const useApplications = () => {
  const apps = useStore(useCallback((state) => state.apps, []));

  return [apps];
};

export default useApplications;
