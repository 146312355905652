import { createContext, useCallback, useEffect, useMemo } from "react";

import { ROLES } from "@tiq/shared/lib/constants";
import { useHistory } from "react-router-dom";

import ScenariosAPI from "api/scenarios";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useReportedFetch } from "hooks/http";
import useBackendEvents from "hooks/useBackendEvents";
import { useRoles } from "hooks/useRoles";

export const ScenariosContext = createContext();

const backendEventsOfInterest = ["scenarios", "sources"];

export default function ScenariosContextProvider(props) {
  const { hasRoles } = useRoles();
  const [backendEvents] = useBackendEvents(backendEventsOfInterest);
  const [scenarios, setScenarios] = useReportedFetch(
    ScenariosAPI.enumScenariosUrl().url,
    [backendEvents]
  );
  const history = useHistory();
  const [currentScenarioId, setCurrentScenarioId] = useCurrentUserSettings(
    "current-scenario",
    null
  );

  const notAdminUser = !hasRoles([ROLES.ADMIN]);

  const scenariosList = useMemo(() => {
    if (notAdminUser) {
      return scenarios.filter((scenario) => scenario.is_production);
    }
    return scenarios;
  }, [notAdminUser, scenarios]);

  const navigateToScenario = useCallback(
    (sid) => {
      if (!sid) {
        return;
      }
      const rest = history.location.pathname.split("/").slice(3);
      const pathname = `/scenarios/${sid}/${
        rest.length ? rest.join("/") : "dashboard"
      }`;
      history.push(pathname, history.location?.state);
    },
    [history]
  );

  const applyURL = useCallback(
    (pathname) => {
      const [section, scenarioId] = pathname.split("/").slice(1);
      if (section !== "scenarios") {
        return;
      }
      if (!scenarioId && currentScenarioId) {
        const currentScenario = scenariosList.find(
          (scenario) => scenario.id === currentScenarioId
        );

        if (notAdminUser && !currentScenario?.is_production) {
          navigateToScenario(scenariosList[0]?.id);
          return;
        }
        navigateToScenario(currentScenarioId);
        return;
      }
      if (scenariosList.find((scenario) => scenario.id === scenarioId)) {
        // pathname contains a valid(!) scenario id:
        // set selected scenario according to the current pathname
        setCurrentScenarioId(scenarioId);
      } else if (scenariosList.length) {
        navigateToScenario(scenariosList[0].id);
      }
    },
    [
      currentScenarioId,
      scenariosList,
      notAdminUser,
      navigateToScenario,
      setCurrentScenarioId,
    ]
  );

  // a new scenario ID selected => establish the scenario full object
  const selectedScenario = useMemo(() => {
    return scenariosList?.find((s) => s.id === currentScenarioId);
  }, [scenariosList, currentScenarioId]);

  // URL changed
  useEffect(() => {
    return history.listen((location) => {
      applyURL(location.pathname);
    });
  }, [applyURL, history, scenarios]);

  // init (or when scenarios reloaded)
  useEffect(() => {
    applyURL(history.location.pathname);
  }, [applyURL, history, scenarios]);

  return (
    <ScenariosContext.Provider
      value={{
        scenarios: scenariosList,
        setScenarios,
        selectedScenario,
        selectScenario: navigateToScenario,
      }}
    >
      {props.children}
    </ScenariosContext.Provider>
  );
}
