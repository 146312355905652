import { useContext, useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { useScenarioSensitivityCoverage } from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import FileInput from "components/ui/form/FileInput";

import useLocalizedStrings from "hooks/useLocalizedStrings";

export const SensitivityCoverageTool = ({ style }) => {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);
  const methods = useForm({
    values: {
      scenarioX: selectedScenario?.id,
      sensitiveEvents: "",
    },
  });
  const { handleSubmit, reset } = methods;
  const [open, setOpen] = useState(false);

  const getCoverage = useScenarioSensitivityCoverage();

  const onSubmit = async (formData) => {
    await getCoverage.mutateAsync(formData);
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <div style={style}>
      <UiSecondaryButton
        style={{
          width: "15em",
          marginBottom: "5px",
          whiteSpace: "pre",
        }}
        onClick={() => setOpen(true)}
      >
        Sensitivity Coverage
      </UiSecondaryButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            {strings.scenario_sensitivity_coverage_title}
          </DialogTitle>
          <DialogContent style={{ padding: "20px" }}>
            <FileInput
              control={methods.control}
              accept="text/csv"
              name="sensitiveEvents"
              placeholder={strings.scenario_sensitivity_coverage_placeholder}
              required={true}
              style={{ width: "500px" }}
            />
          </DialogContent>
          <DialogActions>
            <UiSecondaryButton onClick={() => setOpen(false)}>
              {strings.button_cancel}
            </UiSecondaryButton>
            <UiPrimaryButton type="submit">
              {strings.button_submit}
            </UiPrimaryButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
