import React, { useRef, useState } from "react";

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { styled } from "@mui/material";
import Popover from "@mui/material/Popover";

import { UiSecondaryButton } from "./StyledButtons";

const StyledButtonPopover = styled(Popover)(() => ({
  pointerEvents: "none",
  "& .popoverContent": {
    pointerEvents: "auto",
  },
}));

const ButtonPopover = ({ children }) => {
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);

  const popoverEnter = (event) => {
    popoverAnchor.current = event.currentTarget;
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  return (
    <>
      <UiSecondaryButton
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
        startIcon={<CloudDownloadOutlinedIcon />}
        style={{
          cursor: "pointer",
          height: "39px",
          justifyContent: "end",
          marginRight: 8,
        }}
        size="small"
      />
      <StyledButtonPopover
        id="mouse-over-popover"
        classes={{
          paper: "popoverContent",
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
      >
        {children}
      </StyledButtonPopover>
    </>
  );
};
export default ButtonPopover;
