import { useMemo } from "react";

import { useFeaturesQuery } from "api/features";

export default function useFeature(featureId) {
  const { data } = useFeaturesQuery();

  const flag = useMemo(() => {
    if (!data || data.auth_url) {
      return null;
    }
    return data?.find((flag) => flag.name === featureId);
  }, [data, featureId]);

  return flag ? flag.value : false;
}
