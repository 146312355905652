import { useMemo } from "react";

import { Typography } from "@mui/material";

import { parseUserId } from "utils/parseUserId";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useOverflowState } from "hooks/useOverflowState";

import HtmlTooltip from "./HtmlTooltip";

const UserId = ({ k, v }) => {
  const { ref, overflow } = useOverflowState();

  const content = `${k} = ${v}`;

  return (
    <HtmlTooltip
      disableHoverListener={!overflow}
      title={<Typography>{content}</Typography>}
    >
      <Typography ref={ref} textOverflow="ellipsis" overflow="hidden">
        {content}
      </Typography>
    </HtmlTooltip>
  );
};

export const UserIdDisplay = ({ userId }) => {
  const strings = useLocalizedStrings();
  const tagStrings = useMemo(
    () => parseUserId(userId, strings.tagsview_untitled),
    [userId, strings.tagsview_untitled]
  );

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      {tagStrings.map((t) => (
        <UserId key={t.key} k={t.key} v={t.value} />
      ))}
    </div>
  );
};
