import * as d3 from "d3";

export const rarityScale = [0, 5];
export const maxRarity = rarityScale.at(-1);

export const parseRarityColor = (rarity) => {
  const b = d3.scaleLinear().domain(rarityScale).range([260, 360]).clamp(true);

  return d3.hsl(b(rarity), 1, 0.75, 1).toString();
};
