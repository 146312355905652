import { useCallback, useContext, useEffect, useRef, useState } from "react";

import {
  CloudDownload,
  CloudUpload,
  CloudUploadOutlined,
} from "@mui/icons-material";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useController, useForm } from "react-hook-form";

import ScenariosAPI, {
  useScenarioParametersQuery,
  useScenarioUpdateParameterMutation,
  useScenarioUploadParameterMutation,
} from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import FileUpload from "components/ui/FileUpload";
import Flexbox from "components/ui/Flexbox";
import FrameContent from "components/ui/FrameContent";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";
import RemoteDownloader from "components/ui/RemoteDownloader";
import { UiPrimaryButton } from "components/ui/StyledButtons";
import TableCellText from "components/ui/TableCellText";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import SettingsOption from "./SettingsOption";

const getDefaultValues = (parameters) => {
  if (!parameters) {
    return {};
  }
  return parameters.reduce((obj, item) => {
    return {
      ...obj,
      [item.name]: item.values,
    };
  }, {});
};

const ValueEditor = ({ param, control, setValue }) => {
  const {
    field,
    fieldState: { error, isDirty },
  } = useController({
    name: param.name,
    control,
    rules: {
      required: param?.validation?.optional ? false : true,
      pattern: new RegExp(param?.validation?.regex),
      min: param?.validation?.min,
      max: param?.validation?.max,
      validate: {
        type: (value) => {
          let valid = true;
          switch (param?.validation?.type) {
            case "int": {
              let n = Math.floor(Number(value));
              valid = String(n) === value && n >= 0;
              break;
            }
            case "float": {
              valid = RegExp(/^(\d+)(\.\d+)?([eE][-+]?\d+)?$/).test(value);
              break;
            }
            case "boolean": {
              valid = ["true", "false"].includes(value?.toLowerCase());
              break;
            }
          }
          return valid;
        },
      },
    },
  });
  const commitButton = useRef();
  const strings = useLocalizedStrings();

  const handleCommit = useCallback(() => {
    setValue(param?.name, field?.value.trim().length ? field?.value : null);
  }, [field.value, param?.name, setValue]);

  return (
    <>
      <TextField
        error={!!error}
        inputRef={field.ref}
        variant="outlined"
        placeholder={param?.validation?.optional ? "" : param.values}
        helperText={
          param.description ||
          strings.scenariosettings_parameters_ui_description_placeholder
        }
        fullWidth
        {...field}
        value={field.value ?? ""}
        label={null}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                ref={commitButton}
                size="small"
                disabled={!isDirty || !!error}
                onClick={handleCommit}
              >
                {isDirty && !error ? <CloudUpload /> : <CloudUploadOutlined />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {!!error ? (
        <FormHelperText style={{ textAlign: "right" }} error={!!error}>
          {strings.invalid_input}
        </FormHelperText>
      ) : null}
    </>
  );
};

const ParametersUi = () => {
  const strings = useLocalizedStrings();
  const { pushMessage } = useMessages();
  const { selectedScenario } = useContext(ScenariosContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [updateContext, setUpdateContext] = useState({});
  const { data: parameters, isPending } = useScenarioParametersQuery(
    selectedScenario?.id
  );
  const { mutate: updateParameter } = useScenarioUpdateParameterMutation();

  const { control, reset, resetField } = useForm({
    defaultValues: getDefaultValues(parameters),
    mode: "onChange",
    shouldUnregister: true,
  });

  useEffect(() => {
    reset(getDefaultValues(parameters));
  }, [reset, parameters]);

  const handleParamChange = useCallback((name, values) => {
    setUpdateContext({ name, values });
    setConfirmOpen(true);
  }, []);

  const handleUpdateParam = ({ name, values }) => {
    updateParameter(
      { scenarioId: selectedScenario?.id, name, values: values ?? "" },
      {
        onSuccess: () => {
          resetField(name, { defaultValue: values });
          pushMessage(
            "success",
            strings.formatString(
              strings.scenariosettings_parameters_ui_success_message,
              updateContext.name
            )
          );
        },
      }
    );
  };

  return (
    <SettingsOption title={strings.scenariosettings_parameters_ui_title}>
      <div
        style={{
          marginBottom: "20px",
          overflow: "hidden",
        }}
      >
        <form>
          {!isPending && parameters?.length !== 0 ? (
            <TableContainer className="paramsTable">
              <Table stickyHeader style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableCellText variant="body1">
                        {strings.scenariosettings_parameters_ui_label_name}
                      </TableCellText>
                    </TableCell>
                    <TableCell>
                      <TableCellText variant="body1">
                        {strings.scenariosettings_parameters_ui_label_values}
                      </TableCellText>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parameters.map((param) => (
                    <TableRow key={param.name}>
                      <TableCell style={{ verticalAlign: "top" }}>
                        <TableCellText
                          variant="body2"
                          style={{ wordWrap: "break-word" }}
                        >
                          {param.name}
                        </TableCellText>
                      </TableCell>
                      <TableCell>
                        <ValueEditor
                          key={selectedScenario?.id}
                          setValue={handleParamChange}
                          param={param}
                          control={control}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <PageLoadingSpinner />
          )}
        </form>
      </div>

      <MultipleOptionsDialog
        cancelText={strings.button_no}
        confirmText={strings.button_yes}
        context={updateContext}
        onConfirm={handleUpdateParam}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title={strings.scenariosettings_parameters_ui_dialog_title}
        text={
          <span style={{ overflowWrap: "break-word" }}>
            Changing&nbsp;
            <b>{updateContext?.name}</b>&nbsp; to{" "}
            <b>{`${updateContext?.values}`}</b>?
          </span>
        }
      />
    </SettingsOption>
  );
};

const ParametersUpload = ({ refreshTrigger }) => {
  const strings = useLocalizedStrings();
  const { pushMessage } = useMessages();
  const { selectedScenario } = useContext(ScenariosContext);
  const { mutate: uploadParameters } = useScenarioUploadParameterMutation();
  const caps = useCapabilities();

  const handleUpload = (file) => {
    uploadParameters(
      { scenarioId: selectedScenario?.id, file },
      {
        onSuccess: () => {
          pushMessage(
            "success",
            strings.formatString(
              strings.scenariosettings_upload_success,
              file.name
            )
          );
        },
      }
    );
  };

  return (
    <SettingsOption title={strings.scenariosettings_parameters_title}>
      <FrameContent>
        <Flexbox justifyContent="center">
          <FileUpload
            placeholder={`${strings.scenariosettings_upload_placeholder}...`}
            accept="text/csv"
            onUpload={handleUpload}
            style={{ flexGrow: 1 }}
            refresh={refreshTrigger}
            disabled={!caps({ "acm.scenarios": { write: true } })}
          />
          <RemoteDownloader
            url={
              ScenariosAPI.getScenarioParametersUrl(selectedScenario?.id).url
            }
            filename={`parameters.${selectedScenario?.id}.csv`}
            style={{ flexGrow: 0 }}
          >
            <UiPrimaryButton
              startIcon={<CloudDownload />}
              style={{
                height: "3.2em",
                marginLeft: "10px",
                marginRight: "8px",
              }}
            >
              {strings.button_download}
            </UiPrimaryButton>
          </RemoteDownloader>
        </Flexbox>
      </FrameContent>
    </SettingsOption>
  );
};

const SettingsParameters = () => {
  return (
    <>
      <ParametersUpload />
      <ParametersUi />
    </>
  );
};

export default SettingsParameters;
