import { useQuery } from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

export default class BundlesAPI extends API {
  static getKeys() {
    return { url: `/api/acm/v1/bundles/use-cases/keys` };
  }
}
const baseKey = ["bundles"];
const bundleKeys = {
  list: () => [baseKey, "list"],
};

export const useBundlesGetKeysQuery = () => {
  return useQuery({
    queryKey: bundleKeys.list(),
    queryFn: () => queryFetch(BundlesAPI.getKeys()),
  });
};
