import { useCallback } from "react";

import { useStore } from "store";

const isDeletedScenarioSelector = (state) => state.isDeletedScenario;
const markDeletedScenarioSelector = (state) => state.markDeletedScenario;
const unmarkDeletedScenarioSelector = (state) => state.unmarkDeletedScenario;
export const useDeletedScenario = () => {
  const isDeletedScenario = useStore(
    useCallback(isDeletedScenarioSelector, [])
  );
  const markDeletedScenario = useStore(
    useCallback(markDeletedScenarioSelector, [])
  );
  const unmarkDeletedScenario = useStore(
    useCallback(unmarkDeletedScenarioSelector, [])
  );

  return {
    isDeletedScenario,
    markDeletedScenario,
    unmarkDeletedScenario,
  };
};
