import { useEffect, useState } from "react";

import { getReasonPhrase } from "http-status-codes";
import { useHistory } from "react-router-dom";

import stdFetch from "utils/stdFetch";

import { useMessages } from "./useMessage";

class HTTPError extends Error {
  constructor(httpResult, data) {
    super(
      `${
        httpResult.statusText.length
          ? httpResult.statusText
          : getReasonPhrase(httpResult.status)
      } (${httpResult.status})${data && data.error ? `: ${data.error}` : ""}`
    );
    this.status = httpResult.status;
    this.data = data;
  }
}

const useFetch = (url, dependencies = []) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  if (!url) {
    throw new Error("Bad URL");
  }
  if (dependencies && !(dependencies instanceof Array)) {
    throw new Error("Bad dependecies type");
  }

  useEffect(() => {
    let mounted = true;
    const abortCtl = new AbortController();
    const baseOpt = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      signal: abortCtl.signal,
    };

    const finalUrl = url instanceof Function ? url() : url;

    setIsLoading(true);
    stdFetch(finalUrl, baseOpt)
      .then((rows) => {
        if (rows?.auth_url) {
          history.push("/?expired=1");
          return;
        }
        setFetchedData(rows);
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          setError(err);
        }
      })
      .finally(() => {
        mounted && setIsLoading(false);
      });

    return () => {
      mounted = false;
      abortCtl.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, url, ...dependencies]);

  return [fetchedData, setFetchedData, isLoading, error];
};

const useReportedFetch = (url, dependencies) => {
  const { pushMessage } = useMessages();
  const [fetchedData, setFetchedData, isLoading, error, headers] = useFetch(
    url,
    dependencies
  );

  useEffect(() => {
    if (error) {
      pushMessage("error", error.message);
    }
  }, [error, pushMessage]);

  return [fetchedData, setFetchedData, isLoading, headers];
};

export { useReportedFetch, HTTPError };
