import React from "react";

import { FormControl, InputLabel, Select } from "@mui/material";

export default function Selector(props) {
  const {
    name,
    value,
    onChange,
    required,
    style,
    label,
    children,
    className,
    ...rest
  } = props;
  return (
    <FormControl className={className} {...rest}>
      <InputLabel id="selector" required={required}>
        {label}
      </InputLabel>
      <Select
        name={name}
        labelId="selector"
        label={label}
        value={value}
        onChange={onChange}
        required={required}
        style={style}
      >
        {children}
      </Select>
    </FormControl>
  );
}
