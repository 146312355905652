import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, styled } from "@mui/material";

const StyledExpander = styled(IconButton)(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
  left: 0,
  position: "absolute",
  top: 0,
  transition: "transform 500ms ease",
  "&:hover": {
    color: "black",
    background: "none",
  },
}));

export const Expander = ({
  isExpandingDisabled,
  expandedState,
  handleExpand,
}) => {
  return (
    <StyledExpander
      disabled={isExpandingDisabled}
      style={{
        transform: expandedState ? "rotate(180deg)" : "rotate(0deg)",
      }}
      onClick={handleExpand}
      size="small"
    >
      <ExpandMoreIcon />
    </StyledExpander>
  );
};
