import React from "react";

import { styled } from "@mui/material";

import Flexbox from "components/ui/Flexbox";

const StyledFrameCollection = styled(Flexbox)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "grid",
  gridTemplateColumns: "1fr",
  gridAutoRows: "1fr",
}));

export default function FramesCollection({ children, ...rest }) {
  return (
    <StyledFrameCollection flexDirection="column" width={1} {...rest}>
      {children}
    </StyledFrameCollection>
  );
}
