import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ROLES } from "@tiq/shared/lib/constants";
import { useHistory } from "react-router-dom";

import AdminsAPI, { useAccountQuery, useSelfQuery } from "api/admins";

import { clearInactivityTime } from "utils/idleTimer";
import stdFetch from "utils/stdFetch";
import { makeUrlState } from "utils/urlState";

const LoginStatusContext = createContext();

const redirectToAuth = (authUrl, account, returnUrl) => {
  const url = new URL(authUrl);
  const state = makeUrlState(account, returnUrl);
  if (state) {
    url.searchParams.set("state", state);
  }
  window.location.assign(url.href);
};

const redirectToBackoffice = () => {
  const url = new URL(window.location.origin + "/backoffice");
  url.searchParams.append("state", window.location.href);
  window.location.assign(url.href);
};

const getReturnUrl = () => window.location.origin + window.location.pathname;
const getAccountFromUrl = () => {
  const url = new URL(window.location);
  return url.searchParams.get("account");
};

const accountName = getAccountFromUrl();
const returnUrl = getReturnUrl();
function LoginStatusContextProvider({ children }) {
  const [user, setUser] = useState();

  const selfPollingAllowed = useMemo(
    () => user?.roles?.length === 1 && user?.roles[0] === ROLES.ACCOUNT_ADMIN,
    [user?.roles]
  );

  const [loading, setLoading] = useState(true);
  const { data, isError } = useSelfQuery(accountName, {
    refetchInterval: selfPollingAllowed ? 30 * 1000 : undefined,
    refetchOnWindowFocus: true,
  });
  const { data: account } = useAccountQuery({
    enabled: !!user,
  });

  const history = useHistory();

  const handleUserData = useCallback((newUser) => {
    if (!newUser) {
      setLoading(false);
      return;
    }

    const rolesEmpty = newUser.roles.length === 0;
    const capsEmpty = Object.keys(newUser.capabilities).length === 0;
    if (newUser.super_user || rolesEmpty || capsEmpty) {
      redirectToBackoffice();
      return;
    }

    setUser((prev) => {
      if (prev?.id === newUser?.id) {
        return prev;
      }
      return newUser;
    });
    setLoading(false);
  }, []);

  const logout = useCallback(async () => {
    const res = await stdFetch(AdminsAPI.logout(account?.id));
    clearInactivityTime();
    if (res?.auth_url) {
      setUser();
    }
    if (res?.[0]?.logout_url) {
      window.location.assign(res[0].logout_url);
    }
  }, [account?.id]);

  const login = useCallback(
    async (account, redirectUrl) => {
      const res = await stdFetch(AdminsAPI.getSelf(account));
      if (!res.auth_url) {
        handleUserData(res?.[0]);
      }
      redirectToAuth(res.auth_url, account, redirectUrl);
    },
    [handleUserData]
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.auth_url) {
      clearInactivityTime();
    }
    if (data.auth_url && accountName) {
      localStorage.setItem("account.name", JSON.stringify(accountName));
      redirectToAuth(data.auth_url, accountName, returnUrl);
      return;
    }

    if (data.auth_url) {
      setUser();
      setLoading(false);
      return;
    }

    const newUser = data?.[0];
    handleUserData(newUser);
  }, [data, handleUserData]);

  useEffect(() => {
    if (isError) {
      setUser();
      setLoading(false);
    }
  }, [isError]);

  useEffect(() => {
    if (user && data?.auth_url) {
      setUser();
      history.push("/?expired=1");
    }
  }, [data, history, user]);

  const fullLabel = `${user ? user.name : ""}/${account ? account.name : ""}`;

  const value = useMemo(
    () => ({
      user,
      setUser,
      fullLabel,
      redirectToAuth,
      loading,
      logout,
      login,
      getReturnUrl,
      getAccountFromUrl,
    }),
    [fullLabel, loading, login, logout, user]
  );

  return (
    <LoginStatusContext.Provider value={value}>
      {children}
    </LoginStatusContext.Provider>
  );
}

export { LoginStatusContext, LoginStatusContextProvider };
