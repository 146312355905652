import { useRef } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { FormHelperText, IconButton } from "@mui/material";
import { styled } from "@mui/material";
import { useController } from "react-hook-form";

import Flexbox from "components/ui/Flexbox";

const StyledFlexbox = styled(Flexbox)(({ error, theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  border: `1px solid ${!!error ? theme.palette.error.main : "#ddd"}`,
  borderRadius: "6px",
  color: `${!!error ? theme.palette.error.main : "inherit"}`,
  padding: `0px 8px`,
  height: "40px",
  flexGrow: 1,
  "& .select-file": {
    flexGrow: 0,
    padding: `0px 8px`,
  },
  "& .filename": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    flexGrow: 1,
    border: "none",
    outline: "none",
    overflow: "hidden",
    backgroundColor: "transparent",
    wordBreak: "break-all",
  },
  "& .upload-file": {
    flexGrow: 0,
    padding: `0px 8px 0px 0px`,
  },
}));

function FileInput({
  accept,
  control,
  name,
  placeholder = "Choose a file to upload",
  required,
  rules,
  style,
}) {
  const {
    field: { value, onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: {
        value: required ?? false,
        message: "This field is required",
      },
      ...rules,
    },
  });
  const inputRef = useRef();

  const handleChange = (e) => {
    onChange(e.target.files[0]);
  };

  const handleClear = () => {
    inputRef.current.type = "";
    inputRef.current.type = "file";
    onChange(null);
  };

  return (
    <div>
      <StyledFlexbox style={style} error={error}>
        <IconButton
          onClick={() => inputRef.current.click()}
          style={{ flexGrow: 0, padding: 0 }}
          size="small"
        >
          <InsertDriveFileOutlinedIcon />
        </IconButton>

        <div className="filename" style={{ padding: "0 4px" }}>
          {value ? (
            value.name
          ) : (
            <span style={{ userSelect: "none" }}>{placeholder}</span>
          )}
        </div>

        <input
          {...field}
          ref={inputRef}
          type="file"
          accept={accept}
          onChange={handleChange}
          style={{ display: "none" }}
        />

        <IconButton
          className="clear"
          size="small"
          onClick={handleClear}
          title="Reset"
          disabled={!value}
        >
          <ClearIcon size="small" />
        </IconButton>
      </StyledFlexbox>
      <FormHelperText error={!!error}>{error?.message ?? " "}</FormHelperText>
    </div>
  );
}

export default FileInput;
