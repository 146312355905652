import React from "react";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import BigMessage from "./ui/BigMessage";

export default function NotFound(props) {
  const strings = useLocalizedStrings();
  return (
    <BigMessage style={{ lineHeight: "5rem" }}>
      <h1>(404) {strings.text_error_404}!</h1>
      <h2>
        <code>{props.location.pathname}</code>
      </h2>
    </BigMessage>
  );
}
