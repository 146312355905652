import { Paper, styled } from "@mui/material";

const StyledPaperFrame = styled(Paper)(({ theme }) => ({
  position: "relative",
  display: "grid",
  border: "1px solid #ddd",
  borderRadius: theme.shape.borderRadius,
  gridTemplateColumns: "100%",
  gridTemplateRows: "min-content 1fr min-content",
  padding: theme.spacing(1),
  maxHeight: "40em",
}));

export const PaperFrame = ({ children, ...props }) => {
  return (
    <StyledPaperFrame elevation={0} {...props}>
      {children}
    </StyledPaperFrame>
  );
};
