import { useCallback, useMemo } from "react";

import { styled } from "@mui/material";
import { useHistory } from "react-router-dom";

import ProfilePictureChart from "components/scenarios/profiles/ProfilePictureChart";
import { useClusterDistChart } from "components/scenarios/profiles/useClusterDistChart";
import FrameContent from "components/ui/FrameContent";
import FrameTitle from "components/ui/FrameTitle";
import FrameTitleText from "components/ui/FrameTitleText";
import { PaperFrame } from "components/ui/PaperFrame";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledFrameContent = styled("div")(() => ({
  height: "100%",
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "min-content 1fr",
  gap: "10px",
}));

export default function ProfileContext({ scenario, session }) {
  const strings = useLocalizedStrings();
  const history = useHistory();
  const [sessionType, cluster] = useMemo(() => {
    if (session?.cluster > 0) {
      return ["profiled", session.cluster];
    }
    if (session?.nearest_cluster > 0) {
      return ["nearest", session.nearest_cluster];
    }
    return ["unprofiled", 0];
  }, [session?.cluster, session?.nearest_cluster]);

  const { sessions, loading } = useClusterDistChart(scenario, cluster);

  const handleBarClicked = useCallback(
    (sid) => {
      history.push(`/scenarios/${scenario}/sessions/${sid}/session-anomaly`);
    },
    [history, scenario]
  );

  return (
    <PaperFrame>
      <FrameTitle>
        <FrameTitleText>
          {strings.sessiondetails_anomaly_distribution_title}
        </FrameTitleText>
      </FrameTitle>
      <FrameContent style={{ height: "100%" }}>
        {session &&
        (sessionType === "nearest" || sessionType === "profiled") ? (
          loading ? (
            <p>Rendering...</p>
          ) : (
            <StyledFrameContent>
              <p>
                {sessionType === "profiled"
                  ? strings.sessiondetails_anomaly_distribution_explanation
                  : strings.sessiondetails_anomaly_distribution_nearest}
              </p>
              <ProfilePictureChart
                histogram={sessions || []}
                selectedSession={session?.app_session_id}
                onBarClicked={handleBarClicked}
                sessionType={sessionType}
              />
            </StyledFrameContent>
          )
        ) : (
          <p>{strings.sessiondetails_anomaly_distribution_unprofiled}</p>
        )}
      </FrameContent>
    </PaperFrame>
  );
}
