import { useState } from "react";

import { CloudUpload } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { useScenarioImportMutation } from "api/scenarios";

import HtmlTooltip from "components/ui/HtmlTooltip";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import FileInput from "components/ui/form/FileInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

const StyledImportContent = styled(DialogContent)`
  width: 550px;
  height: 170px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

//separate component so state can be reset by key
const ImportDialog = ({ open, onClose, onUpload, collector }) => {
  const strings = useLocalizedStrings();
  const methods = useForm({
    importNameOverride: "",
    file: "",
  });

  const isSubmitting = methods.formState.isSubmitting;

  const title = strings.formatString(
    strings.sidebar_import_dialog_title,
    collector ? "Collector" : "Scenario"
  );
  const filePlaceholder = strings.formatString(
    strings.sidebar_import_dialog_placeholder,
    collector ? "collector" : "scenario"
  );
  const namePlaceholder = strings.formatString(
    strings.sidebar_import_dialog_name_placeholder,
    collector ? "collector" : "scenario"
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={methods.handleSubmit(onUpload)}>
        <FormProvider {...methods}>
          <DialogTitle>{title}</DialogTitle>
          <StyledImportContent>
            <FileInput
              placeholder={filePlaceholder}
              accept="application/json"
              name="file"
              style={{
                width: "100%",
              }}
              required
            />
            <TextFieldInput
              variant="outlined"
              name="importNameOverride"
              placeholder={namePlaceholder}
            />
          </StyledImportContent>
          <DialogActions>
            <UiSecondaryButton onClick={onClose}>
              {strings.button_cancel}
            </UiSecondaryButton>
            <UiPrimaryButton type="submit" disabled={isSubmitting}>
              {strings.sidebar_import_scenario_button}
            </UiPrimaryButton>
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  );
};

export const ScenarioImporter = ({ collector, onImportEnd, title }) => {
  const caps = useCapabilities();
  const strings = useLocalizedStrings();
  const [open, setOpen] = useState(false);
  const { pushMessage } = useMessages();

  const { mutateAsync: importScenario } = useScenarioImportMutation();

  const successMessage = strings.formatString(
    strings.sidebar_import_dialog_success,
    collector ? "Collector" : "Scenario"
  );

  const handleUpload = async ({ file, importNameOverride }) => {
    try {
      const res = await importScenario({ file, importNameOverride });
      pushMessage("success", successMessage);
      if (onImportEnd) {
        onImportEnd(res);
      }
      setOpen(false);
    } catch {}
  };

  return (
    <>
      <HtmlTooltip disableHoverListener={!!title} title={strings.button_import}>
        <UiPrimaryButton
          startIcon={<CloudUpload />}
          onClick={() => setOpen(true)}
          style={{ padding: "10px 8px" }}
          disabled={!caps({ "acm.scenarios": { write: true } })}
          variant="text"
          sx={{ color: "primary.contrastText" }}
        >
          {title}
        </UiPrimaryButton>
      </HtmlTooltip>
      <ImportDialog
        key={`importDialog-${open}`}
        open={open}
        onClose={() => setOpen(false)}
        onUpload={handleUpload}
        collector={collector}
      />
    </>
  );
};
