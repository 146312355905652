import React, { useState } from "react";

import { FormControlLabel, FormGroup, RadioGroup } from "@mui/material";

import { useClustersGetUsersHistogramQuery } from "api/clusters";

import BrandedRadio from "components/ui/BrandedRadio";
import NotAllowedMessage from "components/ui/NotAllowedMessage";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import HistogramSlicer from "./HistogramSlicer";
import ProfileTabContainer from "./ProfileTabContainer";
import ProfileUsersHistogramChart from "./ProfileUsersHistogramChart";
import StyledChartWrapper from "./StyledChartWrapper";

export default function ProfileUsersHistogram({ scenario, profileId }) {
  const strings = useLocalizedStrings();
  const [mode, setMode] = useState("absolute");
  const [slice, setSlice] = useState(100);
  const caps = useCapabilities();

  const { data: histogram, isPending } = useClustersGetUsersHistogramQuery({
    scenarioId: scenario,
    cluster: profileId,
  });

  if (!caps({ "analysis-model.clusters": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <ProfileTabContainer
      loading={isPending}
      explanation={strings.profiledetails_tab_users_explanation}
    >
      <StyledChartWrapper>
        <div className="toolbar">
          <FormGroup label="View" row>
            <HistogramSlicer
              value={slice}
              onChange={(e) => setSlice(e.target.value)}
            />
          </FormGroup>

          <RadioGroup
            label={strings.profiledetails_tab_users_quantities}
            row
            className="chart-modes"
            name="chart-mode"
            value={mode}
            onChange={(e) => setMode(e.target.value)}
          >
            <FormControlLabel
              value="relative"
              control={<BrandedRadio />}
              label={strings.profiledetails_tab_users_quantities_relative}
              labelPlacement="end"
            />
            <FormControlLabel
              value="absolute"
              control={<BrandedRadio />}
              label={strings.profiledetails_tab_users_quantities_absolute}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>

        <ProfileUsersHistogramChart
          histogram={histogram ?? []}
          mode={mode}
          slice={slice}
        />
      </StyledChartWrapper>
    </ProfileTabContainer>
  );
}
