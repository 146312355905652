export const steelBlue = "rgba(70, 130, 180, .7)";

export const diagonalPattern = (fill = steelBlue, stroke = "gray") => {
  let shape = document.createElement("canvas");
  let c = shape.getContext("2d");

  shape.width = 20;
  shape.height = 20;

  c.fillStyle = fill;
  c.fillRect(0, 0, shape.width, shape.height);

  c.strokeStyle = stroke;
  c.lineWidth = 1;
  c.beginPath();
  c.moveTo(11, -1);
  c.lineTo(-1, 11);
  c.moveTo(21, 9);
  c.lineTo(9, 21);
  c.stroke();

  return c.createPattern(shape, "repeat");
};
