import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { IconButton } from "@mui/material";

import HtmlTooltip from "components/ui/HtmlTooltip";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useNavigation } from "hooks/useNavigation";

export default function ScenarioAlertsButton({ scenario }) {
  const navigate = useNavigation();

  const strings = useLocalizedStrings();

  const handleClick = (e) => {
    e.stopPropagation();
    navigate(`/scenarios/${scenario.id}/alerts`);
  };

  return (
    <HtmlTooltip title={strings.sidebar_tooltip_alerts}>
      <IconButton size="small" onClick={(e) => handleClick(e)}>
        <NotificationsNoneOutlinedIcon style={{ fontSize: "1.3rem" }} />
      </IconButton>
    </HtmlTooltip>
  );
}
