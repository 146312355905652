import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { CircularProgress } from "@mui/material";

import { useSessionDownloadActionsMutation } from "api/sessions";

import { UiSecondaryButton } from "components/ui/StyledButtons";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const SessionDownloadAsCSV = ({ scenario, session }) => {
  const strings = useLocalizedStrings();
  const { mutate, isPending } = useSessionDownloadActionsMutation({
    scenario,
    session,
  });

  return (
    <UiSecondaryButton
      startIcon={
        isPending ? <CircularProgress size={18} /> : <CloudDownloadIcon />
      }
      size="small"
      disabled={isPending}
      onClick={() => mutate()}
    >
      {strings.button_download_as_csv}
    </UiSecondaryButton>
  );
};

export default SessionDownloadAsCSV;
