import { useEffect, useMemo, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  MenuItem,
  MenuList,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";

import CollectorsAPI, {
  useDataAnalysisColumnsAvailableQuery,
  useDataAnalysisColumnsInfiniteQuery,
} from "api/collectors";
import { useDataAnalysisColumnJobMutation } from "api/scheduler";

import FiltersSection from "components/scenarios/FiltersSection";
import FreeSearchStrip from "components/scenarios/FreeSearchStrip";
import ButtonPopover from "components/ui/ButtonPopover";
import DataTableINF from "components/ui/DataTableINF";
import HtmlTooltip from "components/ui/HtmlTooltip";
import InformationStripItem from "components/ui/InformationStripItem";
import RemoteDownloader from "components/ui/RemoteDownloader";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import FilterOperators from "utils/FilterOperators";
import { inUtc, timeFormatter } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useStateDeep } from "hooks/deepcomp";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import useScenarioJobState from "hooks/useScenarioJobState";

import { DataAnalysisDialog } from "./DataAnalysisDialog";

const operators = FilterOperators;

const filtersSchema = (strings) =>
  Object.entries({
    name: {
      label: strings.collectors_dataExploration_tab_filters_name,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    unique: {
      label: strings.collectors_dataExploration_tab_filters_unique,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },

    appearances: {
      label: strings.collectors_dataExploration_tab_filters_appearances,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    entropy: {
      label: strings.collectors_dataExploration_tab_filters_entropy,
      type: "number",
      inputProps: { step: "0.001" },
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    types: {
      label: strings.collectors_dataExploration_tab_filters_types,
      type: "select",
      default_value: "string",
      dropdown_options: [
        {
          id: "string",
          label: "String",
          value: "string",
        },
        {
          id: "integer",
          label: "Integer",
          value: "integer",
        },
        {
          id: "date",
          label: "Date",
          value: "date",
        },
        {
          id: "unknown",
          label: "Unknown",
          value: "unknown",
        },
      ],
      operators: [operators["contains"]],
    },
  });

const fields = (handleChange, isRunning, strings) => [
  {
    id: "checkbox",
    label: "",
    render: (v) => {
      return (
        <Checkbox
          color="primary"
          disabled={isRunning}
          onChange={(e) => handleChange(e, v.id)}
        />
      );
    },
    style: {
      textAlign: "center",
      width: "4em",
    },
    hidden: true,
  },
  {
    id: "name",
    label: strings.collectors_dataExploration_tab_column_field_name,
    sort: "name",
    render: (v) => (
      <HtmlTooltip title={v.name}>
        <Typography className="nameCell">{v.name}</Typography>
      </HtmlTooltip>
    ),
    style: {
      width: "9em",
      overflowWrap: "break-word",
    },
  },
  {
    id: "unique",
    label: strings.collectors_dataExploration_tab_column_unique_values,
    sort: "unique",
    render: (v) => (
      <Typography className="nameCell">{v.unique_display}</Typography>
    ),
    style: {
      width: "4em",
      overflowWrap: "break-word",
    },
  },
  {
    id: "types",
    label: strings.collectors_dataExploration_tab_column_types,
    render: (v) => <Typography>{v.types.join(", ")}</Typography>,
    style: {
      width: "3em",
    },
  },
  {
    id: "appearances",
    label: strings.collectors_dataExploration_tab_column_appearances,
    sort: "appearances",
    render: (v) => <Typography>{`${v.appearances}%`}</Typography>,
    style: {
      width: "5em",
    },
  },
  {
    id: "entropy",
    label: strings.collectors_dataExploration_tab_column_entropy,
    render: (v) => <Typography variant="body1">{v.entropy}</Typography>,
    style: {
      width: "4em",
    },
  },
  {
    id: "case_num_variance",
    label: strings.collectors_dataExploration_tab_column_case_num_variance,
    render: (v) => <Typography>{`${v.case_num_variance}`}</Typography>,
    style: {
      width: "4em",
    },
  },
  {
    id: "top_values",
    label: strings.collectors_dataExploration_tab_column_top_value,
    render: (v) => (
      <HtmlTooltip title={v?.top_values[0].value}>
        <Typography className="topValue">{v?.top_values[0].value}</Typography>
      </HtmlTooltip>
    ),
    style: {
      width: "5em",
    },
  },
  {
    id: "",
    render: (_, expanded, setExpanded) => (
      <RowExpandCollapseButton open={expanded} setOpen={setExpanded} />
    ),
    style: {
      width: "3em",
    },
  },
];
const RowExpandCollapseButton = (props) => {
  const { open, setOpen } = props;
  const strings = useLocalizedStrings();

  return (
    <IconButton
      className={`expandButton +  ${open ? "rotate180" : ""}`}
      title={strings.account_settings_system_log_table_details_title}
      size="small"
      onClick={() => setOpen(!open)}
    >
      <ExpandMoreIcon />
    </IconButton>
  );
};

const StyledDataAnalysisTab = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  height: "Calc(100vh - 200px)",
  justifyContent: "center",
  width: "100%",

  "& .noData": {
    textAlign: "center",
    whiteSpace: "pre-wrap",
    width: "40em",
  },
}));

const StyledDataAnalysisTable = styled("div")(() => ({
  display: "grid",
  gridTemplateRows: "min-content 1fr",
  height: "calc(100vh - var(--appbar-height) - 100px)",

  "& .dataExplorationToolbar": {
    alignItems: "center",
    display: "flex",
    height: "40px",
  },
  "& .dataExplorationSearchWrapper": {
    display: "flex",
    flexGrow: 1,
    "& .text-search": {
      height: "30px",
    },
  },
  "& .dataExplorationInfoStripWrapper": {
    alignItems: "center",
    display: "flex",
    "& .MuiButtonBase-root": {
      height: "30px !important",
    },
  },
  "& .dataExplorationInfoStrip": {
    borderRight: "1px solid #ddd",
    display: "flex",
  },
  "& .detailsWrapper": {
    display: "flex",
    alignItems: "center",
  },
  "& .expandButton": {
    color: "inherit",
    transform: "rotate(0)",
    transition: "transform 200ms ease",
    "&.rotate180": {
      transform: "rotate(180deg)",
    },
  },
  "& .expandableRow": {
    "& .MuiTableCell-root": {
      "& .MuiTypography-root": {
        transition: "font-size 200ms ease",
      },
    },
  },
  "& .rowExpantion": {
    "& > .MuiTableCell-root": {},
    "&:not(.visible) > .MuiTableCell-root": {
      border: "none",
    },
  },
  "& .systemLogDetails": {
    display: "flex",
    justifyContent: "center",
    padding: 10,
    "& pre": {
      fontFamily: "monospace",
      whiteSpace: "pre",
    },
  },
  "& .topValuesTable": {
    position: "relative !important",
  },

  "& .infobar": {
    fontWeight: "bold",
  },
  "& .topValue": {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
}));

const DataAnalysisRow = ({ columns, item, innerRef, expand }) => {
  const [expanded, setExpanded] = useState(expand);
  return (
    <>
      <TableRow
        className={`${expanded ? " expanded" : ""}`}
        hover
        key={item.id}
        ref={innerRef}
      >
        {columns
          .filter((c) => c.visible !== false)
          .map((c) => {
            return (
              <TableCell
                align={c.align}
                key={`${item.id}/${c.id}`}
                style={c.style}
              >
                {c.render(item, expanded, setExpanded)}
              </TableCell>
            );
          })}
      </TableRow>

      <TableRow
        className={`rowExpantion + ${expanded ? " visible" : ""}`}
        key={`${item.id}/top_values`}
      >
        <TableCell colSpan={columns.length} style={{ padding: "0.5px" }}>
          <Collapse in={expanded} unmountOnExit>
            <Box mb={1}>
              <div className="systemLogDetails" key={item.id}>
                <Table className="topValuesTable">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "1em" }}>
                        <div>#</div>
                      </TableCell>
                      <TableCell style={{ width: "2em" }}>
                        <div>Appearances</div>
                      </TableCell>
                      <TableCell style={{ width: "8em" }}>
                        <div>Value</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.top_values.map(({ value, appearances }, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{appearances}</td>
                        <td style={{ wordWrap: "break-word" }}>{value}</td>
                      </tr>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const DataAnalysisTable = ({ collector, run }) => {
  const strings = useLocalizedStrings();
  const [filters, setFilters] = useCurrentUserSettings(
    "dataAnalysis.filters",
    {}
  );
  const [orderBy, setOrderBy] = useCurrentUserSettings(
    "dataAnalysis.orderBy",
    {}
  );
  const [textSearchPattern, setTextSearchPattern] = useState(
    filters?.["name"]?.operand || ""
  );
  const [openFilters, setOpenFilters] = useStateDeep(false);
  const [columnIds, setColumnIds] = useState([]);
  const [explorationDialogOpen, setExplorationDialogOpen] = useState(false);
  const { isRunning } = useScenarioJobState(collector?.id);

  const { data: columnsAvailable } = useDataAnalysisColumnsAvailableQuery({
    collectorId: collector?.id,
    runId: run?.id,
  });
  const { mutate: runColumnJob } = useDataAnalysisColumnJobMutation();
  const { data, isPending, containerRef, cancel } =
    useDataAnalysisColumnsInfiniteQuery({
      id: collector?.id,
      runId: run?.id,
      orderBy,
      filters,
    });

  const noRows = data?.rows?.length === 0;

  const handleResetFilters = () => {
    cancel();
    setFilters({});
    setTextSearchPattern("");
  };

  const handleChangeFilters = (f) => {
    cancel();
    setFilters((prev) => {
      if (prev?.["name"]?.operand && !f?.["name"]?.operand) {
        setTextSearchPattern("");
      }
      return f;
    });
  };

  const handleChange = (e, columnID) => {
    const { checked } = e.target;
    if (checked) {
      setColumnIds((prev) => [...prev, columnID]);
    } else {
      setColumnIds((prev) => {
        return prev.filter((id) => id !== columnID);
      });
    }
  };

  // apply text-search changes into current filters
  useEffect(() => {
    if (textSearchPattern) {
      setFilters((prev) => {
        const f = {
          ...prev,
          name: {
            operator: "contains",
            operand: textSearchPattern,
          },
        };
        return f;
      });
    } else {
      setFilters((prev) => {
        const o = { ...prev };
        if (o["name"]) {
          delete o["name"];
          return o;
        }
        return prev;
      });
    }
  }, [setFilters, textSearchPattern]);

  const handleNextSortDirection = (id) => {
    cancel();
    setOrderBy((prev) => {
      let d = (prev?.[id] || 0) + 1;
      if (d > 1) {
        d = -1;
      }
      return d ? { [id]: d } : {};
    });
  };

  const downloadTableCsvUrl = useMemo(() => {
    const limit = 10_000;
    const fetchUrl = CollectorsAPI.getCollectorDataAnalysisColumns(
      collector?.id,
      run?.id,
      0,
      limit,
      filters,
      orderBy
    );
    fetchUrl.url += "&format=csv";

    return fetchUrl.url;
  }, [collector?.id, run?.id, filters, orderBy]);

  const downloadColumnsCsvUrl = useMemo(() => {
    const limit = 10_000;
    let { url } = CollectorsAPI.getCollectorDataAnalysisColumnValues(
      collector?.id,
      run?.id,
      0,
      limit,
      {},
      {}
    );
    url += "&format=csv";
    return url;
  }, [collector?.id, run?.id]);

  const handleExplore = (type, applyFilter) => {
    runColumnJob(
      {
        collectorId: collector?.id,
        columnIds,
        explorationMode: type,
        filtering: applyFilter,
      },
      {
        onSuccess: () => setColumnIds([]),
      }
    );
  };

  return (
    <>
      <StyledDataAnalysisTable>
        <div className="dataExplorationToolbar">
          <UiPrimaryButton
            disabled={isRunning || columnIds.length === 0}
            onClick={() => setExplorationDialogOpen(true)}
            style={{
              height: "30px",
              marginLeft: "5px",
            }}
          >
            {strings.collectors_columns_tab_explore_button}
          </UiPrimaryButton>
          <div className="dataExplorationSearchWrapper">
            <FreeSearchStrip
              filters={filters}
              schema={filtersSchema(strings)}
              setFilters={handleChangeFilters}
              openFilters={openFilters}
              setOpenFilters={setOpenFilters}
              searchPattern={textSearchPattern}
              setSearchPattern={setTextSearchPattern}
            />
          </div>
          <div className="dataExplorationInfoStripWrapper">
            <ButtonPopover>
              <MenuList>
                <MenuItem>
                  <RemoteDownloader
                    disabled={noRows}
                    filename={`Data_Exploration_${collector?.id}_${inUtc(
                      new Date(run.created)
                    ).format("YYYY-MM-DD")}.csv`}
                    url={downloadTableCsvUrl}
                  >
                    <a disabled={noRows}>
                      {
                        strings.collectors_dataExploration_tab_button_fields_table_download_csv
                      }
                    </a>
                  </RemoteDownloader>
                </MenuItem>
                <MenuItem>
                  <RemoteDownloader
                    disabled={!columnsAvailable}
                    filename={`Data_Exploration_Fields_${collector?.id}${inUtc(
                      new Date(run.created)
                    ).format("YYYY-MM-DD_HH-mm-ss")}.csv`}
                    url={downloadColumnsCsvUrl}
                    text={
                      strings.collectors_dataExploration_tab_button_values_analysis_download_csv
                    }
                  >
                    <a disabled={!columnsAvailable}>
                      {
                        strings.collectors_dataExploration_tab_button_values_analysis_download_csv
                      }
                    </a>
                  </RemoteDownloader>
                </MenuItem>
              </MenuList>
            </ButtonPopover>

            <div className="dataExplorationInfoStrip">
              <InformationStripItem
                label={strings.collectors_dataExploration_tab_infobar_created}
                style={{
                  background: "inherit",
                  borderBottom: "none",
                  minWidth: "10rem",
                }}
              >
                {timeFormatter(new Date(run.created))}
              </InformationStripItem>
              <InformationStripItem
                label={strings.collectors_dataExploration_tab_infobar_files}
                style={{ background: "inherit", minWidth: "4rem" }}
              >
                {run.files.length}
              </InformationStripItem>
              <InformationStripItem
                label={strings.collectors_dataExploration_tab_infobar_records}
                style={{ background: "inherit", minWidth: "4rem" }}
              >
                {run.records}
              </InformationStripItem>
            </div>
          </div>

          <IconButton
            onClick={handleResetFilters}
            size="small"
            style={{ margin: "0 10px" }}
            title="Reset filters"
          >
            <ClearIcon />
          </IconButton>
        </div>
        <DataTableINF
          RowComponent={DataAnalysisRow}
          ref={containerRef}
          scenario={collector}
          columns={fields(handleChange, isRunning, strings)}
          items={data?.rows || []}
          itemsCount={data?.count}
          perPageCount={true}
          footerDetails={`, ${columnIds?.length ?? 0} selected`}
          orderBy={orderBy}
          onOrderBy={handleNextSortDirection}
          isPending={isPending}
          hover
        />
        <FiltersSection
          filters={filters}
          schema={filtersSchema(strings)}
          setFilters={setFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          top={35}
        />
      </StyledDataAnalysisTable>
      <DataAnalysisDialog
        collector={collector}
        onClose={() => setExplorationDialogOpen(false)}
        onExplore={handleExplore}
        open={explorationDialogOpen}
        key={explorationDialogOpen}
      />
    </>
  );
};

const DataAnalysisTab = ({ collector, run }) => {
  const strings = useLocalizedStrings();
  const { isDataExplorationRunning } = useScenarioJobState(collector?.id);

  if (isDataExplorationRunning && !run?.id) {
    return (
      <StyledDataAnalysisTab>
        <Typography variant="h6" className="noData">
          {strings.collectors_datatab_data_exploration_in_progress_message}
        </Typography>
      </StyledDataAnalysisTab>
    );
  }
  if (!run?.id) {
    return (
      <StyledDataAnalysisTab>
        <Typography variant="h6" className="noData">
          {strings.collectors_datatab_no_result_message}
        </Typography>
      </StyledDataAnalysisTab>
    );
  }

  return <DataAnalysisTable collector={collector} run={run} />;
};

export default DataAnalysisTab;
