import { Typography } from "@mui/material";
import { startCase } from "lodash-es";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { useFieldInsightData } from "./FieldInsightData";
import { formatPeriod } from "./relevance";

export const SessionPropertiesTooltip = ({
  fieldName,
  fromDate,
  isPendingTagTotals,
  percent,
  toDate,
  totalSessions,
  value,
  foundInSessions,
  withoutFirstRow = false,
}) => {
  const strings = useLocalizedStrings();
  const fieldInsightData = useFieldInsightData();
  const type = "sessions_found";
  const field = fieldInsightData?.[fieldName]?.[type]?.[value]?.[0];
  const significanceValue = field?.significance?.value;
  const inSessions =
    foundInSessions || field?.valueData?.found_in_sessions || 0;

  const t1 = `${value} (${(percent * 100).toFixed(1)}%)`;
  const t2 = isPendingTagTotals
    ? strings.text_loading2
    : strings.formatString(
        strings.sessiondetails_tags_value_tooltip,
        inSessions,
        totalSessions,
        formatPeriod(fromDate, toDate, strings)
      );

  const title = withoutFirstRow ? t2 : `${t1}\n${t2}`;

  return (
    <>
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: 1,
          right: 5,
        }}
      >
        {startCase(significanceValue)}
      </Typography>
      <pre>{title}</pre>
    </>
  );
};
