import { useEffect, useRef, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@mui/material";

import LogUsersAPI, { useLogUsersInfiniteQuery } from "api/logusers";
import { useScenarioParameterKeyQuery } from "api/scenarioparams";

import BarChart from "components/ui/BarChart";
import DataLink from "components/ui/DataLink";
import DataTableINF from "components/ui/DataTableINF";
import FiltersShowHideButton from "components/ui/FiltersShowHideButton";
import Flexbox from "components/ui/Flexbox";
import RiskIndicator from "components/ui/RiskIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";
import TextSearchInput from "components/ui/TextSearchInput";
import { UserIdDisplay } from "components/ui/UserIdDisplay";
import { UserTypeIcon } from "components/ui/UserTypeIcon";

import FilterOperators from "utils/FilterOperators";
import { parseRiskColor } from "utils/parseRiskColor";
import { formatIntervalObject, timeFormatter } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useReportedFetch } from "hooks/http";
import useCurrentUrlState from "hooks/useCurrentUrlState";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useNavigation } from "hooks/useNavigation";

import FilterChips from "./FiltersChips";
import FiltersSection from "./FiltersSection";

const operators = FilterOperators;

const filtersSchema = (strings) =>
  Object.entries({
    total_sessions: {
      label: strings.userstable_filters_total_sessions,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    earliest: {
      label: strings.userstable_filters_earliest,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    latest: {
      label: strings.userstable_filters_latest,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    orig_app_user_id: {
      label: strings.userstable_filters_user,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    search_user: {
      label: strings.userstable_filters_user,
      type: "text",
      visible: false,
      operators: [operators["text-search"]],
    },
    max_risk: {
      label: strings.userstable_filters_max_risk,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    num_profiles: {
      label: strings.profiles,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    num_risky_sessions: {
      label: strings.userstable_header_risky_sessions,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    avg_sensitivity: {
      label: "Average sensitivity",
      type: "number",
      operators: [
        //reversed operators because sensitivity is negative on backend
        { id: "lt", label: ">", op: "lt" },
        { id: "le", label: ">=", op: "le" },
        { id: "gt", label: "<", op: "gt" },
        { id: "ge", label: "<=", op: "ge" },
        operators["eq"],
        operators["ne"],
      ],
    },
    num_unclustered_sessions: {
      label: strings.userstable_header_unprofiled_sessions,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
  });

const fields = ({ strings, scenario, minRisk }) => [
  {
    id: "actions",
    label: null,
    immutable: true,
    render: (v, expanded, setExpanded) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <RowExpandCollapseButton
          user={v}
          open={expanded}
          setOpen={setExpanded}
          title={strings.formatString(
            strings.userstable_action_tooltip_expand,
            v.orig_app_user_id
          )}
        />
        <UserTypeIcon type={v.sensitivity_type} />
      </div>
    ),
    style: {
      textAlign: "left",
      width: "3em",
    },
  },
  {
    id: "orig_app_user_id",
    label: strings.userstable_header_user,
    sort: "orig_app_user_id",
    render: (v) => <UserIdDisplay userId={v.orig_app_user_id} />,
    style: {
      width: "20em",
      whiteSpace: "nowrap",
    },
  },
  {
    id: "total_profiles",
    label: strings.profiles,
    sort: "num_profiles",
    render: (v) => {
      if (!v.num_profiles) {
        return v.num_profiles;
      }
      return (
        <DataLink
          to={`/scenarios/${scenario}/profiles?uid=${encodeURIComponent(
            v.orig_app_user_id
          )}`}
        >
          {v.num_profiles}
        </DataLink>
      );
    },
    style: {
      width: "100px",
    },
  },
  {
    id: "total_sessions",
    label: strings.userstable_header_num_sessions,
    sort: "total_sessions",
    render: (v) => {
      const label = v.total_sessions < 0 ? "N/A" : v.total_sessions;

      if (v.total_sessions < 1) {
        return label;
      }

      const link = `/scenarios/${scenario}/sessions?uid=${encodeURIComponent(
        v.orig_app_user_id
      )}`;

      return <DataLink to={link}>{label}</DataLink>;
    },
    style: {
      width: "7em",
    },
  },
  {
    id: "earliest",
    label: strings.userstable_header_earliest,
    sort: "earliest",
    render: (v) => (
      <Typography variant="body1">
        {timeFormatter(new Date(v.earliest))}
      </Typography>
    ),
    style: {
      width: "8em",
    },
  },
  {
    id: "latest",
    label: strings.userstable_header_latest,
    sort: "latest",
    render: (v) => (
      <Typography variant="body1">
        {timeFormatter(new Date(v.latest))}
      </Typography>
    ),
    style: {
      width: "8em",
    },
  },
  {
    id: "max_risk",
    label: strings.userstable_header_max_risk,
    sort: "max_risk",
    render: (v) => <RiskIndicator value={+v.max_risk * 100} />,
    style: {
      width: "7em",
    },
  },
  {
    id: "num_risky_sessions",
    label: strings.userstable_header_risky_sessions,
    sort: "num_risky_sessions",
    render: (v) => {
      const label = v.num_risky_sessions < 0 ? "N/A" : v.num_risky_sessions;
      if (v.num_risky_sessions < 1) {
        return label;
      }
      const link = `/scenarios/${scenario}/sessions?uid=${encodeURIComponent(
        v.orig_app_user_id
      )}&whole_risk=${minRisk}`;
      return <DataLink to={link}>{label}</DataLink>;
    },
    style: {
      width: "7em",
    },
  },
  {
    id: "avg_sensitivity",
    label: strings.userstable_header_average_sensitivity,
    sort: "avg_sensitivity",
    reverse_sort_indicator: true,
    render: (v) => <SensitivityIndicator value={Math.abs(v.avg_sensitivity)} />,
    style: {
      width: "7em",
    },
  },
  {
    id: "num_unclustered_sessions",
    label: strings.userstable_header_unprofiled_sessions,
    sort: "num_unclustered_sessions",
    render: (v) => {
      const label =
        v.num_unclustered_sessions < 0 ? "N/A" : v.num_unclustered_sessions;

      if (v.num_unclustered_sessions < 1) {
        return label;
      }

      const link = `/scenarios/${scenario}/sessions?uid=${encodeURIComponent(
        v.orig_app_user_id
      )}&cluster=-1`;

      return <DataLink to={link}>{label}</DataLink>;
    },
    style: {
      width: "7em",
    },
  },
];

function RowExpandCollapseButton({ user, open, setOpen }) {
  return (
    <IconButton
      style={{
        transform: open ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 200ms ease",
        color: "inherit",
      }}
      title={"See details of " + user.orig_app_user_id}
      size="small"
      onClick={() => setOpen(!open)}
    >
      <ExpandMoreIcon />
    </IconButton>
  );
}

function UserDailyActivitiesChart({ user, scenario }) {
  const navigate = useNavigation();
  const [data, , isPending] = useReportedFetch(
    LogUsersAPI.getUserDailyActivities(scenario, user).url,
    [scenario, user]
  );
  const [translated, setTranslated] = useState([]);

  const makeStory = (e) =>
    [
      new Date(e.date).toDateString(),
      `Sessions: ${e.total_sessions}`,
      `Max.Risk: ${
        e.max_risk >= 0 ? `${(e.max_risk * 100).toFixed(1)}%` : "--"
      }`,
      `Total Time: ${formatIntervalObject(e.total_time)}`,
    ].join("\n");

  useEffect(() => {
    if (isPending) {
      return;
    }
    if (!data || !data.length) {
      setTranslated([]);
      return;
    }
    setTranslated(
      data.map((e) => ({
        x: new Date(e.date),
        label: new Date(e.date).toDateString(),
        ylabel: formatIntervalObject(e.total_time),
        story: makeStory(e),
        y: e.total_sessions,
        z: e.max_risk,
        color: (day) => parseRiskColor(Math.max(day.z * 100, 0)),
      }))
    );
  }, [data, isPending]);

  if (isPending) {
    return null;
  }

  return (
    <BarChart
      items={translated}
      isBarVisible={() => true}
      onClick={(_ev, elem) => {
        const fromTime = elem.x;
        const toTime = new Date(elem.x.getTime() + 24 * 60 * 60 * 1000);
        const uid = user;
        navigate(
          `/scenarios/${scenario}/sessions?uid=${encodeURIComponent(
            uid
          )}&from=${encodeURIComponent(
            fromTime.toISOString()
          )}&to=${encodeURIComponent(toTime.toISOString())}`
        );
      }}
      showStory
    />
  );
}

function UsersRow(props) {
  const strings = useLocalizedStrings();
  const { columns, item, innerRef, expand, scenario } = props;
  const [expanded, setExpanded] = useState(expand);

  useEffect(() => {
    setExpanded(expand);
  }, [expand]);

  return (
    <>
      <TableRow ref={innerRef} hover>
        {columns
          .filter((c) => c.visible !== false)
          .map((c) => (
            <TableCell
              key={`${item.id}/${c.id}`}
              align={c.align}
              style={c.style}
            >
              {c.render(item, expanded, setExpanded)}
            </TableCell>
          ))}
      </TableRow>

      <TableRow key={`${item.id}/details`}>
        <TableCell
          colSpan={columns.length}
          style={{ padding: 0.5, border: !expanded ? "none" : "" }}
        >
          <Collapse in={expanded} unmountOnExit>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                padding: "10px",
                height: "45vh",
                overflowX: "auto",
                overflowY: "hidden",
              }}
            >
              <Flexbox mb={1} width={1}>
                <h4>{strings.userstable_chart_title}</h4>
              </Flexbox>
              <UserDailyActivitiesChart
                user={item.orig_app_user_id}
                scenario={scenario}
              />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const StyldUsersView = styled("div")`
  position: relative;
  height: calc(100vh - var(--appbar-height));
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 1fr 30px;
  .users-toolbar {
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 0.4em;
  }
  table {
    tbody td {
      padding: 10px;
    }
  }
`;

function Users(props) {
  const pageLimit = 40;
  const strings = useLocalizedStrings();
  const { scenario } = props;
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useCurrentUserSettings("users.filters", {});
  const [orderBy, setOrderBy] = useCurrentUserSettings("users.orderby", {});
  const [textSearchPattern, setTextSearchPattern] = useState();
  const search = useRef(null);
  const expand = useRef(false);

  const { removeQueryString } = useCurrentUrlState();

  const { data, containerRef, isPending, cancel } = useLogUsersInfiniteQuery({
    scenarioId: scenario,
    filters,
    orderBy,
    pageLimit,
  });

  const { data: minRisk } = useScenarioParameterKeyQuery(
    {
      scenarioId: scenario,
      paramKey: "com/trackeriq/analysis/risk-floor",
    },
    {
      enabled: !!scenario,
    }
  );

  //  build filters from query-string if exists
  useEffect(() => {
    const s = removeQueryString();
    search.current = s;
    if (!s) {
      return;
    }
    const f = {};
    if (s["uid"]) {
      f["orig_app_user_id"] = { operator: "eq", operand: s["uid"] };
      expand.current = true;
    }
    setFilters(f);
  }, [removeQueryString, setFilters]);

  const handleNextSortDirection = (id) => {
    cancel();
    setOrderBy((prev) => {
      let d = prev[id] || 0;
      d++;
      if (d > 1) {
        d = -1;
      }
      return d ? { [id]: d } : {};
    });
  };

  const handleResetFilters = () => {
    cancel();
    expand.current = false;
    setFilters({});
    setTextSearchPattern("");
  };

  const handleFilterChipsChange = (f) => {
    cancel();
    setFilters(f);
    expand.current = false;
  };

  // update filters upon changes in the text-search field
  useEffect(() => {
    if (textSearchPattern === undefined) {
    } else if (textSearchPattern === "") {
      setFilters((prev) => {
        const f = { ...prev };
        delete f.search_user;
        return f;
      });
    } else {
      setFilters({
        search_user: {
          operator: "text-search",
          operand: textSearchPattern,
        },
      });
    }
  }, [setFilters, textSearchPattern]);

  useEffect(() => {
    if (!filters?.["search_user"] || !filters?.["search_user"].operand) {
      setTextSearchPattern(undefined);
    }
  }, [filters]);

  return (
    <StyldUsersView>
      <div className="users-toolbar">
        <FiltersShowHideButton setOpenFilters={setShowFilters} />
        <FilterChips
          filters={filters}
          schema={filtersSchema(strings)}
          setFilters={handleFilterChipsChange}
        />
        <TextSearchInput
          searchPattern={textSearchPattern}
          setSearchPattern={setTextSearchPattern}
        />
        <IconButton size="small" onClick={handleResetFilters}>
          <ClearIcon />
        </IconButton>
      </div>

      <DataTableINF
        ref={containerRef}
        scenario={scenario}
        columns={fields({
          strings,
          scenario,
          minRisk,
        })}
        items={data?.rows}
        itemsCount={data?.count}
        showTotal={true}
        orderBy={orderBy}
        onOrderBy={handleNextSortDirection}
        RowComponent={UsersRow}
        isPending={isPending}
        hover
        expand={expand.current}
      />

      <FiltersSection
        filters={filters}
        schema={filtersSchema(strings)}
        setFilters={setFilters}
        openFilters={showFilters}
        setOpenFilters={setShowFilters}
      />
    </StyldUsersView>
  );
}

export default Users;
