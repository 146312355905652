export function makeUrlState(account, url) {
  if (!account && !url) {
    return;
  }

  const state = {
    a: account || undefined,
    u: url || undefined,
  };
  const encoded = encodeURIComponent(JSON.stringify(state));
  return encoded;
}
