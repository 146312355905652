import { styled } from "@mui/material";

const StyledSectionWrap = styled("div")(({ theme }) => ({
  alignItems: "center",
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "5px",
  display: "flex",
  padding: "10px 20px",
  position: "relative",
  width: "100%",

  "&.errorSection,&.customError": {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

export const SectionWrap = ({ children, ...props }) => {
  return <StyledSectionWrap {...props}>{children}</StyledSectionWrap>;
};
