import { FormControlLabel } from "@mui/material";
import { styled } from "@mui/material";
import Switch from "@mui/material/Switch";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 28,
  padding: theme.spacing(0.5),

  ".MuiSwitch-switchBase": {
    padding: 5,
  },
  ".MuiSwitch-thumb": {
    width: 17,
    height: 17,
    backgroundColor: "#fff",
  },
  ".MuiSwitch-track": {
    background: "#888",
    opacity: "1 !important",
    borderRadius: 20,
    position: "relative",
    "&::before, &::after": {
      display: "inline-block",
      position: "absolute",
      fontWeight: "bold",
      top: "50%",
      width: "50%",
      transform: "translateY(-50%)",
      color: "#fff",
      textAlign: "center",
    },
  },
  ".Mui-checked": {
    "&.MuiSwitch-switchBase": {
      color: "#185a9d",
      transform: "translateX(32px)",
      "&:hover": {
        backgroundColor: "rgba(24,90,157,0.08)",
      },
    },
    "& + .MuiSwitch-track": {
      background: "#52d869 !important",
      "&::before": {
        opacity: 1,
      },
      "&::after": {
        opacity: 0,
      },
    },
  },
  ".Mui-disabled": {
    "& + .MuiSwitch-track": {
      background: "#DDD !important",
    },
  },
}));

const StyledSwitchComponent = ({ beforeLabel, afterLabel, ...props }) => (
  <StyledSwitch
    disableRipple
    sx={{
      "& .MuiSwitch-track": {
        "&::before": {
          content: `"${beforeLabel}"`,
          display: "inline-block",
          position: "absolute",
          fontWeight: "bold",
          top: "50%",
          width: "50%",
          transform: "translateY(-50%)",
          color: "#fff",
          textAlign: "center",
          left: 5,
          opacity: 0,
        },
        "&::after": {
          content: `"${afterLabel}"`,
          display: "inline-block",
          position: "absolute",
          fontWeight: "bold",
          top: "50%",
          width: "50%",
          transform: "translateY(-50%)",
          color: "#fff",
          textAlign: "center",
          right: 5,
        },
      },
    }}
    {...props}
  />
);

export const StdSwitch = ({
  beforeLabel,
  afterLabel,
  label,
  labelPlacement,
  ...props
}) => {
  if (label) {
    return (
      <FormControlLabel
        control={
          <StyledSwitchComponent
            beforeLabel={beforeLabel}
            afterLabel={afterLabel}
            {...props}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    );
  }
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
      }}
    >
      <StyledSwitchComponent
        beforeLabel={beforeLabel}
        afterLabel={afterLabel}
        {...props}
      />
    </div>
  );
};
