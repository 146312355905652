import { useCallback, useEffect, useRef } from "react";

import stdFetch from "utils/stdFetch";

export const queryFetch = stdFetch;

export const DEFAULT_STALE_TIME = 10 * 60 * 1000;

export const useInfiniteTableContainer = (
  data,
  fetchNextPage,
  selector = "tbody > tr"
) => {
  const bottom = useRef();
  const containerRef = useRef();

  const callbackFunction = useCallback(
    (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        fetchNextPage();
      }
    },
    [fetchNextPage]
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    const container = containerRef.current;
    const obs = new IntersectionObserver(callbackFunction, {
      root: null,
      margin: "15px",
    });

    if (container) {
      const l = container.querySelectorAll(selector);
      if (l.length) {
        bottom.current = l.item(l.length - 1);
        obs.observe(bottom.current);
      }
    }

    return () => obs.disconnect();
  }, [callbackFunction, data, selector]);

  return containerRef;
};

export const downloadResponse = ({ data, type, fileName }) => {
  const blob =
    type === "application/octet-stream" ? data : new Blob([data], { type });
  const objUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = objUrl;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(objUrl);
};
