import { useContext, useEffect, useMemo, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { useScenarioCompare } from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import { HelpIcon } from "components/ui/HelpIcon";
import { InformationPopover } from "components/ui/InformationPopover";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import FileInput from "components/ui/form/FileInput";
import { SelectorInput } from "components/ui/form/SelectorInput";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const Row = ({ label, style, children }) => (
  <div
    style={{
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-between",
      ...style,
    }}
  >
    <Typography>{`${label}:`}</Typography>
    {children}
  </div>
);

const HelperPopover = () => {
  const strings = useLocalizedStrings();
  const popupContent = useMemo(
    () => [
      {
        name: <p>{strings.scenario_comparison_tooltip_subtitle} </p>,
      },
      {
        name: (
          <p>
            {"\u2022"} {strings.scenario_comparison_tooltip_user_info}
          </p>
        ),
      },
      {
        name: (
          <p>
            {"\u2022"} {strings.scenario_comparison_tooltip_date_info}
          </p>
        ),
      },
      {
        name: <p>{strings.scenario_comparison_tooltip_footer}</p>,
      },
    ],
    [strings]
  );

  return (
    <InformationPopover
      title={strings.scenario_comparison_tooltip_title}
      content={popupContent}
    >
      <HelpIcon />
    </InformationPopover>
  );
};

const rowWidth = "350px";
export const ScenarioComparisonTool = ({ style }) => {
  const strings = useLocalizedStrings();
  const { selectedScenario, scenarios } = useContext(ScenariosContext);
  const methods = useForm({
    values: {
      scenarioY: "",
      scenarioX: selectedScenario?.id,
      userData: "",
    },
  });
  const { handleSubmit, reset } = methods;
  const [open, setOpen] = useState(false);

  const scenarioCompare = useScenarioCompare();

  const dropdownScenarios = useMemo(
    () => scenarios.filter((s) => s.id !== selectedScenario.id),
    [scenarios, selectedScenario]
  );

  const onSubmit = async (formData) => {
    await scenarioCompare.mutateAsync(formData);
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <div style={style}>
      <UiSecondaryButton
        style={{
          width: "15em",
          marginBottom: "5px",
          whiteSpace: "pre",
        }}
        onClick={() => setOpen(true)}
      >
        {strings.scenario_comparison_title}
      </UiSecondaryButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{strings.scenario_comparison_title}</DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              paddingRight: "30px",
            }}
          >
            <Row label={strings.scenario_comparison_first_label}>
              <Typography width={rowWidth}>{selectedScenario?.name}</Typography>
            </Row>
            <Divider>vs.</Divider>
            <FormProvider {...methods}>
              <Row label={strings.scenario_comparison_second_label}>
                <SelectorInput
                  name="scenarioY"
                  label={strings.scenario_comparison_select_label}
                  style={{ width: rowWidth }}
                  required={true}
                >
                  {dropdownScenarios.map((s) => {
                    return (
                      <MenuItem key={s.id} value={s.id}>
                        <span>{s.name}</span>
                      </MenuItem>
                    );
                  })}
                </SelectorInput>
              </Row>
              <Divider>For</Divider>
              <Row label={strings.scenario_comparison_upload_label}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "15px",
                  }}
                >
                  <HelperPopover />
                  <FileInput
                    accept="text/csv"
                    name="userData"
                    required={true}
                    style={{ width: rowWidth }}
                  />
                </div>
              </Row>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <UiSecondaryButton onClick={() => setOpen(false)}>
              {strings.button_cancel}
            </UiSecondaryButton>
            <UiPrimaryButton type="submit">
              {strings.button_submit}
            </UiPrimaryButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
