import React from "react";

import NotificationsIcon from "@mui/icons-material/Notifications";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";

import SessionsAPI from "api/sessions";

import DataLink from "components/ui/DataLink";
import Table from "components/ui/Table";

import { useRiskClassification } from "utils/riskClassification";
import { inUtc, timeFormatter } from "utils/time-fmt";

import { useReportedFetch } from "hooks/http";
import useBackendEvents from "hooks/useBackendEvents";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledRisk = styled("span")`
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const StyledActions = styled("div")`
  display: flex;
  align-items: center;
`;

function NamedRisk(props) {
  const riskClassifier = useRiskClassification();
  const { value } = props;
  const [riskClass, riskColor] = riskClassifier(value);
  return (
    <StyledRisk
      className={riskClass}
      color={riskColor}
      title={`${value.toFixed(0)}%`}
    />
  );
}

const columns = (strings) => [
  {
    id: "actions",
    style: {
      width: "3em",
      color: "#666",
    },
    render: (d) => (
      <StyledActions>
        <Link
          to={`/scenarios/${d.scenario}/sessions/${d.id}/session-timeline`}
          title={strings.dashboard_recent_alerts_action_details}
          size="small"
        >
          <VisibilityIcon />
        </Link>
        <Link
          to={`/scenarios/${d.scenario}/sessions/${d.id}/alert-details`}
          title={strings.dashboard_recent_alerts_action_alert}
          size="small"
        >
          <NotificationsIcon />
        </Link>
      </StyledActions>
    ),
  },
  {
    id: "user",
    label: strings.dashboard_recent_alerts_user,
    style: {
      width: "auto",
    },
    render: (d) => (
      <DataLink
        to={`/scenarios/${d.scenario}/users?uid=${encodeURIComponent(
          d.orig_app_user_id
        )}`}
      >
        {d.orig_app_user_id}
      </DataLink>
    ),
  },
  {
    id: "start",
    label: strings.alertstable_table_column_start,
    style: {
      width: "8em",
    },
    render: (d) => timeFormatter(d.start),
  },
  {
    id: "time",
    label: strings.dashboard_recent_alerts_issued,
    style: {
      width: "8em",
    },
    render: (d) =>
      d.time_alerted ? (
        <DataLink to={`sessions/${d.app_session_id}/alert-details`}>
          {timeFormatter(new Date(d.time_alerted))}
        </DataLink>
      ) : null,
  },
  {
    id: "whole_risk",
    label: strings.dashboard_recent_alerts_risk,
    style: {
      width: "5em",
      textAlign: "center",
    },
    render: (d) =>
      d.whole_risk >= 0 ? (
        <Link
          to={`/scenarios/${d.scenario}/sessions/${d.app_session_id}/session-anomaly`}
        >
          <NamedRisk value={d.whole_risk} />
        </Link>
      ) : null,
  },
];

const recentAlertsBackendEvents = ["jobs"];

export const RecentAlerts = ({ scenario, DownloadComponent, numDays }) => {
  const strings = useLocalizedStrings();
  const recentAlertsDate = inUtc()
    .subtract(numDays || 1, "day")
    .startOf("day")
    .toISOString();
  const [backendEvent] = useBackendEvents(recentAlertsBackendEvents, [
    scenario,
  ]);
  const [sessions, , loading] = useReportedFetch(
    SessionsAPI.getAlerts({
      filters: {
        start: { operator: "ge", operand: recentAlertsDate },
      },
      limit: 100,
      offset: 0,
      orderBy: {
        start: -1,
      },
      scenario,
    }).url,
    [scenario, backendEvent, numDays]
  );

  const processed = React.useMemo(() => {
    if (!sessions) {
      return null;
    }
    return sessions
      .map((d) => ({ ...d, id: d.app_session_id, scenario: scenario }))
      .slice(0, 20);
  }, [sessions, scenario]);

  if (loading) {
    return <DownloadComponent />;
  }

  return (
    <Table
      columns={columns(strings)}
      items={processed}
      nofooter
      dense
      style={{ height: "100%", fontSize: "inherit" }}
    />
  );
};
