import React, { forwardRef } from "react";

import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Checkbox } from "@mui/material";

const ActivationButton = forwardRef((props, ref) => {
  const { checked, onChange, colored = true, disabled, style, ...rest } = props;
  return (
    <Checkbox
      ref={ref}
      checked={checked}
      onChange={onChange}
      icon={<BlockIcon {...rest} />}
      checkedIcon={<CheckCircleOutlineIcon />}
      size="medium"
      style={{
        padding: "2px",
        color: disabled
          ? undefined
          : colored
          ? checked
            ? "MediumSeaGreen"
            : "IndianRed"
          : "inherit",
        ...style,
      }}
      disabled={disabled}
      {...rest}
    />
  );
});

export default ActivationButton;
