import { useContext, useMemo, useState } from "react";

import { BarChart } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import { AppBar, Chip, Fade, Menu, MenuItem, styled } from "@mui/material";
import { ROLES } from "@tiq/shared/lib/constants";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { LoginStatusContext } from "contexts/LoginStatusContext";

import Flexbox from "components/ui/Flexbox";
import Logo from "components/ui/Logo";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMaintenanceMode } from "hooks/useMaintenanceMode";
import { useRoles } from "hooks/useRoles";

const StyledAppBar = styled(AppBar)(({ theme }) => {
  return {
    alignItems: "center",
    background: `linear-gradient(90deg, ${theme.palette.secondary.purple} 33%, black)`,
    color: theme.palette.primary.contrastText,
    display: "flex",
    flexDirection: "row",
    height: "var(--appbar-height)",
    justifyContent: "space-between",
    padding: "0px 10px",

    "& .actions": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "auto",
      gap: "1em",
      paddingBottom: "2px",
      "& .active": {
        boxShadow: `0 4px 0px -2px ${theme.palette.accent.orange}`,
      },
    },
    "& .maintenanceBannerWrapper": {
      left: "50%",
      marginLeft: "-300px",
      marginTop: "10px",
      position: "absolute",
      right: "0",
      top: 0,
      width: "600px",
      pointerEvents: "none",
    },
    "& .maintenanceBanner": {
      alignItems: "center",
      background: theme.palette.accent.blue,
      borderRadius: "6px",
      color: theme.palette.primary.dark,
      display: "flex",
      height: "30px",
      padding: "10px",
      pointerEvents: "none",
      width: "fit-content",
      zIndex: "2000",

      "&.visible": {
        opacity: "1",
        transition: "opacity 0.4s linear",
        visibility: "visible",
      },

      "&.hidden": {
        opacity: "0",
        transition: "visibility 0s 0.4s, opacity 0.4s linear",
        visibility: "hidden",
      },
    },
  };
});

function Navbar() {
  const strings = useLocalizedStrings();
  const location = useLocation();
  const { user, fullLabel, logout } = useContext(LoginStatusContext);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const [messageHidden, setMessageHidden] = useState(false);
  const { maintenanceMode } = useMaintenanceMode();
  const handleMouseEnter = () => {
    setMessageHidden(true);
  };

  const handleMouseLeave = () => {
    setMessageHidden(false);
  };
  const { hasRoles } = useRoles();

  const showScenarios = useMemo(
    () => [ROLES.VIEWER, ROLES.ANALYST, ROLES.ADMIN].some((r) => hasRoles([r])),
    [hasRoles]
  );

  const showSettings = useMemo(
    () => [ROLES.ACCOUNT_ADMIN, ROLES.ADMIN].some((r) => hasRoles([r])),
    [hasRoles]
  );

  return (
    <StyledAppBar
      elevation={0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Logo />
      {user && location.pathname !== "/" && (
        <div className="actions">
          {showScenarios && (
            <>
              <NavLink to="/scenarios">
                <HomeIcon />
              </NavLink>
              <NavLink to="/analytics">
                <BarChart />
              </NavLink>
            </>
          )}

          {showSettings && (
            <>
              <NavLink to="/settings">
                <SettingsIcon />
              </NavLink>
            </>
          )}
          <Chip
            label={fullLabel}
            icon={<AccountCircle color="primary.dark" />}
            onClick={(e) => setMenuAnchor(e.target)}
            sx={{
              backgroundColor: "primary.contrastText",
              color: "primary.dark",
              fontSize: "0.9em",
              height: "2em",
              "&:hover": {
                backgroundColor: "primary.contrastText",
              },
            }}
          />

          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <MenuItem style={{ pointerEvents: "all" }} onClick={logout}>
              <Flexbox>
                <ArrowBackIcon />
                <span style={{ marginLeft: "1em" }}>
                  {strings.navbar_logout}
                </span>
              </Flexbox>
            </MenuItem>
          </Menu>
        </div>
      )}
      {maintenanceMode === "true" && (
        <div className="maintenanceBannerWrapper">
          <div
            className={`maintenanceBanner ${
              messageHidden ? "hidden" : "visible"
            }`}
          >
            {strings.maintenance_mode}
          </div>
        </div>
      )}
    </StyledAppBar>
  );
}

export default Navbar;
