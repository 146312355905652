import { useCallback, useEffect, useState } from "react";

import useCustomEvent from "./useCustomEvent";

function useStorage(impl, key, defValue) {
  const [value, setValue] = useState(() => {
    if (!key) {
      return undefined;
    }
    try {
      const v = JSON.parse(impl.getItem(key));
      return v !== undefined && v !== null ? v : defValue;
    } catch (err) {
      return defValue;
    }
  });

  const load = useCallback(() => {
    if (!key) {
      setValue(undefined);
    }
    try {
      const v = JSON.parse(impl.getItem(key));
      setValue(v !== undefined && v !== null ? v : defValue);
    } catch (err) {
      setValue(defValue);
    }
  }, [impl, key, defValue]);

  const listener = useCallback(
    (e) => {
      if (e.detail.key === key || !e.detail.key) {
        load();
      }
    },
    [key, load]
  );
  const [raiseStorageChanged] = useCustomEvent("localstorage", listener);

  useEffect(() => {
    if (!key || !impl) {
      return;
    }
    if (value !== null && value !== undefined) {
      impl.setItem(key, JSON.stringify(value));
    } else {
      impl.removeItem(key);
    }
    raiseStorageChanged({ key, value });
  }, [key, impl, raiseStorageChanged, value]);

  const resetAll = useCallback(() => {
    impl.clear();
    raiseStorageChanged({});
  }, [impl, raiseStorageChanged]);

  const getEntries = useCallback(() => {
    return Object.entries(impl);
  }, [impl]);

  return [value, setValue, resetAll, getEntries];
}

export function useLocalStorage(key, defValue, dependencies) {
  return useStorage(localStorage, key, defValue, dependencies);
}

export function storageRemoveByPrefix(impl, prefix) {
  Array.from({ length: impl.length }, (_, i) => impl.key(i))
    .filter((k) => k.includes(prefix))
    .forEach((k) => impl.removeItem(k));
}
