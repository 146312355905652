import React from "react";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { styled } from "@mui/material";

import Flexbox from "./Flexbox";

const StyledFrameTitle = styled(Flexbox)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  flexGrow: 0,
  "& > *": {
    padding: 0,
  },
  "&.collapsible": {
    cursor: "pointer",
    "& .collapse-icon": {
      marginRight: ".5em",
      transition: "transform 150ms ease",
      "&:not(.collapsed)": {
        transform: "rotate(90deg)",
      },
    },
  },
}));

export default function FrameTitle(props) {
  const { children, onToggleCollapse, collapsed, collapsible, ...rest } = props;

  return (
    <StyledFrameTitle
      className={`${collapsible ? "collapsible" : ""}`}
      onClick={() => onToggleCollapse?.()}
      {...rest}
    >
      {collapsible && (
        <KeyboardArrowRightIcon
          className={`collapse-icon ${collapsed ? "collapsed" : ""}`}
        />
      )}
      {children}
    </StyledFrameTitle>
  );
}
