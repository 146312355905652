import { styled } from "@mui/material";

import SensitivityIndicator from "components/ui/SensitivityIndicator";

import ActionDescription from "./ActionDescription";

const BarChartTooltipStyled = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
  background-color: whitesmoke;
  border: 1px solid #888;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 15px 15px 24px -15px rgba(0, 0, 0, 0.75);
  margin: 10px;

  .tooltip-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
`;

export default function BarChartTooltip({ data }) {
  if (!data) {
    return null;
  }
  return (
    <BarChartTooltipStyled>
      <div className="tooltip-header">
        <SensitivityIndicator value={data.sensitivity} />
        <ActionDescription item={data} />
      </div>
      {/* <TagsView items={splitTags(data.action).map(d => ({key:d[0], value:d[1]}))}/> */}
      <div className="tooltip-footer">
        <hr />
        <p>
          <small>
            <sup>*</sup>
            Left-Click to select as filter
          </small>
        </p>
        <p>
          <small>Right-Click to toggle</small>
        </p>
      </div>
    </BarChartTooltipStyled>
  );
}
