import React, { useEffect, useState } from "react";

import { styled } from "@mui/material";

const StyledLocalDownloader = styled("a")(() => ({
  textDecoration: "none",
  color: "inherit",
  "&[disabled]": {
    opacity: 1,
  },
}));

function jsonArrayToCSV(j) {
  if (!j) {
    return null;
  }
  // collect column names
  const columnsDict = j.reduce((a, r) => {
    Object.keys(r).forEach((k) => {
      a[k] = 0;
    }, {});
    return a;
  }, {});
  const columns = Object.keys(columnsDict);
  return (
    // header
    // end of record
    columns.join(",") +
    "\n" +
    // for each row...
    j
      .map((r) => {
        return (
          columns
            // return values in the same order of the header, quoted
            .reduce((a, c) => [...a, r[c]], [])
            // wrap with quotes and escape double-quote marks
            .map((v) =>
              v !== undefined && v !== null
                ? `"${`${v}`.replace(/"/g, '""')}"`
                : ""
            )
            // convert to comma-delimited string
            .join(",")
        );
      })
      .join("\n")
  );
}

function LocalDownloader({ data, filename, mimetype, children, ...other }) {
  const [blob, setBlob] = useState();
  const [objectUrl, setObjectUrl] = useState();

  useEffect(() => {
    if (data) {
      let d = data;
      if (data instanceof Object) {
        if (!mimetype || mimetype === "text/plain") {
          d = [data];
        } else if (mimetype === "text/csv") {
          d = [jsonArrayToCSV(data)];
        } else if (mimetype === "application/json") {
          d = [JSON.stringify(d)];
        }
      } else {
        d = [data];
      }
      setBlob(new Blob(d, { type: mimetype || "text/plain" }));
    } else {
      setBlob();
    }
  }, [data, mimetype]);

  useEffect(() => {
    blob ? setObjectUrl(URL.createObjectURL(blob)) : setObjectUrl();
    return () => objectUrl && URL.revokeObjectURL(objectUrl);
    // TODO: Needs a rework
    //eslint-disable-next-line
  }, [blob]);

  return (
    <StyledLocalDownloader
      href={objectUrl}
      download={filename}
      disabled={!objectUrl}
      {...other}
    >
      {children}
    </StyledLocalDownloader>
  );
}

export default LocalDownloader;
