import { useMemo } from "react";

import { useAuditGetUsageQuery } from "api/audit";
import { useScenariosQuery } from "api/scenarios";

export default function useBillingInfo(fromDate, toDate) {
  const { data: scenarios, isPending: isPendingScenarios } =
    useScenariosQuery();
  const { data: eventData, isPending: isPendingEvents } = useAuditGetUsageQuery(
    {
      fromDate,
      toDate,
      format: "json",
    }
  );

  const events = useMemo(() => {
    if (isPendingScenarios || isPendingEvents) {
      return [];
    }
    return eventData.reduce((a, d) => {
      if (!a[d.scenario]) {
        // init scenario
        const scenarioDetails = scenarios.find((s) => s.id === d.scenario);
        a[d.scenario] = {
          name: (scenarioDetails?.name || d.scenario).toUpperCase(),
          description: scenarioDetails?.description,
          events: [],
          total: 0,
          deleted: !scenarioDetails,
        };
      }
      a[d.scenario].events.push(d);
      a[d.scenario].total += +d.num_events;
      return a;
    }, {});
  }, [eventData, scenarios, isPendingEvents, isPendingScenarios]);

  return events;
}
