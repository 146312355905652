import { useContext, useEffect } from "react";

import { useRefreshJwtMutation, useSelfQuery } from "api/admins";

import { LoginStatusContext } from "contexts/LoginStatusContext";

import { startIdleTracker, userActive } from "utils/idleTimer";

const FIVE_MIN = 5 * 60 * 1000;
export default function ActivityWrapper({ children }) {
  const { user, loading } = useContext(LoginStatusContext);
  const { data: bgUser } = useSelfQuery();
  const { mutate: updateJWT } = useRefreshJwtMutation();

  useEffect(() => {
    if (!loading && user?.id) {
      updateJWT();
      const cleanUpTrackers = startIdleTracker({
        refreshInterval: FIVE_MIN,
        onTimeout: updateJWT,
      });

      return () => {
        cleanUpTrackers();
      };
    }
  }, [updateJWT, loading, user?.id]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const expiresInSec = bgUser?.[0]?.session_expires_in;
    if (!expiresInSec) {
      return;
    }

    if (expiresInSec < 30 && userActive(FIVE_MIN)) {
      updateJWT();
      return;
    }

    const t = setTimeout(() => {
      if (userActive(FIVE_MIN)) {
        updateJWT();
      }
    }, expiresInSec * 1000);

    return () => {
      clearTimeout(t);
    };
  }, [bgUser, loading, updateJWT]);

  return <>{children}</>;
}
