import { useEffect, useState } from "react";

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { useCollectorsUpdateMutation } from "api/collectors";

import FilteringTabComponent from "components/scenarios/MappingEditor/tabComponents/filtering/FilteringTabComponent";
import BrandedRadio from "components/ui/BrandedRadio";
import Flexbox from "components/ui/Flexbox";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { useDataAnalysisColumns } from "hooks/useDataAnalysisColumns";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

const getDefaultValues = (collector) => {
  const newValues = {
    options: {
      internal_config: {
        filtering: collector?.extra?.internal_config?.filtering || {
          entries: [{ and_rules: [{ input_field: "", op: "=", value: "" }] }],
        },
        exploration_max_records:
          collector?.extra?.internal_config?.exploration_max_records ||
          100_000_000,
      },
    },
  };

  return newValues;
};

export const DataAnalysisDialog = ({
  collector,
  fileSelection,
  fullExplorationLabel,
  onClose,
  onExplore,
  open,
  sampleExplorationLabel,
  filesExplorationTitle,
}) => {
  const strings = useLocalizedStrings();
  const [explorationType, setExplorationType] = useState(
    fileSelection === 0 ? "full" : "sample"
  );
  const [applyFilter, setApplyFilter] = useState(
    explorationType === "sample" ? false : true
  );
  const { getAnalysisColumns } = useDataAnalysisColumns();
  const updateCollectorMutation = useCollectorsUpdateMutation();

  const { pushMessage } = useMessages();

  useEffect(() => {
    if (!collector) {
      return;
    }
    const getColumns = async () =>
      await getAnalysisColumns(collector, { collector: true });
    getColumns();
  }, [getAnalysisColumns, collector]);

  const methods = useForm({
    mode: "onChange",
    defaultValues: getDefaultValues(collector),
  });

  const onSubmit = (data) => {
    const newData = {
      extra: {
        ...data.options,
      },
    };
    newData.extra.internal_config.exploration_max_records = parseInt(
      newData.extra.internal_config.exploration_max_records
    );

    updateCollectorMutation.mutate(
      {
        id: collector.id,
        info: newData,
      },
      {
        onSuccess: () => {
          pushMessage("success", strings.collectors_config_saved_successfully);
          onExplore(explorationType, applyFilter);
          onClose();
        },
      }
    );
  };

  const watchedFiltering = methods.watch(
    `options.internal_config.filtering.entries.0.and_rules`
  );

  useEffect(() => {
    if (explorationType === "sample") {
      setApplyFilter(false);
    } else {
      setApplyFilter(true);
    }
  }, [explorationType, fileSelection, watchedFiltering]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <DialogTitle>
            {filesExplorationTitle ||
              strings.collectors_dataExploration_tab_explore_dialog_title}
          </DialogTitle>
          <DialogContent
            style={{
              maxHeight: explorationType === "sample" ? 150 : 500,
              width: 1000,
            }}
          >
            <RadioGroup
              onChange={(e) => setExplorationType(e.target.value)}
              row
              value={explorationType}
            >
              <FormControlLabel
                control={<BrandedRadio />}
                label={
                  sampleExplorationLabel ||
                  strings.collectors_dataExploration_tab_explore_dialog_sample_exploration
                }
                labelPlacement="end"
                value="sample"
                disabled={fileSelection === 0 && explorationType !== "sample"}
              />
              <FormControlLabel
                control={<BrandedRadio />}
                label={
                  fullExplorationLabel ||
                  strings.collectors_dataExploration_tab_explore_dialog_full_exploration
                }
                labelPlacement="end"
                value="full"
                disabled={!!fileSelection && explorationType !== "full"}
              />
            </RadioGroup>

            {explorationType === "full" && (
              <Flexbox alignItems="center" marginTop="10px">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applyFilter}
                      color="primary"
                      disabled={false}
                      onChange={() => setApplyFilter(!applyFilter)}
                    />
                  }
                  style={{ marginBottom: "20px" }}
                  label={
                    strings.collectors_dataExploration_tab_explore_dialog_apply_filter
                  }
                />
                <TextFieldInput
                  label={
                    strings.collectors_dataExploration_tab_explore_dialog_number_of_records_label
                  }
                  name="options.internal_config.exploration_max_records"
                  required
                  rules={{
                    pattern: {
                      value: /^((?=[1-9])\d*)\s*$/i,
                      message: strings.invalid_number,
                    },
                  }}
                />
              </Flexbox>
            )}
            {explorationType === "sample" && (
              <Typography style={{ whiteSpace: "pre-wrap", marginTop: "10px" }}>
                {
                  strings.collectors_dataExploration_tab_explore_dialog_sample_exploration_text
                }
              </Typography>
            )}
            {applyFilter && (
              <div style={{ marginTop: "20px" }}>
                <FilteringTabComponent />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <UiSecondaryButton onClick={onClose}>
              {strings.button_cancel}
            </UiSecondaryButton>
            <UiPrimaryButton type="submit">
              {
                strings.collectors_dataExploration_tab_explore_dialog_explore_button
              }
            </UiPrimaryButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
