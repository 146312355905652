import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { BackendEventsContext } from "contexts/BackendEventsContext";

import { useDeletedScenario } from "./useDeletedScenario";

export default function useBackendEvents(events, scenarios) {
  const { socket } = useContext(BackendEventsContext);
  const { isDeletedScenario } = useDeletedScenario();
  const eventsOfInterest = useMemo(() => new Set(events || []), [events]);
  const scenariosOfInterest = useMemo(
    () => new Set(scenarios || []),
    [scenarios]
  );
  const [incoming, setIncoming] = useState();

  const listener = useCallback(
    (type, payload) => {
      if (eventsOfInterest.size && !eventsOfInterest.has(type)) {
        return;
      }
      if (
        scenariosOfInterest.size &&
        payload?.scenario &&
        !scenariosOfInterest.has(payload.scenario)
      ) {
        return;
      }
      if (isDeletedScenario(payload?.scenario)) {
        return;
      }
      setIncoming({ type, payload });
    },
    [eventsOfInterest, scenariosOfInterest, isDeletedScenario]
  );

  useEffect(() => {
    socket?.onAny(listener);
    return () => socket?.offAny(listener);
  }, [socket, listener]);

  return [incoming];
}
