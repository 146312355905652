import { useRef, useState } from "react";

import { Divider, IconButton, Menu, MenuItem, Zoom } from "@mui/material";

import Hamburger from "components/ui/Hamburger";
import HtmlTooltip from "components/ui/HtmlTooltip";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const ScenarioMoreOptions = ({ menuItems, disabled }) => {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  const strings = useLocalizedStrings();

  const handleClose = () => setOpen(false);
  const handleOpen = () => !disabled && setOpen(true);

  return (
    <>
      <HtmlTooltip title={strings.sidebar_tooltip_more}>
        <IconButton
          sx={{ fontSize: "18px" }}
          onClick={handleOpen}
          ref={menuRef}
        >
          <Hamburger hovered={open} />
        </IconButton>
      </HtmlTooltip>
      <Menu
        className="more-options-menu"
        anchorEl={menuRef.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        TransitionComponent={Zoom}
      >
        {menuItems?.map((d, idx) =>
          d.id ? (
            <MenuItem
              className="more-options-menu-item"
              key={d.id}
              onClick={() => {
                d.handler && d.handler();
                handleClose();
              }}
              disabled={d.disabled ?? false}
              style={{ paddingRight: "3.5em" }}
            >
              {d.icon}
              <span style={{ marginLeft: "1em" }}>{d.label}</span>
            </MenuItem>
          ) : (
            <Divider key={idx} />
          )
        )}
      </Menu>
    </>
  );
};
export default ScenarioMoreOptions;
