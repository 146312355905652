import { styled } from "@mui/material";
import { useHistory } from "react-router-dom";

import NotAllowedMessage from "components/ui/NotAllowedMessage";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import ProfilePictureChart from "./ProfilePictureChart";
import ProfileTabContainer from "./ProfileTabContainer";
import { useClusterDistChart } from "./useClusterDistChart";

const StyledChartWrapper = styled("div")(
  () => `
  height: 100%;
  display: grid;
  position: relative;
  grid-template-rows: 1fr;
`
);

export default function ProfilePicture({ scenario, profileId, isPending }) {
  const strings = useLocalizedStrings();
  const { sessions, loading } = useClusterDistChart(scenario, profileId);
  const history = useHistory();
  const caps = useCapabilities();

  const handleBarClicked = (sid) => {
    history.push(`/scenarios/${scenario}/sessions/${sid}/session-timeline`);
  };

  if (!caps({ "analysis-model.clusters": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <ProfileTabContainer
      loading={isPending || loading || !sessions || sessions.length === 0}
      explanation={strings.profiledetails_tab_diffusion_explanation}
    >
      <StyledChartWrapper>
        {loading ? (
          `${strings.profiledetails_tab_diffusion_rendering}...`
        ) : (
          <ProfilePictureChart
            histogram={sessions || []}
            onBarClicked={handleBarClicked}
          />
        )}
      </StyledChartWrapper>
    </ProfileTabContainer>
  );
}
