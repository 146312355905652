import React, { useEffect, useMemo, useRef } from "react";

import { styled } from "@mui/material";

import ScenariosAPI from "api/scenarios";

import StatusIcon from "components/ui/StatusIcon";

import { formatIntervalObject, timeFormatter } from "utils/time-fmt";

import { useReportedFetch } from "hooks/http";
import useBackendEvents from "hooks/useBackendEvents";
import useScenarioJobState from "hooks/useScenarioJobState";

const StyledProgressView = styled("ul")`
  --graph-color: #888;
  overflow: visible;
  display: flex;
  flex-direction: column;
  list-style: none;
  border-left: 1px solid var(--graph-color);
  gap: 0.5em;
  font-size: 90%;
  margin-left: 6em;
  line-height: 2.5em;
  li {
    position: relative;
    display: flex;
    align-items: center;
    .timestamp {
      color: #555;
      width: 11em;
      margin-left: 1em;
    }
    &:before {
      --size: 1em;
      content: "";
      position: absolute;
      left: calc(var(--size) * -0.5);
      height: var(--size);
      width: var(--size);
      border-radius: 50%;
      border: 1px solid var(--graph-color);
      background-color: white;
    }
    &:after {
      content: attr(data-duration);
      position: absolute;
      color: #999;
      transform: translateX(calc((100% + 0.5em) * -1)) translateY(2.2em)
        scale(0.9);
    }
  }
  .stage-chip {
    --arrow-width: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7em calc(var(--arrow-width) + 1em);
    gap: 1em;
    width: max-content;
    &.task {
      background-color: #d5d4d950;
      clip-path: polygon(
        calc(100% - var(--arrow-width)) 0%,
        100% 50%,
        calc(100% - var(--arrow-width)) 100%,
        0% 100%,
        var(--arrow-width) 50%,
        0% 0%
      );
      &.active {
        animation: blink-active 1s ease-in-out alternate infinite;
      }
      .progress-text-container {
        display: flex;
        flex-direction: column;
        .progress-stage {
          font-weight: bold;
        }
        .progress-details {
          font-style: italic;
          opacity: 0.7;
          white-space: "pre-wrap";
        }
      }
    }
  }
  @keyframes blink-active {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

function Task({ task }) {
  const c = task.aborted ? "error" : task.complete ? "success" : "active";
  return (
    <div className={`stage-chip task ${c}`}>
      <StatusIcon status={c} />
      <div className="progress-text-container">
        <p className="progress-stage">{task.stage}</p>
        <div className="progress-details">{task.message}</div>
      </div>
    </div>
  );
}

const backendJobProgressEvents = ["progress"];

export default function JobProgress({ scenario }) {
  const { isRunning } = useScenarioJobState(scenario);
  const [backendNotification] = useBackendEvents(backendJobProgressEvents, [
    scenario,
  ]);
  const listRef = useRef();

  const [progress] = useReportedFetch(ScenariosAPI.getProgress(scenario).url, [
    scenario,
    backendNotification,
  ]);

  // establish each task's abort state
  const processedProgress = useMemo(
    () =>
      progress.map((d) => ({
        ...d,
        aborted: d.complete !== true && !isRunning,
      })),
    [progress, isRunning]
  );

  // scroll to bottom each time a change has occured
  useEffect(() => {
    if (!listRef.current) {
      return;
    }
    const w = listRef.current.parentNode;
    if (!w) {
      return;
    }
    w.scrollTop = w.scrollHeight - w.clientHeight;
  }, [processedProgress]);

  if (isRunning && processedProgress.length === 0) {
    return "Waiting...";
  }

  return (
    <StyledProgressView ref={listRef}>
      {processedProgress.map((d) => (
        <li key={d.stage} data-duration={formatIntervalObject(d.duration)}>
          <span className="timestamp">
            {timeFormatter(new Date(d.first_seen))}
          </span>
          <Task task={d} />
        </li>
      ))}
    </StyledProgressView>
  );
}
