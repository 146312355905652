import { useEffect, useState } from "react";

import { useSessionsGetUrlQuery } from "api/sessions";

export function useClusterDistChart(scenario, cluster) {
  const [loading, setLoading] = useState(true);
  const [calculatedSessions, setCalculatedSessions] = useState();

  useEffect(() => {
    setLoading(true);
  }, [scenario, cluster]);

  const { data: sessions } = useSessionsGetUrlQuery({
    scenario,
    offset: 0,
    limit: 1000,
    orderBy: {},
    filters: { cluster: { operator: "eq", operand: cluster } },
  });

  // calculate distance for each session
  useEffect(() => {
    if (!sessions || sessions.length === 0) {
      setLoading(false);
      return;
    }
    setCalculatedSessions(
      sessions
        .map((s) => ({
          x: s.app_session_id,
          y: Math.abs(s.distance) || 0,
        }))
        .sort((a, b) => a.y - b.y)
    );
    setLoading(false);
  }, [sessions]);

  return {
    sessions: calculatedSessions,
    loading,
  };
}
