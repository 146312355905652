import { useCallback } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TableCell, TableSortLabel } from "@mui/material";

const orderDirectionToVerb = (d) => {
  switch (d) {
    case -1:
      return "desc";
    case 1:
      return "asc";
    default:
      return null;
  }
};

export const SortHeader = ({ header, orderBy, onOrderBy }) => {
  const handleClick = useCallback(
    () => onOrderBy(header.sort),
    [onOrderBy, header.sort]
  );
  let ob = orderBy?.[header.sort];
  if (header?.reverse_sort_indicator) {
    ob *= -1;
  }

  return (
    <TableCell align="left" style={header.style}>
      {header.sort ? ( // sortable column?
        <TableSortLabel
          active={Boolean(ob)}
          direction={orderDirectionToVerb(ob) || "asc"}
          onClick={handleClick}
          IconComponent={ArrowDropDownIcon}
        >
          {header.label}
        </TableSortLabel>
      ) : (
        <span>{header.label}</span>
      )}
    </TableCell>
  );
};
