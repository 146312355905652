import { useCallback, useEffect, useRef, useState } from "react";

import { omit } from "lodash-es";

function useInfiniteScrollContainer(
  containerRef,
  selector,
  onBackward,
  onForward,
  options,
  dependencies
) {
  const top = useRef();
  const bottom = useRef();
  const observer = useRef();

  useEffect(() => {
    // set up observer
    observer.current = new IntersectionObserver(
      (entries, ob) =>
        entries.forEach((e) => {
          if (e.isIntersecting) {
            ob.unobserve(e.target);
            if (e.target === top.current) {
              onBackward?.();
            } else if (e.target === bottom.current) {
              onForward?.();
            }
          }
        }),
      { ...options, root: containerRef.current }
    );

    // register top and bottotm elements for observation
    const l = containerRef.current.querySelectorAll(selector);
    if (l.length) {
      bottom.current = l.item(l.length - 1);
      observer.current.observe(bottom.current);

      // observer top element separately only if it's not also the last element
      if (l.length > 1) {
        top.current = l.item(0);
        observer.current.observe(top.current);
      }
    }

    // cleanup: disconnect observer
    return () => observer.current?.disconnect();
    // eslint-disable-next-line
  }, [options, selector, onBackward, onForward, ...dependencies]);
}

const useCounter = (initialValue) => {
  const [counter, setCounter] = useState(initialValue || 0);
  const increment = useCallback(
    (by) => setCounter((prev) => prev + (by || 1)),
    []
  );
  const decrement = useCallback(
    (by) => setCounter((prev) => prev - (by || 1)),
    []
  );
  return [counter, increment, decrement];
};

function useInfiniteScrollData(
  containerRef,
  loadPage,
  { returnAdditionalResInfo = false }
) {
  const maxFramesCache = 0;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState();
  const [additionalResInfo, setAdditionalResInfo] = useState();
  const [refresh, setRefresh] = useCounter();
  const hasMore = useRef(true);
  const pageToLoad = useRef(-1);
  const frames = useRef([]);
  const direction = useRef(0);

  // request loading previous page
  const prepend = useCallback(() => {
    if (!frames.current.length || frames.current[0].page === 0 || loading) {
      return;
    }
    direction.current = -1;
    const prevPage = frames.current[0].page - 1;
    pageToLoad.current = prevPage;
    setRefresh();
  }, [setRefresh, loading]);

  // request loaiding next page
  const append = useCallback(() => {
    if (!hasMore.current || error) {
      return;
    }
    if (loading) {
      return;
    }
    const lastFrame = frames.current[frames.current.length - 1];
    const nextPage = lastFrame ? lastFrame.page + 1 : 0;
    direction.current = 1;
    pageToLoad.current = nextPage;
    setRefresh();
  }, [setRefresh, loading, error]);

  const reset = useCallback(() => {
    frames.current = [];
    direction.current = 0;
    pageToLoad.current = -1;
    hasMore.current = true;
    append();
    //eslint-disable-next-line
  }, []);

  // insert new rows at the beginning
  const insertBackward = useCallback((rows) => {
    if (frames.current.find((d) => d.page === pageToLoad.current)) {
      return;
    }
    frames.current.unshift({
      page: frames.current[0] ? frames.current[0].page - 1 : 0,
      count: rows.length,
    });
    setItems((prev) => [...rows, ...prev]);
    if (maxFramesCache && frames.current.length > maxFramesCache) {
      // dispose last frame
      const [removedFrame] = frames.current.splice(-1);
      setItems((prev) => {
        let d = [...prev];
        d.splice(-removedFrame.count);
        return d;
      });
    }
  }, []);

  // insert new rows at the end
  const insertForward = useCallback((rows) => {
    if (rows.length === 0) {
      hasMore.current = false;
      // return;
    }
    if (frames.current.find((d) => d.page === pageToLoad.current)) {
      return;
    }
    const lastFrame = frames.current[frames.current.length - 1];
    frames.current.push({
      page: lastFrame ? lastFrame.page + 1 : 0,
      count: rows.length,
    });
    if (pageToLoad.current) {
      setItems((prev) => [...prev, ...rows]);
    } else {
      setItems(rows);
      // new start - scroll to top
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    }
    if (maxFramesCache && frames.current.length > maxFramesCache) {
      // dispose first frame
      const [removedFrame] = frames.current.splice(0, 1);
      setItems((prev) => {
        let d = [...prev];
        d.splice(0, removedFrame.count);
        return d;
      });
    }
    //eslint-disable-next-line
  }, []);

  // load page
  useEffect(() => {
    if (loading || pageToLoad.current === -1 || direction.current === 0) {
      return;
    }

    setError();

    // The provided loadPage function must return an array with two elements:
    // 0: the loading promise, where its resolve callback returns an array of rows
    // 1: an abort function (optional)
    const [load, abort] = loadPage(pageToLoad.current);
    if (!load) {
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const res = await load;
        let rows;
        if (returnAdditionalResInfo) {
          rows = res?.rows;
          setAdditionalResInfo(omit(res, "rows"));
        } else {
          rows = res;
        }
        // add new rows based on current direction
        if (direction.current === 1) {
          insertForward(rows);
        } else if (direction.current === -1) {
          insertBackward(rows);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => abort?.();
    // eslint-disable-next-line
  }, [refresh /*, insertBackward, insertForward, loadPage*/]);

  // reset upon new data source
  useEffect(() => {
    reset();
  }, [loadPage, reset]);

  return {
    items,
    additionalResInfo,
    prepend,
    append,
    reset,
    loading,
    error,
  };
}
// add returnAdditionalResInfo: true to observerOptions object in order to get additionalResInfo (top level json without rows)
export function useInfiniteScroll(selector, loadPage, observerOptions) {
  const containerRef = useRef();
  const { items, additionalResInfo, append, prepend, reset, loading, error } =
    useInfiniteScrollData(containerRef, loadPage, observerOptions);
  useInfiniteScrollContainer(
    containerRef,
    selector,
    prepend,
    append,
    observerOptions,
    [items]
  );

  return {
    additionalResInfo: additionalResInfo,
    scrollableAreaRef: containerRef,
    scrollableItems: items,
    resetScrollableItems: reset,
    loading,
    error,
  };
}
