import { useContext, useRef, useState } from "react";

import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import { useScenarioUpdateRankMutation } from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import ScenarioThumbnail from "./ScenarioThumbnail";

const ScenarioThumbnailsList = ({ handleCloneScenario, handleDelete }) => {
  const { scenarios, selectedScenario, selectScenario, setScenarios } =
    useContext(ScenariosContext);

  const [isDragging, setIsDragging] = useState(false);
  const oldScenarios = useRef(null);
  const { mutate: updateRank } = useScenarioUpdateRankMutation();

  const handleDragStart = (event) => {
    setIsDragging(true);
    const { active } = event;
    selectScenario(active.id);
  };

  const handleDragEnd = (event) => {
    setIsDragging(false);
    const { active, over } = event;

    if (active.id !== over.id) {
      const newRank = scenarios.find(({ id }) => id === over.id).rank;

      setScenarios((prev) => {
        oldScenarios.current = prev;
        const oldIndex = prev.findIndex(({ id }) => id === active.id);
        const newIndex = prev.findIndex(({ id }) => id === over.id);

        return arrayMove(prev, oldIndex, newIndex);
      });

      updateRank(
        { scenarioId: active.id, newRank },
        {
          onError: () => {
            setScenarios(oldScenarios.current);
          },
        }
      );
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  if (!scenarios) {
    return null;
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToFirstScrollableAncestor]}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <ul>
        <SortableContext items={scenarios} strategy={rectSortingStrategy}>
          {scenarios.map((scenario) => (
            <ScenarioThumbnail
              id={scenario.id}
              key={scenario.id}
              onClone={() => handleCloneScenario(scenario)}
              onDelete={() => handleDelete(scenario)}
              scenario={scenario}
              selected={selectedScenario && selectedScenario.id === scenario.id}
              someoneIsDragging={isDragging}
            />
          ))}
        </SortableContext>
      </ul>
    </DndContext>
  );
};

export default ScenarioThumbnailsList;
