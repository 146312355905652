import React, { useMemo } from "react";
import { useCallback } from "react";
import { useEffect } from "react";

import { TextField, styled } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useOutputFields } from "hooks/useOutputFields";

const StyledOutputFieldInput = styled(TextField)(() => ({
  marginBottom: "10px !important",
  width: "200px !important",

  "& fieldset": {
    height: "100%",
    marginBottom: "0",
  },
  "& input": {
    height: "56px",

    width: "20em",
  },

  "& input[type=number]::-webkit-outer-spin-button": {
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    margin: 0,
  },
  "& .MuiFormHelperText-contained": {
    marginTop: "0px",
  },
}));

export const OutputFieldInput = ({ name }) => {
  const strings = useLocalizedStrings();

  const { isValid, setOutputFields, removeOutputField } = useOutputFields(name);
  const { getValues } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: {
      pattern: /^[a-zA-Z0-9_. ]([a-zA-Z0-9_. ])*$/,
      required: true,
      validate: () => {
        return isValid(name) || "Value must be unique";
      },
    },
  });

  const getErrorMessage = useMemo(() => {
    if (!!error?.customRule?.message) {
      return error?.customRule?.message;
    }
    if (!!error?.message) {
      return error?.message;
    }
    if (error) {
      return strings.invalid_input;
    }
  }, [strings, error]);

  const handleChange = useCallback(
    (event) => {
      setOutputFields(name, event.target.value);
      field.onChange(event.target.value);
    },
    [field, name, setOutputFields]
  );

  useEffect(() => {
    const val = getValues(name);
    setOutputFields(name, val);
    return () => {
      removeOutputField(name);
    };
  }, [name, removeOutputField, setOutputFields, getValues]);

  return (
    <StyledOutputFieldInput
      {...field}
      error={!!error}
      helperText={getErrorMessage}
      label={
        strings.sourcedialog_mapping_editor_bucketing_tab_label_output_field
      }
      value={field.value || ""}
      variant="outlined"
      onChange={(event) => handleChange(event)}
    />
  );
};
