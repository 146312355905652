import { useContext, useMemo } from "react";

import { InfoOutlined } from "@mui/icons-material";

import { useScenarioParameterKeyQuery } from "api/scenarioparams";

import { ScenariosContext } from "contexts/ScenariosContext";

import DataLink from "components/ui/DataLink";
import HtmlTooltip from "components/ui/HtmlTooltip";
import { InformationPopover } from "components/ui/InformationPopover";
import InformationStrip from "components/ui/InformationStrip";
import InformationStripItem from "components/ui/InformationStripItem";
import RiskIndicator from "components/ui/RiskIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";
import { UserTypeIcon } from "components/ui/UserTypeIcon";

import {
  formatInterval,
  inUtc,
  timeFormatter,
  timeInterval,
} from "utils/time-fmt";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { useSessionDetails } from "./SessionDetailsContext";
import { UserSessions } from "./UserSessions";

const UserStatsContent = ({ si, strings, scenario }) => {
  const { selectedScenario } = useContext(ScenariosContext);
  const { data: minRisk } = useScenarioParameterKeyQuery(
    {
      scenarioId: scenario,
      paramKey: "com/trackeriq/analysis/risk-floor",
    },
    {
      enabled: !!scenario,
    }
  );
  const popupContent = useMemo(
    () => [
      {
        name: "User",
        value: (
          <DataLink
            to={`/scenarios/${scenario}/users?uid=${encodeURIComponent(
              si.orig_app_user_id
            )}`}
          >
            <p style={{ maxWidth: "350px", overflowWrap: "break-word" }}>
              {si.orig_app_user_id}
            </p>
          </DataLink>
        ),
      },
      {
        name: strings.profiles,
        value:
          si.user_num_profiles > 0 ? (
            <DataLink
              to={`/scenarios/${scenario}/profiles?uid=${encodeURIComponent(
                si.orig_app_user_id
              )}`}
            >
              {si.user_num_profiles}
            </DataLink>
          ) : (
            "N/A"
          ),
      },
      {
        name: strings.userstable_header_num_sessions,
        value:
          si.user_total_sessions > 0 ? (
            <DataLink
              to={`/scenarios/${scenario}/sessions?uid=${encodeURIComponent(
                si.orig_app_user_id
              )}`}
            >
              {si.user_total_sessions}
            </DataLink>
          ) : (
            "N/A"
          ),
      },
      {
        name: strings.userstable_header_earliest,
        value: timeFormatter(si.user_earliest, { includeTime: false }),
      },
      {
        name: strings.userstable_header_latest,
        value: timeFormatter(si.user_latest, { includeTime: false }),
      },
      {
        name: strings.userstable_header_max_risk,
        value: <RiskIndicator value={si.user_max_risk * 100} />,
      },
      {
        name: strings.userstable_header_risky_sessions,
        value:
          Number(si.user_num_risky_sessions) > 0 ? (
            <DataLink
              to={`/scenarios/${scenario}/sessions?uid=${encodeURIComponent(
                si.orig_app_user_id
              )}&whole_risk=${minRisk}`}
            >
              {si.user_num_risky_sessions}
            </DataLink>
          ) : (
            0
          ),
      },
      {
        name: strings.userstable_header_average_sensitivity,
        value: (
          <SensitivityIndicator
            value={Math.abs(Math.min(si.user_avg_sensitivity, 0))}
            size="small"
          />
        ),
      },
      {
        name: strings.userstable_header_unprofiled_sessions,
        value:
          Number(si.user_num_unclustered_sessions) > 0 ? (
            <DataLink
              to={`/scenarios/${scenario}/sessions?uid=${encodeURIComponent(
                si.orig_app_user_id
              )}&cluster=-1`}
            >
              {si.user_num_unclustered_sessions}
            </DataLink>
          ) : (
            0
          ),
      },
    ],
    [strings, si, scenario, minRisk]
  );

  const titleDetails = selectedScenario?.is_production ? (
    <DataLink to="#multi-scenario">Multi scenario</DataLink>
  ) : null;

  return (
    <InformationPopover
      title="User Details"
      titleDetails={titleDetails}
      content={popupContent}
    >
      <div
        style={{
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textDecoration: "underline",
          cursor: "default",
        }}
      >
        {si.orig_app_user_id}
      </div>
    </InformationPopover>
  );
};

const TimeDisplay = ({ start, end, timeLabel, durationLabel }) => {
  const utcStart = inUtc(start);
  const utcEnd = inUtc(end);
  const fmtStart = timeFormatter(utcStart);
  const fmtEnd = timeFormatter(utcEnd);
  const interval = formatInterval(timeInterval(utcStart, utcEnd));

  return (
    <>
      <InformationStripItem label={timeLabel}>
        <span style={{ whiteSpace: "nowrap" }}>
          {fmtStart}
          <strong> - </strong> {fmtEnd}
        </span>
      </InformationStripItem>
      <InformationStripItem label={durationLabel}>
        <span>{interval}</span>
      </InformationStripItem>
    </>
  );
};

const InfoItemWithTooltip = ({ children, tooltip, label, ...props }) => {
  if (!tooltip) {
    return (
      <InformationStripItem label={label} {...props}>
        <span>{children}</span>
      </InformationStripItem>
    );
  }

  return (
    <HtmlTooltip title={tooltip}>
      <InformationStripItem label={label} {...props}>
        <span>{children}</span>
      </InformationStripItem>
    </HtmlTooltip>
  );
};

const SessionStatus = ({ reevaluated }) => {
  const strings = useLocalizedStrings();

  return (
    <span
      style={{
        display: "inline-block",
        padding: "0px 0.8em",
        borderRadius: "100vw",
        color: "white",
        backgroundColor: reevaluated ? "darkorange" : "green",
        textTransform: "uppercase",
        cursor: "default",
      }}
    >
      {reevaluated
        ? strings.session_info_strip_label_state_rescored
        : strings.session_info_strip_label_state_new}
    </span>
  );
};

export const SessionProfileIcon = ({
  scenarioId,
  cluster,
  nearest_distance,
  nearest_cluster,
}) => {
  const strings = useLocalizedStrings();

  if (cluster > 0) {
    return (
      <DataLink to={`/scenarios/${scenarioId}/profiles/${cluster}/diffusion`}>
        #{cluster}
      </DataLink>
    );
  }

  const dist = Number(nearest_distance).toFixed(4);
  const content = [
    {
      name: strings.session_info_strip_nearest_profile,
      value: nearest_cluster > 0 && dist < 1 ? `#${nearest_cluster}` : "N/A",
    },
  ];
  if (nearest_cluster < 0) {
    content.push({
      name: strings.session_info_strip_avg_user_no_profiles,
      value: "",
    });
  } else {
    content.push({
      name: strings.session_info_strip_avg_nearest_distance,
      value: nearest_distance ? dist : "N/A",
    });
  }

  return (
    <InformationPopover content={content}>
      <InfoOutlined data-testid="profileIcon" fontSize="small" />
    </InformationPopover>
  );
};

export function SessionInfo({ scenario }) {
  const strings = useLocalizedStrings();
  const { data } = useSessionDetails();

  const si = useMemo(() => {
    if (!data) {
      return {};
    }
    return {
      ...data,
      start: new Date(data.start),
      end: new Date(data.end),
      sensitivity: Math.abs(Math.min(data.num_sensitive_events, 0)),
    };
  }, [data]);

  const avgLength = si?.facts?.totals?.avg_length;
  const lengthTooltip =
    avgLength != undefined
      ? strings.formatString(
          strings.session_info_strip_avg_tooltip,
          Math.floor(avgLength)
        )
      : null;
  const hasLenInsight = si?.insights?.some(
    (i) => i.type === "length_probability"
  );

  const avgSensitivity = si?.facts?.totals?.avg_sensitivity;
  const sensitivityTooltip =
    avgSensitivity !== undefined
      ? strings.formatString(
          strings.session_info_strip_avg_tooltip,
          Math.abs(avgSensitivity)
        )
      : null;
  const hasSensitivityInsight = si?.insights?.some(
    (i) => i.type === "session_sensitivity"
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <InformationStrip>
        <InformationStripItem label={strings.session_info_strip_label_session}>
          {si.app_session_id}
        </InformationStripItem>

        <InformationStripItem
          label={strings.profile}
          style={{ minWidth: "5em" }}
        >
          <SessionProfileIcon
            scenarioId={scenario}
            cluster={si.cluster}
            nearest_cluster={si.nearest_cluster}
            nearest_distance={si.nearest_distance}
          />
        </InformationStripItem>

        <InformationStripItem label={strings.session_info_strip_label_user}>
          <div
            style={
              si.user_sensitivity_type
                ? { marginRight: "5px", display: "flex" }
                : { display: "flex" }
            }
          >
            {si.user_sensitivity_type && (
              <UserTypeIcon type={si.user_sensitivity_type} />
            )}
          </div>
          <UserStatsContent si={si} strings={strings} scenario={scenario} />
        </InformationStripItem>

        <TimeDisplay
          start={si.start}
          end={si.end}
          timeLabel={strings.session_info_strip_label_time}
          durationLabel={"Duration"}
        />

        {si.device && (
          <InfoItemWithTooltip
            label={strings.session_info_strip_label_device}
            tooltip={si.device.length > 20 ? si.device : null}
          >
            {si.device.length > 20 ? `${si.device.slice(0, 20)}...` : si.device}
          </InfoItemWithTooltip>
        )}

        <InfoItemWithTooltip
          label={strings.session_info_strip_label_length}
          tooltip={lengthTooltip}
          sx={{
            minWidth: "4em",
            border: hasLenInsight ? `1px solid` : "",
            borderColor: hasLenInsight ? `info.dark` : "",
          }}
        >
          {si.num_events}
        </InfoItemWithTooltip>

        <InfoItemWithTooltip
          label="Sensitivity"
          tooltip={sensitivityTooltip}
          sx={{
            minWidth: "6em",
            border: hasSensitivityInsight ? `1px solid` : "",
            borderColor: hasSensitivityInsight ? `info.dark` : "",
          }}
        >
          {si.sensitivity}
        </InfoItemWithTooltip>

        <InformationStripItem label={strings.session_info_strip_label_state}>
          <SessionStatus reevaluated={si.reevaluated} />
        </InformationStripItem>

        <InformationStripItem label={strings.session_info_strip_label_risk}>
          <RiskIndicator value={si.whole_risk} />
        </InformationStripItem>
      </InformationStrip>
      <UserSessions selectedSession={si} scenarioId={scenario} />
    </>
  );
}
