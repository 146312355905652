export default function histogram(items, key, attach, order) {
  const o = items.reduce((a, d) => {
    const k = key ? key(d) : d;
    if (!a[k]) {
      a[k] = { count: 0, data: undefined };
    }
    a[k].count++;
    a[k].data = attach?.(a[k].data, d);
    return a;
  }, {});

  return Object.entries(o)
    .map(([k, v]) => [k, v.count, v.data])
    .sort((a, b) => (order ? order(a, b) : 0));
}
