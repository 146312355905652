import React from "react";

import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import { Badge, styled } from "@mui/material";

import HtmlTooltip from "components/ui/HtmlTooltip";

function AppIconTooltip(props) {
  const tooltip = props.tooltip ? `${props.app?.name}` : "";
  const pausedStatus = props?.paused ? " (paused)" : "";
  return (
    <HtmlTooltip title={`${tooltip}${pausedStatus}`}>
      {props.children}
    </HtmlTooltip>
  );
}
// TODO check this
const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    color: "#333",
    backgroundColor: "rgba(255,255,255,.7)",
    border: "1px solid #bbb",
    fontSize: "7pt",
  },
});

function ApplicationIcon(props) {
  const sizes = {
    small: 16,
    medium: 24,
    large: 48,
  };

  const w = sizes[props.size || "medium"] || sizes.medium;

  if (!props.app || !props.app.icon_data) {
    return (
      <AppIconTooltip {...props}>
        <Badge
          className="AppIconTooltipBadge"
          color="primary"
          overlap="circular"
        >
          <CloudCircleIcon
            style={{
              width: `${w}px`,
              height: `${w}px`,
              color: props.paused ? "#9e9e9e" : "",
            }}
          />
        </Badge>
      </AppIconTooltip>
    );
  }

  return (
    <AppIconTooltip {...props}>
      <StyledBadge color="primary" overlap="rectangular">
        <img
          alt={`${props.app.name} icon`}
          className={props.paused ? "GreyscaleIcon" : ""}
          src={`data:${props.app.icon_content_type};base64, ${props.app.icon_data}`}
          style={{ width: `${w}px`, height: `${w}px` }}
        />
      </StyledBadge>
    </AppIconTooltip>
  );
}

export default ApplicationIcon;
