import { useCallback, useContext, useRef, useState } from "react";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { useUpdateScenarioMutation } from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import FileUpload from "components/ui/FileUpload";
import Flexbox from "components/ui/Flexbox";
import FrameContent from "components/ui/FrameContent";
import LocalDownloader from "components/ui/LocalDownloader";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import SettingsOption from "./SettingsOption";

export const SettingsInsights = () => {
  const strings = useLocalizedStrings();
  const { pushMessage } = useMessages();
  const { selectedScenario } = useContext(ScenariosContext);
  const [expanded, setExpanded] = useState(false);
  const opened = useRef(false);
  const caps = useCapabilities();

  const { mutateAsync: updateScenario } = useUpdateScenarioMutation();

  const handleExpand = useCallback(() => {
    setExpanded((prev) => {
      if (!prev && !opened.current) {
        opened.current = true;
      }
      return !prev;
    });
  }, []);

  const handleUpload = async (newInsights) => {
    let parsedInsights;
    try {
      parsedInsights = JSON.parse(await newInsights.text());
    } catch (e) {
      pushMessage(
        "error",
        strings.formatString(
          strings.scenariosettings_insights_error,
          newInsights.name
        )
      );
      return;
    }

    await updateScenario({
      id: selectedScenario.id,
      details: {
        name: selectedScenario.name,
        description: selectedScenario.description,
        generate_profile_details: selectedScenario.generate_profile_details,
        users_activity_timeframe: selectedScenario.users_activity_timeframe,
        siem_id: selectedScenario.siem_id ?? "",
        is_production: selectedScenario.is_production,
        is_sync_with_sources: selectedScenario.is_sync_with_sources,
        extra: {
          ...selectedScenario.extra,
          action_insights_config: parsedInsights,
        },
      },
    });
    pushMessage("success", strings.scenariosettings_insights_uploaded);
  };

  const actionInsights = selectedScenario?.extra?.action_insights_config;
  const hasInsights = Object.keys(actionInsights || {}).length !== 0;

  return (
    <SettingsOption
      title={strings.scenariosettings_insights_title}
      open={expanded}
      onChange={handleExpand}
    >
      <FrameContent>
        <Flexbox justifyContent="center" width={1} style={{ gap: "10px" }}>
          <FileUpload
            placeholder={`${strings.scenariosettings_insights_upload_placeholder}...`}
            accept="application/json"
            onUpload={handleUpload}
            style={{ flexGrow: 1 }}
            disabled={!caps({ "acm.scenarios": { write: true } })}
          />
          <LocalDownloader
            data={actionInsights}
            filename={`insights_config.${selectedScenario.id}.json`}
            mimetype="application/json"
            disabled={!hasInsights}
          >
            <UiPrimaryButton
              startIcon={<CloudDownloadIcon />}
              style={{
                height: "3.2em",
                marginLeft: "10px",
                marginRight: "8px",
              }}
              disabled={!hasInsights}
            >
              {strings.button_download}
            </UiPrimaryButton>
          </LocalDownloader>
        </Flexbox>
      </FrameContent>
    </SettingsOption>
  );
};
