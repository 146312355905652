import { createContext, useContext } from "react";

const FieldInsightDataContext = createContext();

export function FieldInsightDataContextProvider({
  children,
  fieldInsightData,
}) {
  return (
    <FieldInsightDataContext.Provider value={fieldInsightData}>
      {children}
    </FieldInsightDataContext.Provider>
  );
}

export function useFieldInsightData() {
  return useContext(FieldInsightDataContext);
}
