const FilterOperators = {
  "": {
    id: "",
    label: "",
    op: "",
  },
  eq: {
    id: "eq",
    label: "=",
    op: "eq",
  },
  ne: {
    id: "ne",
    label: "<>",
    op: "ne",
  },
  lt: {
    id: "lt",
    label: "<",
    op: "lt",
  },
  le: {
    id: "le",
    label: "<=",
    op: "le",
  },
  gt: {
    id: "gt",
    label: ">",
    op: "gt",
  },
  ge: {
    id: "ge",
    label: ">=",
    op: "ge",
  },
  contains: {
    id: "contains",
    label: "Contains",
    op: "contains",
  },
  "not-contains": {
    id: "not-contains",
    label: "Not Contains",
    op: "notcontains",
  },
  in: {
    id: "in",
    label: "In List",
    op: "in",
    help: "comma delimiter values",
  },
  notin: {
    id: "notin",
    label: "Not In List",
    op: "notin",
    help: "comma delimiter values",
  },
  defined: {
    id: "notnull",
    label: "Defined",
    op: "notnull",
    help: "Not NULL",
  },
  undefined: {
    id: "null",
    label: "Undefined",
    op: "null",
    help: "Is NULL",
  },
  null: {
    id: "null",
    label: "Undefined",
    op: "null",
    help: "Is NULL",
  },
  notnull: {
    id: "notnull",
    label: "Defined",
    op: "notnull",
    help: "Not NULL",
  },
  "text-search": {
    id: "text-search",
    label: "Text Search",
    op: "ts",
    help: "Text Search",
  },
};

export default FilterOperators;
