import { useEffect } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useBackendEvents from "hooks/useBackendEvents";

import { downloadResponse, queryFetch } from "./query";

const baseUrl = "/api/acm/v1/files";
const baseKey = ["files"];
const fileKeys = {
  list: () => [baseKey, "list"],
};
const filesEvents = ["files"];

export const useGetFilesQuery = (opts) => {
  const [backendEvent] = useBackendEvents(filesEvents);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (backendEvent) {
      queryClient.invalidateQueries({
        queryKey: fileKeys.list(),
      });
    }
  }, [backendEvent, queryClient]);

  return useQuery({
    queryKey: fileKeys.list(),
    queryFn: () => queryFetch(baseUrl),
    ...opts,
  });
};

export const useDownloadFileQuery = () => {
  return useMutation({
    mutationFn: ({ id }) => queryFetch(`${baseUrl}/key/${id}`),
    onSuccess: (data, { name }) => {
      const fileName = `${name}.csv`;
      downloadResponse({ data, type: "application/octet-stream", fileName });
    },
  });
};

export const useDeleteFileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => {
      const opts = {
        url: `${baseUrl}/key/${id}`,
        options: {
          method: "DELETE",
        },
      };
      return queryFetch(opts);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: fileKeys.list() }),
  });
};

const useUploadFileMutation = () => {
  return useMutation({
    mutationFn: ({ id, content }) => {
      const opts = {
        url: `${baseUrl}/key/${id}/upload`,
        options: {
          method: "PATCH",
          headers: {
            "content-type": "text/csv",
          },
          body: new Blob([content], { type: "text/csv" }),
        },
      };
      return queryFetch(opts);
    },
  });
};

export const useAccountFileMutation = () => {
  const queryClient = useQueryClient();
  const uploadContent = useUploadFileMutation();

  return useMutation({
    mutationFn: ({ id, name, secret }) => {
      const opts = {
        url: id ? `${baseUrl}/key/${id}` : baseUrl,
        options: {
          method: id ? "PATCH" : "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(id ? { name } : { name, secret }),
        },
      };
      return queryFetch(opts);
    },
    onSuccess: async (res, { id, content }) => {
      if (content) {
        await uploadContent.mutateAsync(
          id ? { id, content } : { id: res[0].id, content }
        );
      }
      return queryClient.invalidateQueries({ queryKey: fileKeys.list() });
    },
  });
};
