import { parse } from "csv-parse/browser/esm/sync";
import { sortBy } from "lodash-es";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

export const useBucketingCsvUpload = ({ onUpload }) => {
  const { pushMessage } = useMessages();
  const strings = useLocalizedStrings();

  return (file) => {
    const csvFileRowsLimit = 10000;
    if (!file) {
      return;
    }

    const readFile = new FileReader();

    readFile.onload = function (e) {
      try {
        const csv = e.target.result;
        const records = parse(csv, {
          trim: true,
          skip_empty_lines: false,
          group_columns_by_name: true,
          delimiter: [",", ";", "|", ":"],
          columns: (header) =>
            header.map((column) => {
              const columnNames = ["value", "label"];
              const formattedColumn = column.toLowerCase();
              if (!columnNames.includes(formattedColumn)) {
                throw new Error(
                  strings.sourcedialog_mapping_editor_bucketing_tab_csv_file_upload_error_message
                );
              }
              return column.toLowerCase();
            }),
        });

        if (records.length > csvFileRowsLimit) {
          const maximumRowsErrorMessage = strings.formatString(
            strings.sourcedialog_mapping_editor_bucketing_tab_csv_file_upload_limit_error_message,
            csvFileRowsLimit
          );

          throw new Error(maximumRowsErrorMessage);
        }

        const sortedRecords = sortBy(records, "label");

        const labelRules = sortedRecords.reduce((acc, line) => {
          const value = line.value;
          const label = line.label;

          const foundLabel = acc.find((a) => a.label === label);

          if (foundLabel) {
            foundLabel.condition = `${foundLabel.condition},${value}`;
          } else {
            acc.push({
              label: label,
              condition: value,
            });
          }
          return acc;
        }, []);

        pushMessage(
          "success",
          strings.sourcedialog_mapping_editor_bucketing_tab_csv_file_upload_success_message
        );
        labelRules && onUpload(labelRules);
      } catch (e) {
        pushMessage(
          "error",
          e?.message ||
            strings.sourcedialog_mapping_editor_bucketing_tab_csv_file_upload_error_message
        );
      }
    };
    readFile.readAsText(file);
  };
};
