import { useState } from "react";

import { styled } from "@mui/material";

import { useCollectorsGetAvailableAppsQuery } from "api/collectors";

import ErrorBoundary from "components/ErrorBoundary";

import CollectorsContentArea from "./CollectorsContentArea";
import { CollectorsSidebar } from "./CollectorsSidebar";
import useCollectors from "./useCollectors";

const StyledCollectorsPage = styled("div")`
  height: calc(100vh - var(--appbar-height));
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
  grid-template-rows: 100%;
`;

export const CollectorsPage = () => {
  const [selectedCollector, setSelectedCollector] = useState(); // ID only!
  const [collectors] = useCollectors();
  const { data: apps = [] } = useCollectorsGetAvailableAppsQuery();

  return (
    <StyledCollectorsPage>
      <ErrorBoundary>
        <CollectorsSidebar
          apps={apps}
          collectors={collectors}
          selectedCollector={selectedCollector}
          setSelectedCollector={setSelectedCollector}
        />
      </ErrorBoundary>

      <CollectorsContentArea
        apps={apps}
        key={!!selectedCollector}
        selectedCollector={selectedCollector}
        setSelectedCollector={setSelectedCollector}
      />
    </StyledCollectorsPage>
  );
};
