import * as d3 from "d3";

import {
  greenLight,
  orangePrimary,
  orangeSecondary,
  redPrimary,
} from "themes/MainTheme";

export const sensitivityScale = [0, 3, 5, 10];
export const maxSensitivity = sensitivityScale.at(-1);
export const sensitivityColors = [
  greenLight,
  orangeSecondary,
  orangePrimary,
  redPrimary,
];

export const parseSensitivityColor = (sensitivity) => {
  return d3.scaleLinear(sensitivityScale, sensitivityColors)(sensitivity);
};
