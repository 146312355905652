import React, { useCallback, useMemo } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { UiPrimaryButton, UiSecondaryButton } from "./StyledButtons";

export default function MultipleOptionsDialog({
  cancelText,
  children,
  confirmText,
  context,
  onCancel,
  onConfirm,
  open,
  optionalText = null,
  optionalHandler = null,
  setOpen,
  text,
  title,
}) {
  const strings = useLocalizedStrings();

  const handleConfirm = useCallback(() => {
    onConfirm(context);
    setOpen(false);
  }, [setOpen, context, onConfirm]);

  const handleCancel = useCallback(
    () => (onCancel ? onCancel() : setOpen(false)),
    [onCancel, setOpen]
  );

  const actionButtons = useMemo(() => {
    return (
      <>
        <UiSecondaryButton key={cancelText} onClick={handleCancel}>
          {cancelText || strings.button_cancel}
        </UiSecondaryButton>

        {optionalText && optionalHandler && (
          <UiSecondaryButton key={optionalText} onClick={optionalHandler}>
            {optionalText}
          </UiSecondaryButton>
        )}
        <UiPrimaryButton key={confirmText} onClick={handleConfirm}>
          {confirmText || strings.button_ok}
        </UiPrimaryButton>
      </>
    );
  }, [
    cancelText,
    handleCancel,
    strings,
    optionalText,
    optionalHandler,
    confirmText,
    handleConfirm,
  ]);

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
          {text}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>{actionButtons}</DialogActions>
    </Dialog>
  );
}
