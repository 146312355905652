import { useMemo, useState } from "react";

import { ArrowDropDown, CloseOutlined, Visibility } from "@mui/icons-material";
import {
  Dialog,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

import { useUserSessionData } from "api/multiscenario";

import { CommentIndicator } from "components/scenarios/SessionCommentIndicator";
import DataLink from "components/ui/DataLink";
import Flexbox from "components/ui/Flexbox";
import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";
import RiskIndicator from "components/ui/RiskIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";
import TableCellText from "components/ui/TableCellText";

import { inUtc } from "utils/time-fmt";
import { formatInterval, timeFormatter, timeInterval } from "utils/time-fmt";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { UserSessionsGraph } from "./UserSessionsGraph";

const columns = (strings) => [
  {
    id: "actions",
    render: (v) => {
      return (
        <div style={{ display: "flex" }}>
          <DataLink
            to={`/scenarios/${v.scenario_id}/sessions/${v.app_session_id}/session-summary`}
            target="_blank"
          >
            <IconButton size="small">
              <Visibility />
            </IconButton>
          </DataLink>
          <CommentIndicator
            scenario={v.scenario_id}
            sessionId={v.app_session_id}
            comment={v.annotation}
          />
        </div>
      );
    },
    style: {
      width: "10px",
    },
  },
  {
    id: "start",
    label: strings.sessionstable_header_start,
    render: (v) => (
      <Typography variant="body1">
        {timeFormatter(new Date(v.start))}
      </Typography>
    ),
    style: {
      width: "10em",
    },
  },
  {
    id: "duration",
    label: strings.sessionstable_header_duration,
    render: (v) => (
      <Typography variant="body1">
        {formatInterval(timeInterval(v.start, v.end))}
      </Typography>
    ),
    style: {
      width: "7em",
    },
  },
  {
    id: "length",
    label: strings.sessionstable_header_length,
    render: (v) => <Typography variant="body1">{+v.num_events}</Typography>,
    style: {
      width: "7em",
    },
  },
  {
    id: "profile-id",
    label: strings.profile,
    render: (v) =>
      +v?.cluster > 0 ? (
        <DataLink
          to={`/scenarios/${v.scenario_id}/sessions?cluster=${+v.cluster}`}
          title={strings.sessionstable_header_profile_tooltip}
          target="_blank"
        >
          {`#${+v?.cluster}`}
        </DataLink>
      ) : null,
    style: {
      width: "8em",
    },
  },
  {
    id: "sensitivity",
    label: strings.sessionstable_header_sensitivity,
    sort: "num_sensitive_events",
    reverse_sort_indicator: true,
    render: (v) => (
      <SensitivityIndicator
        value={Math.abs(Math.min(+v.num_sensitive_events, 0))}
      />
    ),
    style: {
      width: "9em",
    },
  },
  {
    id: "whole_risk",
    label: strings.sessionstable_header_risk,
    render: (v) => (
      <DataLink
        to={() =>
          `/scenarios/${v.scenario_id}/sessions/${encodeURIComponent(
            v.app_session_id
          )}/risk-history`
        }
        target="_blank"
      >
        <RiskIndicator
          link
          value={v.whole_risk}
          reevaluated={!!v.reevaluated}
        />
      </DataLink>
    ),
    style: {
      width: "7em",
      paddingLeft: "10px",
    },
  },
  {
    id: "scenarios",
    label: "Scenario",
    render: (v) => <Typography variant="body1">{v.scenario_name}</Typography>,
    style: {
      width: "14em",
    },
  },
];

const TableHeader = () => {
  const strings = useLocalizedStrings();
  return (
    <>
      <colgroup>
        {columns(strings).map((c) => (
          <col key={c.id} style={c.style} />
        ))}
      </colgroup>

      <TableHead>
        <TableRow>
          {columns(strings).map((c) => (
            <TableCell key={c.id} style={c.style}>
              <TableCellText style={{ display: "flex" }}>
                <strong>{c.label}</strong>
                {c.id === "start" ? <ArrowDropDown /> : ""}
              </TableCellText>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};

function UserSessionsTable({ sessionsData, isPending, selectedId }) {
  const strings = useLocalizedStrings();

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHeader />
        <TableBody>
          {sessionsData?.map((s) => (
            <TableRow key={s.id} selected={s.id === selectedId}>
              {columns(strings).map((c) => (
                <TableCell key={c.id} style={c.style}>
                  {c.render(s)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function UserSessionsHeader({ onClose, tab, setTab }) {
  const strings = useLocalizedStrings();

  return (
    <Flexbox
      sx={{
        minHeight: "80px",
        backgroundColor: "purple.light",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: "20px",
      }}
    >
      <Typography variant="h5">{strings.multi_user_details_title}</Typography>
      <Typography variant="h5" color="white" px="10px">
        {strings.multi_user_details_subtitle}
      </Typography>
      <Tabs value={tab} onChange={(_, v) => setTab(v)} sx={{ ml: "auto" }}>
        <Tab label="Table" />
        <Tab label="Graph" />
      </Tabs>
      <IconButton onClick={onClose}>
        <CloseOutlined />
      </IconButton>
    </Flexbox>
  );
}

export function UserSessions({ selectedSession, scenarioId }) {
  const [tab, setTab] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const selectedId = `${selectedSession.app_session_id}+${scenarioId}`;
  const open = location.hash === "#multi-scenario";

  const { s, e } = useMemo(() => {
    const start = inUtc(selectedSession?.start);
    const end = inUtc(selectedSession?.end);
    const s = start.subtract(start.isToday() ? 2 : 1, "days").toISOString();
    const e = start.isToday()
      ? end.toISOString()
      : end.add(1, "days").toISOString();
    return { s, e };
  }, [selectedSession?.start, selectedSession?.end]);

  const { result, isPending } = useUserSessionData({
    user: selectedSession.orig_app_user_id,
    start: s,
    end: e,
    enabled: open,
  });

  const withSelected = useMemo(() => {
    if (!result) {
      return [];
    }
    return result.some((s) => s.id === selectedId)
      ? result
      : [...result, selectedSession].sort(
          (a, b) => new Date(b.start) - new Date(a.start)
        );
  }, [result, selectedSession, selectedId]);

  const handleClose = () => history.goBack();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: "70vw",
          width: "85vw",
          height: "65vh",
        },
      }}
    >
      <UserSessionsHeader onClose={handleClose} tab={tab} setTab={setTab} />
      {tab === 0 ? (
        <UserSessionsTable
          selectedId={selectedId}
          sessionsData={withSelected}
          isPending={isPending}
        />
      ) : (
        <UserSessionsGraph
          selectedId={selectedId}
          scenarioId={scenarioId}
          sessionsData={withSelected}
          start={s}
          end={e}
          isPending={isPending}
        />
      )}
    </Dialog>
  );
}
