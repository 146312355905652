import { useEffect, useRef, useState } from "react";

import useBackendEvents from "./useBackendEvents";
import useFeature from "./useFeature";

const backendEventsOfInterest = ["maintenance"];

export function useMaintenanceMode() {
  const maintenanceModeFeatureFlag = useFeature("devops/maintenance_mode");
  const [backendEvent] = useBackendEvents(backendEventsOfInterest);
  const prevValueRef = useRef("false");
  const [maintenanceMode, setMaintenanceMode] = useState("false");

  const maintenanceModeFinished = String(
    prevValueRef.current === "true" && maintenanceMode === "false"
  ).toLowerCase();

  useEffect(() => {
    setMaintenanceMode((prev) => {
      prevValueRef.current = prev;
      return String(
        backendEvent?.payload.value || maintenanceModeFeatureFlag
      ).toLowerCase();
    });
  }, [backendEvent, maintenanceModeFeatureFlag]);

  return {
    maintenanceMode,
    maintenanceModeFinished,
  };
}
