import React from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";

import useLocalizedStrings from "hooks/useLocalizedStrings";

export default function FiltersShowHideButton(props) {
  const strings = useLocalizedStrings();
  const { setOpenFilters } = props;
  return (
    <IconButton
      size="small"
      onClick={() => setOpenFilters?.((prev) => !prev)}
      title={strings.filters_show_hide_tooltip}
    >
      <FilterListIcon />
    </IconButton>
  );
}
