import { TableCell, TableHead, TableRow } from "@mui/material";

import TableCellText from "components/ui/TableCellText";

import useLocalizedStrings from "hooks/useLocalizedStrings";

export const TableColumnHeaders = ({ columns }) => {
  const strings = useLocalizedStrings();
  return (
    <>
      <colgroup>
        {columns(strings).map((c) => (
          <col key={c.id} style={{ width: c.width }} />
        ))}
      </colgroup>

      <TableHead>
        <TableRow>
          {columns(strings).map((c) => (
            <TableCell key={c.id}>
              <TableCellText>
                <strong>{c.label}</strong>
              </TableCellText>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};
