import { useContext } from "react";

import { useParams } from "react-router-dom";

import { ScenariosContext } from "contexts/ScenariosContext";

import Frame from "components/ui/Frame";
import FrameContent from "components/ui/FrameContent";
import FrameTitle from "components/ui/FrameTitle";
import FrameTitleText from "components/ui/FrameTitleText";
import SubNavigator from "components/ui/SubNavigator";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useTags } from "hooks/useTags";

import AlertDetails from "./AlertDetails";
import { FieldInsightDataContextProvider } from "./FieldInsightData";
import RiskHistory from "./RiskHistory";
import { SessionDetailsContextProvider } from "./SessionDetailsContext";
import { SessionInfo } from "./SessionInfo";
import SessionSummary from "./SessionSummary";
import SessionTokensPopularityChart from "./SessionTokensPopularityChart";
import AnomalyPage from "./anomaly/AnomalyPage";
import SessionTimeline from "./timeline/SessionTimeline";

function SessionFields({ session, scenario }) {
  const strings = useLocalizedStrings();

  const { fieldInsightData } = useTags({
    scenario,
    session,
    favoriteFields: true,
  });

  return (
    <FieldInsightDataContextProvider fieldInsightData={fieldInsightData}>
      <div
        style={{
          padding: "15px",
          overflowY: "auto",
          height: "calc(100vh - 160px)",
        }}
      >
        <Frame elevation={0} style={{ margin: 0 }}>
          <FrameTitle>
            <FrameTitleText>{strings.sessiondetails_tags_title}</FrameTitleText>
          </FrameTitle>
          <FrameContent style={{ height: "100%" }}>
            <SessionTokensPopularityChart
              scenario={scenario}
              session={session}
            />
          </FrameContent>
        </Frame>
      </div>
    </FieldInsightDataContextProvider>
  );
}

const tabs = (strings) => [
  {
    id: "session-summary",
    label: strings.sessionsdetails_navbar_summary,
    route: "session-summary",
    component: SessionSummary,
    caps: { "analysis-model.sessions": { read: true } },
  },
  {
    id: "session-timeline",
    label: strings.sessionsdetails_navbar_timeline,
    route: "session-timeline",
    component: SessionTimeline,
    caps: { "analysis-model.sessions": { read: true } },
  },
  {
    id: "fields",
    label: strings.sessionsdetails_navbar_tags,
    route: "session-fields",
    component: SessionFields,
    caps: { "analysis-model.sessions": { read: true } },
  },
  {
    id: "session-anomaly",
    label: strings.sessionsdetails_navbar_anomaly,
    route: "session-anomaly",
    component: AnomalyPage,
    caps: { "analysis-model.sessions": { read: true } },
  },
  {
    id: "risk-history",
    label: "Risk History",
    route: "risk-history",
    component: RiskHistory,
    caps: { "analysis-model.sessions": { read: true } },
  },
  {
    id: "alert-details",
    label: strings.sessionsdetails_navbar_alert,
    route: "alert-details",
    component: AlertDetails,
    caps: { "analysis-model.sessions": { read: true } },
    tabStyle: { marginLeft: "auto" },
  },
];

export default function SessionDetailsLayout(props) {
  const strings = useLocalizedStrings();
  const { session } = useParams();
  const { selectedScenario } = useContext(ScenariosContext);

  if (!selectedScenario || !session) {
    return null;
  }

  return (
    <SessionDetailsContextProvider
      session={session}
      scenario={selectedScenario.id}
    >
      <SubNavigator
        label={strings.sessionsdetails_navbar_name}
        scenario={selectedScenario.id}
        session={session}
        tabsSpec={tabs(strings)}
        routes={tabs(strings)}
        nestLevel={1}
        backPath={`/scenarios/${selectedScenario.id}/dashboard`}
        {...props}
      >
        <SessionInfo scenario={selectedScenario.id} session={session} />
      </SubNavigator>
    </SessionDetailsContextProvider>
  );
}
