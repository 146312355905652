import { useEffect, useState } from "react";

import {
  Divider,
  FormControlLabel,
  MenuItem,
  RadioGroup,
  styled,
} from "@mui/material";
import { isEqual } from "lodash-es";
import { useFieldArray, useFormContext } from "react-hook-form";

import { AddButton } from "components/scenarios/MappingEditor/components/AddButton";
import { Expander } from "components/scenarios/MappingEditor/components/Expander";
import { SectionErrorMessage } from "components/scenarios/MappingEditor/components/SectionErrorMessage";
import { SectionWrap } from "components/scenarios/MappingEditor/components/SectionWrap";
import BrandedRadio from "components/ui/BrandedRadio";
import { DeleteButton } from "components/ui/DeleteButton";
import Flexbox from "components/ui/Flexbox";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import { CheckboxInput } from "components/ui/form/CheckboxInput";
import { HiddenInput } from "components/ui/form/HiddenInput";
import { SelectorInput } from "components/ui/form/SelectorInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { useExpanded } from "hooks/useExpanded";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledEnrichmentWrapper = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "flex-start",
  marginTop: "20px",

  "& .itemDivider": {
    background: "black",
    marginTop: "20px",
    marginBottom: "20px",
    height: "1px",
  },
  "& .itemWrapper": {
    height: "auto",
    padding: "10px 0px 10px 25px",
    width: "95%",
    transition: "max-height 500ms ease",
    "&.expanded": {
      maxHeight: "600px",
    },
  },

  "& .labelRulesWrapper": {
    height: "100%",
    justifyContent: "space-evenly",
    paddingBottom: "10px",
    width: "100%",
  },
  "& .itemFooter": {
    alignContent: "center",
    display: "flex",
    gap: "20px",
    height: "40px",
  },
}));

const defaultAppendValue = {
  enrichment_file_field: "",
  event_field: "",
};
const inputStyle = { height: "54px", width: "25em" };

const useFileInS3 = (initialVal) => {
  const [fileInS3, setFileInS3] = useState(false);

  useEffect(() => {
    setFileInS3(initialVal);
  }, [initialVal]);

  return {
    fileInS3,
    setFileInS3,
  };
};

const FileTypeSelector = ({ handleFileTypeChange, disabled, fileType }) => {
  const strings = useLocalizedStrings();
  return (
    <RadioGroup
      row={true}
      value={fileType}
      onChange={handleFileTypeChange}
      sx={{ pb: 2 }}
    >
      <FormControlLabel
        disabled={disabled}
        value={false}
        control={<BrandedRadio />}
        label={strings.text_file}
      />
      <FormControlLabel
        disabled={disabled}
        value={true}
        control={<BrandedRadio />}
        label={strings.text_file_s3}
      />
    </RadioGroup>
  );
};

const FileSelector = ({ name, s3FieldName, files, fileInS3 }) => {
  const strings = useLocalizedStrings();

  if (fileInS3) {
    return (
      <TextFieldInput
        name={s3FieldName}
        required={true}
        style={inputStyle}
        label={
          strings.sourcedialog_mapping_editor_enrichment_tab_enrichment_s3_prefix
        }
      />
    );
  }

  return (
    <SelectorInput
      required={true}
      name={name}
      label={
        strings.sourcedialog_mapping_editor_enrichment_tab_enrichment_file_id_label
      }
      style={inputStyle}
      rules={{
        validate: (val) =>
          files?.some((f) => f.id === val) ||
          strings.sourcedialog_mapping_editor_enrichment_tab_invalid_file_error,
      }}
    >
      {files?.map((f) => (
        <MenuItem value={f.id} key={f.id}>
          {f.name}
        </MenuItem>
      ))}
    </SelectorInput>
  );
};

export const EnrichmentTabItemComponent = ({
  files,
  dataKey,
  onDelete,
  enrichmentFromBucket = false,
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const strings = useLocalizedStrings();

  const {
    setError,
    clearErrors,
    getValues,
    formState: { errors, defaultValues },
  } = useFormContext();
  const namePrefix = `options.internal_config.enrichment.entries.${dataKey}`;
  const fieldArrayName = `${namePrefix}.field_mapping`;
  const errs = errors?.options?.internal_config?.enrichment?.entries?.[dataKey];

  const hasS3File =
    !!defaultValues?.options?.internal_config?.enrichment?.entries?.[dataKey]
      ?.s3_prefix;
  const { fileInS3, setFileInS3 } = useFileInS3(hasS3File);

  const { fields, append, remove } = useFieldArray({
    name: fieldArrayName,
  });

  useEffect(() => {
    if (fields?.length === 0) {
      setError(namePrefix, {
        customRule: {
          type: "custom",
          message:
            strings.sourcedialog_mapping_editor_enrichment_tab_no_labels_errors,
        },
      });
      return;
    }
    const ruleError =
      errors?.options?.internal_config?.enrichment?.entries?.[dataKey];
    if (!!ruleError?.customRule) {
      clearErrors([namePrefix]);
    }
  }, [
    fields?.length,
    setError,
    clearErrors,
    errors,
    namePrefix,
    strings.sourcedialog_mapping_editor_enrichment_tab_no_labels_errors,
    dataKey,
  ]);

  const { expandedState, isExpandingDisabled, handleExpand } = useExpanded(
    fields?.length
  );

  const handleDelete = (index) => {
    const values = getValues(`${namePrefix}.field_mapping.${index}`);

    if (isEqual(values, defaultAppendValue)) {
      remove(index);
      return;
    }

    setConfirmDeleteOpen(true);
    setDeleteIndex(index);
  };

  const handleConfirmationAction = () => {
    remove(deleteIndex);
  };

  const errorClasses = !!errs?.label_rules?.root
    ? "customError"
    : errs
    ? "errorSection"
    : "";

  const handleAddNewItem = () => {
    append(defaultAppendValue);
  };

  const handleFileTypeChange = (event) => {
    enrichmentFromBucket && setFileInS3(event.target.value === "true");
  };

  const pfx = (string) => `${namePrefix}.${string}`;

  return (
    <StyledEnrichmentWrapper>
      <DeleteButton onClick={() => onDelete(dataKey)} />
      <SectionWrap className={errorClasses}>
        <div className="itemWrapper">
          <FileTypeSelector
            handleFileTypeChange={handleFileTypeChange}
            disabled={!enrichmentFromBucket}
            fileType={fileInS3}
          />
          <div
            style={{
              display: "flex",
              gap: "1em",
              paddingBottom: "10px",
            }}
          >
            <HiddenInput name={pfx("type")} />
            <TextFieldInput
              name={pfx("identifier.event_field")}
              required={true}
              style={inputStyle}
              label={
                strings.sourcedialog_mapping_editor_enrichment_tab_event_identifier_label
              }
            />
            <TextFieldInput
              name={pfx("identifier.enrichment_file_field")}
              required={true}
              style={inputStyle}
              label={
                strings.sourcedialog_mapping_editor_enrichment_tab_enrichment_file_identifier_label
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "1em",
              paddingBottom: "10px",
            }}
          >
            <FileSelector
              key={fileInS3}
              name={pfx("file_id")}
              s3FieldName={pfx("s3_prefix")}
              fileInS3={fileInS3}
              files={files}
            />
            <CheckboxInput
              name={pfx("copy_original_value")}
              label={
                strings.sourcedialog_mapping_editor_enrichment_tab_enrichment_checkbox_label
              }
            />
          </div>
          <Divider
            className="itemDivider"
            flexItem
            orientation="horizontal"
            variant="fullWidth"
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="labelRulesWrapper">
              {fields.map((item, idx) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    paddingBottom: "1em",
                    gap: "1em",
                  }}
                  key={item.id}
                >
                  <TextFieldInput
                    name={pfx(`field_mapping.${idx}.enrichment_file_field`)}
                    required={true}
                    label={
                      strings.sourcedialog_mapping_editor_enrichment_tab_enrichment_file_field_label
                    }
                    style={inputStyle}
                  />
                  <TextFieldInput
                    name={pfx(`field_mapping.${idx}.event_field`)}
                    required={true}
                    label={
                      strings.sourcedialog_mapping_editor_enrichment_tab_enrichment_event_field_label
                    }
                    style={inputStyle}
                  />
                  <div>
                    <DeleteButton onClick={() => handleDelete(idx)} />
                  </div>
                </div>
              ))}
              {!!errs?.customRule && (
                <SectionErrorMessage style={{ paddingTop: "10px" }}>
                  {errs?.customRule?.message}
                </SectionErrorMessage>
              )}
            </div>
            <div className="itemFooter">
              <Flexbox>
                <AddButton onClick={handleAddNewItem} />
              </Flexbox>
            </div>
          </div>
        </div>

        <Expander
          isExpandingDisabled={isExpandingDisabled}
          expandedState={expandedState}
          handleExpand={handleExpand}
        />
      </SectionWrap>

      <MultipleOptionsDialog
        onConfirm={handleConfirmationAction}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        text={
          strings.sourcedialog_mapping_editor_enrichment_tab_delete_item_question
        }
        title={
          strings.sourcedialog_mapping_editor_enrichment_tab_delete_item_title
        }
      />
    </StyledEnrichmentWrapper>
  );
};
