import { styled } from "@mui/material";

export const BaseAccountPage = styled("div")(
  () => `
    height: calc(100vh - var(--appbar-height) - 49px);
    width: 70%;
    display: grid;
    min-width: 1100px;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    padding: 2em;
    gap: 1em;`
);
