const whois = "https://who.is/whois-ip/ip-address";

export default function IpAddressLink({ className = "", ip, value }) {
  return (
    <a
      className={className}
      target="_blank"
      rel="noreferrer"
      href={`${whois}/${ip}`}
      style={{ display: "inline" }}
    >
      {value}
    </a>
  );
}
