import React from "react";

import { Box, Typography, styled } from "@mui/material";

const StyledInformationStripItem = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "GhostWhite",
  borderLeft: "1px solid #ddd",
  display: "flex",
  fontSize: ".8rem",
  height: "3em",
  justifyContent: "center",
  overflow: "clip",
  padding: theme.spacing(0.8),
  position: "relative",
  "&[label]::before": {
    content: "attr(label)",
    fontSize: "8pt",
    fontWeight: "bold",
    left: "3px",
    position: "absolute",
    textTransform: "uppercase",
    top: "1px",
  },
  "& .infoStripContent": {
    alignItems: "center",
    display: "flex",
    fontSize: "inherit",
    marginTop: "1em",
  },
}));

function InformationStripItem({ children, label, hover, ...rest }, ref) {
  return (
    <StyledInformationStripItem
      className={hover ? " hover" : ""}
      flexGrow={1}
      label={label}
      ref={ref}
      {...rest}
    >
      <Typography
        className="infoStripContent"
        display="block"
        variant="body2"
        component={(props) => <div {...props} />}
      >
        {children}
      </Typography>
    </StyledInformationStripItem>
  );
}

export default React.forwardRef(InformationStripItem);
