import { CircularProgress } from "@mui/material";

export const PageLoadingSpinner = () => (
  <div
    style={{
      height: "100%",
      width: "90%",
      display: "flex",
      placeItems: "center",
      margin: "auto",
    }}
  >
    <CircularProgress
      style={{ margin: "auto" }}
      size={100}
      sx={{ color: "purple.main" }}
    />
  </div>
);
