import { useContext } from "react";

import { FormGroup } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { ScenariosContext } from "contexts/ScenariosContext";

import Flexbox from "components/ui/Flexbox";
import FrameActions from "components/ui/FrameActions";
import { UiPrimaryButton } from "components/ui/StyledButtons";
import FileInput from "components/ui/form/FileInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const CsvSettingsAnalysisEngineForm = ({ open, onAccept }) => {
  const strings = useLocalizedStrings();

  const { selectedScenario } = useContext(ScenariosContext);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      files: "",
      description: "",
    },
  });

  const onSubmit = ({ files, description }) => {
    onAccept(selectedScenario.id, files, description);
  };

  const isValid = methods.formState.isValid;

  return (
    <div className={`collapse ${open ? "open" : "closed"}`}>
      <FormProvider {...methods}>
        <form
          className="flexbox flexbox-hcenter"
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Flexbox justifyContent="center" width={1}>
            <FormGroup
              style={{
                width: "100%",
                maxWidth: "47em",
                height: "100%",
              }}
            >
              <FileInput
                control={methods.control}
                accept={"*"}
                name="files"
                key={open}
                required={true}
                style={{ width: "100%" }}
              />

              <TextFieldInput
                label="Description"
                name="description"
                required
                rules={{
                  minLength: {
                    value: 3,
                    message: strings.formatString(
                      strings.invalid_min_length,
                      3
                    ),
                  },
                }}
                inputProps={{
                  style: {
                    height: 40,
                  },
                }}
              />
            </FormGroup>
          </Flexbox>
          <FrameActions>
            <UiPrimaryButton type="submit" disabled={!isValid}>
              {strings.button_save}
            </UiPrimaryButton>
          </FrameActions>
        </form>
      </FormProvider>
    </div>
  );
};

export default CsvSettingsAnalysisEngineForm;
