import { useState } from "react";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  RadioGroup,
} from "@mui/material";

import BrandedRadio from "components/ui/BrandedRadio";
import NotAllowedMessage from "components/ui/NotAllowedMessage";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import HistogramSlicer from "./HistogramSlicer";
import ProfileActionsHistogramChart from "./ProfileActionsHistogramChart";
import ProfileTabContainer from "./ProfileTabContainer";
import StyledChartWrapper from "./StyledChartWrapper";

export default function ProfileActionsHistogram({ profile, isPending }) {
  const strings = useLocalizedStrings();
  const [mode, setMode] = useState("relative");
  const [translated, setTranslated] = useState(true);
  const [orderBy, setOrderBy] = useState("occurences");
  const [slice, setSlice] = useState(100);
  const caps = useCapabilities();

  if (!caps({ "analysis-model.clusters": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <ProfileTabContainer
      loading={isPending}
      explanation={strings.profiledetails_tab_actions_explanation}
    >
      <StyledChartWrapper>
        <div className="toolbar">
          <FormGroup label="View" row>
            <HistogramSlicer
              value={slice}
              onChange={(e) => setSlice(e.target.value)}
            />
          </FormGroup>

          <FormGroup label="Actions" row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={translated}
                  onChange={(e) => setTranslated(e.target.checked)}
                  color="primary"
                />
              }
              label={strings.profiledetails_tab_actions_translated}
            />
          </FormGroup>

          <RadioGroup
            label={strings.profiledetails_tab_actions_quantities}
            row
            className="chart-modes"
            name="chart-mode"
            value={mode}
            onChange={(e) => setMode(e.target.value)}
          >
            <FormControlLabel
              value="relative"
              control={<BrandedRadio />}
              label={strings.profiledetails_tab_actions_quantities_relative}
              labelPlacement="end"
            />
            <FormControlLabel
              value="absolute"
              control={<BrandedRadio />}
              label={strings.profiledetails_tab_actions_quantities_absolute}
              labelPlacement="end"
            />
          </RadioGroup>

          <RadioGroup
            label={strings.profiledetails_tab_actions_orderby}
            row
            className="chart-order"
            name="chart-order"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
          >
            <FormControlLabel
              value="occurences"
              control={<BrandedRadio />}
              label={strings.profiledetails_tab_actions_orderby_occurences}
              labelPlacement="end"
            />
            <FormControlLabel
              value="sensitivity"
              control={<BrandedRadio />}
              label={strings.profiledetails_tab_actions_orderby_sensitivity}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>

        <ProfileActionsHistogramChart
          histogram={profile?.histogram || []}
          mode={mode}
          translated={translated}
          orderBy={orderBy}
          slice={slice}
        />
      </StyledChartWrapper>
    </ProfileTabContainer>
  );
}
