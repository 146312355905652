import { useMemo } from "react";

import { styled } from "@mui/material";

const StyledAlerterDetails = styled("table")`
  tbody {
    th,
    td {
      padding: 5px 1em;
    }
    th {
      white-space: nowrap;
    }
  }
`;

export const AlerterDetails = ({ alerter }) => {
  const details = useMemo(() => {
    if (!alerter) {
      return [];
    }
    return Object.entries(alerter.options)
      .map((d) => ({
        key: d[0],
        value: d[1],
        metadata: alerter.available_options[d[0]],
      }))
      .filter((d) => d.metadata?.display);
  }, [alerter]);

  return (
    <StyledAlerterDetails>
      <tbody>
        {details.map((d) => (
          <tr key={d.key} title={d.metadata.help}>
            <th>{d.metadata.label}</th>
            <td>{d.value}</td>
          </tr>
        ))}
      </tbody>
    </StyledAlerterDetails>
  );
};
