import { useEffect, useState } from "react";

import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import StackedBarChartOutlinedIcon from "@mui/icons-material/StackedBarChartOutlined";
import { Typography, styled } from "@mui/material";

import { UiSecondaryButton } from "components/ui/StyledButtons";

import { AlertsDailyTrendsChart } from "./AlertsDailyTrendsChart";
import { AlertsList } from "./AlertsList";
import { ScenariosMultiSelect } from "./ScenariosMultiSelect";
import { SectionTitle } from "./SectionTitle";

const StyledAnalyticsContentArea = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  flexBasis: "100%",
  height: "99%",
  margin: "4px",
  minWidth: "1000px",
  padding: "8px",

  "& .subtitle-wrap": {
    display: "flex",
    justifyContent: "space-between",
    "& .subtitle": {
      paddingTop: "10px",
      paddingLeft: "10px",
    },
  },
  "& .content-wrapper": {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 70px)",
  },
  "& .dailyTrends-wrapper": {
    height: "50%",
    marginBottom: "10px",
  },
  "& .chart-wrapper": {
    position: "relative",
    marginTop: "10px",
    height: "90%",
    borderTop: `1px solid ${theme.palette.divider} `,
    "& canvas": {
      position: "absolute",
    },
  },
  "& .chart-filter": {
    fontSize: ".8em",
    fontWeight: "bold",
    padding: "5px 5px",
    border: `1px solid ${theme.palette.button.secondary.main}`,

    "&.active": {
      background: theme.palette.secondary.purple50,
      color: theme.palette.button.primary.contrastText,
    },

    "&:hover": {
      background: theme.palette.button.secondary.hoverLight,
    },
  },

  "& .select": {
    border: `1px solid ${theme.palette.button.secondary.main}`,
    borderRadius: "6px",
    height: "40px",
    width: "170px",

    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    ".MuiSvgIcon-root ": {
      fill: theme.palette.purple.light2,
    },
  },
}));

const filterPresets = [10, 30, 60].reduce((acc, num) => {
  acc[`Last ${num} days`] = num;
  return acc;
}, {});

const SubtitleControls = ({
  numDays,
  setNumDays,
  scenarios,
  filteredScenarios,
  setFilteredScenarios,
  setIsBarChart,
  isBarChart,
}) => {
  return (
    <div className="subtitle-wrap">
      <Typography variant="h6" className="subtitle">
        Daily Trends
      </Typography>
      <div style={{ display: "flex", gap: "5px" }}>
        <ScenariosMultiSelect
          scenarios={scenarios}
          filteredScenarios={filteredScenarios}
          setFilteredScenarios={setFilteredScenarios}
        />
        {Object.entries(filterPresets).map(([k, v]) => (
          <UiSecondaryButton
            className={`chart-filter ${numDays === v ? "active" : ""}`}
            key={v}
            onClick={() => setNumDays(v)}
            value={v}
            variant="outlined"
            style={{ minWidth: "10em" }}
          >
            {k}
          </UiSecondaryButton>
        ))}

        <UiSecondaryButton
          className="chart-filter active"
          variant="outlined"
          onClick={() => setIsBarChart((prev) => !prev)}
          style={{ width: "40px", marginLeft: "20px" }}
        >
          {isBarChart ? (
            <SsidChartOutlinedIcon fontSize="small" />
          ) : (
            <StackedBarChartOutlinedIcon fontSize="small" />
          )}
        </UiSecondaryButton>
      </div>
    </div>
  );
};

export const AnalyticsContentArea = ({ scenarios }) => {
  const [numDays, setNumDays] = useState(30);
  const [filteredScenarios, setFilteredScenarios] = useState(scenarios);
  const [isBarChart, setIsBarChart] = useState(true);

  useEffect(() => {
    setFilteredScenarios(scenarios);
  }, [scenarios]);

  return (
    <StyledAnalyticsContentArea numDays={numDays}>
      <SectionTitle>Alerts</SectionTitle>
      <div className="content-wrapper">
        <div className="dailyTrends-wrapper">
          <SubtitleControls
            numDays={numDays}
            scenarios={scenarios}
            filteredScenarios={filteredScenarios}
            setFilteredScenarios={setFilteredScenarios}
            setNumDays={setNumDays}
            setIsBarChart={setIsBarChart}
            isBarChart={isBarChart}
          />
          <AlertsDailyTrendsChart
            numDays={numDays}
            scenarios={filteredScenarios}
            isBarChart={isBarChart}
          />
        </div>
        <AlertsList numDays={numDays} scenarios={filteredScenarios} />
      </div>
    </StyledAnalyticsContentArea>
  );
};
