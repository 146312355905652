import { Component } from "react";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Typography } from "@mui/material";
import { fromError } from "stacktrace-js";

import stdFetch from "utils/stdFetch";

import { UiDangerButton } from "./ui/StyledButtons";

async function sendErrorLog(message, stack, context) {
  if (import.meta.env.DEV) {
    return;
  }
  await stdFetch({
    url: `/api/acm/v1/frontend/error_logger`,
    options: {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        message: `APP: ${message}`,
        details: {
          stack,
          context,
        },
      }),
    },
  });
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    fromError(error)
      .then((stack) => {
        sendErrorLog(error.message, stack[0].toString(), this.props.context);
      })
      .catch(() =>
        sendErrorLog(error.message, info.componentStack, this.props.context)
      );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          role="alert"
          style={{
            alignItems: "center",
            color: "#d32f2f",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            height: "90%",
            justifyContent: "center",
            padding: "10px",
            flexGrow: 1,
          }}
        >
          <ErrorOutlineOutlinedIcon style={{ width: "48px", height: "48px" }} />
          <Typography variant="h6" align="center">
            Something went wrong
          </Typography>
          <UiDangerButton
            size="small"
            onClick={() =>
              this.setState({
                hasError: false,
              })
            }
          >
            Try again
          </UiDangerButton>
        </div>
      );
    }

    return this.props.children;
  }
}
