import React, { useCallback, useContext, useMemo, useState } from "react";

import { Checkbox, FormControlLabel, styled } from "@mui/material";

import { useGetClusterHistogramQuery } from "api/clusters";
import { useSessionsGetHistogramQuery } from "api/sessions";

import { ScenariosContext } from "contexts/ScenariosContext";

import {
  RaritySelector,
  SensitivitySelector,
} from "components/sessions/GradientSelectors";
import FrameActions from "components/ui/FrameActions";
import FrameContent from "components/ui/FrameContent";
import FrameTitle from "components/ui/FrameTitle";
import FrameTitleText from "components/ui/FrameTitleText";
import { PaperFrame } from "components/ui/PaperFrame";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import Radar from "./Radar";

const StyledSelect = styled("select")`
  padding: 5px 10px;
  border: 1px solid #ddd;
  text-align: center;
  border-radius: 100vh;
  min-width: 8em;
  outline: none !important;
`;

const StyledAnomalyChartContainer = styled("div")`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  gap: 8px;
`;

function enrich(h, histType) {
  if (!h || !h.length) {
    return [];
  }
  const make_s = (s) => {
    const rescale_s = (s) => Math.abs(Math.min(+s, 0));
    if (typeof s === "object") {
      return Math.max(rescale_s(s.s1), rescale_s(s.s2));
    }
    return rescale_s(s);
  };
  const make_k = (k) => {
    if (histType === "paths") {
      try {
        const kj = JSON.parse(k);
        return Object.entries(kj)
          .map((d) => d[1].trim().replace(/[\t]+/g, ";"))
          .join("\n=>\n");
      } catch {}
    }
    return k.replace(/[\t]+/g, "; ");
  };
  const make_d = (d) => {
    if (!d) {
      return;
    }
    if (typeof d === "object") {
      return Object.entries(d)
        .map((t) => t[1].trim())
        .join(", ");
    }
    return d.replace(/[\t]+/g, "; ");
  };
  const total = h.reduce((a, d) => {
    a += d.c;
    return a;
  }, 0);
  return h.map((d) => ({
    ...d,
    k: make_k(d.k),
    s: make_s(d.s),
    d: make_d(d?.d),
    nc: (d.c * 100) / total,
    rarity_score: +d.rarity_score,
  }));
}

const useAnomalyHistograms = ({
  session,
  scenario,
  histogramType,
  sensitivityRange,
  rarityRange,
  topSelection,
}) => {
  const isProfiled = session?.cluster > 0;
  const hasNearest = session?.nearest_cluster && session?.nearest_distance < 1;

  const { data: sessionHistogram, isPending: isPending1 } =
    useSessionsGetHistogramQuery(
      {
        entity: "session",
        scenario,
        session: session?.app_session_id,
        type: histogramType,
      },
      { enabled: !!session?.app_session_id }
    );
  const { data: sessionClusterHist, isPending: sessionClusterHistLoading } =
    useSessionsGetHistogramQuery(
      {
        entity: "cluster",
        scenario,
        session: session?.app_session_id,
        type: histogramType,
      },
      {
        enabled: !isProfiled === false && !!session?.app_session_id,
      }
    );
  const { data: profileClusterHist, isPending: profileClusterHistLoading } =
    useGetClusterHistogramQuery(
      {
        scenarioId: scenario,
        cluster: session?.nearest_cluster,
        type: histogramType,
      },
      {
        enabled:
          !isProfiled === true && !!session?.nearest_cluster && hasNearest,
      }
    );
  const [clusterHistogram, isPending2] = !isProfiled
    ? hasNearest
      ? [profileClusterHist, profileClusterHistLoading]
      : [[], false]
    : [sessionClusterHist, sessionClusterHistLoading];

  const [enrichedSessionHist, enrichedClusterHist] = useMemo(() => {
    if (isPending1 || isPending2) {
      return [[], []];
    }
    const applyRarityAndSensitivity = (d) =>
      histogramType !== "delta_t"
        ? d.s >= sensitivityRange[0] &&
          d.s <= sensitivityRange[1] &&
          d.rarity_score >= rarityRange[0] &&
          d.rarity_score <= rarityRange[1]
        : true;
    const objectify = (a, d) => {
      a[d.k] = d;
      return a;
    };

    // enrich and objectify
    const sh = enrich(sessionHistogram, histogramType)
      .filter(applyRarityAndSensitivity)
      .reduce(objectify, {});
    const ch = enrich(clusterHistogram, histogramType)
      .filter(applyRarityAndSensitivity)
      .reduce(objectify, {});

    // combine
    const allKeys = Array.from(
      new Set([...Object.keys(sh), ...Object.keys(ch)])
    );
    const composite = allKeys.map((k) => ({ s: sh[k], c: ch[k] }));
    const sliced = composite
      .sort((a, b) => (b?.s?.c || 0) - (a?.s?.c || 0))
      .slice(0, Math.max((composite.length * topSelection) / 100, 1))
      .filter((item) => item?.c?.nc >= 0.1 || item?.s?.nc >= 0.1);

    const shOut = sliced.map((d) => d.s).filter((d) => !!d);
    const chOut = sliced.map((d) => d.c).filter((d) => !!d);

    return [shOut, chOut];
  }, [
    histogramType,
    isPending1,
    isPending2,
    sessionHistogram,
    clusterHistogram,
    topSelection,
    sensitivityRange,
    rarityRange,
  ]);

  return {
    enrichedSessionHist,
    enrichedClusterHist,
    loading: isPending1 || isPending2,
    isProfiled,
  };
};

const Anomaly = ({ scenario, session }) => {
  const strings = useLocalizedStrings();
  const [histogramType, setHistogramType] = useState("locations");
  const [colorBy, setColorBy] = useState("sensitivity");
  const [topSelection, setTopSelection] = useState(100);
  const [sensitivityRange, setSensitivityRange] = useState([0, 10]);
  const [rarityRange, setRarityRange] = useState([0, 5]);
  const [translated, setTranslated] = useState(true);
  const { selectedScenario } = useContext(ScenariosContext);
  const { enrichedClusterHist, enrichedSessionHist, isPending, isProfiled } =
    useAnomalyHistograms({
      session,
      scenario,
      topSelection,
      histogramType,
      sensitivityRange,
      rarityRange,
    });

  const timeHistogramEnabled = selectedScenario.enable_time_gap;
  const timeSelected = histogramType === "delta_t";

  const xTitle =
    histogramType === "locations"
      ? strings.sessiondetails_anomaly_chart_x_locations
      : histogramType === "paths"
      ? strings.sessiondetails_anomaly_chart_x_paths
      : strings.sessiondetails_anomaly_chart_x_time_gap;

  const yTitle = strings.sessiondetails_anomaly_chart_y;

  const explanationText = useMemo(() => {
    if (!isProfiled) {
      return histogramType === "locations"
        ? strings.sessiondetails_anomaly_nearest_actions_explanation
        : histogramType === "paths"
        ? strings.sessiondetails_anomaly_nearest_transitions_explanation
        : strings.sessiondetails_anomaly_nearest_time_gap_explanation;
    }
    return histogramType === "locations"
      ? strings.sessiondetails_anomaly_actions_explanation
      : histogramType === "paths"
      ? strings.sessiondetails_anomaly_transitions_explanation
      : strings.sessiondetails_anomaly_time_gap_explanation;
  }, [isProfiled, histogramType, strings]);

  const handleHistogramSelectionChanged = useCallback((e) => {
    setHistogramType(e.target.value);
    setTopSelection(100);
    if (e.target.value === "delta_t") {
      setColorBy("sensitivity");
    }
  }, []);

  const handleColorSystemChanged = useCallback((e) => {
    setColorBy(e.target.value);
  }, []);

  const handleTopSelectionChanged = useCallback((e) => {
    setTopSelection(e.target.value);
  }, []);

  const anomalyTitle = useMemo(() => {
    return histogramType === "locations"
      ? strings.sessiondetails_anomaly_actions_title
      : histogramType === "paths"
      ? strings.sessiondetails_anomaly_transitions_title
      : strings.sessiondetails_anomaly_time_gap_title;
  }, [histogramType, strings]);

  return (
    <PaperFrame>
      <FrameTitle>
        <FrameTitleText>{anomalyTitle}</FrameTitleText>
      </FrameTitle>
      <FrameContent style={{ height: "100%" }}>
        <StyledAnomalyChartContainer>
          <p>{explanationText}</p>
          {!isPending && (
            <Radar
              clusterhist={enrichedClusterHist}
              xTitle={xTitle}
              yTitle={yTitle}
              sessionhist={enrichedSessionHist}
              isProfiled={isProfiled}
              colorBy={colorBy}
              translated={translated}
              nearestDistance={session?.nearest_distance}
            />
          )}
        </StyledAnomalyChartContainer>
      </FrameContent>

      <FrameActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={translated}
              color="primary"
              onChange={(e) => setTranslated(e.target.checked)}
              disabled={timeSelected}
            />
          }
          label={strings.profiledetails_tab_actions_translated}
        />
        <RaritySelector
          range={rarityRange}
          setRange={setRarityRange}
          disabled={timeSelected}
        />
        <SensitivitySelector
          minSensitivity={sensitivityRange}
          setMinSensitivity={setSensitivityRange}
          disabled={timeSelected}
        />

        <StyledSelect
          value={colorBy}
          disabled={timeSelected}
          onChange={handleColorSystemChanged}
        >
          <option value="sensitivity">
            {strings.sessiondetails_anomaly_color_system_sensitivity}
          </option>
          <option value="rarity">
            {strings.sessiondetails_anomaly_color_system_rarity}
          </option>
        </StyledSelect>

        <StyledSelect
          disabled={timeSelected}
          value={topSelection}
          onChange={handleTopSelectionChanged}
        >
          <option value={5}>5%</option>
          <option value={10}>10%</option>
          <option value={25}>25%</option>
          <option value={50}>50%</option>
          <option value={75}>75%</option>
          <option value={100}>100%</option>
        </StyledSelect>

        <StyledSelect
          value={histogramType}
          onChange={handleHistogramSelectionChanged}
        >
          <option value="locations">
            {strings.sessiondetails_anomaly_anomaly_view_actions}
          </option>
          <option value="paths">
            {strings.sessiondetails_anomaly_anomaly_view_transitions}
          </option>
          {timeHistogramEnabled && (
            <option value="delta_t">
              {strings.sessiondetails_anomaly_anomaly_view_time_gap}
            </option>
          )}
        </StyledSelect>
      </FrameActions>
    </PaperFrame>
  );
};

export default Anomaly;
