import React from "react";

import { styled } from "@mui/material";
import Box from "@mui/material/Box";

const StyledFrameContent = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  padding: theme.spacing(1),
  flexGrow: 0,
  overflow: "hidden",
}));

export default function FrameContent({ children, ...other }) {
  return <StyledFrameContent {...other}>{children}</StyledFrameContent>;
}
