import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { useStore } from "store";

import ScenariosContextProvider, {
  ScenariosContext,
} from "contexts/ScenariosContext";
import { TagsVisibilityContextProvider } from "contexts/TagsVisibilityContext";

import ErrorBoundary from "components/ErrorBoundary";
import Flexbox from "components/ui/Flexbox";

import ScenarioContentLayout from "./ScenarioContentLayout";
import ScenariosSidebar from "./ScenariosSidebar";

const StyledScenariosPage = styled(Flexbox)(() => ({
  height: "100%",
  margin: 0,
  position: "absolute",
  width: "100%",
  "& .sidebar-pane": {
    height: "100%",
    maxWidth: "var(--sidebar-width)",
    minWidth: "var(--sidebar-width)",
    overflowY: "auto",
    zIndex: 9999,
  },
  "& .content-pane": {
    alignItems: "start",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    height: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    position: "relative",
    width: "100%",
  },
  "& .sidebar-pane + .content-pane": {
    width: "calc(100vw - var(--sidebar-width))",
  },
}));

const ScenariosErrorBoundary = ({ children }) => {
  const { selectedScenario } = useContext(ScenariosContext);

  const errorContext = useMemo(() => {
    return {
      selectedScenario: selectedScenario?.id,
      scenarioName: selectedScenario?.name,
    };
  }, [selectedScenario?.id, selectedScenario?.name]);

  return (
    <ErrorBoundary key={selectedScenario?.id} context={errorContext}>
      {children}
    </ErrorBoundary>
  );
};

function ScenariosPage() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const fetchAppData = useStore(useCallback((state) => state.getApps, []));
  useEffect(() => {
    fetchAppData();
  }, [fetchAppData]);

  return (
    <ScenariosContextProvider>
      <TagsVisibilityContextProvider>
        <StyledScenariosPage
          className="scenariosPage"
          alignItems="stretch"
          width={1}
        >
          <Slide direction="right" in={sidebarOpen} mountOnEnter unmountOnExit>
            <Box className="sidebar-pane">
              <ErrorBoundary>
                <ScenariosSidebar />
              </ErrorBoundary>
            </Box>
          </Slide>
          <Box className="content-pane">
            <ScenariosErrorBoundary>
              <ScenarioContentLayout
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
            </ScenariosErrorBoundary>
          </Box>
        </StyledScenariosPage>
      </TagsVisibilityContextProvider>
    </ScenariosContextProvider>
  );
}

export default ScenariosPage;
