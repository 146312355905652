import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import StdTextField from "components/ui/StdTextField";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const CollectorCloneDialog = ({ open, collector, onCancel, onAccept }) => {
  const strings = useLocalizedStrings();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: `${collector?.name} (${strings.text_copy})`,
      description: `${collector?.description} (${strings.text_copy})`,
    },
  });

  return (
    <Dialog open={open} fullWidth>
      <form onSubmit={handleSubmit(onAccept)}>
        <DialogTitle>{strings.collectors_clone_dialog_title}</DialogTitle>
        <DialogContent>
          <Grid container gap={"1em"} spacing={0}>
            <Grid item xs={12}>
              <h4 style={{ marginBottom: "2em" }}>
                Cloning collector {collector?.id}
              </h4>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...fieldData } }) => (
                  <StdTextField
                    label={strings.scenarioform_name}
                    required
                    inputRef={ref}
                    style={{ width: "100%" }}
                    {...fieldData}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...fieldData } }) => (
                  <StdTextField
                    label={strings.scenarioform_description}
                    required
                    inputRef={ref}
                    style={{ width: "100%" }}
                    {...fieldData}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <UiSecondaryButton onClick={onCancel}>
            {strings.button_cancel}
          </UiSecondaryButton>
          <UiPrimaryButton type="submit">
            {strings.button_clone}
          </UiPrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CollectorCloneDialog;
