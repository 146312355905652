import { useCallback, useContext } from "react";

import { LoginStatusContext } from "contexts/LoginStatusContext";

export function useRoles() {
  const { user } = useContext(LoginStatusContext);

  const hasRoles = useCallback(
    (roles) => {
      if (!user || !user.roles) {
        return false;
      }
      return roles.every((role) => user.roles.includes(role));
    },
    [user]
  );

  const isRole = useCallback(
    (role) => {
      if (!user || !user.roles) {
        return false;
      }

      return user.roles.length === 1 && user.roles[0] === role;
    },
    [user]
  );

  return { hasRoles, isRole };
}
