import React, { useEffect, useMemo, useRef } from "react";

import { Chart, registerables } from "chart.js";

import useLocalizedStrings from "hooks/useLocalizedStrings";

Chart.register(...registerables);

const ProfileUsersHistogramChart = (props) => {
  const strings = useLocalizedStrings();
  const { histogram: clusterhist, mode, slice } = props;
  const canvasRef = useRef();
  const chart = useRef();

  const total = useMemo(
    () => clusterhist.reduce((a, d) => a + Number(d.count), 0),
    [clusterhist]
  );
  const processed = useMemo(() => {
    const entryValue = (d) => (mode === "absolute" ? Number(d.count) : d.nc);
    return total
      ? clusterhist
          .map((d) => ({
            ...d,
            nc: (Number(d.count) * 100) / total,
          }))
          .sort((a, b) => entryValue(b) - entryValue(a))
          .filter((_, idx, a) => (idx * 100) / a.length < slice)
      : [];
  }, [mode, clusterhist, total, slice]);

  useEffect(() => {
    const entryValue = (d) => (mode === "absolute" ? d.count : d.nc);
    chart.current = new Chart(canvasRef.current, {
      type: "bar",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: {
          duration: 500,
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: strings.profiledetails_tab_users_chart_x,
              align: "center",
              font: {
                size: 14,
              },
            },
          },
          y: {
            title: {
              display: true,
              text:
                mode === "absolute"
                  ? strings.profiledetails_tab_actions_chart_occurences
                  : strings.profiledetails_tab_actions_chart_popularity,
              align: "center",
              font: {
                size: 14,
              },
            },
            min: 0,
            // max: max,
            ticks: {
              callback: (val) => (mode === "absolute" ? val : `${val}%`),
            },
          },
        },
      },
      data: {
        labels: processed.map((d) => d.orig_app_user_id),
        datasets: [
          {
            label: mode === "absolute" ? "Occurences" : "Popularity",
            backgroundColor: "rgba(0,100,200,.5)",
            data: processed.map((d) => entryValue(d)),
          },
        ],
      },
    });
    return () => {
      chart.current && chart.current.destroy();
    };
  }, [strings, processed, mode]);

  return (
    <div style={{ height: "50vh", minHeight: "500px" }}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default ProfileUsersHistogramChart;
