import { useContext, useEffect, useRef, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { useScenarioAlertersQuery } from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import { AlerterDetails } from "components/alerters/AlerterDetails";
import FrameContent from "components/ui/FrameContent";
import StatusIcon from "components/ui/StatusIcon";
import { StdSwitch } from "components/ui/StdSwitch";
import TableCellText from "components/ui/TableCellText";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import SettingsOption from "./SettingsOption";

const AlerterHeader = () => {
  const strings = useLocalizedStrings();

  return (
    <>
      <colgroup>
        <col style={{ width: "2em" }} />
        <col style={{ width: "10em" }} />
        <col style={{ width: "10em" }} />
        <col style={{ width: "2em" }} />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell variant="body2">
            {strings.scenariosettings_alerters_label}
          </TableCell>
          <TableCell>
            <TableCellText variant="body2">
              {strings.account_settings_alerters_header_description}
            </TableCellText>
          </TableCell>
          <TableCell>
            <TableCellText variant="body2">
              {strings.account_settings_alerters_header_details}
            </TableCellText>
          </TableCell>
          <TableCell>
            <TableCellText variant="body2">
              {strings.account_settings_alerters_header_active}
            </TableCellText>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export const SettingsAlerters = () => {
  const location = useLocation();
  const open = location?.state?.alertersOpen ?? false;
  const [expanded, setExpanded] = useState(() => open);
  const { selectedScenario } = useContext(ScenariosContext);

  const opened = useRef(open);
  const scrollRef = useRef(null);
  const strings = useLocalizedStrings();

  useEffect(() => {
    setExpanded(location?.state?.alertersOpen ?? false);
  }, [location?.state]);

  useEffect(() => {
    if (expanded && scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [expanded, location?.state]);

  const { alerters, mutation } = useScenarioAlertersQuery(
    {
      scenarioId: selectedScenario?.id,
    },
    {
      enabled: !!selectedScenario?.id && opened.current,
    }
  );

  const handleExpand = () => {
    setExpanded((prev) => {
      if (!prev && !opened.current) {
        opened.current = true;
      }
      return !prev;
    });
  };

  const handleAssociate = (associated, alerter_id) => {
    mutation.mutate({ alerter_id, associated });
  };

  return (
    <SettingsOption
      title={strings.scenariosettings_alerters_title}
      open={expanded}
      onChange={handleExpand}
      ref={scrollRef}
    >
      <FrameContent>
        <TableContainer>
          <Table stickyHeader>
            <AlerterHeader />
            <TableBody>
              {alerters?.map((a) => (
                <TableRow key={a.alerter_id}>
                  <TableCell>
                    <StdSwitch
                      beforeLabel="YES"
                      afterLabel="NO"
                      checked={a.associated}
                      disabled={!selectedScenario.is_production}
                      onChange={(e) =>
                        handleAssociate(e.target.checked, a.alerter_id)
                      }
                    />
                  </TableCell>
                  <TableCell>{a.description}</TableCell>
                  <TableCell>
                    <AlerterDetails alerter={a} />
                  </TableCell>
                  <TableCell>
                    <StatusIcon status={a.active ? "success" : "fail"} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FrameContent>
    </SettingsOption>
  );
};
