import { v4 as uuid } from "uuid";

const MSG_LEVELS = {
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
};

export const getMessageLevel = (l) => {
  if (typeof l !== "string") {
    return MSG_LEVELS.INFO;
  }
  return MSG_LEVELS[l.toUpperCase()] ?? MSG_LEVELS.INFO;
};

export const createMessage = (level, text, noAutoClose) => {
  return {
    id: uuid(),
    time: noAutoClose ? 0 : Date.now(),
    level: getMessageLevel(level),
    text,
  };
};
