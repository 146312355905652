import { useQuery } from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

class FeaturesAPI extends API {
  static getFeaturesState() {
    return { url: `/api/acm/v1/feature_flags/features` };
  }
}

export default FeaturesAPI;

const queryKey = ["features"];

export const useFeaturesQuery = (queryOptions) => {
  return useQuery({
    queryKey,
    queryFn: () => queryFetch(FeaturesAPI.getFeaturesState()),
    staleTime: Infinity,
    ...queryOptions,
  });
};
