import { useEffect, useMemo, useRef } from "react";

import { styled } from "@mui/material";
import { Chart, registerables } from "chart.js";
import { isEqual } from "lodash-es";

import { diagonalPattern } from "utils/colorPatterns";
import { parseRarityColor } from "utils/parseRarityColor";
import { parseSensitivityColor } from "utils/parseSensitivityColor";

import useLocalizedStrings from "hooks/useLocalizedStrings";

Chart.register(...registerables);

const StyledChartWrapper = styled("div")`
  position: relative;
  height: 100%;
  overflow: hidden;
  canvas {
    position: absolute;
  }
`;

const Radar = ({
  sessionhist,
  clusterhist,
  xTitle,
  yTitle,
  colorBy,
  translated,
  isProfiled,
  nearestDistance,
}) => {
  const canvasRef = useRef();
  const chartRef = useRef();
  const chart = useRef();
  const strings = useLocalizedStrings();

  const sessionHistObj = useMemo(() => {
    return sessionhist.reduce((a, d) => {
      a[d.k] = d;
      return a;
    }, {});
  }, [sessionhist]);

  const clusterHistObj = useMemo(() => {
    return clusterhist.reduce((a, d) => {
      a[d.k] = d;
      return a;
    }, {});
  }, [clusterhist]);

  const colorSystem = useMemo(() => {
    return (e) => {
      if (!e) {
        return 0;
      }
      return colorBy === "sensitivity"
        ? parseSensitivityColor(e.s)
        : parseRarityColor(+e.rarity_score);
    };
  }, [colorBy]);

  const formatSessionAndClusterLabels = (d) => {
    const formattedKey = d.k.replace(/(,|;)\s*/g, "\n").trim();
    const formattedDescription = d.d
      ? d.d.replace(/(,|;)\s*/g, "\n").trim()
      : formattedKey;
    return {
      k: [formattedKey],
      d: [!formattedDescription ? formattedKey : formattedDescription],
    };
  };

  useEffect(() => {
    const sessionKeys2 = sessionhist.map((d) => {
      return formatSessionAndClusterLabels(d);
    });
    const clusterKeys2 = clusterhist.map((d) => {
      return formatSessionAndClusterLabels(d);
    });
    const getUniqueKeys = () => {
      return keys2.reduce((acc, item) => {
        if (!acc.find((a) => isEqual(a.k, item.k))) {
          acc.push(item);
        }
        return acc;
      }, []);
    };
    const sessionKeys = sessionhist.map((d) => d.k);
    const clusterKeys = clusterhist.map((d) => d.k);
    const keys = Array.from(new Set([...sessionKeys, ...clusterKeys]));
    const keys2 = Array.from(new Set([...sessionKeys2, ...clusterKeys2]));
    const keys3 = getUniqueKeys();
    const datasets = [
      {
        backgroundColor: keys.map((k) => colorSystem(sessionHistObj[k])),
        data: keys.map((k) => sessionHistObj[k]?.nc || 0),
        label: "Journey",
      },
      {
        backgroundColor: isProfiled
          ? "rgba(0, 0, 0, .3)"
          : diagonalPattern("rgba(0, 0, 0, .3)", "white"),
        data: keys.map((k) => clusterHistObj[k]?.nc),
        label: isProfiled ? strings.profile : strings.nearest_profile,
      },
    ];

    if (nearestDistance === 1) {
      datasets.pop();
    }

    chart.current = new Chart(canvasRef.current, {
      type: "bar",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: {
          duration: 500,
        },
        scales: {
          x: {
            title: {
              display: true,
              text: xTitle,
              align: "center",
              font: {
                size: 14,
              },
            },
            ticks: {
              callback: () => "",
            },
          },
          y: {
            title: {
              display: true,
              text: yTitle,
              align: "center",
              font: {
                size: 14,
              },
            },
            min: 0,
            // max: 100,
            ticks: {
              callback: (val) => {
                return `${val}%`;
              },
            },
          },
        },
        plugins: {
          tooltip: {
            intersect: false,
            displayColors: true,
            callbacks: {
              label: function (context) {
                const value =
                  context.formattedValue === "0"
                    ? `${Number(context.formattedValue)}%`
                    : `${Number(context.formattedValue).toFixed(2)}%`;
                return `${context.dataset.label}: ${value}`;
              },
              title: function (context) {
                let title = context[0].label;
                return title;
              },
            },
          },
        },
      },
      data: {
        labels: keys3.map((d) => {
          return translated ? d.d : d.k;
        }),
        datasets,
      },
    });
    return () => {
      chart.current && chart.current.destroy();
    };
  }, [
    strings.profile,
    strings.nearest_profile,
    nearestDistance,
    isProfiled,
    clusterhist,
    xTitle,
    yTitle,
    sessionhist,
    translated,
    sessionHistObj,
    clusterHistObj,
    colorSystem,
  ]);

  return (
    <StyledChartWrapper className="chart-wrapper" ref={chartRef}>
      <canvas ref={canvasRef} />
    </StyledChartWrapper>
  );
};

export default Radar;
