import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import HtmlTooltip from "components/ui/HtmlTooltip";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const formatProfileEvaluationTooltipText = (
  { cluster, cluster_malicious, cluster_annotation },
  strings
) => {
  if (cluster > 0) {
    if (cluster_malicious) {
      return strings.formatString(
        strings.sessionstable_session_action_tooltip_profile_malicious,
        cluster_annotation
          ? strings.formatString(
              strings.sessionstable_session_action_tooltip_profile_evaluation,
              cluster_annotation
            )
          : ""
      );
    }

    return strings.formatString(
      strings.sessionstable_session_action_tooltip_profile_unclassified,
      cluster_annotation
        ? strings.formatString(
            strings.sessionstable_session_action_tooltip_profile_evaluation,
            cluster_annotation
          )
        : ""
    );
  }
};

export const ProfileEvaluationIndicator = ({
  cluster,
  cluster_malicious,
  cluster_annotation,
  scenario,
}) => {
  const strings = useLocalizedStrings();
  const theme = useTheme();

  return (
    <HtmlTooltip
      title={formatProfileEvaluationTooltipText(
        { cluster, cluster_malicious, cluster_annotation },
        strings
      )}
    >
      <Link
        to={`/scenarios/${scenario}/profiles?id=${encodeURIComponent(cluster)}`}
        size="small"
        style={{
          color:
            cluster > 0
              ? cluster_malicious
                ? `${theme.palette.error.main}`
                : `${theme.palette.green.light}`
              : `${theme.palette.grey.main}`,
        }}
        disabled={cluster < 1}
      >
        <SupervisedUserCircleIcon />
      </Link>
    </HtmlTooltip>
  );
};
