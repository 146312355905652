import React from "react";

import { styled } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";

const StyledFrameActions = styled(FormGroup)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  padding: theme.spacing(2),
  rowGap: "5px",
  "& > *": {
    marginLeft: theme.spacing(1),
    minWidth: "8em",
  },
}));

export default function FrameActions({ children }) {
  return <StyledFrameActions>{children}</StyledFrameActions>;
}
