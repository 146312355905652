import React, { useEffect, useMemo, useRef } from "react";

import { Chart, registerables } from "chart.js";

import { parseSensitivityColor } from "utils/parseSensitivityColor";

import useLocalizedStrings from "hooks/useLocalizedStrings";

Chart.register(...registerables);

const ProfileTransitionsHistogramChart = (props) => {
  const strings = useLocalizedStrings();
  const { histogram: clusterhist, mode, translated, orderBy, slice } = props;
  const canvasRef = useRef();
  const chart = useRef();

  const total = useMemo(
    () => clusterhist.reduce((a, d) => a + d.c, 0),
    [clusterhist]
  );
  const processed = useMemo(() => {
    const entryValue = (d) => (mode === "absolute" ? d.c : d.nc);
    return total
      ? clusterhist
          .map((d) => ({
            ...d,
            k: Object.entries(
              translated ? (d.d.d1 !== "" && d.d.d2 !== "" ? d.d : d.k) : d.k
            )
              .map((k) => k[1].replace(/[ \t.]+$/, ""))
              .join("\n=>\n"),
            nc: (d.c * 100) / total,
            s: Math.max(
              Math.abs(Math.min(d.s.s1, 0)),
              Math.abs(Math.min(d.s.s2, 0))
            ),
          }))
          .sort((a, b) =>
            orderBy === "occurences" ? entryValue(b) - entryValue(a) : b.s - a.s
          )
          .filter(
            (item, idx, a) => (idx * 100) / a.length < slice && item.nc >= 0.1
          )
      : [];
  }, [mode, clusterhist, total, orderBy, translated, slice]);

  useEffect(() => {
    const entryValue = (d) => (mode === "absolute" ? d.c : d.nc);
    chart.current = new Chart(canvasRef.current, {
      type: "bar",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: {
          duration: 500,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            intersect: false,
            displayColors: true,
            callbacks: {
              label: function (context) {
                const value =
                  mode === "relative"
                    ? `${Number(context.formattedValue).toFixed(2)}%`
                    : context.raw;
                return `${context.dataset.label}: ${value}`;
              },
              title: function (context) {
                let title = context[0].label;
                return title;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: strings.profiledetails_tab_transitions_chart_x,
              align: "center",
              font: {
                size: 14,
              },
            },
            ticks: {
              callback: () => "",
            },
          },
          y: {
            title: {
              display: true,
              text:
                mode === "absolute"
                  ? strings.profiledetails_tab_transitions_chart_occurences
                  : strings.profiledetails_tab_transitions_chart_popularity,
              align: "center",
              font: {
                size: 14,
              },
            },
            min: 0,
            // max: max,
            ticks: {
              callback: (val) => (mode === "absolute" ? val : `${val}%`),
            },
          },
        },
      },
      data: {
        labels: processed.map((d) => d.k),
        datasets: [
          {
            label: mode === "absolute" ? "Occurences" : "Popularity",
            backgroundColor: (ctx) =>
              parseSensitivityColor(processed[ctx.dataIndex]?.s),
            data: processed.map((d) => entryValue(d)),
          },
        ],
      },
    });
    return () => {
      chart.current && chart.current.destroy();
    };
  }, [processed, mode, translated, strings]);

  return (
    <div style={{ height: "40vh", minHeight: "500px" }}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default ProfileTransitionsHistogramChart;
