import { useState } from "react";

import { joiResolver } from "@hookform/resolvers/joi";
import {
  AddCircle,
  Delete,
  Visibility,
  VisibilityOff,
  WarningRounded,
} from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { schemas } from "@tiq/shared/lib/input-validations";
import { FormProvider, useForm } from "react-hook-form";

import {
  useApiKeysCreateMutation,
  useApiKeysDeleteMutation,
  useApiKeysQuery,
} from "api/apikeys";

import CopyToClipboardButton from "components/ui/CopyToClipboard";
import Flexbox from "components/ui/Flexbox";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import { TextFieldInput } from "components/ui/form/TextFieldInput";
import { TableColumnHeaders } from "components/ui/tables/TableColumnHeader";

import { inUtc, timeFormatter } from "utils/time-fmt";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import { BaseAccountPage } from "./AccountPageContent";

const CreateKeyForm = ({ submitting, handleClose }) => {
  const strings = useLocalizedStrings();
  return (
    <>
      <DialogContent
        sx={{
          width: "600px",
          height: "250px",
          display: "flex",
          placeContent: "center",
          gap: "15px",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <TextFieldInput
          label={strings.account_settings_apikeys_name_label}
          name={"name"}
          required={true}
        />
        <TextFieldInput
          label={strings.account_settings_apikeys_expires_label}
          name="expires"
          required={true}
        />
      </DialogContent>
      <DialogActions>
        <UiSecondaryButton onClick={handleClose}>
          {strings.button_cancel}
        </UiSecondaryButton>
        <UiPrimaryButton type="submit" disabled={submitting}>
          {submitting ? strings.text_saving : strings.button_save}
        </UiPrimaryButton>
      </DialogActions>
    </>
  );
};

const placeholder = Array.from({ length: 32 }).fill("*").join("  ");
const NewKeyDisplay = ({ id, secret, handleClose }) => {
  const strings = useLocalizedStrings();
  const [shown, setShown] = useState(false);

  return (
    <>
      <DialogContent
        sx={{
          width: "600px",
          height: "200px",
          display: "flex",
          placeContent: "center",
          gap: "15px",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Typography>
          {strings.account_settings_apikeys_saved_message}
        </Typography>
        <Divider />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Flexbox
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
            width="100%"
          >
            <Typography fontSize={13}>
              {strings.account_settings_apikeys_id_label}
            </Typography>
            <Typography fontSize={12}>{id}</Typography>
            <CopyToClipboardButton
              message={strings.account_settings_apikeys_id_copy_message}
              text={id}
            />
          </Flexbox>
          <Flexbox
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            gap="10px"
          >
            <Typography fontSize={13}>
              {strings.account_settings_apikeys_secret_label}
            </Typography>
            <Typography fontSize={12} textAlign="center" alignItems="center">
              {shown ? secret : placeholder}
            </Typography>
            <Flexbox>
              <IconButton
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => setShown((s) => !s)}
              >
                {shown ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <CopyToClipboardButton
                message={strings.account_settings_apikeys_secret_copy_message}
                text={secret}
              />
            </Flexbox>
          </Flexbox>
        </Box>
      </DialogContent>
      <DialogActions>
        <UiSecondaryButton onClick={handleClose}>
          {strings.button_close}
        </UiSecondaryButton>
      </DialogActions>
    </>
  );
};

const CreateKeyDialog = ({ open, onClose }) => {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      expires: 10,
    },
    resolver: joiResolver(schemas.api_keys.post),
    shouldUnregister: true, //reset form when dialog closed
  });
  const submitting = methods.formState.isSubmitting;
  const { mutateAsync: createKey, data, reset } = useApiKeysCreateMutation();

  const handleSubmit = async (formData) => {
    await createKey(formData);
  };
  const handleClose = () => {
    onClose();
    setTimeout(reset, 100);
  };
  const handleClickAway = () => !data && handleClose();

  return (
    <Dialog open={open} onClose={handleClickAway}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <DialogTitle>{"Create API Key"}</DialogTitle>
          {data ? (
            <NewKeyDisplay
              id={data[0].id}
              secret={data[0].secret}
              handleClose={handleClose}
            />
          ) : (
            <CreateKeyForm submitting={submitting} handleClose={handleClose} />
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
};

const columns = (strings) => [
  {
    id: "actions",
    width: "1em",
    render: (k, onDelete) => (
      <IconButton size="small" onClick={() => onDelete({ id: k.id })}>
        <Delete />
      </IconButton>
    ),
  },
  {
    id: "name",
    label: strings.account_settings_apikeys_column_name,
    width: "8em",
    render: (k) => <Typography>{k.name}</Typography>,
  },
  {
    id: "id",
    label: strings.account_settings_apikeys_column_id,
    width: "3em",
    render: (k) => <Typography>{k.id}</Typography>,
  },
  {
    id: "expires",
    label: strings.account_settings_apikeys_column_expires,
    width: "10em",
    render: (k) => {
      const expired = inUtc(k.expires).isBefore(inUtc());
      return (
        <Flexbox gap="1em">
          <Typography>{timeFormatter(k.expires)}</Typography>
          {expired ? <WarningRounded color="error" /> : null}
        </Flexbox>
      );
    },
  },
  {
    id: "last_login",
    label: strings.account_settings_apikeys_column_last_login,
    width: "10em",
    render: (k) => (
      <Typography>
        {k.last_login ? timeFormatter(k.last_login) : strings.text_never}
      </Typography>
    ),
  },
  {
    id: "created",
    label: strings.account_settings_apikeys_column_created,
    width: "10em",
    render: (k) => <Typography>{timeFormatter(k.created)}</Typography>,
  },
];

const useApiKeys = () => {
  const { data: apiKeys, isPending } = useApiKeysQuery();
  const { mutate: deleteKey } = useApiKeysDeleteMutation();

  return {
    apiKeys,
    isPending,
    deleteKey,
  };
};

export const ApiKeysPage = () => {
  const strings = useLocalizedStrings();
  const caps = useCapabilities();

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteContext, setDeleteContext] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { apiKeys, deleteKey, isPending } = useApiKeys();

  const enableAdd = caps({ "acm.api_keys": { write: true } });

  const handleDeleteConfirm = () => deleteKey(deleteContext);
  const handleDeleteClick = (apiKey) => {
    setDeleteContext(apiKey);
    setDeleteDialogOpen(true);
  };

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  return (
    <BaseAccountPage>
      <Box>
        <UiPrimaryButton
          startIcon={<AddCircle />}
          onClick={() => setCreateDialogOpen(true)}
          disabled={!enableAdd}
        >
          {strings.button_add}
        </UiPrimaryButton>
      </Box>
      <TableContainer>
        <Table stickyHeader>
          <TableColumnHeaders columns={columns} />
          <TableBody>
            {apiKeys?.map((apiKey) => (
              <TableRow key={apiKey.id}>
                {columns(strings).map((c) => (
                  <TableCell key={c.id}>
                    {c.render(apiKey, handleDeleteClick)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateKeyDialog
        open={enableAdd && createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
      <MultipleOptionsDialog
        confirmText={strings.button_delete}
        onConfirm={handleDeleteConfirm}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        context={deleteContext}
        title={strings.account_settings_apikeys_delete_dialog_title}
        text={strings.account_settings_apikeys_delete_dialog_message}
      />
    </BaseAccountPage>
  );
};
