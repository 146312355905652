import { useContext } from "react";

import { LoginStatusContext } from "contexts/LoginStatusContext";

export function useCapabilities() {
  const { user } = useContext(LoginStatusContext);

  return (required) => {
    if (!user) {
      return false;
    }
    const caps = user.capabilities;

    return Object.keys(required).every(
      (k) =>
        (required[k].read === undefined ||
          caps[k]?.read === required[k].read) &&
        (required[k]?.write === undefined ||
          caps[k]?.write === required[k]?.write)
    );
  };
}
