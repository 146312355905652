import { useContext, useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";

import { ScenariosContext } from "contexts/ScenariosContext";

import LogUsersAPI from "./logusers";
import { queryFetch } from "./query";
import SessionsAPI from "./sessions";

const baseKey = ["multiscenario"];
const multiScenarioKeys = {
  users: ({ scenario, filter }) => [baseKey, scenario, "users", filter],
  sessions: ({ scenario, filters, orderBy }) => [
    baseKey,
    scenario,
    "sessionData",
    filters,
    orderBy,
  ],
};

const useMultiUserQuery = ({ scenarios, user, enabled }) => {
  const filter = useMemo(
    () => ({ orig_app_user_id: { operator: "eq", operand: user } }),
    [user]
  );

  return useQueries({
    queries: scenarios.map((scenario) => ({
      queryKey: multiScenarioKeys.users({ scenario, filter }),
      queryFn: ({ signal }) =>
        queryFetch(LogUsersAPI.getLogUsersUrl(scenario, 0, 1, {}, filter).url, {
          signal,
        }),
      select: (rows) => ({
        scenario,
        participating: !!rows.length,
      }),
      enabled,
    })),
  });
};

const useMultiSessionQuery = ({ scenarios, filters, orderBy, enabled }) => {
  return useQueries({
    queries: scenarios.map((scenario) => ({
      queryKey: multiScenarioKeys.sessions({ scenario, filters, orderBy }),
      queryFn: ({ signal }) =>
        queryFetch(
          SessionsAPI.getUrl({
            scenario,
            limit: 50,
            offset: 0,
            orderBy,
            filters,
          }).url,
          { signal }
        ),
      enabled,
    })),
  });
};

export const useUserSessionData = ({ user, start, end, enabled }) => {
  const { selectedScenario, scenarios } = useContext(ScenariosContext);
  const productionScenarios = scenarios
    .filter((s) => s.is_production && s.id !== selectedScenario.id)
    .map((s) => s.id);

  const participatingRes = useMultiUserQuery({
    scenarios: productionScenarios,
    user,
    enabled,
  });
  const loadingParticipating = participatingRes.some((r) => r?.isFetching);

  const scenariosToFetch = useMemo(() => {
    if (loadingParticipating) {
      return [];
    }
    const p = participatingRes
      .filter((r) => r.isSuccess && r.data.participating)
      .flatMap((r) => r.data.scenario);

    return [selectedScenario?.id, ...p];
  }, [participatingRes, selectedScenario?.id, loadingParticipating]);

  const [orderBy] = useState({ start: -1 });
  const filters = useMemo(() => {
    return {
      start: {
        operator: "gt",
        operand: start,
      },
      end: {
        operator: "le",
        operand: end,
      },
      orig_app_user_id: { operator: "eq", operand: user },
    };
  }, [user, start, end]);

  const sessionDataRes = useMultiSessionQuery({
    scenarios: scenariosToFetch,
    filters,
    orderBy,
    enabled: !!enabled && !loadingParticipating,
  });
  const loadingData = sessionDataRes.some((r) => r?.isFetching);

  const isPending = loadingParticipating || loadingData;

  const result = useMemo(() => {
    if (!enabled || isPending) {
      return [];
    }
    return sessionDataRes
      .flatMap((r, idx) => {
        const sessionScenario = scenarios.find(
          (s) => s.id === scenariosToFetch[idx]
        );
        return r?.data?.map((session) => ({
          ...session,
          id: `${session.app_session_id}+${sessionScenario.id}`,
          scenario_id: sessionScenario.id,
          scenario_name: sessionScenario.name,
        }));
      })
      .sort((a, b) => new Date(b.start) - new Date(a.start));
  }, [sessionDataRes, enabled, isPending, scenarios, scenariosToFetch]);

  return {
    isPending,
    result,
  };
};
