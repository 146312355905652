import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material";
import { startCase } from "lodash-es";
import { useHistory, useParams } from "react-router-dom";

import Flexbox from "components/ui/Flexbox";
import HtmlTooltip from "components/ui/HtmlTooltip";

import useFeature from "hooks/useFeature";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import { useFieldInsightData } from "./FieldInsightData";
import { SessionPropertiesTooltip } from "./SessionPropertiesTooltip";
import { useSessionSummaryFields } from "./SessionSummaryFields";

const StyledChart = styled(Flexbox)(() => ({
  alignItems: "flex-start",
  flexDirection: "column",
  flexGrow: 1,
  overflowX: "hidden",
  overflowY: "auto",
  paddingRight: "2px",
  position: "relative",
  width: "100%",
}));

const StyledFragmentedBar = styled(
  Grid,
  {}
)(({ theme, ownerState: { maxLabelWidth, tagNameHighlighted } }) => ({
  alignItems: "center",
  display: "flex",
  position: "relative",
  transition: "all 200ms ease",
  width: "100%",
  "&:not(:last-child)": {
    marginBottom: theme.spacing(0.4),
  },
  "& .gridItemLabel": {
    maxWidth: maxLabelWidth,
    minWidth: "100px",
  },
  "& .hoverable": {
    "&:hover": {
      width: "auto !important",
      maxWidth: "max-content",
      "& .label": {
        maxWidth: "max-content",
        transition: "all 500ms ease",
        width: "100%",
      },
    },
  },
  "& .label": {
    fontSize: tagNameHighlighted ? "10pt" : "",
    border: tagNameHighlighted
      ? `1px solid ${theme.palette.info.dark}`
      : "none",
    padding: "2px 4px",
    fontWeight: "700",
    marginRight: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    "& .long": {
      minWidth: "auto",
      width: "auto",
    },
    "& .short": {
      minWidth: 120,
      width: 120,
    },
  },
  "& .bar": {
    flexGrow: 1,
    height: "2.3em",
    overflow: "clip",
    position: "relative",
  },
}));

const StyledTagValueBar = styled("div")(
  ({ theme, tagValueHighlighted, percent }) => ({
    width: `${percent * 100}%`,
    backgroundColor: theme.palette.grey[300],
    border: "1px solid",
    borderColor: theme.palette.grey[400],
    position: "relative",
    transition: "all 200ms ease",
    padding: 0,
    display: "flex",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    overflow: "clip",
    whiteSpace: "nowrap",
    "&.exceeded": {
      width: "100%",
      justifyContent: "center",
      backgroundColor: "#666",
      color: "#aaa",
      textTransform: "uppercase",
      letterSpacing: "2px",
    },

    "&:not(.exceeded)::before": {
      content: "attr(value)",
      position: "absolute",
      left: "6px",
      maxWidth: "calc(100% - 12px)",
      overflow: "clip",
      padding: "1px",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: tagValueHighlighted ? theme.palette.info.dark : "white",
      color: tagValueHighlighted ? "white" : theme.palette.text.primary,
      fontSize: "9pt",
      transition: "all 200ms ease",
    },
  })
);

export const FieldTooltipContent = ({ title, significance }) => (
  <>
    <Typography
      variant="caption"
      sx={{
        position: "absolute",
        top: 1,
        right: 5,
      }}
    >
      {startCase(significance)}
    </Typography>
    <pre>{title}</pre>
  </>
);

const TagValueBar = memo(
  ({
    name,
    percent,
    totalSessions,
    isPendingTagTotals,
    factsEnabled,
    foundInSessions,
    fromDate,
    tagName,
    toDate,
  }) => {
    const history = useHistory();
    const summaryFields = useSessionSummaryFields();
    const { scenario, session } = useParams();
    const fieldInsightData = useFieldInsightData();
    const type = "sessions_found";
    const field = fieldInsightData?.[tagName]?.[type]?.[name]?.[0];
    const tagValueHighlighted = field;
    const linkedToTimeline = !!summaryFields;

    const handleTimelineRedirect = () => {
      if (linkedToTimeline) {
        let pathname = `/scenarios/${scenario}/sessions/${session}/session-timeline`;
        history.push({
          pathname,
          state: {
            initialField: { name: tagName, value: name },
            summaryFields,
          },
        });
      }
    };

    if (!factsEnabled) {
      return (
        <StyledTagValueBar
          tagValueHighlighted={tagValueHighlighted}
          percent={percent}
          value={name}
          onClick={handleTimelineRedirect}
          style={{ cursor: linkedToTimeline && "pointer" }}
        />
      );
    }

    return (
      <HtmlTooltip
        key={name}
        title={
          <SessionPropertiesTooltip
            fieldName={tagName}
            fromDate={fromDate}
            isPendingTagTotals={isPendingTagTotals}
            percent={percent}
            toDate={toDate}
            totalSessions={totalSessions}
            foundInSessions={foundInSessions}
            value={name}
          />
        }
      >
        <StyledTagValueBar
          tagValueHighlighted={tagValueHighlighted}
          percent={percent}
          value={name}
          onClick={handleTimelineRedirect}
          style={{ cursor: linkedToTimeline && "pointer" }}
        />
      </HtmlTooltip>
    );
  }
);

const SessionTokensFragmentedBar = ({
  tagData,
  width,
  totals,
  isPendingTagTotals,
}) => {
  const maxLabelWidth = 350;
  const strings = useLocalizedStrings();
  const valueLimit = useFeature("ui_settings/tag_values_max_number");
  const labelGridTextRef = useRef();
  const [isHoverable, setIsHoverable] = useState(false);
  const factsEnabled = !!totals?.num_sessions;
  const fieldInsightData = useFieldInsightData();
  const type = "values_probability";
  const field = fieldInsightData?.[tagData?.tag]?.[type]?.[0];

  const calculateHoverability = useCallback(() => {
    setIsHoverable(labelGridTextRef.current?.offsetWidth + 90 > maxLabelWidth);
  }, []);

  const titleTooltipContent = useMemo(() => {
    if (factsEnabled) {
      return;
    }
    return (
      <FieldTooltipContent
        title={strings.formatString(
          strings.sessiondetails_tags_key_tooltip,
          tagData.values_count,
          tagData.avg_values_count ?? 0,
          tagData.appearances
        )}
        significance={field?.significance?.value}
      />
    );
  }, [
    factsEnabled,
    strings,
    tagData.values_count,
    tagData.avg_values_count,
    tagData.appearances,
    field?.significance?.value,
  ]);

  useEffect(() => {
    calculateHoverability();
    window.addEventListener("resize", calculateHoverability);
    return () => window.removeEventListener("resize", calculateHoverability);
  }, [calculateHoverability]);
  return (
    <StyledFragmentedBar
      container
      ownerState={{
        maxLabelWidth: maxLabelWidth,
        tagNameHighlighted: field,
      }}
    >
      <Grid
        className={`gridItemLabel ${isHoverable ? "hoverable" : ""}`}
        item
        xs={"auto"}
        style={{ width: width + 90 }}
      >
        <Flexbox style={{ height: "100%", gap: "5px" }}>
          <HtmlTooltip title={titleTooltipContent}>
            <Typography
              className="label long"
              ref={labelGridTextRef}
              variant="body2"
            >
              {tagData.tag}
            </Typography>
          </HtmlTooltip>
        </Flexbox>
      </Grid>
      <Grid xs={true} item>
        <Flexbox className="bar">
          {tagData.tag_values?.length <= valueLimit ? (
            tagData.tag_values
              .sort((a, b) => b.count - a.count)
              .map((e) => (
                <TagValueBar
                  key={e.name}
                  name={e.name}
                  foundInSessions={e.found_in_sessions}
                  percent={e.percent}
                  factsEnabled={factsEnabled}
                  isPendingTagTotals={isPendingTagTotals}
                  totalSessions={totals?.num_sessions}
                  tagName={tagData.tag}
                  fromDate={totals?.from_date}
                  toDate={totals?.to_date}
                />
              ))
          ) : (
            <div className="fragment exceeded">
              ...too many distinct values...
            </div>
          )}
        </Flexbox>
      </Grid>
    </StyledFragmentedBar>
  );
};

export const longestLabelWidth = (histograms) => {
  let longestLabel = histograms?.reduce((savedText, tag) => {
    return tag?.tag?.length > savedText.length ? tag.tag : savedText;
  }, "");

  const inputText = longestLabel;
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = "13px roboto";
  const width = context.measureText(inputText).width;
  const formattedLabelWidth = Math.ceil(width);
  canvas.remove();
  return formattedLabelWidth;
};

const SessionTokens = ({
  histograms,
  totals,
  readOnly,
  isPendingTagTotals,
  longestWidth,
}) => {
  // sort token bars by #of distinct values
  const sortBars = (a, b) => {
    return a?.tag_values?.length - b?.tag_values?.length;
  };

  return (
    <StyledChart>
      {histograms?.sort(sortBars).map((tagData) => (
        <SessionTokensFragmentedBar
          tagData={tagData}
          key={tagData.tag}
          readOnly={readOnly || false}
          width={longestWidth}
          totals={totals}
          isPendingTagTotals={isPendingTagTotals}
        />
      ))}
    </StyledChart>
  );
};
export default SessionTokens;
