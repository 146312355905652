import React, { useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton } from "@mui/material";
import { isEqual } from "lodash-es";
import { useFieldArray, useFormContext } from "react-hook-form";

import { useGetFilesQuery } from "api/files";

import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { EnrichmentTabItemComponent } from "./EnrichmentTabItemComponent";

const defaultAppendValues = {
  type: "csv_file",
  file_id: "",
  copy_original_value: false,
  identifier: {
    enrichment_file_field: "",
    event_field: "",
  },
  field_mapping: [
    {
      enrichment_file_field: "",
      event_field: "",
    },
  ],
};

export const EnrichmentTabComponent = ({ enrichmentFromBucket }) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const strings = useLocalizedStrings();
  const { trigger, getValues } = useFormContext();
  const { data: files } = useGetFilesQuery({
    staleTime: Infinity,
  });

  const name = "options.internal_config.enrichment.entries";
  const { fields, append, remove } = useFieldArray({ name });

  const handleAppend = () => {
    append(defaultAppendValues);
    trigger("options.internal_config.mapping");
  };

  const handleDelete = (index) => {
    const values = getValues(`${name}.${index}`);

    if (isEqual(values, defaultAppendValues)) {
      remove(index);
      return;
    }

    setDeleteIndex(index);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmationAction = () => {
    remove(deleteIndex);
    trigger("options.internal_config.mapping.location");
  };

  return (
    <div>
      {fields &&
        fields.map((item, index) => {
          return (
            <EnrichmentTabItemComponent
              files={files}
              dataKey={index}
              key={item.id}
              enrichmentFromBucket={enrichmentFromBucket}
              onDelete={handleDelete}
            />
          );
        })}
      <IconButton onClick={handleAppend} size="small">
        <AddCircleOutlineIcon />
      </IconButton>

      <MultipleOptionsDialog
        onConfirm={handleConfirmationAction}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        text={
          strings.sourcedialog_mapping_editor_bucketing_tab_delete_entry_question
        }
        title={
          strings.sourcedialog_mapping_editor_bucketing_tab_delete_entry_title
        }
      />
    </div>
  );
};
