import { createContext, useContext, useMemo } from "react";

import { useSessionQuery } from "api/sessions";

const SessionDetailsContext = createContext();

export function SessionDetailsContextProvider({ session, scenario, children }) {
  const { data, isPending } = useSessionQuery({ session, scenario });
  const value = useMemo(() => ({ data, isPending }), [data, isPending]);
  return (
    <SessionDetailsContext.Provider value={value}>
      {children}
    </SessionDetailsContext.Provider>
  );
}

export function useSessionDetails() {
  return useContext(SessionDetailsContext);
}
