import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton, styled } from "@mui/material";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover": {
    color: "black",
  },
}));

export const DeleteButton = ({ onClick, ...props }) => {
  return (
    <StyledIconButton onClick={onClick} size="small" {...props}>
      <DeleteOutlineIcon />
    </StyledIconButton>
  );
};
