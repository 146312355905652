import { Divider } from "@mui/material";

function ToolbarDivider(props) {
  return (
    <Divider
      flexItem
      orientation="vertical"
      variant="fullWidth"
      style={{ margin: `0 1em`, ...props.style }}
    />
  );
}

export default ToolbarDivider;
