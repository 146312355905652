import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Chip, styled } from "@mui/material";

const ActionDescriptionStyled = styled("ul")`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  list-style: none;
  .MuiChip-outlined {
    background-color: white;
  }
`;

export default function ActionDescription({ item, ...rest }) {
  return (
    <ActionDescriptionStyled {...rest}>
      {item.info.length > 0 ? (
        item.info
          ?.filter((d) => d.description)
          .map((d, i) => (
            <Chip
              key={i}
              icon={<DescriptionOutlinedIcon />}
              label={d.description.replace(/[. \t]$/, "")}
              variant="outlined"
              color="default"
            />
          ))
      ) : (
        <Chip
          icon={<DescriptionOutlinedIcon />}
          label={item.action.replace(/[. \t]$/, "")}
          variant="outlined"
          color="default"
        />
      )}
    </ActionDescriptionStyled>
  );
}
