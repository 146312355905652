export function sortSpecToQueryParams(sort) {
  if (!sort) {
    return "";
  }
  return (
    Object
      // get sort fields
      .entries(sort)
      // filter out neutral filters (direction 0)
      .filter(([_, v]) => v)
      .map(
        ([k, v]) => `orderBy[${v === 1 ? "asc" : "desc"}]=${k.toLowerCase()}`
      )
      .join("&")
  );
}

export function filtersSpecToQueryParams(filters) {
  return Object.entries(filters)
    .filter(
      ([_, v]) =>
        v.operator && v.operator.length && v.operator && v.operator.length
    )
    .map(([k, v]) => `${k}[${v.operator}]=${encodeURIComponent(v.operand)}`)
    .join("&");
}
