import { useContext, useMemo, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, TableCell, TableRow, Typography, styled } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { isEmpty } from "lodash-es";

import { useAdminsQuery } from "api/admins";
import { useAuditGetSystemLogQuery } from "api/audit";

import { ScenariosContext } from "contexts/ScenariosContext";

import FiltersSection from "components/scenarios/FiltersSection";
import FreeSearchStrip from "components/scenarios/FreeSearchStrip";
import DataTableINF from "components/ui/DataTableINF";

import FilterOperators from "utils/FilterOperators";
import { timeFormatter } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useStateDeep } from "hooks/deepcomp";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import useCollectors from "./collectors/useCollectors";

const operators = FilterOperators;

const filtersSchema = (strings) =>
  Object.entries({
    type: {
      label: strings.account_settings_system_log_table_filters_type,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    action: {
      label: strings.account_settings_system_log_table_filters_action,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    created: {
      label: strings.account_settings_system_log_table_filters_created,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["gt"],
        operators["ge"],
        operators["eq"],
        operators["ne"],
      ],
    },
    scenario: {
      label: strings.account_settings_system_log_table_filters_scenario,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
  });

const fields = (props) => [
  {
    id: "empty",
    label: null,
    immutable: true,
    render: () => null,
    style: {
      width: "1em",
    },
  },
  {
    id: "type",
    label: props.strings.account_settings_system_log_table_header_type,
    sort: "type",
    render: (v) => <Typography variant="body1">{v.type}</Typography>,
    style: {
      width: "2em",
    },
  },
  {
    id: "action",
    label: props.strings.account_settings_system_log_table_header_action,
    sort: "action",
    render: (v) => <Typography variant="body1">{v.action}</Typography>,
    style: {
      width: "2em",
    },
  },
  {
    id: "created",
    label: props.strings.account_settings_system_log_table_header_created,
    sort: "created",
    render: (v) => (
      <Typography variant="body1">
        {timeFormatter(new Date(v.created))}
      </Typography>
    ),
    style: {
      textAlign: "left",
      width: "3em",
    },
  },
  {
    id: "uid",
    label: props.strings.account_settings_system_log_table_header_user_id,
    render: (v) => {
      const user = props.users[v.uid];
      if (user) {
        return (
          <>
            <div className="keyValueWrap">
              <div className="keyValue">Name: </div>
              <div>{user.name}</div>
            </div>
            <div className="keyValueWrap">
              <div className="keyValue">Email: </div>
              <div>{user.email}</div>
            </div>
            <div className="keyValueWrap">
              <div className="keyValue">ID: </div>
              <div>{v.uid}</div>
            </div>
          </>
        );
      }

      return props.strings.account_settings_system_log_table_user_not_found;
    },
    style: {
      width: "6em",
    },
  },
  {
    id: "scenario",
    label: props.strings.account_settings_system_log_table_header_scenario,
    sort: "scenario",
    render: (v) => {
      const scenarioID = v.scenario;
      const scenario = props?.scenarios?.filter(
        (scenario) => scenario.id === v.scenario
      )[0];
      const collector = props?.collectors?.filter(
        (collector) => collector.id === v.scenario
      )[0];

      const getNameValue = () => {
        if (scenario) {
          return scenario?.name;
        } else if (collector) {
          return collector?.name;
        } else {
          return props.strings
            .account_settings_system_log_table_details_not_found;
        }
      };

      if (scenarioID) {
        return (
          <>
            <div className="keyValueWrap">
              <div className="keyValue">Name: </div>
              <div>{getNameValue()}</div>
            </div>
            <div className="keyValueWrap">
              <div className="keyValue">ID: </div>
              <div>{v.scenario}</div>
            </div>
          </>
        );
      }
      return props.strings.account_settings_system_log_table_scenario_not_found;
    },
    style: {
      width: "6em",
    },
  },
  {
    id: "details",
    label: props.strings.account_settings_system_log_table_header_details,
    render: (v, expanded, setExpanded) => {
      return (
        <>
          {!isEmpty(v.details) && (
            <div className="detailsWrapper">
              <span>
                {props.strings.account_settings_system_log_table_details}
              </span>
              <RowExpandCollapseButton
                user={v}
                open={expanded}
                setOpen={setExpanded}
              />
            </div>
          )}
        </>
      );
    },
    style: {
      textAlign: "left",
      width: "2em",
    },
  },
];

const StyledSystemLogTable = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "min-content 1fr",
  height: "calc(100vh - var(--appbar-height) - 30px)",
  position: "relative",
  "& .sessions-toolbar": {
    alignItems: "center",
    display: "flex",
  },
  "& .detailsWrapper": {
    alignItems: "center",
    display: "flex",
  },

  "& .expandButton": {
    color: "inherit",
    transform: "rotate(0)",
    transition: "transform 200ms ease",
    "&.rotate180": {
      transform: "rotate(180deg)",
    },
  },
  "& .expandableRow": {
    "& .MuiTableCell-root": {
      "& .MuiTypography-root": {
        transition: "font-size 200ms ease",
      },
    },
  },
  "& .freeSearchStripIcon": {
    margin: "0 10px",
  },
  "& .rowExpantion": {
    "& > .MuiTableCell-root": {},
    "&:not(.visible) > .MuiTableCell-root": {
      border: "none",
    },
  },
  "& .keyValueWrap": {
    display: "flex",
    whiteSpace: "pre-wrap",
  },
  "& .keyValue": {
    fontWeight: "bold",
  },

  "& .systemLogDetails": {
    display: "flex",
    justifyContent: "center",
    padding: 10,
    "& pre": {
      fontFamily: "monospace",
      whiteSpace: "pre",
    },
  },
}));

const RowExpandCollapseButton = (props) => {
  const { open, setOpen } = props;
  const strings = useLocalizedStrings();

  return (
    <IconButton
      className={`expandButton ${open ? "rotate180" : ""}`}
      title={strings.account_settings_system_log_table_details_title}
      size="small"
      onClick={() => setOpen(!open)}
    >
      <ExpandMoreIcon />
    </IconButton>
  );
};

const SystemLogRow = ({ columns, item, innerRef, expand }) => {
  const [expanded, setExpanded] = useState(expand);

  return (
    <>
      <TableRow
        className={`${expanded ? " expanded" : ""}`}
        hover
        key={item.id}
        ref={innerRef}
      >
        {columns
          .filter((c) => c.visible !== false)
          .map((c) => (
            <TableCell
              align={c.align}
              key={`${item.id}/${c.id}`}
              style={c.style}
            >
              {c.render(item, expanded, setExpanded)}
            </TableCell>
          ))}
      </TableRow>

      <TableRow
        className={`rowExpantion + ${expanded ? " visible" : ""}`}
        key={`${item.id}/details`}
      >
        <TableCell colSpan={columns.length} style={{ padding: "0.5px" }}>
          <Collapse in={expanded} unmountOnExit>
            <Box mb={1}>
              {columns
                .filter((c) => c.id === "details")
                .map(() => {
                  return (
                    <div className="systemLogDetails" key={item.id}>
                      <pre> {JSON.stringify(item.details, null, 4)}</pre>
                    </div>
                  );
                })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SystemLogTable = () => {
  const { scenarios } = useContext(ScenariosContext);
  const pageLimit = 20;
  const strings = useLocalizedStrings();
  const [filters, setFilters] = useCurrentUserSettings("systemLog.filters", {});
  const [orderBy, setOrderBy] = useCurrentUserSettings("systemLog.orderby", {});
  const [openFilters, setOpenFilters] = useStateDeep(false);
  const [collectors] = useCollectors();

  const { data: admins = [] } = useAdminsQuery();

  const users = useMemo(
    () => admins?.reduce((a, d) => ({ ...a, [d.id]: d }), {}),
    [admins]
  );

  const { data, containerRef, isPending } = useAuditGetSystemLogQuery({
    filters,
    orderBy,
    pageLimit,
  });

  const handleNextSortDirection = (id) => {
    setOrderBy((prev) => {
      let d = (prev?.[id] || 0) + 1;
      if (d > 1) {
        d = -1;
      }
      return d ? { [id]: d } : {};
    });
  };

  const handleResetFilters = () => setFilters({});

  const handleChangeFilters = (f) => setFilters(f);

  return (
    <StyledSystemLogTable>
      <div className="sessions-toolbar">
        <FreeSearchStrip
          filters={filters}
          hideSearchInput
          openFilters={openFilters}
          schema={filtersSchema(strings)}
          setFilters={handleChangeFilters}
          setOpenFilters={setOpenFilters}
        />
        <IconButton
          className="freeSearchStripIcon"
          onClick={handleResetFilters}
          size="small"
          title="Reset filters"
        >
          <ClearIcon />
        </IconButton>
      </div>
      <DataTableINF
        ref={containerRef}
        RowComponent={SystemLogRow}
        columns={fields({ collectors, scenarios, strings, users })}
        hover
        isPending={isPending}
        items={data}
        onOrderBy={handleNextSortDirection}
        orderBy={orderBy}
        noCount
      />
      <FiltersSection
        filters={filters}
        openFilters={openFilters}
        schema={filtersSchema(strings)}
        setFilters={setFilters}
        setOpenFilters={setOpenFilters}
      />
    </StyledSystemLogTable>
  );
};

export default SystemLogTable;
