import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";

import { StyleProviders } from "themes/StyleProviders";

import { AdoptionPlatformWrapper } from "contexts/AdoptionPlatformWrapper";

import { BrowserNotificationCenter } from "hooks/useBrowserNotificationCenter";

import Landing from "./Landing";
import NotFound from "./NotFound";
import AccountSettings from "./account/AccountSettings";
import { AlertAnalyticsPage } from "./analytics/AlertAnalyticsPage";
import ScenariosPage from "./scenarios/ScenariosPage";
import PrivateRoute from "./ui/PrivateRoute";

const App = () => {
  return (
    <div className="App">
      <StyleProviders>
        <AdoptionPlatformWrapper>
          <BrowserNotificationCenter>
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => <Landing {...props} />}
              />
              <PrivateRoute
                path="/scenarios/:scenario"
                render={(props) => <ScenariosPage {...props} />}
              />
              <PrivateRoute
                path="/scenarios"
                render={(props) => <ScenariosPage {...props} />}
              />
              <PrivateRoute
                path="/analytics"
                render={(props) => <AlertAnalyticsPage {...props} />}
              />
              <PrivateRoute
                path="/settings"
                render={(props) => <AccountSettings {...props} />}
              />
              <PrivateRoute
                path="*"
                render={(props) => <NotFound {...props} />}
              />
            </Switch>
          </BrowserNotificationCenter>
        </AdoptionPlatformWrapper>
      </StyleProviders>
    </div>
  );
};

export default App;
