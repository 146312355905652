import { useGetJobCronQuery, useUpdateCronJobMutation } from "api/scheduler";

import ActivationButton from "components/ui/ActivationButton";
import HtmlTooltip from "components/ui/HtmlTooltip";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const CollectorActivationButton = ({ collectorId, inView, disabled }) => {
  const strings = useLocalizedStrings();
  const updateCronjob = useUpdateCronJobMutation();
  const { data: cronJob } = useGetJobCronQuery({
    scenarioId: collectorId,
    enabled: inView && !disabled,
  });

  const isActive = cronJob?.status === "enabled";

  const tooltipText = isActive
    ? strings.button_deactivate_job
    : strings.button_activate_job;

  const handleActivationChanged = (e) => {
    updateCronjob.mutate({
      scenarioId: collectorId,
      info: {
        ...cronJob,
        status: e.target.checked ? "enabled" : "disabled",
      },
    });
  };

  return (
    <HtmlTooltip
      title={tooltipText.split("\n").map((d, i) => (
        <p key={i}>{d}</p>
      ))}
    >
      <ActivationButton
        checked={isActive}
        onChange={handleActivationChanged}
        sx={{ color: "purple.dark" }}
        disabled={disabled || updateCronjob.isPending}
      />
    </HtmlTooltip>
  );
};

export default CollectorActivationButton;
