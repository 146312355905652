import { useState } from "react";

import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "react-hook-form";

import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import { DatePickerInput } from "components/ui/form/DatePickerInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { inUtc, timeFormatter } from "utils/time-fmt";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { Action } from "./DiagnosticJobActions";

const StyledDialogContent = styled(DialogContent)(() => ({
  "& > * + *": {
    marginTop: "1rem",
  },
  "& .MuiAccordion-root.Mui-disabled": {
    backgroundColor: "inherit",
  },
  "& .MuiAccordionSummary-root.Mui-disabled": {
    opacity: "inherit",
  },
  "& .column": {
    flexBasis: "75%",
  },
  "& .inputLabel": {
    flexBasis: "25%",
  },
  "& .row": {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  "& .details": {
    alignItems: "left",
    padding: "1rem",
    flexDirection: "column",
    "& > * + *": {
      marginTop: "1rem",
    },
    "& .MuiInputBase-input": {
      height: "100%",
    },
  },
  "& .incrementContainer": {
    display: "flex",
    paddingLeft: "1.2rem",
    height: "3rem",
    marginTop: "1.5rem",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      height: "100%",
    },
    "& .MuiTextField-root": {
      marginBottom: "15px",
    },
    "& p": {
      flexBasis: "22%",
    },
    "& .inputBox": {
      display: "flex",
      height: "100%",
      flexBasis: "60%",
      justifyContent: "space-between",
    },
  },
}));

const DateSelectorRow = ({
  disabled,
  expanded,
  handleExpand,
  handleAllAvailable,
  title,
  children,
  label,
}) => {
  const strings = useLocalizedStrings();
  const allAvailableLabel =
    strings.scenariodiagnostics_actions_run_dialog_all_label;

  return (
    <Accordion expanded={expanded} onChange={handleExpand} disabled={disabled}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className="inputLabel">{title}</Typography>
        <Typography
          className="column"
          style={disabled ? { color: "gray" } : null}
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="details">
        <Divider />
        <MenuItem onClick={handleAllAvailable}>{allAvailableLabel}</MenuItem>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export const RunScenarioForm = ({ scenario, sources, handler, setOpen }) => {
  const today = inUtc();
  const strings = useLocalizedStrings();

  const [expanded, setExpanded] = useState(false);
  const [incrementSelected, setIncrementSelected] = useState(false);

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: { from: "", to: "", interval: "" },
    mode: "onChange",
    shouldUnregister: true,
  });
  const watchedFrom = watch("from", "");
  const watchedTo = watch("to", "");

  const mostRecentEventTime = scenario?.most_recent_event_time || false;

  const allAvailableLabel =
    strings.scenariodiagnostics_actions_run_dialog_all_label;

  const continueFromLastLabel = strings.formatString(
    strings.scenariodiagnostics_actions_run_dialog_continue_label,
    timeFormatter(mostRecentEventTime)
  );

  const fromLabel = watchedFrom
    ? timeFormatter(watchedFrom)
    : allAvailableLabel;

  const toLabel = watchedTo ? timeFormatter(watchedTo) : allAvailableLabel;

  const incrementLabel =
    strings.scenariodiagnostics_actions_run_increment_label;

  const minFromTime = inUtc(mostRecentEventTime);
  const maxFromTime = inUtc(watchedTo || today).subtract(1, "d");

  const minToTime = inUtc(watchedFrom || mostRecentEventTime).add(1, "d");

  const inputsDisabled =
    inUtc(mostRecentEventTime).isToday() ||
    inUtc().diff(inUtc(mostRecentEventTime, "YYYY-MM-DD"), "day") <= 1 ||
    scenario?.external_no_logs ||
    !!sources?.find(
      (source) => source.active && !source.options.internal_connector
    );

  const incrementDisabled = watchedTo === "" || watchedFrom === "";
  const intervalAvailable = incrementSelected && !incrementDisabled;

  const handleExpand = (panel) => (_, isExpanded) => {
    if (inputsDisabled) {
      return;
    }

    setExpanded(isExpanded ? panel : false);
  };

  const onSubmit = (data) => {
    let params = [];

    if (data.from !== "") {
      params.push(["from", data.from]);
    }
    if (data.to !== "") {
      params.push(["to", data.to]);
    }

    if (data.interval) {
      params.push(["interval", parseInt(data.interval)]);
    }

    handler(params.length === 0 ? null : params);
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {strings.scenariodiagnostics_actions_run_dialog_title}
        </DialogTitle>
        <StyledDialogContent>
          {errors?.from?.message || errors?.to?.message ? (
            <Typography style={{ color: "red" }}>
              {strings.scenariodiagnostics_actions_run_error}
            </Typography>
          ) : null}
          <DateSelectorRow
            disabled={inputsDisabled}
            handleExpand={handleExpand("panel1")}
            handleAllAvailable={() => {
              setValue("from", "");
              setExpanded(false);
            }}
            expanded={expanded === "panel1"}
            title={"From:"}
            label={fromLabel}
          >
            {mostRecentEventTime && (
              <MenuItem
                onClick={() => {
                  setValue("from", mostRecentEventTime);
                  setExpanded(false);
                }}
              >
                {continueFromLastLabel}
              </MenuItem>
            )}

            <Divider />
            <div className="row">
              <DatePickerInput
                name="from"
                control={control}
                rules={{
                  validate: (value, formValues) =>
                    value === "" ||
                    formValues.to === "" ||
                    inUtc(value).isBefore(inUtc(formValues.to), "day") ||
                    "Invalid to date",
                  deps: "to",
                }}
                testId="input-from"
                minDate={minFromTime}
                maxDate={maxFromTime}
              />
            </div>
          </DateSelectorRow>
          <DateSelectorRow
            disabled={inputsDisabled}
            handleExpand={handleExpand("panel2")}
            handleAllAvailable={() => {
              setValue("to", "");
              setExpanded(false);
            }}
            expanded={expanded === "panel2"}
            title={"To:"}
            label={toLabel}
          >
            <Divider />
            <div className="row">
              <DatePickerInput
                name="to"
                control={control}
                rules={{
                  validate: (value, formValues) =>
                    value === "" ||
                    formValues.from === "" ||
                    inUtc(value).isAfter(inUtc(formValues.from), "day") ||
                    "Invalid to date",
                  deps: "from",
                }}
                testId="input-to"
                minDate={minToTime}
                maxDate={today}
              />
            </div>
          </DateSelectorRow>
          <div className="incrementContainer">
            <Typography>Increment:</Typography>
            <div className="inputBox">
              <Checkbox
                onChange={(e) => setIncrementSelected(e.target.checked)}
                disabled={incrementDisabled}
                checked={incrementDisabled ? false : incrementSelected}
              />
              {intervalAvailable && (
                <TextFieldInput
                  control={control}
                  name="interval"
                  required
                  size="small"
                  margin="dense"
                  disabled={incrementDisabled}
                  label={incrementLabel}
                  rules={{
                    pattern: {
                      value: /^((?=[1-9])\d*)\s*$/i,
                      message: strings.invalid_number,
                    },
                  }}
                />
              )}
            </div>
          </div>
        </StyledDialogContent>
        <DialogActions>
          <UiSecondaryButton onClick={() => setOpen(false)}>
            {strings.button_cancel}
          </UiSecondaryButton>
          <UiPrimaryButton type="submit" disabled={!isValid}>
            {strings.button_run}
          </UiPrimaryButton>
        </DialogActions>
      </form>
    </LocalizationProvider>
  );
};

const RunAction = ({ scenario, sources, handler, disabled }) => {
  const strings = useLocalizedStrings();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Action
        description={strings.scenariodiagnostics_actions_analysis_title}
        handler={() => setOpen(true)}
        buttonLabel={strings.scenariodiagnostics_jobprogress_run}
        disabled={disabled}
        ButtonType={UiPrimaryButton}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll="paper"
        fullWidth
        maxWidth="sm"
      >
        <RunScenarioForm
          handler={handler}
          scenario={scenario}
          sources={sources}
          setOpen={setOpen}
        />
      </Dialog>
    </>
  );
};

export default RunAction;
