import * as d3 from "d3";

import {
  greenLight,
  greyLight,
  orangePrimary,
  orangeSecondary,
  redPrimary,
} from "themes/MainTheme";

export const parseRiskColor = (risk) => {
  if (risk < 0) {
    return greyLight;
  }

  return d3.scaleLinear(
    [0, 35, 65, 95],
    [greenLight, orangeSecondary, orangePrimary, redPrimary]
  )(risk);
};
