import { useEffect, useRef, useState } from "react";

export const useOverflowState = () => {
  const ref = useRef();
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    const container = ref.current;
    const obs = new ResizeObserver((entries) => {
      const el = entries[0].target;
      setOverflow(el.scrollWidth > el.clientWidth);
    });

    if (container) {
      obs.observe(container);
    }

    return () => {
      if (container) {
        obs.unobserve(container);
      }
    };
  }, []);

  return { ref, overflow };
};
