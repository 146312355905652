import { useRef } from "react";

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

import HtmlTooltip from "components/ui/HtmlTooltip";
import { UiSecondaryButton } from "components/ui/StyledButtons";

import useLocalizedStrings from "hooks/useLocalizedStrings";

export const SessionLocalDownloader = ({ filename, onClick }) => {
  const ref = useRef();
  const strings = useLocalizedStrings();

  const handleClick = async () => {
    const data = await onClick();
    const blob = new Blob([data], { type: "text/plain" });
    const objectURL = URL.createObjectURL(blob);
    ref.current.href = objectURL;
    ref.current.download = filename;
    ref.current.click();
  };

  return (
    <>
      {<a ref={ref} />}
      <div style={{ display: "flex" }}>
        <HtmlTooltip title={strings.sessionstable_csv_download_tooltip}>
          <UiSecondaryButton
            onClick={handleClick}
            startIcon={<CloudDownloadOutlinedIcon />}
            style={{
              height: "38px",
              justifyContent: "end",
              marginRight: "8px",
              width: "100%",
            }}
            size="small"
          ></UiSecondaryButton>
        </HtmlTooltip>
      </div>
    </>
  );
};
