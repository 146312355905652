export default function postfixedNumberFormatter(v, decimal = 0) {
  const scales = [
    { scale: 1_000_000_000, postfix: "B" },
    { scale: 1_000_000, postfix: "M" },
    { scale: 1_000, postfix: "K" },
  ];
  for (let s in scales) {
    if (+v > scales[s].scale) {
      return `${(+v / scales[s].scale).toFixed(decimal)}${scales[s].postfix}`;
    }
  }
  return (+v).toFixed(decimal);
}
