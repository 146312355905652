import { Typography, styled } from "@mui/material";

const StyledSectionTitle = styled("div")(({ theme }) => ({
  background: theme.palette.sidebar.light,
  borderRadius: "5px",
  marginBottom: "10px",
  marginLeft: "auto",
  marginRight: "auto",
  padding: 10,
  textAlign: "center",
  width: "100%",
}));

export const SectionTitle = ({ children }) => {
  return (
    <StyledSectionTitle>
      <Typography variant="h6">{children}</Typography>
    </StyledSectionTitle>
  );
};
