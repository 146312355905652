import { useCallback } from "react";

import { useLocation } from "react-router-dom";

const parseQueryString = (qs) => {
  const q = Array.from(new URLSearchParams(qs).entries()).reduce(
    (a, [k, v]) => ({ ...a, [k]: v }),
    {}
  );
  return Object.keys(q).length ? q : null;
};

const useCurrentUrlState = () => {
  const { pathname, search } = useLocation();

  const removeQueryString = useCallback(() => {
    const q = parseQueryString(search);
    const basename = import.meta.env.BASE_URL || "";
    window.history.replaceState(null, "", `${basename}${pathname}`);
    return q;
  }, [pathname, search]);

  return {
    pathname,
    search,
    removeQueryString,
  };
};

export default useCurrentUrlState;
