import { StrictMode } from "react";

import "@fontsource/roboto";
import { CssBaseline } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import ActivityWrapper from "contexts/ActivityWrapper";
import { BackendEventsContextProvider } from "contexts/BackendEventsContext";
import { BrowserNotificationsContextProvider } from "contexts/BrowserNotificationsContext";
import FeatureFlagsWrapper from "contexts/FeatureFlagsWrapper";
import { LocalizedStringsContextProvider } from "contexts/LocalizedStringsContext";
import { LoginStatusContextProvider } from "contexts/LoginStatusContext";
import { QueryProvider } from "contexts/QueryProvider";

import App from "./components/App";
import "./index.css";

LicenseInfo.setLicenseKey(
  "3a91518853e5cf7ea09d638a4ac978a4Tz05NTY3OCxFPTE3NTQ1NjUyNjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
);

const root = createRoot(document.getElementById("root"));
const basename = import.meta.env.BASE_URL || "";
root.render(
  <StrictMode>
    <CssBaseline />
    <LocalizedStringsContextProvider>
      <BrowserRouter basename={basename}>
        <QueryProvider>
          <LoginStatusContextProvider>
            <BrowserNotificationsContextProvider>
              <BackendEventsContextProvider>
                <ActivityWrapper>
                  <FeatureFlagsWrapper>
                    <App />
                  </FeatureFlagsWrapper>
                </ActivityWrapper>
              </BackendEventsContextProvider>
            </BrowserNotificationsContextProvider>
          </LoginStatusContextProvider>
        </QueryProvider>
      </BrowserRouter>
    </LocalizedStringsContextProvider>
  </StrictMode>
);
