import { forwardRef } from "react";

import { HelpOutline } from "@mui/icons-material";

export const HelpIcon = forwardRef((props, ref) => (
  <HelpOutline
    {...props}
    ref={ref}
    fontSize="small"
    sx={{ color: "secondary.gray" }}
  />
));
