export const parseUserId = (value, defaultKey) => {
  return (value || "")
    .split("\t")
    .filter((t) => t && t.length)
    .map((t) => t.split("="))
    .map((t) => t.map((e) => e?.trim()))
    .map(([k, v]) => ({
      key: v ? k : defaultKey,
      value: v ? v : k,
    }));
};
