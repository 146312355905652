import { styled } from "@mui/material";

import NotAllowedMessage from "components/ui/NotAllowedMessage";

import { useCapabilities } from "hooks/useCapabilities";

const StyledProfileTabContainer = styled("div")`
  height: calc(100vh - var(--appbar-height) - 151px);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  .profile-content {
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content 1fr;
    gap: 1em;
    padding: 1em;
    .explanation:not(:empty) {
      font-size: 1.1rem;
      overflow: hidden;
      white-space: pre-wrap;
      opacity: 0.65;
    }
    .profile-details-main {
      height: 60vh;
      padding: 0.5em 0.5em 1em 0.5em;
      opacity: 1;
      transition: opacity 200ms ease;
      &.loading {
        opacity: 0.2;
      }
    }
  }
`;

export default function ProfileTabContainer({ children, ...props }) {
  const { explanation, loading } = props;
  const caps = useCapabilities();

  if (!caps({ "analysis-model.clusters": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <StyledProfileTabContainer className={loading ? "loading" : ""}>
      <div className="profile-content">
        <p className="explanation">{explanation}</p>
        <div className={`profile-details-main ${loading ? "loading" : ""}`}>
          {children}
        </div>
      </div>
    </StyledProfileTabContainer>
  );
}
