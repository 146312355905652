import React, { useMemo } from "react";

import { MenuOpen } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, styled } from "@mui/material";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useLocation } from "react-router-dom";

import NotFound from "components/NotFound";

import { useCapabilities } from "hooks/useCapabilities";

import NotImplemented from "./NotImplemented";
import ToolbarDivider from "./ToolbarDivider";

const StyledSubnavigator = styled("div")(({ theme }) => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "min-content 1fr",
  height: "100%",
  "& .appbar": {
    position: "sticky",
    top: 0,
    zIndex: 999,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& > .MuiToolbar-root": {
      marginLeft: "0.5rem",
      minHeight: "2rem",
    },
  },
  "& .menuOpenIcon": {
    paddingRight: "5px",
    zIndex: 1,
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  position: "relative",
  display: "inline-flex",
  padding: `3px ${theme.spacing(2)}`,
  justifyContent: "center",
  textDecoration: "none",
  textTransform: "uppercase",
  letterSpacing: 2,
  lineHeight: "initial",
  color: theme.palette.grey.dark,
  transition: "all 200ms ease",
  fontSize: "13px",
  userSelect: "none",
  "&:hover, &:active, &.active": {
    color: theme.palette.purple.dark,
  },
  "&.active": {
    position: "relative",
    textShadow: "1px 0px 0px white",
    backgroundColor: theme.palette.purple.dark,
    color: "white",
    borderRadius: "5px",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "-3px",
      left: "50%",
      width: "0.5em",
      height: "0.5em",
      transform: "translate(-50%) rotate(-135deg)",
      backgroundColor: theme.palette.purple.dark,
    },
  },
}));

const NavLink = ({ to, children, ...rest }) => {
  const location = useLocation();
  return (
    <StyledLink
      className={`${location.pathname.startsWith(to) ? "active" : ""}`}
      to={to}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

function SubNavigatorBar({ tabsSpec, nestLevel, ...other }) {
  const caps = useCapabilities();
  const { url } = useRouteMatch();

  const sidebarButtonVisible = !nestLevel && other.setSidebarOpen; //its the first bar, and has the setter function for sidebar state

  const tabs = useMemo(
    () =>
      tabsSpec.filter(
        (t) =>
          (t.visible === undefined || t.visible === true) &&
          (!t.caps || caps(t.caps))
      ),
    [tabsSpec, caps]
  );

  return (
    <AppBar className="appbar" position="relative" elevation={0}>
      <Toolbar
        className="appbar-toolbar"
        style={{
          fontSize: "inherit",
          paddingLeft: `${(nestLevel || 0) * 1}rem`,
        }}
      >
        {sidebarButtonVisible && (
          <IconButton
            className={`menuOpenIcon ${other.sidebarOpen && "open"}`}
            onClick={() => other.setSidebarOpen((prev) => !prev)}
            size="small"
          >
            <MenuOpen size="small" />
          </IconButton>
        )}
        {tabs.map((t, idx) =>
          t.id ? (
            !!t.route ? (
              <NavLink
                to={`${url}/${t.route}`}
                key={t.id}
                value={t.id}
                style={t.tabStyle}
              >
                {t.label}
              </NavLink>
            ) : (
              <span key={t.id} style={t.tabStyle}>
                {t.label}
              </span>
            )
          ) : (
            <ToolbarDivider key={"divider_" + idx} style={t.tabStyle} />
          )
        )}
      </Toolbar>
    </AppBar>
  );
}

function SubNavigatorRouter({ routes, ...other }) {
  const caps = useCapabilities();
  const { path } = useRouteMatch();

  const filteredRoutes = useMemo(() => {
    return routes.filter(
      (d) =>
        !!d.route &&
        (d.visible === undefined || d.visible) &&
        (!d.caps || caps(d.caps))
    );
  }, [routes, caps]);

  const defaultEntry = useMemo(() => {
    return filteredRoutes.find((d) => !!d.isDefault);
  }, [filteredRoutes]);

  return (
    <Switch>
      {defaultEntry && (
        <Route
          exact
          path={path}
          render={() => <Redirect to={`${path}/${defaultEntry.route}`} />}
        />
      )}
      {filteredRoutes.map((r) => (
        <Route
          key={r.id}
          path={`${path}/${r.route}`}
          render={
            r.component
              ? (props) => <r.component {...props} {...other} />
              : () => <NotImplemented />
          }
        />
      ))}
      {<Route path="*" render={(props) => <NotFound {...props} />} />}
    </Switch>
  );
}

function SubNavigator({ tabsSpec, routes, label, children, ...other }) {
  return (
    <StyledSubnavigator>
      <SubNavigatorBar tabsSpec={tabsSpec} label={label || ""} {...other} />
      {children}
      <SubNavigatorRouter routes={routes} {...other} />
    </StyledSubnavigator>
  );
}

export default SubNavigator;
