import DataLink from "components/ui/DataLink";
import StatusIcon from "components/ui/StatusIcon";

export function SessionEvaluationIndicator({ scenario, sessionInfo: v }) {
  if (!v.benign && !v.malicious) {
    return null;
  }
  return (
    <DataLink
      to={() =>
        `/scenarios/${scenario}/sessions/${encodeURIComponent(
          v.app_session_id
        )}/session-summary`
      }
    >
      <StatusIcon fontSize="medium" status={v.benign ? "success" : "error"} />
    </DataLink>
  );
}
