import { useCallback, useEffect } from "react";

import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import useBackendEvents from "hooks/useBackendEvents";
import { useDeletedScenario } from "hooks/useDeletedScenario";

import API from "./apibase";
import {
  DEFAULT_STALE_TIME,
  queryFetch,
  useInfiniteTableContainer,
} from "./query";

export default class CollectorsAPI extends API {
  static getCollectors() {
    return {
      url: "/api/acm/v1/collectors",
    };
  }

  static getCollector(id, include_logs = false) {
    return {
      url: `/api/acm/v1/collectors/key/${encodeURIComponent(
        id
      )}?job_log=${!!include_logs}`,
    };
  }

  static createCollector(info) {
    return {
      url: "/api/acm/v1/collectors",
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(info || {}),
      },
    };
  }

  static updateCollector(id, info) {
    return {
      url: `/api/acm/v1/collectors/key/${encodeURIComponent(id)}`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(info || {}),
      },
    };
  }

  static resetCollector(id, jobType) {
    const q = new URLSearchParams([["job_type", jobType]]).toString();

    return {
      url: `/api/acm/v1/collectors/key/${encodeURIComponent(id)}/reset?${q}`,
      options: {
        method: "POST",
      },
    };
  }

  static deleteCollector(id) {
    return {
      url: `/api/acm/v1/collectors/key/${encodeURIComponent(id)}`,
      options: {
        method: "DELETE",
      },
    };
  }

  static getAvailableApps() {
    return {
      url: "/api/acm/v1/collectors/available",
    };
  }

  static getFileCatalog(id, offset, limit, filters, orderby) {
    const f = JSON.parse(JSON.stringify(filters));
    f.status = { operand: "ok", operator: "eq" };
    return {
      url: super.composeChunkedQueryUrl(
        `/api/acm/v1/collectors/key/${id}/file-catalog`,
        offset,
        limit,
        f,
        orderby,
        "created"
      ),
    };
  }

  static getCollectorDataAnalysisRuns(id) {
    return {
      url: `/api/acm/v1/collectors/key/${encodeURIComponent(
        id
      )}/data_analysis_runs`,
    };
  }

  static getCollectorDataAnalysisColumns(
    id,
    runID,
    offset,
    limit,
    filters,
    orderby
  ) {
    const f = JSON.parse(JSON.stringify(filters));
    f.run_id = { operand: runID, operator: "eq" };

    const newOrderBy = Object.assign({}, orderby);
    if (newOrderBy.appearances === undefined) {
      newOrderBy["appearances"] = -1;
    }
    if (newOrderBy.name === undefined) {
      newOrderBy["name"] = -1;
    }
    return {
      url: super.composeChunkedQueryUrl(
        `/api/acm/v1/collectors/key/${encodeURIComponent(
          id
        )}/data_analysis_columns`,
        offset,
        limit,
        f,
        newOrderBy
      ),
    };
  }

  static getCollectorDataAnalysisColumnValues(
    id,
    runID,
    offset,
    limit,
    filters,
    orderby
  ) {
    const f = JSON.parse(JSON.stringify(filters));
    if (runID) {
      f.run_id = { operand: runID, operator: "eq" };
    }
    return {
      url: super.composeChunkedQueryUrl(
        `/api/acm/v1/collectors/key/${encodeURIComponent(
          id
        )}/data_analysis_column_values`,
        offset,
        limit,
        f,
        orderby,
        "appearances",
        "desc"
      ),
    };
  }

  static updateCollectorRank(id, newRank) {
    return {
      url: `/api/acm/v1/scenarios/key/${id}/rank`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          rank: newRank,
        }),
      },
    };
  }
}

const baseKey = ["collectors"];
const collectorKeys = {
  all: () => [baseKey, "all"],
  get: ({ id, include_logs }) => [baseKey, id, "get", include_logs],
  available: () => ["available"],
  fileCatalog: ({ id, offset, limit, f, orderBy }) => [
    baseKey,
    id,
    "fileCatalog",
    offset,
    limit,
    f,
    orderBy,
  ],
  fileCatalogTable: ({ id, filters, orderBy, pageLimit }) => [
    baseKey,
    pageLimit,
    id,
    "fileCatalogTable",
    filters,
    orderBy,
  ],
  dataAnalysisRuns: ({ id }) => [baseKey, id, "runs"],
  dataAnalysisColumns: ({ id, runId, orderBy, filters }) => [
    baseKey,
    id,
    "columns",
    runId,
    orderBy,
    filters,
  ],
  dataAnalysisColumnsAvailable: ({ collectorId, runId }) => [
    baseKey,
    collectorId,
    "columnsAvailable",
    runId,
  ],
};
const dataAnalysisEvents = ["data_analysis"];

export const useCollectorsQuery = () => {
  return useQuery({
    queryKey: collectorKeys.all(),
    queryFn: () => queryFetch(CollectorsAPI.getCollectors()),
  });
};

export const useCollectorsGetCollectorQuery = ({ id, include_logs }) => {
  return useQuery({
    queryKey: collectorKeys.get({ id, include_logs }),
    queryFn: ({ signal }) =>
      queryFetch(CollectorsAPI.getCollector(id, include_logs).url, { signal }),
    select: (rows) => {
      const collector = rows[0];
      return collector;
    },
    enabled: !!id,
    staleTime: DEFAULT_STALE_TIME,
    structuralSharing: false,
  });
};

export const useCollectorsCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (info) => queryFetch(CollectorsAPI.createCollector(info)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [baseKey] }),
  });
};

export const useCollectorsUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, info }) =>
      queryFetch(CollectorsAPI.updateCollector(id, info)),
    onSuccess: (_data, vars) => {
      queryClient.invalidateQueries({
        queryKey: collectorKeys.get({ id: vars.id }),
      });
    },
  });
};

export const useCollectorsResetMutation = () => {
  return useMutation({
    mutationFn: ({ scenarioId, jobType }) =>
      queryFetch(CollectorsAPI.resetCollector(scenarioId, jobType)),
  });
};

export const useCollectorsDeleteMutation = () => {
  const queryClient = useQueryClient();
  const queryKey = collectorKeys.all();

  return useMutation({
    mutationFn: ({ id }) => queryFetch(CollectorsAPI.deleteCollector(id)),
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries({ queryKey });
      const previousCollectors = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old) =>
        old.filter((collector) => collector.id !== id)
      );
      return { previousCollectors };
    },
    onError: (_err, _mutateData, context) => {
      queryClient.setQueryData(queryKey, context.previousCollectors);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  });
};

export const useCollectorsGetAvailableAppsQuery = () => {
  return useQuery({
    queryKey: collectorKeys.available(),
    queryFn: () => queryFetch(CollectorsAPI.getAvailableApps()),
    select: (row) => row.sort((a, b) => a.name.localeCompare(b.name)),
  });
};

export const useCollectorsGetFileCatalogQuery = ({
  id,
  offset,
  limit,
  f,
  orderBy,
}) => {
  const { isDeletedScenario } = useDeletedScenario();
  return useQuery({
    queryKey: collectorKeys.fileCatalog({ id, offset, limit, f, orderBy }),
    queryFn: () =>
      queryFetch(CollectorsAPI.getFileCatalog(id, offset, limit, f, orderBy)),
    enabled: !!id && !isDeletedScenario(id),
  });
};

export const useCollectorsFileCatalogInfiniteQuery = ({
  id,
  filters,
  orderBy,
  pageLimit,
}) => {
  const queryClient = useQueryClient();
  const {
    data,
    fetchNextPage,
    isPending,
    isPreviousData,
    isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey: collectorKeys.fileCatalogTable({
      id,
      pageLimit,
      filters,
      orderBy,
    }),
    enabled: !!id,
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    queryFn: ({ pageParam, signal }) =>
      queryFetch(
        CollectorsAPI.getFileCatalog(
          id,
          (pageParam - 1) * pageLimit,
          pageLimit,
          filters,
          orderBy
        ).url,
        { signal },
        { returnRawJson: true }
      ),
    select: (data) => {
      const formattedRes = data.pages.reduce(
        (acc, curr) => {
          acc.count = curr?.count;
          acc.pages.push(curr.rows);
          return acc;
        },
        { count: 0, pages: [] }
      );

      const rows = formattedRes.pages.flat();

      return {
        rows,
        count: formattedRes?.count,
      };
    },
    retry: false,
    getNextPageParam: (lastPage, pages) =>
      lastPage.rows.length < pageLimit ? undefined : pages.length + 1,
  });

  const cancel = useCallback(
    () =>
      queryClient.cancelQueries({
        queryKey: collectorKeys.fileCatalogTable({
          id,
          pageLimit,
          filters,
          orderBy,
        }),
      }),
    [queryClient, filters, orderBy, id, pageLimit]
  );

  const loadMoreItems = useCallback(
    () => !isFetching && fetchNextPage(),
    [isFetching, fetchNextPage]
  );

  return {
    data,
    refetch,
    cancel,
    isPending: isPending || isPreviousData,
    isFetching,
    fetchNextPage,
    loadMoreItems,
  };
};

export const useGetCollectorDataAnalysisRunsQuery = ({ id, opts }) => {
  const [backendEvent] = useBackendEvents(dataAnalysisEvents);
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: collectorKeys.dataAnalysisRuns({ id }),
    });
  }, [backendEvent, queryClient, id]);

  return useQuery({
    queryKey: collectorKeys.dataAnalysisRuns({ id }),
    enabled: !!id,
    queryFn: ({ signal }) =>
      queryFetch(CollectorsAPI.getCollectorDataAnalysisRuns(id), { signal }),
    ...opts,
  });
};

export const useDataAnalysisColumnsInfiniteQuery = ({
  id,
  runId,
  orderBy,
  filters,
}) => {
  const [backendEvent] = useBackendEvents(dataAnalysisEvents);
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: collectorKeys.dataAnalysisColumns({
        id,
        runId,
        orderBy,
        filters,
      }),
    });
  }, [backendEvent, filters, id, orderBy, queryClient, runId]);

  const { data, fetchNextPage, isPending, isFetching } = useInfiniteQuery({
    queryKey: collectorKeys.dataAnalysisColumns({
      id,
      runId,
      orderBy,
      filters,
    }),
    initialPageParam: 1,
    queryFn: ({ pageParam, signal }) =>
      queryFetch(
        CollectorsAPI.getCollectorDataAnalysisColumns(
          id,
          runId,
          (pageParam - 1) * 30,
          30,
          filters,
          orderBy
        ).url,
        { signal },
        { returnRawJson: true }
      ),
    enabled: !!id && !!runId,
    placeholderData: keepPreviousData,
    select: (data) => {
      const formattedRes = data.pages.reduce(
        (acc, curr) => {
          acc.count = curr?.count;
          acc.pages.push(curr.rows);
          return acc;
        },
        { count: 0, pages: [] }
      );

      const rows = formattedRes.pages.flat();

      return {
        rows,
        count: formattedRes?.count,
      };
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage.rows.length < 30 ? undefined : pages.length + 1,
  });

  const cancel = useCallback(() => {
    queryClient.cancelQueries({
      queryKey: collectorKeys.dataAnalysisColumns({
        id,
        runId,
        orderBy,
        filters,
      }),
    });
  }, [filters, id, orderBy, queryClient, runId]);

  const containerRef = useInfiniteTableContainer(
    !isFetching && data,
    fetchNextPage
  );

  return {
    data,
    isPending,
    cancel,
    containerRef,
  };
};

export const useDataAnalysisColumnsAvailableQuery = ({
  collectorId,
  runId,
}) => {
  const [backendEvent] = useBackendEvents(dataAnalysisEvents);
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: collectorKeys.dataAnalysisColumnsAvailable({
        collectorId,
        runId,
      }),
    });
  }, [backendEvent, queryClient, collectorId, runId]);

  return useQuery({
    queryKey: collectorKeys.dataAnalysisColumnsAvailable({
      collectorId,
      runId,
    }),
    enabled: !!collectorId && !!runId,
    queryFn: ({ signal }) =>
      queryFetch(
        CollectorsAPI.getCollectorDataAnalysisColumnValues(
          collectorId,
          runId,
          0,
          1,
          {}
        ),
        { signal }
      ),
    select: (rows) => rows.length > 0,
  });
};

export const useCollectorUpdateRankMutation = () => {
  const queryKey = collectorKeys.all();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, newRank }) =>
      queryFetch(CollectorsAPI.updateCollectorRank(id, newRank)),
    onMutate: ({ newArray }) => {
      const previousCollectors = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, newArray);
      return { previousCollectors };
    },
    onError: (_err, _mutateData, context) => {
      queryClient.setQueryData(queryKey, context.previousCollectors);
    },
  });
};
