import { styled } from "@mui/material";

import { parseRarityColor } from "utils/parseRarityColor";

const StyledRarityIndicator = styled("div")(({ bkgColor }) => ({
  alignItems: "center",
  backgroundColor: "transparent",
  border: `2px solid ${bkgColor}`,
  color: "black",
  display: "inline-flex",
  fontSize: "1em",
  height: "20px",
  justifyContent: "center",
  transform: "rotate(45deg)",
  transition: "all 100ms ease-in-out",
  width: "20px",
  "& span": {
    transform: "rotate(-45deg)",
  },
}));

export default function RarityIndicator({ value = 0 }) {
  return (
    <StyledRarityIndicator bkgColor={parseRarityColor(value)}>
      <span>{value}</span>
    </StyledRarityIndicator>
  );
}
