import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useController } from "react-hook-form";

import BrandedCheckbox from "components/ui/BrandedCheckbox";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const sortDays = (a, b) => days.indexOf(a) - days.indexOf(b);

export const WeekdaySelector = ({
  name,
  required,
  style,
  label,
  className,
}) => {
  const { field, fieldState } = useController({
    name,
    defaultValue: [],
    rules: {
      required: {
        value: required ?? false,
        message: "This field is required",
      },
      validate: (val) => val?.every((x) => days.includes(x)) || "Invalid value",
    },
  });

  const errorMessage = fieldState?.error?.message;

  const val =
    !field.value || typeof field?.value === "string" ? [] : field.value;

  return (
    <FormControl
      className={className}
      error={!!errorMessage}
      style={{ width: "100%" }}
      required={required}
    >
      <InputLabel error={!!errorMessage} id="selector">
        {label}
      </InputLabel>
      <Select
        name={field.name}
        labelId="selector"
        label={label}
        value={val}
        onChange={(e) => field.onChange(e.target.value.sort(sortDays))}
        onBlur={field.onBlur}
        inputRef={field.ref}
        multiple
        renderValue={(selected) => selected.join(", ")}
        style={style}
        inputProps={{ required: false }}
      >
        {days.map((d) => (
          <MenuItem key={d} value={d}>
            <BrandedCheckbox checked={field?.value?.indexOf(d) > -1} />
            <ListItemText>{d}</ListItemText>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage ?? " "}</FormHelperText>
    </FormControl>
  );
};
