const key = "_expiredTime";
let tracker;
let interval;

export function startIdleTracker({ onTimeout, refreshInterval }) {
  setTracker();
  updateExpiredTime();
  interval = setInterval(() => {
    if (!userActive(refreshInterval)) {
      return;
    }
    onTimeout();
  }, refreshInterval);
  return cleanUpTrackers;
}

export function clearInactivityTime() {
  localStorage.removeItem(key);
}

export function userActive(period) {
  return Date.now() - getExpiredTime() <= period;
}

function getExpiredTime() {
  return parseInt(localStorage.getItem(key), 10);
}

function updateExpiredTime() {
  if (tracker) {
    clearTimeout(tracker);
  }
  tracker = setTimeout(() => {
    localStorage.setItem(key, Date.now());
  }, 300);
}

function setTracker() {
  window.addEventListener("mousemove", updateExpiredTime);
  window.addEventListener("scroll", updateExpiredTime);
  window.addEventListener("keydown", updateExpiredTime);
}

function cleanUpTrackers() {
  localStorage.removeItem(key);
  clearInterval(interval);
  window.removeEventListener("mousemove", updateExpiredTime);
  window.removeEventListener("scroll", updateExpiredTime);
  window.removeEventListener("keydown", updateExpiredTime);
}
