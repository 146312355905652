import { forwardRef } from "react";

import { Comment } from "@mui/icons-material";

import DataLink from "components/ui/DataLink";
import HtmlTooltip from "components/ui/HtmlTooltip";

export const CommentIndicator = forwardRef((props, ref) => {
  const { scenario, sessionId, comment } = props;
  if (!comment || comment.length === 0) {
    return null;
  }
  return (
    <HtmlTooltip title={comment}>
      <DataLink
        ref={ref}
        to={() =>
          `/scenarios/${scenario}/sessions/${encodeURIComponent(
            sessionId
          )}/session-summary`
        }
      >
        <Comment style={{ color: "#888", marginLeft: `4px` }} />
      </DataLink>
    </HtmlTooltip>
  );
});
