import { useContext } from "react";

import { Redirect, Route } from "react-router-dom";

import { LoginStatusContext } from "contexts/LoginStatusContext";

import Layout from "components/Layout";

import { PageLoadingSpinner } from "./PageLoadingSpinner";

export default function PrivateRoute({ render, ...rest }) {
  const { user, loading, getReturnUrl, getAccountFromUrl } =
    useContext(LoginStatusContext);

  if (loading) {
    return <PageLoadingSpinner />;
  }

  if (!user) {
    return (
      <Redirect
        to={{
          to: "/",
          state: {
            a: getAccountFromUrl(),
            u: getReturnUrl(),
          },
        }}
      />
    );
  }

  return (
    <Layout>
      <Route {...rest} render={(props) => render(props)} />
    </Layout>
  );
}
