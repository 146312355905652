import React from "react";

import { styled } from "@mui/material";
import { Link } from "react-router-dom";

const StyledDataLink = styled(Link)(() => ({
  color: "SteelBlue",
  letterSpacing: 0,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const DataLink = React.forwardRef((props, ref) => {
  const { children, to, ...other } = props;

  return (
    <StyledDataLink
      className={`data-link ${props?.className ?? ""}`}
      ref={ref}
      to={to}
      {...other}
    >
      {children}
    </StyledDataLink>
  );
});

export default DataLink;
