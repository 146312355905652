import { useMemo } from "react";

import { PeopleOutline, PermIdentity } from "@mui/icons-material";

import HtmlTooltip from "components/ui/HtmlTooltip";

import useLocalizedStrings from "hooks/useLocalizedStrings";

export const ScoringSystemIcon = ({ scoringSystem }) => {
  const strings = useLocalizedStrings();
  const help = {
    "multi-user": strings.scenarioinfo_multiuser_description,
    "single-user": strings.scenarioinfo_singleuser_description,
  };

  const systemIcon = useMemo(() => {
    if (scoringSystem === "multi-user") {
      return <PeopleOutline fontSize="small" />;
    }
    return <PermIdentity fontSize="small" />;
  }, [scoringSystem]);

  if (!scoringSystem || scoringSystem.length === 0) {
    return null;
  }

  return <HtmlTooltip title={help[scoringSystem]}>{systemIcon}</HtmlTooltip>;
};
