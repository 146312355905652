import { useState } from "react";

import { Box, Divider, TextField, styled } from "@mui/material";
import { capitalize, get, isEqual } from "lodash-es";
import { useFieldArray, useFormContext } from "react-hook-form";

import { AddButton } from "components/scenarios/MappingEditor/components/AddButton";
import { ColumnSelector } from "components/scenarios/MappingEditor/components/ColumnSelector";
import { Expander } from "components/scenarios/MappingEditor/components/Expander";
import { OutputFieldInput } from "components/scenarios/MappingEditor/components/OutputFieldInput";
import { SectionErrorMessage } from "components/scenarios/MappingEditor/components/SectionErrorMessage";
import { SectionWrap } from "components/scenarios/MappingEditor/components/SectionWrap";
import { DeleteButton } from "components/ui/DeleteButton";
import HtmlTooltip from "components/ui/HtmlTooltip";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";

import { useExpanded } from "hooks/useExpanded";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledItemWrapper = styled("div")(() => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  height: "auto",
  marginLeft: "10px",
  maxHeight: "120px",
  overflow: "auto",
  paddingTop: "10px",
  width: "100%",
  transition: "max-height 500ms ease",

  "&.expanded": {
    maxHeight: "600px",
  },
}));

const mappingLabels = {
  annotation: "annotation",
  appuser: "appuser",
  device: "device",
  location: "location (action)",
};

const AdditionalEntries = ({ additionalEntries, errors }) => {
  const strings = useLocalizedStrings();

  const getAdditionalFieldsHelperText = (item) => {
    const error = get(errors, item.name);
    if (error?.customRule) {
      return error?.customRule?.message;
    }

    if (error?.type?.pattern) {
      return strings.invalid_input;
    }

    return "";
  };

  if (!additionalEntries) {
    return null;
  }

  return additionalEntries.map((item) => {
    return (
      <Box
        key={item.output_field}
        sx={{
          alignItems: "center",
          display: "flex",
          marginBottom: "10px",
          width: "100%",
          "& fieldset": {
            height: "100%",
          },
          "& .additionalFieldError": {
            //apply these on the field directly, so we can use div instead of Box and style instead of sx
            "& .MuiInputLabel-outlined": {
              color: "#f44336",
              opacity: "0.5",
            },
            "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #f44336",
                opacity: "0.5",
              },
          },
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "5px",
            width: "312px",
          }}
        >
          {`${
            strings.sourcedialog_mapping_editor_mapping_tab_additional_item_text
          } ${capitalize(item.source)}`}
        </span>
        <HtmlTooltip title={item.output_field || ""}>
          <TextField
            className={!!get(errors, item.name) ? "additionalFieldError" : ""}
            disabled
            inputProps={{
              style: { height: "56px" },
            }}
            label={
              strings.sourcedialog_mapping_editor_mapping_tab_label_mapped_column
            }
            helperText={getAdditionalFieldsHelperText(item)}
            error={!!get(errors, item.name)}
            name="output_field"
            style={{ width: "200px" }}
            value={item.output_field || ""}
            variant="outlined"
          />
        </HtmlTooltip>
      </Box>
    );
  });
};

const MappingField = ({ item, baseName, handleDelete }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "1em",
      }}
    >
      <ColumnSelector
        name={`${baseName}.input_field`}
        outputField={`${baseName}.output_field`}
        defaultValue={item.input_field || ""}
      />
      <OutputFieldInput name={`${baseName}.output_field`} />
      {/* TODO check why this height needs to be set, there's a problem on the inputs */}
      <DeleteButton style={{ height: "50px" }} onClick={handleDelete} />
    </div>
  );
};

const MappingTabItemComponent = ({ additionalEntries, dataKey }) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const strings = useLocalizedStrings();
  const {
    formState: { errors },
    getValues,
  } = useFormContext();
  const namePrefix = "options.internal_config.mapping";

  const customValidate = (value, formValues) => {
    const mappingColumns = ["appuser", "location"];

    if (!mappingColumns.includes(dataKey)) {
      return true;
    }

    const bucketing = formValues.options.internal_config.bucketing;
    const transformations = formValues.options.internal_config.transformations;

    const additionalData = {
      bucketing: bucketing,
      transformations: transformations,
    };

    let initialInternalConfigMapping = {
      appuser: [],
      location: [],
    };

    let additionalEntries = JSON.parse(
      JSON.stringify(initialInternalConfigMapping)
    );

    const getInternalConfig = (data) => {
      data &&
        Object.keys(data)
          .filter((key) => key !== "filtering" && key !== "mapping")
          .forEach((item) => {
            data[item]?.entries?.forEach((entry, index) => {
              if (mappingColumns.includes(entry.output_column)) {
                additionalEntries[entry.output_column].push({
                  name: `options.internal_config.${item}.entries.${index}.output_field`,
                  source: item,
                  output_field: entry.output_field,
                });
              }
            });
          });

      return additionalEntries;
    };

    const requiredMappedColumnsArray = getInternalConfig(additionalData);
    if (
      (value && value.length !== 0) ||
      requiredMappedColumnsArray[dataKey]?.length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const { fields, append, remove } = useFieldArray({
    name: `${namePrefix}.${dataKey}`,
    rules: {
      validate: (value, formValues) => customValidate(value, formValues),
      deps: ["options.internal_config.transformations.entries"],
    },
  });

  const defaultAppendValues = { input_field: "", output_field: "" };

  const { expandedState, isExpandingDisabled, handleExpand } = useExpanded(
    fields?.length + additionalEntries?.length
  );

  const handleDelete = (index) => {
    const values = getValues(`${namePrefix}.${dataKey}.${index}`);

    if (isEqual(values, defaultAppendValues)) {
      remove(index);
      return;
    }

    setConfirmDeleteOpen(true);
    setDeleteIndex(index);
  };

  const handleConfirmationAction = () => {
    remove(deleteIndex);
  };

  const additionalEntriesError = additionalEntries.find(
    (item) => !!get(errors, item.name)
  );

  const checkForErrorClasses = () => {
    if (!!errors?.options?.internal_config?.mapping?.[dataKey]?.root) {
      return " customError";
    } else if (
      !!errors?.options?.internal_config?.mapping?.[dataKey] ||
      additionalEntriesError
    ) {
      return " errorSection";
    } else {
      return "";
    }
  };

  return (
    <SectionWrap className={checkForErrorClasses()} key={dataKey}>
      <div
        style={{
          minWidth: "150px",
          padding: "20px",
          textTransform: "capitalize",
        }}
      >
        {mappingLabels[dataKey]}
      </div>
      <Divider
        flexItem
        orientation="vertical"
        variant="fullWidth"
        style={{
          background: "black",
          margin: `0 10px`,
        }}
      />
      <AddButton
        style={{ alignSelf: "end" }}
        onClick={() => append(defaultAppendValues)}
      />

      <StyledItemWrapper className={expandedState ? "expanded" : ""}>
        <div>
          <AdditionalEntries
            additionalEntries={additionalEntries}
            errors={errors}
          />
          {fields?.map((item, index) => (
            <MappingField
              key={item.id}
              item={item}
              handleDelete={() => handleDelete(index)}
              baseName={`${namePrefix}.${dataKey}.${index}`}
            />
          ))}

          <MultipleOptionsDialog
            onConfirm={handleConfirmationAction}
            open={confirmDeleteOpen}
            setOpen={setConfirmDeleteOpen}
            text={
              strings.sourcedialog_mapping_editor_mapping_tab_delete_item_question
            }
            title={
              strings.sourcedialog_mapping_editor_mapping_tab_delete_item_title
            }
          />
        </div>
        {!!errors?.options?.internal_config?.mapping?.[dataKey]?.root && (
          <SectionErrorMessage>
            {`${capitalize(
              dataKey
            )} must have at least one mapped field (a field generated from Bucketing/Transformations counts as a mapped field) !`}
          </SectionErrorMessage>
        )}
      </StyledItemWrapper>

      <Expander
        isExpandingDisabled={isExpandingDisabled}
        expandedState={expandedState}
        handleExpand={handleExpand}
      />
    </SectionWrap>
  );
};

export default MappingTabItemComponent;
