import { useContext, useMemo, useState } from "react";

import { Chip } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { useUpdateScenarioMutation } from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import FrameActions from "components/ui/FrameActions";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import { UiPrimaryButton } from "components/ui/StyledButtons";
import { CheckboxInput } from "components/ui/form/CheckboxInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import SettingsOption from "./SettingsOption";

const EncrpytionStatusMessage = ({ saasDeploy, annotationEncryption }) => {
  const strings = useLocalizedStrings();

  const encryptionStatus = useMemo(() => {
    if (saasDeploy) {
      const status = annotationEncryption
        ? `${strings.text_encryption_disk} & ${strings.text_encryption_object}`
        : strings.text_encryption_disk;
      return strings.formatString(strings.text_encryption_base_label, status);
    }
    const status = annotationEncryption
      ? strings.text_encryption_object
      : strings.text_none;
    return strings.formatString(strings.text_encryption_base_label, status);
  }, [strings, saasDeploy, annotationEncryption]);

  return (
    <div>
      <Chip label={encryptionStatus} />
    </div>
  );
};

const SettingsGeneral = () => {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);
  const { pushMessage } = useMessages();

  const caps = useCapabilities();
  const writeAllowed = caps({ "acm.scenarios": { write: true } });
  const [confirmCloseOpen, setConfirmCloseOpen] = useState(false);
  const [updateContext, setUpdateContext] = useState({});
  const methods = useForm({
    values: {
      name: selectedScenario?.name,
      description: selectedScenario?.description,
      config_file: selectedScenario?.extra?.config_file ?? "",
      generate_profile_details: selectedScenario?.generate_profile_details,
      users_activity_timeframe: selectedScenario?.users_activity_timeframe,
      siem_id: selectedScenario?.siem_id ?? "",
      is_production: selectedScenario?.is_production,
      is_sync_with_sources: selectedScenario?.is_sync_with_sources,
    },
    mode: "onChange",
  });

  const { mutate: updateScenario } = useUpdateScenarioMutation();

  const handleSave = (formData) => {
    const updatedScenario = {
      ...formData,
      extra: {
        ...selectedScenario?.extra,
        config_file: formData.config_file || undefined,
      },
    };
    delete updatedScenario.config_file;
    if (selectedScenario.is_production && !updatedScenario.is_production) {
      setUpdateContext(updatedScenario);
      setConfirmCloseOpen(true);
    } else {
      updateScenario(
        {
          id: selectedScenario.id,
          details: updatedScenario,
        },
        {
          onSuccess: () => {
            pushMessage("success", strings.scenariosettings_general_success);
          },
        }
      );
    }
  };

  const handleConfirmationAction = (ctx) => {
    updateScenario(
      { id: selectedScenario.id, details: ctx },
      {
        onSuccess: () => {
          pushMessage("success", strings.scenariosettings_general_success);
        },
      }
    );
  };

  return (
    <SettingsOption title={strings.scenariosettings_general_title}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleSave)}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            paddingRight: "1rem",
          }}
        >
          <TextFieldInput name={"name"} label={"Name"} required />
          <TextFieldInput name={"description"} label={"Description"} required />
          <CheckboxInput
            name={"is_production"}
            label={strings.scenariosettings_is_production_label}
            disabled={!writeAllowed}
          />
          <TextFieldInput name={"config_file"} label={"Config file"} />
          <TextFieldInput
            name={"siem_id"}
            label={strings.scenariosettings_siem_id_label}
          />
          <TextFieldInput
            name={"users_activity_timeframe"}
            required
            rules={{
              pattern: {
                value: /^[1-9]\d*$/,
                message: strings.invalid_input,
              },
            }}
            label={strings.scenariosettings_users_activity_timeframe_label}
          />
          <CheckboxInput
            name={"generate_profile_details"}
            label={strings.scenariosettings_profile_details_enabled_label}
            disabled={!writeAllowed}
          />
          <EncrpytionStatusMessage
            saasDeploy={selectedScenario?.saas_deploy}
            annotationEncryption={selectedScenario?.annotation_encryption}
          />
          <FrameActions>
            <UiPrimaryButton disabled={!writeAllowed} type="submit">
              {strings.button_save}
            </UiPrimaryButton>
          </FrameActions>

          <MultipleOptionsDialog
            context={updateContext}
            onConfirm={handleConfirmationAction}
            open={confirmCloseOpen}
            setOpen={setConfirmCloseOpen}
            text={strings.scenariosettings_prod_to_non_prod_dialog_text}
            title={strings.text_confirmation}
          />
        </form>
      </FormProvider>
    </SettingsOption>
  );
};

export default SettingsGeneral;
