import { useCallback } from "react";

import { useStore } from "store";

const setOutputFieldsSelector = (state) => state.setOutputFields;
const clearOutputFieldsSelector = (state) => state.clearOutputFields;
const removeOutputFieldSelector = (state) => state.removeOutputField;

export const useOutputFields = () => {
  const setOutputFields = useStore(setOutputFieldsSelector);
  const clearOutputFields = useStore(clearOutputFieldsSelector);
  const removeOutputField = useStore(removeOutputFieldSelector);

  const isValid = useCallback((name) => {
    return !useStore.getState().duplicateOutputFields.includes(name);
  }, []);

  return {
    isValid,
    setOutputFields,
    clearOutputFields,
    removeOutputField,
  };
};
