import { useMemo } from "react";

import {
  CheckCircleOutline,
  ErrorOutline,
  HelpOutline,
  InfoOutlined,
} from "@mui/icons-material";
import { capitalize } from "lodash-es";

import HtmlTooltip from "./HtmlTooltip";

export const UserTypeIcon = ({ type }) => {
  const icon = useMemo(() => {
    switch (type) {
      case "malicious":
        return <ErrorOutline htmlColor="#f87171" />;
      case "suspicious":
        return <HelpOutline htmlColor="#fbbf24" />;
      case "trusted":
        return <CheckCircleOutline htmlColor="#4ade80" />;
      case "credible":
        return <InfoOutlined htmlColor="#000" />;
      default:
        return null;
    }
  }, [type]);

  if (!icon) {
    return null;
  }

  return <HtmlTooltip title={`${capitalize(type)} user`}>{icon}</HtmlTooltip>;
};
