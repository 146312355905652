import * as d3 from "d3";

const distinctColors = [
  "#1f77b4b3", // Blue
  "#ff7f0eb3", // Orange
  "#2ca02cb3", // Green
  "#7e632ab3", // Dusky Bronze
  "#9467bdb3", // Purple
  "#e377c2b3", // Pink
  "#17becfb3", // Cyan
  "#7fc97fb3", // Light Green
  "#beaed4b3", // Pale Purple
  "#fdc086b3", // Light Orange
  "#1b9e77b3", // Dark Green
  "#7570b3b3", // Dark Purple
  "#a6cee3b3", // Pale Blue
  "#b2df8ab3", // Light Green
  "#6a3d9ab3", // Dark Purple
  "#b15928b3", // Brownish Orange
  "#a55194b3", // Purplish Pink
  "#ce4d8fb3", // Pinkish Red
  "#f8c63eb3", // Golden Haze
  "#ffaa3bb3", // Orange Yellow
  "#7dd1b6b3", // Light Jade
  "#fbb4aeb3", // Pale Pink
  "#66c2a5b3", // Turquoise
  "#8da0cbb3", // Lavender
  "#37484ab3", // Steely Blue
  "#edc948b3", // Mustard Yellow
  "#9c755fb3", // Brownish Gray
  "#f0027fb3", // Magenta Pink
  "#b3b3b3b3", // Light Gray
  "#bcbd22b3", // Yellow-Green
];

const colorScale = d3.scaleOrdinal(distinctColors);

export const getUniqueColor = ({ index }) => {
  const color = d3.color(colorScale(index));

  return color;
};
