import React, { useMemo } from "react";

import { Chip } from "@mui/material";

import Flexbox from "components/ui/Flexbox";

export default function FilterChips(props) {
  const { filters, schema, setFilters } = props;

  const schemaReduced = useMemo(
    () =>
      schema.reduce((a, d) => {
        a[d[0]] = d[1];
        return a;
      }, {}),
    [schema]
  );

  if (!filters) {
    return null;
  }

  return (
    <Flexbox
      className="filter-chips"
      justifyItems="flex-start"
      style={{ gap: ".2em" }}
    >
      {Object.entries(filters)
        .filter(([_k, v]) => v && v.operator && v.operator !== "noop")
        .map(([k, _v]) => (
          <Chip
            variant="outlined"
            key={`${k}`}
            id={k}
            color="secondary"
            label={schemaReduced[k]?.label}
            onDelete={() => setFilters?.({ ...filters, [k]: undefined })}
          />
        ))}
    </Flexbox>
  );
}
