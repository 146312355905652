import { Paper, styled } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  useDiagnosticsDeleteMessagesForQuery,
  useDiagnosticsGetMessagesForUrlQuery,
} from "api/diagnostics";

import { DeleteButton } from "components/ui/DeleteButton";
import Flexbox from "components/ui/Flexbox";
import Frame from "components/ui/Frame";
import FrameContent from "components/ui/FrameContent";
import FrameTitle from "components/ui/FrameTitle";
import FrameTitleText from "components/ui/FrameTitleText";

import { getMessageLevel } from "utils/createMessage";
import { timeFormatter } from "utils/time-fmt";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useLocalStorage } from "hooks/useStorage";

const StyledMessagesList = styled(Box)(({ length, theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridAutoRows: "max-content",
  overflow: "auto",
  height: length ? "18em" : "3em",
  width: "100%",
  "& .message": {
    "&:not(:last-child)": {
      marginBottom: "5px",
    },
    "& .messageBodyWrapper": {
      backgroundColor: "rgba(255,255,255,.5)",
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1.5),
    },
    "& .captionRegion": {
      marginTop: theme.spacing(1),
      "& .caption": {
        marginRight: theme.spacing(2),
        opacity: 0.6,
      },
    },
  },
}));

function MessagesItem({ level, message, service, username, created }) {
  const severity = getMessageLevel(level);

  return (
    <Alert className="message" severity={severity}>
      <AlertTitle sx={{ textTransform: "uppercase" }}>{severity}</AlertTitle>
      <Paper className="messageBodyWrapper" elevation={0}>
        <code style={{ whiteSpace: "pre-wrap" }}>{message}</code>
      </Paper>
      <Flexbox className="captionRegion">
        <Typography className="caption" variant="caption">
          <b>FROM</b> {(service || "-unknown-").toUpperCase()}
        </Typography>
        <Typography className="caption" variant="caption">
          <b>BY</b> {username || "-unknown-"}
        </Typography>
        <Typography className="caption" variant="caption">
          <b>AT</b> {timeFormatter(new Date(created))}
        </Typography>
      </Flexbox>
    </Alert>
  );
}

function MessagesList({ selectedScenarioId }) {
  const strings = useLocalizedStrings();
  const caps = useCapabilities();
  const [messagesBulkSize] = useLocalStorage("max-user-messages-bulk-size", 30);
  const { data: messages } = useDiagnosticsGetMessagesForUrlQuery({
    scenarioId: selectedScenarioId,
    count: messagesBulkSize,
  });

  const { mutate: deleteMessages } = useDiagnosticsDeleteMessagesForQuery({
    scenarioId: selectedScenarioId,
  });

  const handleDeleteMessages = () => {
    deleteMessages(messages?.map((d) => d.id));
  };

  return (
    <Frame>
      <FrameTitle>
        <FrameTitleText>
          {strings.scenariodiagnostics_messages_title}
        </FrameTitleText>

        <DeleteButton
          title={strings.scenariodiagnostics_messages_clear}
          onClick={handleDeleteMessages}
          disabled={!caps({ "acm.user_messages": { write: true } })}
          style={{ marginLeft: "auto" }}
        />
      </FrameTitle>
      <FrameContent>
        <StyledMessagesList length={messages?.length}>
          {messages?.length ? (
            messages
              .sort((a, b) => b.created - a.created)
              .map((message, idx) => (
                <MessagesItem key={idx} {...message}></MessagesItem>
              ))
          ) : (
            <Typography variant="subtitle1">
              {strings.scenariodiagnostics_messages_empty}
            </Typography>
          )}
        </StyledMessagesList>
      </FrameContent>
    </Frame>
  );
}

export default MessagesList;
