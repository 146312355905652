import { useContext, useEffect, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";

import { useGetClusterQuery } from "api/clusters";

import { ScenariosContext } from "contexts/ScenariosContext";

import NotAllowedMessage from "components/ui/NotAllowedMessage";
import SubNavigator from "components/ui/SubNavigator";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import EvaluateProfile from "./EvaluateProfile";
import ProfileActionsHistogram from "./ProfileActionsHistogram";
import { ProfileInfo } from "./ProfileInfoStrip";
import ProfilePicture from "./ProfilePicture";
import ProfileTimeHistogram from "./ProfileTimeHistogram";
import ProfileTransitionsHistogram from "./ProfileTransitionsHistogram";
import ProfileUsersHistogram from "./ProfileUsersHistogram";

const tabs = (strings) => [
  {
    id: "profile-picture",
    label: strings.profiledetails_navbar_tab_diffusion,
    route: "diffusion",
    component: ProfilePicture,
    caps: { "analysis-model.clusters": { read: true } },
  },
  {
    id: "profile-actions",
    label: strings.profiledetails_navbar_tab_actions,
    route: "actions",
    component: ProfileActionsHistogram,
    caps: { "analysis-model.clusters": { read: true } },
  },
  {
    id: "profile-transitions",
    label: strings.profiledetails_navbar_tab_transitions,
    route: "transitions",
    component: ProfileTransitionsHistogram,
    caps: { "analysis-model.clusters": { read: true } },
  },
  {
    id: "profile-time",
    label: strings.profiledetails_navbar_tab_time_gap,
    route: "time",
    component: ProfileTimeHistogram,
    caps: { "analysis-model.clusters": { read: true } },
  },
  {
    id: "profile-users",
    label: strings.profiledetails_navbar_tab_users,
    route: "users",
    component: ProfileUsersHistogram,
    caps: { "analysis-model.clusters": { read: true } },
  },
  {
    id: "evaluate-profile",
    label: strings.profiledetails_navbar_tab_evaluate,
    route: "evaluate",
    component: EvaluateProfile,
    caps: { "analysis-model.clusters": { read: true } },
  },
];

const routes = (strings) => [...tabs(strings).filter((t) => t.route)];

export default function ProfileDetailsMenu(props) {
  const strings = useLocalizedStrings();
  const history = useHistory();
  const { url } = useRouteMatch();
  const caps = useCapabilities();
  const { selectedScenario } = useContext(ScenariosContext);

  const profileId = props.match.params.profile;
  const timeHistogramEnabled = selectedScenario?.enable_time_gap;

  const { data: profile, isPending } = useGetClusterQuery({
    scenarioId: selectedScenario.id,
    cluster: profileId,
  });

  const [filteredRoutes, filteredTabs] = useMemo(
    () =>
      timeHistogramEnabled
        ? [routes(strings), tabs(strings)]
        : [
            routes(strings).filter((route) => route.id !== "time"),
            tabs(strings).filter((tab) => tab.id !== "profile-time"),
          ],
    [strings, timeHistogramEnabled]
  );

  useEffect(() => {
    tabs(strings)[0] && history.push(`${url}/${tabs(strings)[0].route}`);
  }, [strings, history, url]);

  if (!caps({ "analysis-model.clusters": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <SubNavigator
      tabsSpec={filteredTabs}
      routes={filteredRoutes}
      label={strings.profiledetails_navbar_title}
      nestLevel={1}
      backPath={`/scenarios/${props.scenario}/profiles`}
      profileId={profileId}
      profile={profile}
      isPending={isPending}
      {...props}
    >
      <ProfileInfo profile={profile} profileId={profileId} />
    </SubNavigator>
  );
}
