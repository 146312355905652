import React from "react";

import { styled } from "@mui/material";

const StyledInformationStrip = styled("div")`
  width: 100%;
  min-width: calc(1366px - var(--sidebar-width));
  display: flex;
  border-bottom: 1px solid #ddd;
  & > * {
    flex-basis: min-content;
  }
  cursor: default;
`;

export default function InformationStrip({ children, className }) {
  return (
    <StyledInformationStrip
      className={"information-strip " + (className ?? "")}
    >
      {children}
    </StyledInformationStrip>
  );
}
