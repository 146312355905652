import { styled } from "@mui/material";

import { useGetCollectorDataAnalysisRunsQuery } from "api/collectors";

import SubNavigator from "components/ui/SubNavigator";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import DataAnalysisTab from "./DataAnalysisTab";
import DataFilesTable from "./DataFilesTable";

const tabs = (strings) => [
  {
    id: "data",
    label: strings.collectors_datatab_navbar_files,
    route: "files",
    component: DataFilesTable,
    isDefault: true,
  },
  {
    id: "new",
    label: strings.collectors_datatab_navbar_data_exploration,
    route: "data-analysis",
    component: DataAnalysisTab,
  },
];

const DataAnalysisContentArea = styled("div")(() => ({
  minHeight: "100%",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "min-content min-content auto",
}));

const DataTab = ({ collector }) => {
  const strings = useLocalizedStrings();

  const { data: run } = useGetCollectorDataAnalysisRunsQuery({
    id: collector?.id,
    opts: {
      select: (rows) => rows[0],
    },
  });

  return (
    <DataAnalysisContentArea>
      <SubNavigator
        collector={collector}
        label={"data"}
        routes={tabs(strings)}
        run={run}
        tabsSpec={tabs(strings)}
      />
    </DataAnalysisContentArea>
  );
};

export default DataTab;
