import { Typography, styled } from "@mui/material";

import { useVersionQuery } from "api/metadata";

const StyledLogo = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  gap: "2em",
  justifyContent: "space-between",
  paddingLeft: "15px",
}));

function Logo() {
  const { data: version } = useVersionQuery();

  return (
    <StyledLogo>
      <img
        src={`${import.meta.env.BASE_URL}/reveal-logo-white.svg`}
        alt="TrackerIQ"
        height="40"
      />

      <Typography fontWeight="bold" variant="h6">
        TrackerIQ
      </Typography>
      <Typography>{version?.long_version}</Typography>
    </StyledLogo>
  );
}

export default Logo;
