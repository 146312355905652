import { useCallback, useMemo } from "react";

import { MenuOpen } from "@mui/icons-material";
import { IconButton, MenuItem, styled } from "@mui/material";
import { useHistory } from "react-router-dom";

import {
  RaritySelector,
  SensitivitySelector,
} from "components/sessions/GradientSelectors";
import { useSessionDetails } from "components/sessions/SessionDetailsContext";
import SessionDownloadAsCSV from "components/sessions/SessionDownloadAsCSV";
import DataTableINF from "components/ui/DataTableINF";
import HtmlTooltip from "components/ui/HtmlTooltip";
import RarityIndicator from "components/ui/RarityIndicator";
import Selector from "components/ui/Selector";
import SensitivityIndicator from "components/ui/SensitivityIndicator";
import { UiSecondaryButton } from "components/ui/StyledButtons";
import TextSearchInput from "components/ui/TextSearchInput";

import { parseRarityColor } from "utils/parseRarityColor";
import { parseSensitivityColor } from "utils/parseSensitivityColor";
import { timeFormatter } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useInfiniteScroll } from "hooks/infinite-scroll";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import ActionDescription from "./ActionDescription";
import BarChart from "./BarChart";
import BarChartTooltip from "./BarChartTooltip";
import TagsView from "./TagsView";
import Timeline from "./Timeline";
import { TimelineDataGrid } from "./TimelineDataGrid";
import { useTimeline } from "./timelineReducer";
import histogram from "./utils/histogram";

const StyledSessionTimeline = styled("div")(({ theme, showTimeline }) => ({
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "min-content",
  height: "calc(100vh - var(--appbar-height))",
  position: "relative",
  "& .timeline-view-area": {
    display: "grid",
    gap: `${theme.spacing(1)} 0`,
    gridTemplateColumns: "100%",
    gridTemplateRows: showTimeline ? "3em 15em 1fr 90px" : "3em 1fr 90px",
    height: "inherit",
    padding: ".5em .5em",

    "& .timeline-main-toolbar": {
      alignItems: "stretch",
      display: "flex",
      gap: ".4em",
      justifyContent: "space-between",
      "& > a": {
        alignItems: "stretch",
        display: "inline-flex",
      },
      "& .text-search": {
        flexGrow: 1,
      },
    },

    "& .charts-container": {
      display: "flex",
      flexDirection: "row",
      gap: "2px",
      "& > *": {
        flexGrow: 1,
      },
      "& .timeline-chart": {
        flexGrow: 4,
        overflowY: "hidden",
        resize: "horizontal",
      },
    },

    "& .timeline-time-range": {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    },

    "& .bar-chart": {
      "& .chart-toolbar > select": {
        backgroundColor: "#eee",
        border: "none",
        marginLeft: "auto",
      },
    },

    "& .tags": {
      "& td": {
        border: "none",
        padding: theme.spacing(0.3),
      },
    },
  },
  "& circle.lolipop:not(.dimmed)": {
    cursor: "pointer",
  },
}));

const columns = (actionKeys) => [
  {
    id: "sensitivity",
    render: (e) => {
      if (!e.match_info.match) {
        return <SensitivityIndicator value={e.sensitivity} />;
      }
      return (
        <HtmlTooltip
          title={
            <div>
              <h4>Matched by:</h4>
              <pre style={{ fontFamily: "monospace" }}>
                {e.match_info.match}
              </pre>
            </div>
          }
        >
          <SensitivityIndicator value={e.sensitivity} />
        </HtmlTooltip>
      );
    },
    style: {
      width: "4em",
    },
  },
  {
    id: "rarity",
    render: (e) => <RarityIndicator value={+e.rarity_score} />,
    style: {
      width: "4em",
    },
  },
  {
    id: "time",
    label: "time",
    render: (e) => timeFormatter(e.time),
    style: {
      width: "12em",
    },
  },
  {
    id: "action",
    label: "action",
    render: (e) => (
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <ActionDescription item={e} />
        <TagsView actionKeys={actionKeys} items={e.tokens_filtered} />
      </div>
    ),
  },
];

const HistogramView = ({ items, onBarClick, onBarRightClick, colorScheme }) => {
  const chartsOptions = { transitionDuration: 200 };

  const hist = useMemo(
    () =>
      colorScheme === "sensitivity"
        ? histogram(
            items,
            (d) => d.action,
            (prev, d) => ({
              ...d,
              sensitivity: Math.max(prev?.sensitivity || 0, d.sensitivity),
              dimmed: prev?.dimmed || d.dimmed,
            }),
            (a, b) => b[2].sensitivity - a[2].sensitivity
          )
        : colorScheme === "rarity"
        ? histogram(
            items,
            (d) => d.action,
            (prev, d) => ({ ...d, dimmed: prev?.dimmed || d.dimmed }),
            (a, b) => b[2].rarity_score - a[2].rarity_score
          )
        : null,
    [items, colorScheme]
  );

  const colorTranslator = useMemo(() => {
    return {
      sensitivity: (d) => parseSensitivityColor(d[2].sensitivity),
      rarity: (d) => parseRarityColor(d[2].rarity_score),
    }[colorScheme];
  }, [colorScheme]);

  return (
    <BarChart
      className="chart chart-hist"
      items={hist}
      onBarClick={onBarClick}
      onBarRightClick={onBarRightClick}
      options={chartsOptions}
      tooltipContent={BarChartTooltip}
      calcColor={colorTranslator}
    />
  );
};

const EventsList = ({ scenario, actionKeys, load }) => {
  const { scrollableAreaRef, scrollableItems, loading, additionalResInfo } =
    useInfiniteScroll("tbody > tr", load, {
      rootMargin: "300px 0px",
      returnAdditionalResInfo: true,
    });

  const enriched = useMemo(() => {
    return scrollableItems.map((s, idx) => ({
      ...s,
      id: s.sid + idx,
      index: idx,
    }));
  }, [scrollableItems]);

  return (
    <DataTableINF
      ref={scrollableAreaRef}
      scenario={scenario}
      columns={columns(actionKeys)}
      items={enriched}
      itemsCount={additionalResInfo?.count}
      showTotal={true}
      isPending={loading}
      hover
      noheader
    />
  );
};

const ListView = ({ items, actionKeys, scenario }) => {
  const load = useCallback(
    (page) => {
      const promise = new Promise((resolve) => {
        const rows = items.slice(page * 20, (page + 1) * 20);
        resolve({ rows, count: items?.length ?? 0 });
      });
      return [promise];
    },
    [items]
  );

  //table is separate with key to prevent stale closures
  return (
    <EventsList
      actionKeys={actionKeys}
      load={load}
      scenario={scenario}
      key={items.length}
    />
  );
};

function SessionTimeline({ scenario, session }) {
  const strings = useLocalizedStrings();
  const chartsOptions = { transitionDuration: 200 };
  const {
    state: {
      routes,
      isPending,
      enriched,
      rarityRange,
      showTimeline,
      colorScheme,
      sensitivityRange,
      actionKeys,
      presentFields,
      searchPattern,
    },
    onTimelineLolipopClicked,
    onSelection,
    onBarChartBarClicked,
    onBarChartBarRightClicked,
    onToggleTimeline,
    onSetSearch,
    onSetColorScheme,
    onSetRarity,
    onSetSensitivity,
  } = useTimeline(scenario, session);
  const history = useHistory();
  const [showTable, setShowTable] = useCurrentUserSettings(
    "show-timeline-table",
    true
  );

  const { data: sessionInfo } = useSessionDetails();

  const visibleEvents = useMemo(
    () => enriched?.filter((d) => !d.dimmed),
    [enriched]
  );

  const handleSimilarSessions = () => {
    if (sessionInfo && sessionInfo.cluster > 0) {
      history.push(
        `/scenarios/${scenario}/sessions?cluster=${sessionInfo.cluster}`
      );
    }
  };

  const handleShowTimeline = () => setShowTable((prev) => !prev);

  return (
    <StyledSessionTimeline showTimeline={showTimeline} className="timeline-tab">
      <div className="timeline-view-area">
        <div className="timeline-main-toolbar">
          <IconButton
            style={{
              zIndex: 1,
              transform: `rotate(${showTimeline ? 90 : 270}deg)`,
            }}
            size="small"
            onClick={onToggleTimeline}
          >
            <MenuOpen size="small" />
          </IconButton>
          <TextSearchInput
            searchPattern={searchPattern}
            setSearchPattern={onSetSearch}
          />
          <Selector
            style={{
              width: "170px",
              height: "42px",
            }}
            value={colorScheme}
            onChange={onSetColorScheme}
          >
            <MenuItem value="sensitivity">
              {strings.sessiondetails_timeline_overlay_sensitivity}
            </MenuItem>
            <MenuItem value="rarity">
              {strings.sessiondetails_timeline_overlay_rarity}
            </MenuItem>
          </Selector>
          <SessionDownloadAsCSV
            routes={routes}
            scenario={scenario}
            session={session}
          />
          <UiSecondaryButton
            onClick={handleSimilarSessions}
            disabled={!sessionInfo || sessionInfo.cluster <= 0}
            size="small"
          >
            {strings.sessiondetails_timeline_similar_sessions}
          </UiSecondaryButton>
          <RaritySelector range={rarityRange} setRange={onSetRarity} />
          <SensitivitySelector
            minSensitivity={sensitivityRange}
            setMinSensitivity={onSetSensitivity}
            disabled={enriched.length === 0}
          />
          <Selector
            style={{
              width: "150px",
              height: "42px",
              textTransform: "capitalize",
            }}
            value={showTable}
            onChange={handleShowTimeline}
          >
            <MenuItem value={true} style={{ textTransform: "capitalize" }}>
              Table View
            </MenuItem>
            <MenuItem value={false} style={{ textTransform: "capitalize" }}>
              List View
            </MenuItem>
          </Selector>
        </div>

        {showTimeline && (
          <div className="charts-container">
            <Timeline
              className="chart chart-timeline"
              items={enriched}
              onBrushSelection={onSelection}
              onEventClick={onTimelineLolipopClicked}
              disabled={enriched.length === 0}
              colorScheme={colorScheme}
              options={{
                ...chartsOptions,
                zoomIndicator: 15,
              }}
            />

            <HistogramView
              items={enriched}
              onBarClick={onBarChartBarClicked}
              onBarRightClick={onBarChartBarRightClicked}
              colorScheme={colorScheme}
            />
          </div>
        )}

        {showTable ? (
          <TimelineDataGrid
            actionKeys={actionKeys}
            items={visibleEvents}
            presentFields={presentFields}
            isPending={isPending}
          />
        ) : (
          <ListView
            items={visibleEvents}
            scenario={scenario}
            session={session}
            actionKeys={actionKeys}
          />
        )}
      </div>
    </StyledSessionTimeline>
  );
}

export default SessionTimeline;
