import { getReasonPhrase } from "http-status-codes";

import { baseUrl } from "./baseUrl";

async function stdFetch(url, options, settings = { returnRawJson: false }) {
  const finalUrl = typeof url !== "string" ? url?.url : url;
  const location = finalUrl.startsWith("http")
    ? finalUrl
    : baseUrl() + finalUrl;
  const finalOptions = typeof url !== "string" ? url.options : options;

  const res = await fetch(location, {
    ...finalOptions,
    credentials: "include",
  });

  if (res.status === 503) {
    throw new Error("Problem connecting to backend service");
  }
  if (res.status === 401) {
    const data = await res.json();
    data.auth_url ??= true;
    return data;
  }
  if (!res.ok) {
    let errorMessage = `${
      res.statusText.length ? res.statusText : getReasonPhrase(res.status)
    } (${res.status})`;
    try {
      const resData = await res.json();
      errorMessage += `${resData.error ? `: ${resData.error}` : ""}`;
    } catch (e) {}
    throw new Error(errorMessage);
  }

  const contentType = res.headers.get("content-type");
  if (contentType.startsWith("text/csv")) {
    return await res.text();
  }

  if (contentType.startsWith("application/octet-stream")) {
    return await res.blob();
  }

  if (settings.returnRawJson) {
    return await res.json();
  } else {
    return (await res.json())?.rows;
  }
}

export default stdFetch;
