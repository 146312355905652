import { useEffect } from "react";

import { useCollectorsQuery } from "api/collectors";

import useBackendEvents from "hooks/useBackendEvents";

const backendEventsOfInterest = ["scenarios"];

const useCollectors = () => {
  const [backendEvents] = useBackendEvents(backendEventsOfInterest);
  const { data: collectors = [], isPending, refetch } = useCollectorsQuery();

  useEffect(() => {
    const refreshCollectors = async () => {
      await refetch();
    };
    refreshCollectors();
  }, [backendEvents, refetch]);

  return [collectors, isPending];
};

export default useCollectors;
