import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Badge,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import { FormControlLabel, RadioGroup } from "@mui/material";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import { keys, omit, pick } from "lodash-es";
import { Controller, FormProvider, useForm } from "react-hook-form";

import ApplicationIcon from "components/ui/ApplicationIcon";
import BrandedRadio from "components/ui/BrandedRadio";
import FileUpload from "components/ui/FileUpload";
import Flexbox from "components/ui/Flexbox";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import Selector from "components/ui/Selector";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";

import { useDataAnalysisColumns } from "hooks/useDataAnalysisColumns";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";
import { useOutputFields } from "hooks/useOutputFields";

import MappingEditor from "./MappingEditor/MappingEditor";
import MapingConfigLocalDownloader from "./MappingEditor/components/MapingConfigLocalDownloader";

const StyledSourceDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxHeight: "95vh",
    maxWidth: "95vw",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  "& .MuiFormControl-root": {
    margin: 0,
    padding: 0,
    width: "100%",
  },
  "& .offline-type-radio-group": {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  "& .data-source-type": {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },

  "& .dialogPaper": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  "& .formContent": {
    display: "flex",
    flexDirection: "column",
    height: "Calc(100% - 100px)",
    justifyContent: "space-between",
    width: "100%",
  },
  "& .sourceDialogTab": {
    cursor: "pointer",
    position: "relative",
    display: "inline-flex",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    justifyContent: "center",
    textDecoration: "none",
    textTransform: "uppercase",
    letterSpacing: 2,
    color: theme.palette.text.secondary,
    transition: "all 200ms ease",
    fontSize: "inherit",
    userSelect: "none",
    "&:hover, &:active, &.active": {
      color: theme.palette.text.primary,
    },
    "&.activeTab": {
      position: "relative",
      fontWeight: "bold",
      backgroundColor: `${theme.palette.primary.dark}`,
      color: "white",
      borderRadius: "5px",
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: "-3px",
        left: "50%",
        width: "1em",
        height: "1em",
        transform: "translate(-50%) rotate(-135deg)",
        backgroundColor: `${theme.palette.primary.dark}`,
        zIndex: -1,
      },
    },
  },
  "& .stdTextField": {
    marginBottom: "10px",
    "& .MuiInputBase-root": {
      width: "100%",
    },
    "&.MuiFormControl-root": {
      padding: "0px !important",
      marginBottom: "10px",
    },
    "& fieldset": {
      marginBottom: "0",
      height: "100%",
    },
    "& .MuiFormHelperText-contained": {
      marginTop: "0px",
    },
  },
  "& .errorNumber": {
    position: "absolute",
    top: "-10px",
    right: "15px",
    "& .MuiBadge-anchorOriginTopRightCircular": {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
}));

const MappingEditorTabs = ({
  activeTabState,
  handleTabClick,
  internalConfigErrors,
}) => {
  const strings = useLocalizedStrings();
  return (
    <div
      style={{
        display: "flex",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "24px",
      }}
    >
      <div
        className={`sourceDialogTab ${
          activeTabState === strings.sourcedialog_tabName_basic_options
            ? "activeTab"
            : ""
        }`}
        onClick={() =>
          handleTabClick(strings.sourcedialog_tabName_basic_options)
        }
      >
        {strings.sourcedialog_tabName_basic_options}
      </div>

      <div
        className={`sourceDialogTab ${
          activeTabState === strings.sourcedialog_tabName_mapping_editor
            ? "activeTab"
            : ""
        }`}
        onClick={() =>
          handleTabClick(strings.sourcedialog_tabName_mapping_editor)
        }
      >
        <div className="errorNumber">
          {!!internalConfigErrors && (
            <Badge badgeContent="!" color="error" overlap="circular" />
          )}
        </div>
        {strings.sourcedialog_tabName_mapping_editor}
      </div>
    </div>
  );
};

function ApplicationSelector({ apps, connector, setConnector }) {
  const strings = useLocalizedStrings();
  return (
    <Selector
      required
      label={strings.sourcedialog_label_application}
      value={connector}
      onChange={(e) => setConnector(e.target.value)}
    >
      {apps.map((a) => (
        <MenuItem key={a.id} value={a.id} style={{ lineHeight: "2.5em" }}>
          <Flexbox
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <ApplicationIcon app={a} />
            <span style={{ textTransform: "capitalize" }}>{a.name}</span>
          </Flexbox>
        </MenuItem>
      ))}
    </Selector>
  );
}

const internalConfigTemplate = {
  mapping: {
    device: [],
    appuser: [],
    location: [],
    annotation: [],
  },
  filtering: {
    entries: [],
  },
  bucketing: {
    entries: [],
  },
  transformations: {
    entries: [],
  },
};

const getDefaultValues = (source, optionsTemplate = null, app) => {
  const isAppPreviouslySaved = app?.id === source?.appid;
  const defaultAppOptions = Object.entries(optionsTemplate || {})
    .filter((d) => d[1].datatype !== "hidden")
    .reduce((all, [k, v]) => {
      v.datatype === "bool"
        ? (all[k] = source?.options[k] || v.default)
        : (all[k] = source?.options[k] || v.default || "");
      return all;
    }, {});

  const defaultOfflineMode = source?.offline_mode
    ? source.offline_type || "s3"
    : "online";
  const defaultInternalConfig =
    source?.options?.internal_config || internalConfigTemplate;

  return {
    appid: app?.id || source?.appid,
    offline_mode: defaultOfflineMode,
    offline_type: source?.offline_type,
    offline_prefix: isAppPreviouslySaved ? source?.offline_prefix : "",
    name: isAppPreviouslySaved ? source?.name : app?.name,
    description: isAppPreviouslySaved ? source?.description : app?.description,
    options: {
      ...defaultAppOptions,
      internal_config: defaultInternalConfig,
    },
  };
};

export default function SourceDialog({
  apps,
  onAccept,
  onCancel,
  open,
  source,
  titleText,
}) {
  const strings = useLocalizedStrings();
  const [confirmCloseOpen, setConfirmCloseOpen] = useState(false);
  const { pushMessage } = useMessages();
  const [activeTabState, setActiveTabState] = useState(
    strings.sourcedialog_tabName_basic_options
  );
  const { getAnalysisColumns } = useDataAnalysisColumns();

  const methods = useForm({
    mode: "onChange",
    defaultValues: source,
  });
  const { clearOutputFields } = useOutputFields();
  const [fileUploaded, setFileUploaded] = useState(0);
  const [formReset, setFormReset] = useState(0);
  const uploadedContent = useRef(false);

  const watchedOffline_mode = methods.watch("offline_mode");
  const watchedAppID = methods.watch("appid");

  const app = useMemo(() => {
    if (!apps?.length) {
      return null;
    }
    const app = apps.find((a) => a.id === watchedAppID);
    getAnalysisColumns(app);
    return app;
  }, [apps, getAnalysisColumns, watchedAppID]);

  const optionsTemplate = useMemo(() => {
    if (!app) {
      return null;
    }

    const mode = Object.entries(app.options_modes || {}).find(
      (om) => om[1].default
    )?.[0];

    if (!mode) {
      return null;
    }

    return app.options[mode];
  }, [app]);

  useEffect(() => {
    methods.reset(getDefaultValues(source, optionsTemplate, app));
  }, [methods, source, optionsTemplate, app]);

  useEffect(() => {
    clearOutputFields();
  }, [clearOutputFields]);

  const onSubmit = (formData) => {
    const hiddenKeys = Object.entries(optionsTemplate)
      .filter((d) => d[1].datatype === "hidden")
      .reduce((all, [k, v]) => {
        all[k] = v.default;
        return all;
      }, {});

    const optionKeys = supportMappingEditor
      ? "internal_config"
      : keys(optionsTemplate);
    const formDataOptions = pick(formData?.options, optionKeys);
    const filteredFormData = omit(formData, "options");
    const prevSource = omit(source, "options");

    let updatedData;

    updatedData = {
      ...prevSource,
      ...filteredFormData,
      options: {
        ...prevSource.options,
        ...formDataOptions,
        ...(hiddenKeys || {}),
      },
    };

    if (updatedData?.offline_mode === "online") {
      updatedData.offline_mode = false;
      updatedData.offline_type = "s3";
    } else if (updatedData?.offline_mode === "s3") {
      updatedData.offline_mode = true;
      updatedData.offline_type = "s3";
    } else if (updatedData?.offline_mode === "fs") {
      updatedData.offline_mode = true;
      updatedData.offline_type = "fs";
    }

    onAccept(updatedData);
    return updatedData;
  };

  const handleConnectorChange = (id) => {
    const a = apps.find((a) => a.id === id);
    if (a) {
      methods.setValue("connector", a.connector);
      methods.setValue("appid", id);
      methods.setValue("name", a.name);
      methods.setValue("description", a.description);
    }
  };

  const offlineSupport = !!app?.offline_support;

  const showLegacyOptions =
    source && source?.options?.legacy_source && source?.appid === watchedAppID;

  const handleConfigUpload = useCallback(
    (file) => {
      const parseAndValidateConfigFile = (jsonFile) => {
        let jsonData;
        try {
          jsonData = JSON.parse(jsonFile);
        } catch (e) {
          throw new Error(strings.sourcedialog_file_upload_error_message);
        }

        const supportedMappingKeys = Object.keys(
          internalConfigTemplate.mapping
        );
        for (const key in jsonData.mapping) {
          if (!supportedMappingKeys.includes(key)) {
            throw new Error(
              strings.sourcedialog_file_upload_mapping_error_message
            );
          }
        }

        return jsonData;
      };

      const readFile = new FileReader();
      readFile.onload = function (e) {
        try {
          const defaultValues = getDefaultValues(source, optionsTemplate, app);
          const jsonData = parseAndValidateConfigFile(e.target.result);

          defaultValues.options.internal_config = {
            ...internalConfigTemplate,
            ...jsonData,
          };
          defaultValues.name = methods.getValues("name");
          defaultValues.description = methods.getValues("description");

          pushMessage(
            "success",
            strings.sourcedialog_file_upload_success_message
          );
          uploadedContent.current = defaultValues;
          setFileUploaded((prev) => ++prev);
        } catch (e) {
          pushMessage("error", e.message);
        }
      };
      readFile.readAsText(file);
    },
    [
      app,
      methods,
      source,
      optionsTemplate,
      pushMessage,
      strings.sourcedialog_file_upload_success_message,
      strings.sourcedialog_file_upload_error_message,
      strings.sourcedialog_file_upload_mapping_error_message,
    ]
  );

  //Next two effects are needed to handle form reset
  //and validation triggering in the proper order after file upload
  //handling this in the event handler leads to bugs
  useEffect(() => {
    if (fileUploaded) {
      methods.reset(uploadedContent.current);
      uploadedContent.current = null;
      setFormReset((prev) => ++prev);
    }
  }, [methods, fileUploaded]);

  useEffect(() => {
    if (formReset) {
      methods.trigger();
    }
  }, [methods, formReset]);

  const handleTabClick = (tab) => {
    setActiveTabState(tab);
  };

  const handleCloseDialog = useCallback(() => {
    const isDirty =
      Object.entries(methods.formState.dirtyFields).length > 0 || fileUploaded;
    if (isDirty) {
      setConfirmCloseOpen(true);
      return;
    }
    onCancel();
  }, [methods.formState.dirtyFields, onCancel, fileUploaded]);

  const supportMappingEditor =
    app?.options?.default?.internal_connector &&
    !source?.options?.legacy_source;

  return (
    <StyledSourceDialog
      fullScreen
      onClose={handleCloseDialog}
      open={open}
      transitionDuration={0}
    >
      {/* if mapping editor is supported, show basic options and mapping editor tabs */}
      {supportMappingEditor && (
        <MappingEditorTabs
          activeTabState={activeTabState}
          handleTabClick={handleTabClick}
          internalConfigErrors={
            methods.formState.errors?.options?.internal_config
          }
        />
      )}
      <DialogTitle>{titleText || "Edit Source"}</DialogTitle>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{
            display: "flex",
            overflow: "hidden",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <div className="formContent">
            <DialogContent className="dialogPaper">
              {/* basic options content */}
              {activeTabState ===
                strings.sourcedialog_tabName_basic_options && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ApplicationSelector
                      connector={watchedAppID || ""}
                      setConnector={(c) => handleConnectorChange(c)}
                      apps={apps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Controller
                          defaultValue={(source && source.name) || ""}
                          name="name"
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              className="stdTextField"
                              error={!!error}
                              helperText={!!error ? strings.invalid_input : ""}
                              label={strings.sourcedialog_label_name}
                              multiline
                              variant="outlined"
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Controller
                          defaultValue={(source && source.description) || ""}
                          name="description"
                          rules={{ required: true }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              className="stdTextField"
                              error={!!error}
                              helperText={!!error ? strings.invalid_input : ""}
                              label={strings.sourcedialog_label_description}
                              multiline
                              variant="outlined"
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    {app && offlineSupport && (
                      <Grid item xs={12}>
                        <Controller
                          className="data-source-type"
                          name="offline_mode"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <RadioGroup
                              className="data-source-type"
                              variant="outlined"
                              {...field}
                            >
                              <FormControlLabel
                                control={<BrandedRadio value={"online"} />}
                                label={strings.sourcedialog_mode_online}
                              />
                              <FormControlLabel
                                control={<BrandedRadio value={"s3"} />}
                                label={strings.sourcedialog_mode_offline}
                              />
                            </RadioGroup>
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Grid container spacing={0} pl={2}>
                        {app
                          ? optionsTemplate &&
                            (watchedOffline_mode === "online" ||
                              !offlineSupport) &&
                            Object.entries(optionsTemplate)
                              .filter((d) => d[1].datatype !== "hidden")
                              .map(([k, v]) => {
                                if (k === "config") {
                                  if (
                                    !!app?.options?.default
                                      ?.internal_connector &&
                                    showLegacyOptions
                                  ) {
                                    return (
                                      <Grid item xs={12} key={k}>
                                        <Controller
                                          name={`options.` + k}
                                          rules={{ required: v.required }}
                                          render={({
                                            field,
                                            fieldState: { error },
                                          }) => (
                                            <TextField
                                              className="stdTextField"
                                              error={!!error}
                                              helperText={
                                                !!error
                                                  ? strings.invalid_input
                                                  : ""
                                              }
                                              label={v.label}
                                              multiline
                                              variant="outlined"
                                              {...field}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    );
                                  }
                                  return null;
                                } else {
                                  if (v.datatype === "bool") {
                                    return (
                                      <Grid item xs={12} key={k}>
                                        <Controller
                                          defaultValue={false}
                                          name={`options.` + k}
                                          render={({
                                            field: { onChange, value },
                                          }) => (
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={value}
                                                  onChange={onChange}
                                                />
                                              }
                                              label={v.label}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    );
                                  } else {
                                    return (
                                      <Grid item xs={12} key={k}>
                                        <Controller
                                          name={`options.` + k}
                                          rules={{ required: v.required }}
                                          render={({
                                            field,
                                            fieldState: { error },
                                          }) => (
                                            <TextField
                                              className="stdTextField"
                                              error={!!error}
                                              helperText={
                                                !!error
                                                  ? strings.invalid_input
                                                  : ""
                                              }
                                              label={v.label}
                                              multiline
                                              variant="outlined"
                                              {...field}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    );
                                  }
                                }
                              })
                          : null}
                        {app &&
                          offlineSupport &&
                          watchedOffline_mode === "s3" && (
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Controller
                                  name={"offline_prefix"}
                                  render={({ field }) => (
                                    <TextField
                                      className="stdTextField"
                                      label={
                                        strings.sourcedialog_label_s3prefix
                                      }
                                      multiline
                                      variant="outlined"
                                      {...field}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* mapping editor content */}
              {supportMappingEditor && (
                <MappingEditor
                  isActive={
                    activeTabState ===
                    strings.sourcedialog_tabName_mapping_editor
                  }
                />
              )}
            </DialogContent>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "24px",
            }}
          >
            {/* if mapping editor is supported, show file upload/download */}
            {supportMappingEditor && (
              <Flexbox width={1}>
                <FileUpload
                  accept="application/json"
                  onUpload={handleConfigUpload}
                  style={{ flexGrow: 1, height: "40px", width: "100%" }}
                />

                <MapingConfigLocalDownloader
                  filename={`${app?.name || source?.name}.json`}
                  onClick={methods.getValues}
                />
              </Flexbox>
            )}

            <DialogActions>
              <UiSecondaryButton onClick={handleCloseDialog}>
                {strings.button_cancel}
              </UiSecondaryButton>
              <UiPrimaryButton type="submit">
                {strings.button_save}
              </UiPrimaryButton>
            </DialogActions>
          </div>
        </form>
      </FormProvider>

      <MultipleOptionsDialog
        onConfirm={methods.handleSubmit(onSubmit)}
        optionalText={strings.button_discard}
        optionalHandler={() => onCancel()}
        confirmText={strings.button_save}
        open={confirmCloseOpen}
        setOpen={setConfirmCloseOpen}
        text={strings.sourcedialog_close_question}
        title={strings.sourcedialog_close_title}
      />
    </StyledSourceDialog>
  );
}
