import { createContext, useCallback } from "react";

import { useCurrentUserSettings } from "hooks/currentUserSettings";

export const BrowserNotificationsContext = createContext();

export function BrowserNotificationsContextProvider(props) {
  const [notificationsEnabled, setNotificationsEnabled] =
    useCurrentUserSettings("notifications_enabled", false);
  const { children } = props;

  const pushNotification = useCallback(
    (text, options) => {
      const send = () => {
        const opt = {
          ...options,
          body: text,
          icon: `${import.meta.env.BASE_URL}/79840824.png`,
        };
        new Notification("TrackerIQ", opt);
      };
      if (
        !notificationsEnabled ||
        !Notification ||
        Notification.permission === "denied"
      ) {
        return;
      }
      if (Notification.permission === "default") {
        Notification.requestPermission()
          .then((result) => {
            if (result === "granted") {
              send();
            }
          })
          .catch(() => {});
      } else {
        send();
      }
    },
    [notificationsEnabled]
  );

  const enableNotifications = useCallback(
    (enable) => {
      setNotificationsEnabled(enable);
    },
    [setNotificationsEnabled]
  );

  return (
    <BrowserNotificationsContext.Provider
      value={{
        pushNotification,
        enableNotifications,
        notificationsEnabled,
      }}
    >
      {children}
    </BrowserNotificationsContext.Provider>
  );
}
