import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Paper, Typography, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

import {
  useCreateSourceMutation,
  useDeleteSourceMutation,
  useSetSourceActiveMutation,
  useSourcesQuery,
  useUpdateSourceMutation,
} from "api/sources";

import { ScenariosContext } from "contexts/ScenariosContext";

import ApplicationIcon from "components/ui/ApplicationIcon";
import { DeleteButton } from "components/ui/DeleteButton";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import NotAllowedMessage from "components/ui/NotAllowedMessage";
import { StdSwitch } from "components/ui/StdSwitch";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import useApplications from "hooks/useApplications";
import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import SourceDialog from "./SourceDialog";

const StyledSources = styled("div")(({ theme }) => ({
  height: "calc(100vh - 171px)",
  overflowX: "hidden",
  padding: theme.spacing(2),
  position: "relative",
  width: "100%",
  "&.covered": {},
  "& .sources-list": {
    marginTop: theme.spacing(2),
  },
  "& .source-item": {
    border: "1px solid #ddd",
    borderRadius: theme.shape.borderRadius,
    height: "13em",
    overflow: "hidden",
    padding: theme.spacing(2),
    position: "relative",
    "& .source-title": {
      gap: ".5em",
    },
    "& .source-actions": {
      alignItems: "center",
      bottom: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
      position: "absolute",
      right: theme.spacing(2),
      "& > *": {
        padding: theme.spacing(0.5),
      },
    },
    "& .source-icon": {
      marginRight: theme.spacing(1),
    },
    "& .status-led": {
      fontSize: "70%",
      marginLeft: theme.spacing(1),
    },
    "& hr": {
      borderColor: "white",
      margin: "10px 0px",
    },
  },
}));

const SourceActions = ({ source, onPause, onDelete, onEdit }) => {
  const strings = useLocalizedStrings();
  const caps = useCapabilities();

  return (
    <div className="source-actions">
      <StdSwitch
        beforeLabel="ON"
        afterLabel="OFF"
        checked={source.active}
        onChange={(e) => onPause(e, source)}
        disabled={!caps({ "acm.sources": { write: true } })}
      />
      <IconButton
        title={strings.sources_tooltip_edit_settings}
        onClick={(e) => onEdit(e, source)}
        disabled={!caps({ "acm.sources": { write: true } })}
        size="large"
      >
        <EditIcon />
      </IconButton>

      <DeleteButton
        title={strings.sources_tooltip_delete}
        onClick={(e) => onDelete(e, source)}
        disabled={!caps({ "acm.sources": { write: true } })}
      />
    </div>
  );
};

const SourceItem = ({ source, onPause, onDelete, onEdit }) => {
  const [apps] = useApplications();
  const app = apps.find((a) => a.connector === source.connector);
  return (
    <Paper className="source-item" elevation={0} data-id={source.id}>
      <Box className="source-title" display="flex" alignItems="center">
        <ApplicationIcon app={app} size="medium" />
        <Typography variant="subtitle1">{source.name}</Typography>
      </Box>
      <hr />
      <Typography variant="subtitle1">{source.description}</Typography>
      <SourceActions
        source={source}
        onPause={onPause}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    </Paper>
  );
};

const SourcesList = (props) => {
  const { sources } = props;
  return (
    <Grid
      container
      width={1}
      spacing={2}
      justifyContent="flex-start"
      alignItems="stretch"
    >
      {sources?.map((s) => (
        <Grid item key={s.id} xs={12} sm={6} md={4} lg={4} xl={3}>
          <SourceItem id={s.id} source={s} {...props} />
        </Grid>
      ))}
    </Grid>
  );
};

function Sources(props) {
  const strings = useLocalizedStrings();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmationContext, setConfirmationContext] = useState();
  const { selectedScenario } = useContext(ScenariosContext);
  const { pushMessage } = useMessages();
  const [openSourceDialog, setOpenSourceDialog] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const dialogTitle = useRef("New Source");
  const location = useLocation();
  const caps = useCapabilities();

  const [apps] = useApplications();

  const { data: results } = useSourcesQuery({
    scenarioId: selectedScenario?.id,
  });

  const { mutate: pauseSource } = useSetSourceActiveMutation();
  const { mutate: deleteSource } = useDeleteSourceMutation();
  const { mutate: createSource } = useCreateSourceMutation();
  const { mutate: updateSource } = useUpdateSourceMutation();

  const sources = useMemo(() => {
    if (apps?.length === 0) {
      return [];
    }

    return results?.map((d) => ({
      ...d,
      appid: d.connector_id
        ? apps.find((a) => a.id === d.connector_id)?.id
        : apps.find((a) => a.connector === d.connector)?.id,
    }));
  }, [apps, results]);

  const handlePause = (_e, source) =>
    pauseSource({
      sourceId: source.id,
      active: !source.active,
    });

  const handleDelete = (_e, source) => {
    setConfirmationContext(source);
    setConfirmOpen(true);
  };

  const handleEdit = (_e, source) => {
    setOpenSourceDialog(true);
    setSelectedSource({ ...source });
    dialogTitle.current = strings.sourcedialog_title_edit;
  };

  const handleNewSource = useCallback(() => {
    setSelectedSource();
    dialogTitle.current = strings.sourcedialog_title_create;
    setOpenSourceDialog(true);
  }, [strings]);

  const handleCancelNewSource = () => {
    setOpenSourceDialog(false);
    setSelectedSource(null);
  };

  const handleSaveNewSource = (newSource) => {
    const s = {
      ...newSource,
      connector: apps.find((a) => a.id === newSource.appid)?.connector,
    };
    delete s.appid;

    if (newSource.scenario) {
      updateSource(s, {
        onSuccess: () =>
          pushMessage("success", strings.sources_message_updated),
      });
    } else {
      createSource(
        { scenarioId: selectedScenario.id, source: s },
        {
          onSuccess: () =>
            pushMessage("success", strings.sources_message_added),
        }
      );
    }
    setSelectedSource();
    setOpenSourceDialog(false);
  };

  const handleRemovalConfirm = (ctx) => deleteSource({ sourceId: ctx.id });

  useEffect(() => {
    const autoOpenCreate = props.location.search.indexOf("new=1") !== -1;
    if (autoOpenCreate) {
      props.history.push(props.location.pathname);
      handleNewSource();
    }
  }, [location, props.history, props.location, handleNewSource]);

  if (!caps({ "acm.sources": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <StyledSources>
      <UiPrimaryButton
        color="primary"
        disabled={!caps({ "acm.sources": { write: true } })}
        onClick={handleNewSource}
        startIcon={<AddCircleIcon />}
      >
        {strings.sources_add_new_source}
      </UiPrimaryButton>
      <Box mt={3}>
        <SourcesList
          onDelete={handleDelete}
          onEdit={handleEdit}
          onPause={handlePause}
          sources={sources}
        />
      </Box>
      <SourceDialog
        apps={apps}
        key={openSourceDialog}
        onAccept={handleSaveNewSource}
        onCancel={handleCancelNewSource}
        open={openSourceDialog}
        source={selectedSource}
        titleText={dialogTitle.current}
      />

      <MultipleOptionsDialog
        confirmText={strings.button_remove}
        context={confirmationContext}
        onConfirm={handleRemovalConfirm}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        text={strings.sources_confirm_delete_question}
        title={strings.sources_confirm_delete_title}
      />
    </StyledSources>
  );
}

export default Sources;
