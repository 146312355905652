import { useCallback } from "react";

import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch, useInfiniteTableContainer } from "./query";

class LogUsersAPI extends API {
  static getLogUsersUrl(scenario, offset, limit, orderby, filters) {
    // convert 'max_risk' operands from 0..100 to 0..1
    const f = JSON.parse(JSON.stringify(filters));
    if (f?.["avg_sensitivity"]) {
      f["avg_sensitivity"].operand *= -1;
    }
    if (f?.["max_risk"]) {
      f["max_risk"]["operand"] /= 100;
    }

    return {
      url: super.composeChunkedQueryUrl(
        `/api/analysis-model/v1/${scenario}/users`,
        offset,
        limit,
        f,
        orderby,
        "orig_app_user_id"
      ),
    };
  }

  static getUserDailyActivities(scenario, userid) {
    return {
      url: `/api/analysis-model/v1/${scenario}/users/key/${encodeURIComponent(
        userid
      )}/sessions/daily?offset=0&limit=1000`,
    };
  }
}

export default LogUsersAPI;

const baseKey = ["log-users"];
const loguserKeys = {
  table: ({ scenarioId, orderBy, filters, pageLimit }) => [
    baseKey,
    scenarioId,
    "table",
    orderBy,
    filters,
    pageLimit,
  ],
  activities: ({ scenarioId, userId }) => [
    baseKey,
    scenarioId,
    "activities",
    userId,
  ],
};

export const useLogUsersInfiniteQuery = ({
  scenarioId,
  orderBy,
  filters,
  pageLimit,
}) => {
  const queryClient = useQueryClient();
  const { data, fetchNextPage, isPending, isFetching, isPreviousData } =
    useInfiniteQuery({
      queryKey: loguserKeys.table({ scenarioId, orderBy, filters, pageLimit }),
      initialPageParam: 1,
      queryFn: ({ pageParam, signal }) => {
        return queryFetch(
          LogUsersAPI.getLogUsersUrl(
            scenarioId,
            (pageParam - 1) * pageLimit,
            pageLimit,
            orderBy,
            filters
          ).url,
          { signal },
          { returnRawJson: true }
        );
      },
      select: (data) => {
        const formattedRes = data.pages.reduce(
          (acc, curr) => {
            acc.count = curr?.count;
            acc.pages.push(curr.rows);
            return acc;
          },
          { count: 0, pages: [] }
        );

        const enrichedRes = formattedRes.pages.flat().map((s, idx) => ({
          ...s,
          id: `${scenarioId}/${s.orig_app_user_id}`,
          index: idx,
          num_profiles: s.profiles.filter((d) => d > 0).length,
        }));

        return {
          rows: enrichedRes,
          count: formattedRes?.count,
        };
      },
      retry: false,
      getNextPageParam: (lastPage, pages) =>
        lastPage.rows.length < pageLimit ? undefined : pages.length + 1,
      placeholderData: keepPreviousData,
    });

  const cancel = useCallback(
    () =>
      queryClient.cancelQueries({
        queryKey: loguserKeys.table({
          scenarioId,
          orderBy,
          filters,
          pageLimit,
        }),
      }),
    [filters, orderBy, pageLimit, queryClient, scenarioId]
  );

  const containerRef = useInfiniteTableContainer(
    !isFetching && data,
    fetchNextPage
  );

  return {
    data,
    cancel,
    isPending: isPending || isPreviousData,
    isFetching,
    containerRef,
  };
};

export const useUserDailyActivitiesQuery = ({ scenarioId, userId }) => {
  return useQuery({
    queryKey: loguserKeys.activities({ scenarioId, userId }),
    queryFn: () =>
      queryFetch(LogUsersAPI.getUserDailyActivities(scenarioId, userId)),
  });
};
