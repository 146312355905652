import { PriorityHigh } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import HtmlTooltip from "components/ui/HtmlTooltip";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const ThreatDisplay = ({ threat }) => {
  const strings = useLocalizedStrings();
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      <Typography>
        <span style={{ fontStyle: "italic" }}>{strings.threat_type_label}</span>{" "}
        {threat.type}
      </Typography>
      <Typography>{threat.description}</Typography>
      {threat.recommendation && (
        <Typography>
          <span style={{ fontStyle: "italic" }}>
            {strings.threat_recommendation_label}
          </span>{" "}
          {threat.recommendation}
        </Typography>
      )}
    </Box>
  );
};

const ThreatList = ({ threats }) => {
  return (
    <Box display="flex" maxWidth="500px" flexDirection="column" gap={"2em"}>
      {threats.map((t, i) => (
        <ThreatDisplay key={i} threat={t} />
      ))}
    </Box>
  );
};

export const ThreatIndicator = ({ threats }) => {
  if (!threats?.length) {
    return null;
  }

  return (
    <HtmlTooltip title={<ThreatList threats={threats} />}>
      <PriorityHigh fontSize="small" />
    </HtmlTooltip>
  );
};
