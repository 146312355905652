import React from "react";

import { Typography } from "@mui/material";

function TableCellText({ children, ...other }) {
  return (
    <Typography variant="body1" display="inline" {...other}>
      {children}
    </Typography>
  );
}

export default TableCellText;
