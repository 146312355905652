import { useMemo } from "react";

import CountersAPI from "api/counters";

import { useReportedFetch } from "./http";
import useBackendEvents from "./useBackendEvents";

const keys = [
  { key: "sessions/total", label: "Total Journeys" },
  { key: "sessions/clustering/clustered", label: "Typical Journeys" },
  { key: "clusters/total", label: "Total Trails" },
  { key: "users/total", label: "Total Inspected Users" },
];

const statsBackendEvents = ["jobs"];

const useScenarioStatistics = (scenario) => {
  const [backendEvent] = useBackendEvents(statsBackendEvents, [scenario]);

  const [counters, , loading] = useReportedFetch(
    CountersAPI.getCountersRecentState(scenario).url,
    [scenario, backendEvent]
  );

  const processed = useMemo(
    () =>
      keys.map((k) => ({
        id: k.key,
        label: k.label,
        value: counters.find((c) => c.key === k.key)?.value || 0,
      })),
    [counters]
  );

  return [processed, loading];
};

export { useScenarioStatistics };
