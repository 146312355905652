import { Radio, styled } from "@mui/material";

const BrandedRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.primary.dark,
  "&.Mui-checked": {
    color: theme.palette.primary.dark,
  },
}));

export default BrandedRadio;
