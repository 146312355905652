import { useCallback } from "react";

import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

class ClustersAPI extends API {
  static getClusters(scenario, offset, limit, orderby = {}, filters = {}) {
    return {
      url: super.composeChunkedQueryUrl(
        `/api/analysis-model/v1/${scenario}/clusters`,
        offset,
        limit,
        filters,
        orderby,
        "id"
      ),
    };
  }

  static getClusterUrl(scenario, cluster) {
    return {
      url: `/api/analysis-model/v1/${scenario}/clusters/key/${cluster}`,
    };
  }

  static getClusterHistogram(scenario, cluster, type) {
    return {
      url: `/api/analysis-model/v1/${scenario}/clusters/key/${cluster}/histogram/${type}`,
    };
  }

  static getClusterUpdateUrl(scenario, cluster, malicious, annotation) {
    return {
      url: `/api/analysis-model/v1/${scenario}/clusters/key/${cluster}`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          malicious,
          annotation: annotation?.trim().length ? annotation.trim() : null,
        }),
      },
    };
  }

  static getTransitionsHistogram(scenario, cluster) {
    return {
      url: `/api/analysis-model/v1/${scenario}/clusters/key/${cluster}/paths`,
    };
  }

  static getUsersHistogram(scenario, cluster) {
    return {
      url: `/api/analysis-model/v1/${scenario}/clusters/key/${cluster}/users`,
    };
  }
}

const baseKey = ["clusters"];
const clusterKeys = {
  table: ({ scenario, offset, pageLimit, orderBy, filters }) => [
    baseKey,
    scenario,
    "table",
    pageLimit,
    offset,
    orderBy,
    filters,
  ],
  cluster: ({ scenarioId, cluster }) => [
    baseKey,
    scenarioId,
    "cluster",
    cluster,
  ],
  transitions: ({ scenarioId, cluster }) => [
    baseKey,
    scenarioId,
    "transitions",
    cluster,
  ],
  users: ({ scenarioId, cluster }) => [baseKey, scenarioId, "users", cluster],
  histogram: ({ scenarioId, cluster, type }) => [
    baseKey,
    scenarioId,
    "histogram",
    cluster,
    type,
  ],
};

export const useClustersInfiniteQuery = ({
  filters,
  orderBy,
  scenario,
  pageLimit,
}) => {
  const queryClient = useQueryClient();
  const { data, fetchNextPage, isPending, isPreviousData, isFetching } =
    useInfiniteQuery({
      queryKey: clusterKeys.table({
        scenario,
        pageLimit,
        orderBy,
        filters,
      }),
      initialPageParam: 1,
      queryFn: ({ pageParam, signal }) =>
        queryFetch(
          ClustersAPI.getClusters(
            scenario,
            (pageParam - 1) * pageLimit,
            pageLimit,
            orderBy,
            filters
          ).url,
          { signal },
          { returnRawJson: true }
        ),
      select: (data) => {
        const formattedRes = data.pages.reduce(
          (acc, curr) => {
            acc.count = curr?.count;
            acc.pages.push(curr.rows);
            return acc;
          },
          { count: 0, pages: [] }
        );

        return {
          rows: formattedRes.pages.flat(),
          count: formattedRes?.count,
        };
      },
      retry: false,
      getNextPageParam: (lastPage, pages) =>
        lastPage.rows.length < pageLimit ? undefined : pages.length + 1,
      placeholderData: keepPreviousData,
    });

  const cancel = useCallback(
    () =>
      queryClient.cancelQueries({
        queryKey: clusterKeys.table({
          scenario,
          pageLimit,
          orderBy,
          filters,
        }),
      }),
    [filters, orderBy, pageLimit, queryClient, scenario]
  );

  return {
    data,
    cancel,
    isPending: isPending || isPreviousData,
    isFetching,
    fetchNextPage,
  };
};

export const useGetClusterQuery = ({ scenarioId, cluster }) => {
  return useQuery({
    queryKey: clusterKeys.cluster({ scenarioId, cluster }),
    select: (rows) => rows[0],
    queryFn: () => queryFetch(ClustersAPI.getClusterUrl(scenarioId, cluster)),
  });
};

export const useClusterUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ scenarioId, cluster, malicious, annotation }) =>
      queryFetch(
        ClustersAPI.getClusterUpdateUrl(
          scenarioId,
          cluster,
          malicious,
          annotation
        )
      ),
    onSuccess: (_, { scenarioId, cluster }) =>
      queryClient.invalidateQueries({
        queryKey: clusterKeys.cluster({ scenarioId, cluster }),
      }),
  });
};

export const useClustersGetTransitionsHistogramQuery = ({
  scenarioId,
  cluster,
}) => {
  return useQuery({
    queryKey: clusterKeys.transitions({ scenarioId, cluster }),
    queryFn: () =>
      queryFetch(ClustersAPI.getTransitionsHistogram(scenarioId, cluster)),
    select: (rows) => rows[0],
  });
};

export const useClustersGetUsersHistogramQuery = ({ scenarioId, cluster }) => {
  return useQuery({
    queryKey: clusterKeys.users({ scenarioId, cluster }),
    queryFn: () =>
      queryFetch(ClustersAPI.getUsersHistogram(scenarioId, cluster)),
  });
};

export const useGetClusterHistogramQuery = (
  { scenarioId, cluster, type },
  opts
) => {
  return useQuery({
    queryKey: clusterKeys.histogram({ scenarioId, cluster, type }),
    queryFn: () =>
      queryFetch(ClustersAPI.getClusterHistogram(scenarioId, cluster, type)),
    ...opts,
  });
};

export default ClustersAPI;
