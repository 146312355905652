import { FormHelperText, Slider, Typography } from "@mui/material";
import { useController } from "react-hook-form";

const defaultValue = [0, 24];
const minDistance = 1;
export const HourSelector = ({ name }) => {
  const { field, fieldState } = useController({
    name,
    defaultValue,
    rules: {
      required: false,
      validate: (val) =>
        (Array.isArray(val) &&
          val.length === 2 &&
          val.every((v) => 0 <= v && v <= 24 && Number.isInteger(v)) &&
          val[0] < val[1]) ||
        "Invalid value",
    },
  });

  const val =
    !Array.isArray(field?.value) || field?.value?.length !== 2
      ? defaultValue
      : field?.value;

  const error = fieldState?.error?.message;
  const from = field?.value?.[0];
  const to = field?.value?.[1];

  const handleChange = (_, [newFrom, newTo], activeThumb) => {
    if (activeThumb === 0) {
      field.onChange([Math.min(newFrom, newTo - minDistance), newTo]);
    } else {
      field.onChange([newFrom, Math.max(newTo, newFrom + minDistance)]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "300px",
        paddingLeft: "10px",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Slider
        {...field}
        value={val}
        onChange={handleChange}
        min={defaultValue[0]}
        max={defaultValue[1]}
        color={!!error ? "secondary" : "primary"}
        step={1}
        marks
      />
      <Typography>{`${+from}:00 - ${+to}:00`} </Typography>
      <FormHelperText
        style={{ right: 0, bottom: 0, position: "absolute" }}
        error={!!error}
      >
        {error ?? " "}
      </FormHelperText>
    </div>
  );
};
