import { styled } from "@mui/material";
import Slider from "@mui/material/Slider";

import { maxRarity, parseRarityColor } from "utils/parseRarityColor";
import { maxSensitivity, sensitivityColors } from "utils/parseSensitivityColor";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledRangeSelectorWrapper = styled("div")(
  () => `
  padding: 0 1em;
  display: inline-grid;
  grid-template-rows: 1em 1em;
  gap: 0.3em;
  .label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
    text-transform: capitalize;
    .label-value {
      font-weight: bold;
      width: 30px;
      text-align: center;
    }
  }
  .range-slider {
    padding: 1em 0em;
  }
`
);

function GradientRangeSelector({
  range,
  disabled,
  setRange,
  label,
  minValue = 0,
  maxValue,
  gradientColors,
}) {
  const gradient = gradientColors.join(", ");

  return (
    <StyledRangeSelectorWrapper>
      <div
        style={{ opacity: disabled ? 0.5 : 1 }}
        className="label-container"
        id="range-setter"
      >
        <span className="label-title">{label}</span>
        <span className="label-value">
          {Array.isArray(range) ? `${range[0]}...${range[1]}` : `${range}`}
        </span>
      </div>
      <Slider
        className="range-slider"
        sx={{
          "& .MuiSlider-track, & .MuiSlider-rail": {
            height: 8,
            backgroundImage: `linear-gradient(.25turn, ${gradient})`,
            opacity: 1,
            border: 0,
          },
          "& .MuiSlider-thumb": {
            backgroundColor: "RGBA(221, 221, 221, 0)",
            border: "2px solid currentColor",
          },
        }}
        value={range}
        size="small"
        track={false}
        step={1}
        min={minValue}
        max={maxValue}
        onChange={(_e, v) => setRange(v)}
        disabled={disabled}
        aria-labelledby="range-setter"
      />
    </StyledRangeSelectorWrapper>
  );
}

export function SensitivitySelector({
  minSensitivity,
  setMinSensitivity,
  disabled,
}) {
  const strings = useLocalizedStrings();
  return (
    <GradientRangeSelector
      label={strings.sensitivity_slider}
      range={minSensitivity}
      setRange={setMinSensitivity}
      disabled={disabled}
      maxValue={maxSensitivity}
      gradientColors={sensitivityColors}
    />
  );
}

export function RaritySelector({ range, setRange, disabled }) {
  const strings = useLocalizedStrings();
  return (
    <GradientRangeSelector
      label={strings.rarity_slider}
      range={range}
      setRange={setRange}
      maxValue={maxRarity}
      disabled={disabled}
      gradientColors={[parseRarityColor(0), parseRarityColor(maxRarity)]}
    />
  );
}
