import { Tooltip, Typography } from "@mui/material";

const HtmlTooltip = ({ children, ...props }) => (
  <Tooltip
    {...props}
    disableFocusListener
    componentsProps={{
      tooltip: {
        sx: {
          position: "relative",
          maxWidth: "60vw",
          backgroundColor: "#f5f5f9",
          color: "rgba(0, 0, 0, 0.87)",
          border: "1px solid #dadde9",
          borderRadius: "5px",
          padding: 2,
          margin: 1,
          fontSize: "inherit",
          whiteSpace: "pre-wrap",
        },
      },
    }}
  >
    {typeof children === "string" ? (
      <Typography>{children}</Typography>
    ) : (
      children
    )}
  </Tooltip>
);

export default HtmlTooltip;
