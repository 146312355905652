import { useCallback, useMemo } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Typography, styled, useTheme } from "@mui/material";

import { useAlertsAnalyticsQuery } from "api/sessions";

import { ProfileEvaluationIndicator } from "components/scenarios/ProfileEvaluationIndicator";
import { CommentIndicator } from "components/scenarios/SessionCommentIndicator";
import { SessionEvaluationIndicator } from "components/scenarios/SessionEvaluationIndicator";
import DataLink from "components/ui/DataLink";
import HtmlTooltip from "components/ui/HtmlTooltip";
import RiskIndicator from "components/ui/RiskIndicator";
import { UserIdDisplay } from "components/ui/UserIdDisplay";
import { VirtuosoTable } from "components/ui/VirtuosoTable";

import { inUtc, timeFormatter } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledAlertsList = styled("div")(() => ({
  height: "50%",
  display: "flex",
  flexDirection: "column",
}));

const fields = ({ strings }) => [
  {
    id: "actions",
    immutable: true,
    render: (v) => (
      <div style={{ display: "flex" }}>
        <HtmlTooltip title={strings.alertsstable_alert_action_tooltip_details}>
          <DataLink
            to={`/scenarios/${v.scenario.id}/sessions/${encodeURIComponent(
              v.app_session_id
            )}/session-summary`}
            size="small"
            style={{ color: "#777" }}
          >
            <VisibilityIcon />
          </DataLink>
        </HtmlTooltip>
        <ProfileEvaluationIndicator
          cluster={+v.cluster}
          cluster_malicious={v.cluster_malicious}
          cluster_annotation={v.cluster_annotation}
          scenario={v.scenario.id}
        />
        <CommentIndicator
          scenario={v.scenario.id}
          sessionId={v.app_session_id}
          comment={v.annotation}
        />
        <SessionEvaluationIndicator scenario={v.scenario.id} sessionInfo={v} />
      </div>
    ),
    style: {
      width: "10em",
    },
  },
  {
    id: "orig_app_user_id",
    label: strings.alertstable_table_column_user,
    sort: "orig_app_user_id",
    render: (v) => <UserIdDisplay userId={v.orig_app_user_id} />,
    style: {
      width: "17em",
      whiteSpace: "nowrap",
    },
  },
  {
    id: "start",
    label: strings.alertstable_table_column_start,
    sort: "start",
    render: (v) => (
      <Typography variant="body1">
        {timeFormatter(new Date(v.start))}
      </Typography>
    ),
    style: {
      width: "9em",
    },
  },

  {
    id: "whole_risk",
    label: strings.alertstable_table_column_risk,
    render: (v) => (
      <RiskIndicator value={v.whole_risk} reevaluated={!!v.reevaluated} />
    ),

    style: {
      textAlign: "left",
      width: "6em",
    },
  },
  {
    id: "issued",
    label: strings.alertstable_table_column_issued,
    sort: "time_alerted",
    render: (v) =>
      v.time_alerted ? (
        <DataLink
          to={`scenarios/${v.scenario.id}/sessions/${v.app_session_id}/alert-details`}
        >
          <Typography variant="body1">
            {timeFormatter(new Date(v.time_alerted))}
          </Typography>
        </DataLink>
      ) : null,
    style: {
      textAlign: "left",
      width: "8em",
    },
  },
  {
    id: "scenario",
    label: strings.alertstable_table_column_scenario,
    render: (v) => <Typography variant="body1">{v.scenario.name}</Typography>,
    style: {
      textAlign: "left",
      width: "15em",
    },
  },
];

const useGetAlertsAnalytics = ({ numDays, orderBy, scenarios }) => {
  const totalLimit = 10000;
  const equalLimit = Math.round(totalLimit / scenarios?.length);

  const filters = useMemo(() => {
    const startDate = inUtc()
      .endOf("day")
      .subtract(numDays, "days")
      .toISOString();

    return {
      start: {
        operator: "gt",
        operand: startDate,
      },
    };
  }, [numDays]);

  const results = useAlertsAnalyticsQuery({
    scenarios: scenarios,
    filters,
    limit: equalLimit,
    offset: 0,
    orderBy: {
      start: -1,
    },
  });

  const isPending = results.some((r) => r?.isPending);

  const data = useMemo(() => {
    return results
      .reduce((acc, res, idx) => {
        const scenario = scenarios[idx];
        if (res?.data?.length > 0) {
          const enrichedData = res.data.map((r) => ({
            ...r,
            scenario: scenario,
          }));
          acc.push(enrichedData);
        }
        return acc;
      }, [])
      .flat()
      .sort((a, b) => {
        const orderByEntries = Object.entries(orderBy);

        if (orderByEntries?.length == 0) {
          return;
        }

        const sortColumn = orderByEntries[0][0];
        const sortDirection = orderByEntries[0][1];

        if (sortColumn === "start" || sortColumn === "time_alerted") {
          if (sortDirection == -1) {
            return new Date(b[sortColumn]) - new Date(a[sortColumn]);
          } else {
            return new Date(a[sortColumn]) - new Date(b[sortColumn]);
          }
        }

        if (sortColumn === "orig_app_user_id") {
          if (sortDirection == -1) {
            return b[sortColumn].localeCompare(a[sortColumn], "en");
          } else {
            return a[sortColumn].localeCompare(b[sortColumn], "en");
          }
        }
      });
  }, [results, scenarios, orderBy]);

  return {
    data,
    isPending,
  };
};

export const AlertsList = ({ numDays, scenarios }) => {
  const strings = useLocalizedStrings();
  const theme = useTheme();
  const [orderBy, setOrderBy] = useCurrentUserSettings(
    "alertsAnalytics.orderby",
    {
      start: -1,
    }
  );

  const { data, isPending } = useGetAlertsAnalytics({
    scenarios,
    orderBy,
    numDays,
  });

  const handleNextSortDirection = useCallback(
    (id) => {
      setOrderBy((prev) => {
        let d = (prev[id] || 0) + 1;
        if (d > 1) {
          d = -1;
        }
        return d ? { [id]: d } : {};
      });
    },
    [setOrderBy]
  );

  return (
    <StyledAlertsList>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <VirtuosoTable
          columns={fields({
            theme,
            strings,
          })}
          isPending={isPending}
          items={data}
          itemsCount={data?.length}
          orderBy={orderBy}
          onOrderBy={handleNextSortDirection}
          pageLimit={data?.length + 1}
        />
      </div>
    </StyledAlertsList>
  );
};
