import { useStore } from "store";

const pushMessageSelector = (state) => state.pushMessage;
const messagesSelector = (state) => state.messages;
const deleteMessagesSelector = (state) => state.deleteMessage;

export const useMessages = () => {
  const pushMessage = useStore(pushMessageSelector);
  const messages = useStore(messagesSelector);
  const deleteMessage = useStore(deleteMessagesSelector);

  return {
    pushMessage,
    messages,
    deleteMessage,
  };
};
