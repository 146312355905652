import React from "react";

import Box from "@mui/material/Box";

export default function Flexbox({ children, ...rest }) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" {...rest}>
      {children}
    </Box>
  );
}
