import { useEffect, useRef, useState } from "react";

export const useResize = () => {
  const observedElement = useRef();
  const observer = useRef();
  const [rect, setRect] = useState();

  useEffect(() => {
    if (!observedElement.current) {
      return;
    }
    observer.current = new ResizeObserver((entries) => {
      entries.forEach((e) => setRect(e.contentRect));
    });
    observer.current.observe(observedElement.current);
    return () => observer.current?.disconnect();
  }, []);

  return [rect, observedElement];
};
