import { forwardRef, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from "@mui/material";

import FrameTitle from "components/ui/FrameTitle";
import FrameTitleText from "components/ui/FrameTitleText";

const StyledSettingsOption = styled("div")(({ theme }) => ({
  maxWidth: "1100px",
  "& .MuiAccordion-root": {
    border: "1px solid #ddd",
    boxShadow: "none",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "48px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
    },
    "& .MuiAccordionDetails-root": {
      display: "grid",
      gridTemplateColumns: "100%",
      gridTemplateRows: "min-content 1fr min-content",
      marginLeft: "1em",
      padding: theme.spacing(1),
      position: "relative",
    },
  },
}));

const SettingsOption = forwardRef(
  ({ open, onChange, title, children }, ref) => {
    const [expanded, setExpanded] = useState(false);
    return (
      <StyledSettingsOption ref={ref}>
        <Accordion
          expanded={open ?? expanded}
          square={true}
          onChange={() =>
            onChange ? onChange() : setExpanded((prev) => !prev)
          }
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary>
            <FrameTitle
              collapsible={true}
              collapsed={open !== undefined ? !open : !expanded}
            >
              <FrameTitleText>{title}</FrameTitleText>
            </FrameTitle>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </StyledSettingsOption>
    );
  }
);

export default SettingsOption;
