import { useCallback, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import {
  useAlertersAvailableQuery,
  useAlertersCreateMutation,
  useAlertersDeleteMutation,
  useAlertersQuery,
  useAlertersUpdateMutation,
} from "api/alerters";

import { AlerterDetails } from "components/alerters/AlerterDetails";
import { AlerterDialog } from "components/alerters/AlerterDialog";
import { DeleteButton } from "components/ui/DeleteButton";
import Flexbox from "components/ui/Flexbox";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import NotAllowedMessage from "components/ui/NotAllowedMessage";
import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";
import { StdSwitch } from "components/ui/StdSwitch";
import { UiPrimaryButton } from "components/ui/StyledButtons";
import { TableColumnHeaders } from "components/ui/tables/TableColumnHeader";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import { BaseAccountPage } from "./AccountPageContent";

function AlerterActions({ a, onToggleActive, onEdit, onDelete, caps }) {
  return (
    <Flexbox>
      <StdSwitch
        beforeLabel="YES"
        afterLabel="NO"
        checked={a.active}
        onChange={() => onToggleActive(a)}
        disabled={!caps({ "acm.alerters": { write: true } })}
      />
      <IconButton
        size="small"
        onClick={() => onEdit(a)}
        disabled={!caps({ "acm.alerters": { write: true } })}
      >
        <EditIcon />
      </IconButton>

      <DeleteButton
        onClick={() => onDelete(a)}
        disabled={!caps({ "acm.alerters": { write: true } })}
      />
    </Flexbox>
  );
}

const columns = (strings) => [
  {
    id: "actions",
    width: "4em",
    label: strings.account_settings_alerters_header_active,

    render: (a, onToggleActive, onEdit, onDelete, caps) => (
      <AlerterActions
        a={a}
        onToggleActive={onToggleActive}
        onEdit={onEdit}
        onDelete={onDelete}
        caps={caps}
      />
    ),
  },
  {
    id: "name",
    label: strings.account_settings_alerters_header_name,
    width: "8em",
    render: (a) => a.name,
  },
  {
    id: "description",
    label: strings.account_settings_alerters_header_description,
    width: "15em",
    render: (a) => a.description,
  },
  {
    id: "transport",
    label: strings.account_settings_alerters_header_type,
    width: "8em",
    render: (a) => a.transport.toUpperCase(),
  },
  {
    id: "format",
    label: strings.account_settings_alerters_header_format,
    width: "8em",
    render: (a) => a.format.toUpperCase(),
  },
  {
    id: "associated_scenarios",
    label: strings.account_settings_alerters_header_associated,
    width: "14em",
    render: (a) => (
      <Typography>{a.associated_scenarios?.length ?? 0}</Typography>
    ),
  },
  {
    id: "details",
    label: strings.account_settings_alerters_header_details,
    width: "auto",
    render: (a) => <AlerterDetails alerter={a} />,
  },
];

function AccountAlerters() {
  const strings = useLocalizedStrings();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmContext, setConfirmContext] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [currAlerter, setCurrAlerter] = useState(null);
  const caps = useCapabilities();
  const isEditing = !!currAlerter;
  const { data: available } = useAlertersAvailableQuery();
  const { data: alerters, isPending } = useAlertersQuery();
  const deleteAlerter = useAlertersDeleteMutation();
  const updateAlerter = useAlertersUpdateMutation();
  const createAlerter = useAlertersCreateMutation();

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  const handleDelete = useCallback(
    (alerter) => {
      setConfirmContext(alerter);
      setConfirmOpen(true);
    },
    [setConfirmContext, setConfirmOpen]
  );

  const handleAdd = useCallback(() => {
    setCurrAlerter();
    setOpenDialog(true);
  }, []);

  const handleEdit = useCallback(
    (alerter) => {
      setCurrAlerter(alerter);
      setOpenDialog(true);
    },
    [setCurrAlerter, setOpenDialog]
  );

  const handleToggleActive = (alerter) =>
    updateAlerter.mutate({
      id: alerter.id,
      alerter: {
        ...alerter,
        active: !alerter.active,
      },
    });

  const alerterOptionsInfo = (alerter) =>
    available?.find((a) => a.id === alerter.alerter_id)?.options;

  const handleCreateAlerter = (alerter) => {
    setOpenDialog(false);

    const optionsInfo = alerterOptionsInfo(alerter);

    for (const k in alerter.options) {
      if (optionsInfo[k]) {
        const { secret = false } = optionsInfo[k];
        const trimmed =
          typeof alerter.options[k] === "string"
            ? alerter.options[k]?.trim()
            : alerter.options[k];

        alerter.options[k] =
          isEditing && trimmed === "" && secret ? null : trimmed;
      }
    }
    if (currAlerter) {
      // edit mode
      updateAlerter.mutate({ id: currAlerter.id, alerter });
    } else {
      // create mode
      createAlerter.mutate(alerter);
    }
    setCurrAlerter(null);
  };

  const handleConfirm = ({ id }) => deleteAlerter.mutate(id);

  if (!caps({ "acm.alerters": { read: true } })) {
    return <NotAllowedMessage />;
  }

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  return (
    <BaseAccountPage>
      <Flexbox>
        <UiPrimaryButton
          startIcon={<AddCircleIcon />}
          onClick={handleAdd}
          disabled={!caps({ "acm.alerters": { write: true } })}
        >
          {strings.button_add}
        </UiPrimaryButton>
      </Flexbox>

      <TableContainer>
        <Table stickyHeader>
          <TableColumnHeaders columns={columns} />
          <TableBody>
            {alerters
              .sort((a, b) => a.id.localeCompare(b.id))
              .map((a) => (
                <TableRow key={a.id}>
                  {columns(strings).map((c) => (
                    <TableCell key={c.id}>
                      {c.render &&
                        c.render(
                          a,
                          handleToggleActive,
                          handleEdit,
                          handleDelete,
                          caps
                        )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AlerterDialog
        available={available ?? []}
        alerter={currAlerter}
        open={openDialog}
        key={openDialog}
        onClose={handleCloseDialog}
        onAccept={handleCreateAlerter}
      />

      <MultipleOptionsDialog
        confirmText={strings.button_remove}
        onConfirm={handleConfirm}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        context={confirmContext}
        title={strings.account_settings_alerters_remove_confirm_title}
        text={strings.formatString(
          strings.account_settings_alerters_remove_confirm_question,
          confirmContext?.associated_scenarios?.length ?? 0
        )}
      />
    </BaseAccountPage>
  );
}

export default AccountAlerters;
