import { styled } from "@mui/material";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { Scenario } from "./Scenario";
import { SectionTitle } from "./SectionTitle";

const StyledSidebar = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  height: "99%",
  margin: "4px",
  maxWidth: "var(--sidebar-width)",
  minWidth: "20rem",
  overflowY: "auto",
  padding: "8px",
  zIndex: 9999,
}));

export const Sidebar = ({ scenarios }) => {
  const strings = useLocalizedStrings();

  return (
    <StyledSidebar>
      <SectionTitle>{strings.alertAnalytics_sidebar_title}</SectionTitle>
      <ul>
        {scenarios.map((scenario) => (
          <Scenario key={scenario.id} scenario={scenario} />
        ))}
      </ul>
    </StyledSidebar>
  );
};
