import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import { useController } from "react-hook-form";

export const SelectorInput = ({
  name,
  required,
  style,
  label,
  children,
  rules,
  className,
  defaultValue,
  onChange,
  ...rest
}) => {
  const { field, fieldState } = useController({
    name,
    defaultValue,
    rules: {
      required: {
        value: required ?? false,
        message: "This field is required",
      },
      ...rules,
    },
  });

  const errorMessage = fieldState?.error?.message;
  const handleChange = (e) => {
    if (onChange) {
      onChange(e, field);
      return;
    }
    field.onChange(e);
  };

  return (
    <FormControl
      className={className}
      {...rest}
      required={required}
      error={!!errorMessage}
    >
      <InputLabel error={!!errorMessage} id="selector">
        {label}
      </InputLabel>
      <Select
        name={field.name}
        labelId="selector"
        label={label}
        value={field.value || ""}
        onChange={handleChange}
        onBlur={field.onBlur}
        inputRef={field.ref}
        style={style}
        inputProps={{ required: false }}
      >
        {children}
      </Select>
      <FormHelperText>{errorMessage ?? " "}</FormHelperText>
    </FormControl>
  );
};
