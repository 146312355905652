import React from "react";

import { TextField, styled } from "@mui/material";
import { useController } from "react-hook-form";

const StyledTextFieldInput = styled(TextField)(({ styles }) => ({
  "& .MuiInputBase-root": {
    width: styles?.width ?? "100%",
  },
  "& .MuiFormControl-root": {
    padding: "0px !important",
  },
  "& .MuiInputBase-input": {
    fontSize: "inherit",
    backgroundColor: "white",
    height: styles?.height ?? "56px",
    width: styles?.width ?? "100%",
  },
}));

export const TextFieldInput = ({
  className = "",
  name,
  rules,
  helperText,
  required,
  style,
  control,
  defaultValue,
  options,
  ...props
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue: defaultValue ?? "",
    rules: {
      pattern: {
        value: required ? /(?!\s*$).+/ : false, // regex doesn't allow value to be empty or whitespace values ('  ')
        message: "This field is required",
      },
      ...rules,
      required: {
        value: required ?? false,
        message: "This field is required",
      },
    },
    ...options,
  });

  return (
    <StyledTextFieldInput
      className={className}
      {...field}
      variant="outlined"
      error={fieldState.invalid}
      helperText={helperText ?? fieldState?.error?.message ?? " "}
      value={field.value || ""}
      required={required}
      inputProps={{
        required: "",
      }}
      styles={style}
      {...props}
    />
  );
};
