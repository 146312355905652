import { useCallback, useContext, useState } from "react";

import { joiResolver } from "@hookform/resolvers/joi";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { ROLES } from "@tiq/shared/lib/constants";
import { schemas } from "@tiq/shared/lib/input-validations";
import { Controller, FormProvider, useForm } from "react-hook-form";

import AdminsAPI, {
  useAccountQuery,
  useAdminsCreateAdminMutation,
  useAdminsDeleteAdminMutation,
  useAdminsUpdateAdminMutation,
} from "api/admins";
import { useRolesQuery } from "api/roles";

import { LoginStatusContext } from "contexts/LoginStatusContext";

import CopyToClipboardButton from "components/ui/CopyToClipboard";
import { DeleteButton } from "components/ui/DeleteButton";
import Flexbox from "components/ui/Flexbox";
import { HelpIcon } from "components/ui/HelpIcon";
import HtmlTooltip from "components/ui/HtmlTooltip";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import NotAllowedMessage from "components/ui/NotAllowedMessage";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import TableCellText from "components/ui/TableCellText";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { timeFormatter } from "utils/time-fmt";

import { useReportedFetch } from "hooks/http";
import useBackendEvents from "hooks/useBackendEvents";
import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

const StyledAccountsContent = styled("div")(({ theme }) => ({
  height: "calc(100vh - var(--appbar-height) - 49px)",
  padding: theme.spacing(6),
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "min-content 1fr",
  gap: "1em",
  "& .toolbar": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > button": {
      padding: "1em 2em",
    },
  },
  "& .MuiTableContainer-root": {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  ".owner": {
    fontWeight: "bold",
    color: "steelblue",
  },
  "& td": {
    fontSize: "inherit",
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    display: "flex",
    flexDirection: "column",
    gap: "0.5em",
    width: "30em",
  },
  "& .form-control": {
    marginBottom: theme.spacing(2),
    "& > *": {
      width: "100%",
    },
  },
  "& .roles": {
    border: "1px solid #ccc",
    padding: "1em",
    margin: "7px 0px",
    "& .MuiFormControlLabel-label": {
      textTransform: "capitalize",
    },
  },
}));

const columns = (strings) => [
  {
    id: "actions",
    width: "1em",
    render: (u, onDelete, onEdit, isMe, writeAllowed) => (
      <Flexbox>
        <IconButton
          size="small"
          onClick={() => onEdit(u)}
          disabled={u.owner || isMe?.(u) || !writeAllowed}
        >
          <EditIcon />
        </IconButton>

        <DeleteButton
          onClick={() => onDelete(u)}
          disabled={u.owner || isMe?.(u) || !writeAllowed}
        />
      </Flexbox>
    ),
  },
  {
    id: "name",
    label: strings.account_settings_admins_header_name,
    width: "7em",
    render: (u) => <span style={{ textTransform: "uppercase" }}>{u.name}</span>,
  },
  {
    id: "email",
    label: strings.account_settings_admins_header_email,
    width: "10em",
    render: (u) => (
      <a
        href={`mailto:${u.email}`}
        style={{ color: "steelblue", textDecoration: "none" }}
      >
        {u.email}
      </a>
    ),
  },
  {
    id: "roles",
    label: strings.account_settings_admins_header_roles,
    width: "8em",
    render: (u) => u.roles.map((r) => r.replace("_", " ")).join(", "),
  },
  {
    id: "lastlogin",
    label: strings.account_settings_admins_header_lastlogin,
    width: "10em",
    render: (u) =>
      u.last_login ? timeFormatter(new Date(u.last_login)) : strings.text_never,
  },
  {
    id: "created",
    label: strings.account_settings_admins_header_created,
    width: "8em",
    render: (u) => timeFormatter(new Date(u.created)),
  },
];

function RolesHelpContent() {
  const strings = useLocalizedStrings();
  return (
    <ul style={{ maxWidth: "400px", paddingLeft: "1em" }}>
      <li>
        <strong>
          {strings.account_settings_admins_roles_help_viewer_title}
        </strong>
        {strings.account_settings_admins_roles_help_viewer_content}
      </li>
      <li>
        <strong>
          {strings.account_settings_admins_roles_help_admin_title}
        </strong>
        {strings.account_settings_admins_roles_help_admin_content}
      </li>
    </ul>
  );
}

// disabled roles for each role, i.e roles that are exclusive
const blockedRoles = {
  ADMIN: [ROLES.ACCOUNT_ADMIN, ROLES.VIEWER],
  ANALYST: [ROLES.VIEWER],
  VIEWER: [ROLES.ANALYST, ROLES.ADMIN],
  ACCOUNT_ADMIN: [ROLES.ADMIN],
};

function CreateAdminForm({ open, onAccept, onClose, admin }) {
  const strings = useLocalizedStrings();
  const { data: roles = [] } = useRolesQuery();

  const methods = useForm({
    defaultValues: {
      name: admin?.name || "",
      email: admin?.email || "",
      roles: admin?.roles || [ROLES.VIEWER],
    },
    resolver: joiResolver(schemas.users.post),
  });

  const checkSelectedRoles = (role) => {
    let roles = methods.getValues("roles");

    return blockedRoles[role].some((exclusiveRole) =>
      roles.includes(exclusiveRole)
    );
  };

  const handleRoleChanged = (e) => {
    const { name, checked } = e.target;
    let roles = methods.getValues("roles");

    if (checked) {
      roles = [...roles, name];
    } else {
      roles = roles.filter((d) => d !== name);
    }

    if (roles.length === 0) {
      methods.setError(`roles`);
    } else {
      methods.clearErrors([`roles`]);
    }

    methods.setValue("roles", Array.from(new Set(roles)));
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onAccept)}>
          <DialogTitle>
            {admin
              ? strings.account_settings_admins_form_update_title
              : strings.account_settings_admins_form_add_title}
          </DialogTitle>
          <DialogContent style={{ gap: "1em", paddingTop: 10 }}>
            <TextFieldInput
              label={strings.account_settings_admins_form_label_name}
              name="name"
              required
            />

            <TextFieldInput
              label={strings.account_settings_admins_form_label_email}
              name="email"
              required
              disabled={!!admin}
            />

            <Flexbox mt={"1em"} gap={"5px"}>
              <FormLabel component="legend">
                {strings.account_settings_admins_form_label_roles}
              </FormLabel>
              <HtmlTooltip title={<RolesHelpContent />}>
                <HelpIcon />
              </HtmlTooltip>
            </Flexbox>
            <FormGroup className="roles">
              {roles.map((d) => (
                <Controller
                  name="roles"
                  key={d.role}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={d.role}
                            onChange={handleRoleChanged}
                            checked={field.value.includes(d.role) || false}
                            disabled={checkSelectedRoles(d.role)}
                          />
                        }
                        label={d.displayName}
                      />
                    );
                  }}
                />
              ))}
            </FormGroup>
            {!!methods.formState.errors?.["roles"] && (
              <Box sx={{ color: "error.main" }}>
                {strings.account_settings_admins_form_roles_error_message}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <UiSecondaryButton onClick={onClose}>
              {strings.button_cancel}
            </UiSecondaryButton>
            <UiPrimaryButton type="submit">
              {strings.button_save}
            </UiPrimaryButton>
          </DialogActions>
        </form>
      </FormProvider>
    </StyledDialog>
  );
}

function AccountInfoRow({ label, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        span: {
          opacity: 0.4,
        },
        letterSpacing: "2px",
      }}
    >
      <span style={{ marginLeft: "auto" }}>{label}</span>
      <span style={{ marginLeft: "10px" }}>{value}</span>
      <CopyToClipboardButton text={value} />
    </Box>
  );
}

function CurrentAccountInfo({ name, id }) {
  const strings = useLocalizedStrings();

  return (
    <Stack gap="5px">
      <AccountInfoRow
        label={strings.account_settings_admins_account_name_label}
        value={name}
      />
      <AccountInfoRow
        label={strings.account_settings_admins_account_id_label}
        value={id}
      />
    </Stack>
  );
}

const backendEventsOfInterest = ["users"];

function AccountAdmins() {
  const strings = useLocalizedStrings();
  const [backendEvent] = useBackendEvents(backendEventsOfInterest);
  const [admins] = useReportedFetch(AdminsAPI.getall().url, [backendEvent]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmDeleteContext, setConfirmDeleteContext] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const { user: loggedInUser } = useContext(LoginStatusContext);
  const caps = useCapabilities();
  const { pushMessage } = useMessages();
  const writeAllowed = caps({ "acm.users": { write: true } });
  const readAllowed = caps({ "acm.users": { read: true } });
  const { data: account } = useAccountQuery();

  const { mutate: deleteAdmin } = useAdminsDeleteAdminMutation();
  const { mutate: updateAdmin } = useAdminsUpdateAdminMutation();
  const { mutate: createAdmin } = useAdminsCreateAdminMutation();

  const handleConfirmDelete = useCallback(
    (ctx) => {
      ctx?.id && deleteAdmin(ctx.id);
    },
    [deleteAdmin]
  );

  const onDelete = useCallback((admin) => {
    setConfirmDeleteContext(admin);
    setConfirmDeleteOpen(true);
  }, []);

  const onEdit = useCallback((admin) => {
    setUserToEdit(admin);
    setOpenForm(true);
  }, []);

  const handleSaveNewAdmin = (admin) => {
    setOpenForm(false);
    if (userToEdit) {
      updateAdmin(
        {
          uid: userToEdit.id,
          userDetails: {
            name: admin.name,
            phone: admin.phone,
            roles: admin.roles,
          },
        },
        {
          onSuccess: () =>
            pushMessage(
              "success",
              strings.account_settings_admins_update_success
            ),
          onSettled: () => setUserToEdit(null),
        }
      );
      return;
    }
    createAdmin(admin, {
      onSuccess: () =>
        pushMessage("success", strings.account_settings_admins_save_success),
    });
  };

  const isMe = useCallback(
    (user) => {
      return user.id === loggedInUser?.id;
    },
    [loggedInUser]
  );

  if (!readAllowed) {
    return <NotAllowedMessage />;
  }

  return (
    <StyledAccountsContent>
      <div className="toolbar">
        <UiPrimaryButton
          disabled={!writeAllowed}
          onClick={() => {
            setUserToEdit(null);
            setOpenForm(true);
          }}
          startIcon={<PersonAddIcon />}
        >
          {strings.button_add}
        </UiPrimaryButton>
        <CurrentAccountInfo name={account?.name} id={account?.id} />
      </div>

      <TableContainer>
        <Table stickyHeader>
          <colgroup>
            {columns(strings).map((c) => (
              <col key={c.id} style={{ width: c.width }} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              {columns(strings).map((c) => (
                <TableCell key={c.id}>
                  <TableCellText>
                    <strong>{c.label}</strong>
                  </TableCellText>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {admins
              .filter((d) => !d.internal)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((a) => (
                <TableRow key={a.id}>
                  {columns(strings).map((c) => (
                    <TableCell
                      className={`${a.owner ? "owner" : ""}`}
                      key={c.id}
                    >
                      {c.render(a, onDelete, onEdit, isMe, writeAllowed)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CreateAdminForm
        admin={userToEdit}
        key={openForm}
        onAccept={handleSaveNewAdmin}
        onClose={() => setOpenForm(false)}
        open={openForm}
      />

      <MultipleOptionsDialog
        onConfirm={handleConfirmDelete}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        context={confirmDeleteContext}
        title={strings.account_settings_admins_remove_confirm_title}
        text={strings.account_settings_admins_remove_confirm_question}
      />
    </StyledAccountsContent>
  );
}

export default AccountAdmins;
