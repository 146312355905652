import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  styled,
} from "@mui/material";

const StyledScenariosMultiSelect = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  gap: "10px",
  marginRight: "20px",
}));

export const ScenariosMultiSelect = ({
  scenarios,
  filteredScenarios,
  setFilteredScenarios,
}) => {
  const handleChange = (_, child) => {
    const selected = child.props.value;
    if (selected === "all") {
      setFilteredScenarios((prev) => (prev.length ? [] : scenarios));
      return;
    }

    setFilteredScenarios((prev) => {
      const includes = prev.some((s) => s.id === selected.id);
      return includes
        ? [...prev].filter((s) => s.id !== selected.id)
        : [...prev, selected].sort((a, b) => a.name.localeCompare(b.name));
    });
  };

  const renderValue = (selected) => {
    if (scenarios.length === selected.length) {
      return "-- All selected --";
    }
    if (selected.length === 0) {
      return "-- None selected --";
    }
    return `${selected.length} selected`;
  };

  return (
    <StyledScenariosMultiSelect>
      <div>Scenarios:</div>
      <Select
        IconComponent={KeyboardArrowDownOutlinedIcon}
        className="select"
        labelId="selector"
        onChange={handleChange}
        multiple
        displayEmpty
        renderValue={renderValue}
        value={filteredScenarios}
      >
        <MenuItem value={"all"}>
          <Checkbox checked={filteredScenarios.length === scenarios.length} />
          <ListItemText primary={"Select all"} />
        </MenuItem>
        {scenarios.map((scenario) => {
          return (
            <MenuItem key={scenario.id} value={scenario}>
              <Checkbox
                checked={filteredScenarios.some(
                  (item) => item.id === scenario.id
                )}
              />
              <ListItemText primary={scenario.name} />
            </MenuItem>
          );
        })}
      </Select>
    </StyledScenariosMultiSelect>
  );
};
