import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material";

import HtmlTooltip from "components/ui/HtmlTooltip";

import { timeFormatter } from "utils/time-fmt";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useScenarioStatistics } from "hooks/useScenarioStatistics";

const StyledStatsContent = styled("div")`
  table {
    opacity: ${(props) => (props.loading === "true" ? 0.4 : 1)};
    transition: opacity 500ms ease;
    .name,
    .value {
      padding: 5px 10px;
    }
    .name: {
      text-align: left;
    }
    .value {
      text-align: right;
    }
  }
`;

function ScenarioStatsTooltipContent({ scenario }) {
  const [stats, loading] = useScenarioStatistics(scenario?.id);
  const strings = useLocalizedStrings();

  return (
    <StyledStatsContent loading={`${loading}`}>
      <h3>Scenario Statistics</h3>
      <hr />
      <table>
        <tbody>
          <tr>
            <td className="name">
              {strings.scenarioinfo_title_last_event_time}
            </td>
            <td className="value">
              {scenario?.most_recent_event_time
                ? timeFormatter(new Date(scenario?.most_recent_event_time))
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td className="name">{strings.scenarioinfo_title_total_events}</td>
            <td className="value">
              {scenario?.num_events
                ? (+scenario.num_events).toLocaleString()
                : "N/A"}
            </td>
          </tr>
          {stats?.map((d) => (
            <tr key={d.id}>
              <td className="name">{d.label}</td>
              <td className="value">{(+d.value).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledStatsContent>
  );
}

export default function ScenarioInfoIcon({ scenario }) {
  return (
    <HtmlTooltip
      style={{
        display: "inline",
        transform: "scale(0.7)",
        color: "inherit",
        zIndex: "1",
      }}
      title={<ScenarioStatsTooltipContent scenario={scenario} />}
    >
      <InfoOutlinedIcon style={{ fontSize: "1.3rem" }} />
    </HtmlTooltip>
  );
}
