export default class Crontab {
  static HALF_HOUR = "*/30 * * * *";

  static fromDaysAndHours(days, hours) {
    const hoursString = hours.reduce((out, e, idx) => {
      return e ? [...out, idx] : out;
    }, []);
    const daysString = days.reduce((out, e, idx) => {
      return e ? [...out, idx] : out;
    }, []);
    return `0 ${hoursString.length ? hoursString.join(",") : "?"} ? * ${
      daysString.length ? daysString.join(",") : "?"
    }`;
  }

  static makeDefault() {
    const hour = Math.floor(Math.random() * 12);
    return `0 ${hour},${hour + 12} * * 0,1,2,3,4,5,6`;
  }

  static getDaysAndHours(pattern) {
    const empty = (n) => new Array(n).fill(false);

    if (!pattern || !pattern.length) {
      return [empty(7), empty(24)];
    }

    const [, _hours, , , _dow] = pattern.split(" ");

    const times = _hours
      ? _hours.split(",").reduce((o, e) => {
          if (e && e !== "?") {
            o[+e] = true;
          }
          return o;
        }, empty(24))
      : empty(24);

    const days = _dow
      ? _dow.split(",").reduce((o, e) => {
          if (e && e !== "?") {
            o[+e] = true;
          }
          return o;
        }, empty(7))
      : empty(7);

    return [days, times];
  }
}
