import { greyDark } from "themes/MainTheme";

import HtmlTooltip from "./HtmlTooltip";

export const InformationPopover = ({
  title,
  content,
  children,
  titleDetails,
  footer,
  disabled,
}) => {
  return (
    <HtmlTooltip
      disableHoverListener={disabled}
      title={
        <div>
          {title && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>{title}</h3>
                {titleDetails}
              </div>
              <hr />
            </>
          )}
          <table
            style={{ transition: "opacity 500ms ease", maxWidth: "400px" }}
          >
            <tbody>
              {content.map(({ name, value }, idx) => (
                <tr key={idx}>
                  <td
                    className="name"
                    style={{ textAlign: "left", padding: "5px 10px" }}
                  >
                    {name}
                  </td>
                  <td
                    className="value"
                    style={{ textAlign: "right", padding: "5px 10px" }}
                  >
                    {value}
                  </td>
                </tr>
              ))}
              {footer && (
                <tr>
                  <td
                    colSpan={2}
                    style={{
                      textAlign: "left",
                      fontSize: "0.9em",
                      color: greyDark,
                      padding: "5px 10px",
                    }}
                  >
                    {footer}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
    </HtmlTooltip>
  );
};
