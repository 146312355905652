import { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import { useStore } from "store";

export function UniquenessValidationWrapper({ children }) {
  const methods = useFormContext();
  useEffect(() => {
    const unsub = useStore.subscribe(
      (state) => state.outputFields,
      (outputFields) => {
        const duplicates = Object.entries(outputFields).reduce(
          (duplicates, [key, value]) => {
            if (!duplicates[value]) {
              duplicates[value] = [];
            }
            duplicates[value].push(key);
            return duplicates;
          },
          {}
        );
        const duplicateOutputFields = Object.entries(duplicates).reduce(
          (duplicates, [_, keys]) => {
            if (keys.length === 1) {
              return duplicates;
            }
            return duplicates.concat(keys);
          },
          []
        );
        useStore.setState({ duplicateOutputFields });
      }
    );
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    const unsub = useStore.subscribe(
      (state) => state.duplicateOutputFields,
      (duplicateOutputFields, prevDuplicateFields) => {
        const fieldsToValidate = new Set([
          ...duplicateOutputFields,
          ...prevDuplicateFields,
        ]);
        if (fieldsToValidate.size) {
          methods.trigger();
        }
      }
    );
    return () => {
      unsub();
    };
  }, [methods]);

  return <>{children}</>;
}
