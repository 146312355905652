import { useContext, useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { useSessionsEvaluationImportMutation } from "api/sessions";

import { ScenariosContext } from "contexts/ScenariosContext";

import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import FileInput from "components/ui/form/FileInput";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

export function SessionEvaluationImporter() {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);
  const { pushMessage } = useMessages();
  const methods = useForm({
    values: {
      scenario: selectedScenario?.id,
      sessionData: "",
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [open, setOpen] = useState(false);

  const { mutateAsync: uploadEvaluations } =
    useSessionsEvaluationImportMutation();

  const onSubmit = async (formData) => {
    try {
      const rows = await uploadEvaluations(formData);
      const { updated, total } = rows[0];
      pushMessage(
        "success",
        strings.formatString(strings.session_eval_import_toast, updated, total)
      );
      setOpen(false);
    } catch {
      reset();
    }
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <div>
      <UiSecondaryButton
        style={{
          width: "15em",
          marginBottom: "5px",
        }}
        onClick={() => setOpen(true)}
      >
        {strings.session_eval_import_title}
      </UiSecondaryButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{strings.session_eval_import_title}</DialogTitle>
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2em",
                alignItems: "center",
              }}
            >
              <Typography style={{ whiteSpace: "pre-wrap" }}>
                {strings.session_eval_import_text}
              </Typography>
              <FileInput
                accept="application/json"
                name="sessionData"
                placeholder="Upload session evaluation file"
                style={{ width: "550px" }}
                required={true}
              />
            </DialogContent>
            <DialogActions>
              <UiSecondaryButton onClick={() => setOpen(false)}>
                {strings.button_cancel}
              </UiSecondaryButton>
              <UiPrimaryButton disabled={isSubmitting} type="submit">
                {strings.button_submit}
              </UiPrimaryButton>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  );
}
