import { forwardRef, useMemo, useRef } from "react";

import PublishIcon from "@mui/icons-material/Publish";
import {
  CircularProgress,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableVirtuoso } from "react-virtuoso";

import TableFooterLoadingMessage from "./TableFooterLoadingMessage";
import { SortHeader } from "./tables/SortHeader";

const StyledTableFooter = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  flexBasis: "min-content",
  fontFamily: "inherit",
  fontSize: "inherit",
  justifyContent: "space-between",
  padding: "0.5em 0 0.5em 0.5em",
  userSelect: "none",
  position: "relative",
  "& .MuiButtonBase-root": {
    backgroundColor: "transparent",
    opacity: "0.6",
    transition: "opacity 200ms ease",
    ".MuiIconButton-label": {
      padding: "4px",
    },
    "&:hover": {
      opacity: "1",
    },
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTop: "1px solid #ddd",
  color: theme.palette.text.paper,
  display: "flex",
  flexBasis: "min-content",
  flexDirection: "column",
  flexGrow: "1",
  overflowY: "scroll !important",
  position: "relative",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  position: "absolute",
  tableLayout: "fixed",
  "&.debug-sizes .MuiTableCell-root": {
    border: "1px solid LightPink",
  },
  "& .MuiTableHead-root .MuiTableCell-root": {
    background: theme.palette.primary.contrastText,
    color: theme.palette.purple.dark,
    padding: `${theme.spacing(0.3)} ${theme.spacing(1)}`,
    "& *": {
      fontWeight: "bold",
      textTransform: "capitalize",
      userSelect: "none",
    },
    "& .MuiTableSortLabel-icon": {
      fontSize: "2rem",
      fill: "tomato",
    },
  },
  "& .MuiTableBody-root td": {
    color: theme.palette.table.main,
    padding: "8px",
    overflow: "hidden",
  },

  "& .MuiTableSortLabel-root": {
    backgroundColor: "inherit",
    color: "inherit",
  },
  "& .MuiTableSortLabel-icon": {
    margin: 0,
  },
}));

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

const TableComponents = {
  Scroller: forwardRef((props, ref) => (
    <StyledTableContainer {...props} ref={ref} />
  )),
  Table: (props) => {
    const itemsToShow = props.context?.items.length;

    if (itemsToShow) {
      return <StyledTable aria-label="sticky table" stickyHeader {...props} />;
    }

    if (!props.context.isPending) {
      return (
        <StyledGridOverlay>
          <Typography variant="h6">No results found</Typography>
        </StyledGridOverlay>
      );
    }

    return null;
  },
  TableHead: TableHead,
  TableRow: (props) => <TableRow hover {...props} />,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  ScrollSeekPlaceholder: () => {
    return (
      <TableRow>
        <TableCell colSpan={8} align="center">
          <CircularProgress size={40} sx={{ color: "purple.main" }} />
        </TableCell>
      </TableRow>
    );
  },
};

export const VirtuosoTable = ({
  columns,
  footerDetails,
  isPending,
  items = [],
  itemsCount,
  loadMoreItems,
  noCount,
  onOrderBy = () => {},
  orderBy = {},
  perPageCount,
  pageLimit,
  overscan = 0,
}) => {
  const count = useMemo(() => {
    const f = new Intl.NumberFormat("en-US");
    const page = f.format(items?.length ?? 0);
    const total = f.format(itemsCount ?? 0);
    if (noCount) {
      return "";
    }
    if (isPending) {
      return "Loading...";
    }
    if (perPageCount) {
      return `${page}/${total} items`;
    }
    if (items?.length % pageLimit > 0) {
      return `Total: ${page} results`;
    }
    return `Displaying ${page} results`;
  }, [noCount, perPageCount, items?.length, itemsCount, isPending, pageLimit]);

  const virtuosoRef = useRef(null);

  const handleScrollToTop = () => {
    virtuosoRef.current.scrollToIndex({
      index: 0,
      align: "start",
      behavior: "instant",
    });
  };

  return (
    <>
      <TableVirtuoso
        context={{ items: items, isPending: isPending }}
        components={TableComponents}
        data={items}
        endReached={loadMoreItems}
        fixedHeaderContent={() => (
          <TableRow>
            {columns
              .filter((c) => c.visible !== false)
              .map((c) => (
                <SortHeader
                  key={c.id}
                  header={c}
                  orderBy={orderBy}
                  onOrderBy={onOrderBy}
                />
              ))}
          </TableRow>
        )}
        itemContent={(_, item) =>
          columns
            ?.filter((column) => column.visible !== false)
            .map((column) => (
              <TableCell
                key={`${item.id}_${column.id}`}
                id={`${item.id}_${column.id}`}
                style={column.style}
              >
                {column.render(item)}
              </TableCell>
            ))
        }
        overscan={overscan}
        ref={virtuosoRef}
        scrollSeekConfiguration={{
          enter: (velocity) => {
            return Math.abs(velocity) > 5000;
          },
          exit: (velocity) => {
            return Math.abs(velocity) < 50;
          },
        }}
      />

      <StyledTableFooter>
        <div>
          {" "}
          {count}
          {footerDetails}
        </div>
        <IconButton
          size="small"
          onClick={handleScrollToTop}
          title="Go To Top"
          color="primary"
        >
          <PublishIcon />
        </IconButton>
      </StyledTableFooter>
      {isPending && <TableFooterLoadingMessage />}
    </>
  );
};
