import { useContext, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import useFeature from "hooks/useFeature";

import { LoginStatusContext } from "./LoginStatusContext";

export const AdoptionPlatformWrapper = ({ children }) => {
  const { user } = useContext(LoginStatusContext);
  const apKey = useFeature("ui_settings/adoption_platform_key");
  const location = useLocation();
  const [ap, setAp] = useState();

  useEffect(() => {
    const loadAp = async () => {
      const mod = (await import("userpilot")).Userpilot;
      mod.initialize(apKey);
      setAp(mod);
    };
    if (user?.id && apKey) {
      loadAp();
    }
  }, [apKey, user?.id]);

  useEffect(() => {
    if (ap && user?.id) {
      ap.identify(user.id, {
        name: user.name,
        account: user?.account,
      });
    }
  }, [user?.id, user?.name, user?.account, ap]);

  useEffect(() => {
    if (ap) {
      ap.reload();
    }
  }, [ap, location]);

  return children;
};
