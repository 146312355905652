const RISK_CLASSES = {
  low: [0, 30],
  med: [31, 75],
  high: [76, 100],
};

function useRiskClassification() {
  return (risk) => {
    if (risk <= RISK_CLASSES.low[1]) {
      return ["low", "gold", RISK_CLASSES.low];
    }
    if (risk <= RISK_CLASSES.med[1]) {
      return ["med", "orange", RISK_CLASSES.med];
    }
    return ["high", "#f00a", RISK_CLASSES.high];
  };
}

export { RISK_CLASSES, useRiskClassification };
