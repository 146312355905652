import { useHistory } from "react-router-dom";

function useNavigation() {
  const history = useHistory();
  return (url) => {
    history.push(url);
  };
}

export { useNavigation };
