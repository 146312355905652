import FilterOperators from "utils/FilterOperators";
import {
  filtersSpecToQueryParams,
  sortSpecToQueryParams,
} from "utils/apiquery";

export default class API {
  constructor() {
    if (constructor === API) {
      throw new Error("Cannot instantiate base class API");
    }
  }

  static composeChunkedQueryUrl(
    baseUrl,
    offset,
    limit,
    filters,
    orderBy,
    defaultOrderKey,
    defaultOrderDirection = "asc"
  ) {
    const operators = FilterOperators;

    // clean ordering entries with direction===0
    const ob = Object.entries(orderBy || {})
      .filter(([_, v]) => v)
      .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});

    // clean filters with no operator
    const f = Object.entries(filters || {})
      .filter(([_, v]) => v && !!v.operator)
      .map(([k, v]) => {
        const operator = operators[v.operator]?.op;
        const operand = v.operand;
        return [k, { operator, operand }];
      })
      .filter(([_, v]) => v && !!v.operator)
      .reduce((a, [k, v]) => {
        return { ...a, [k]: v };
      }, {});

    let url = `${baseUrl}?offset=${offset || 0}&limit=${limit || 20}`;
    const qsFilter = filtersSpecToQueryParams(f);
    if (qsFilter) {
      url += "&" + qsFilter;
    }
    const qsSort = sortSpecToQueryParams(ob);
    if (qsSort) {
      url += "&" + qsSort;
    }

    // add a secondary sort order, or a default one.
    const sort = Object.entries(ob)[0];

    if (defaultOrderKey && sort?.[0] !== defaultOrderKey) {
      if (sort && sort[1]) {
        url += `&orderBy[${sort[1] === 1 ? "asc" : "desc"}]=${defaultOrderKey}`;
      } else {
        url += `&orderBy[${defaultOrderDirection}]=${defaultOrderKey}`;
      }
    }

    return url;
  }
}
