import { useQuery } from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

class CountersAPI extends API {
  static getCountersRecentState(scenario) {
    return { url: `/api/analysis-model/v1/${scenario}/counters/latest` };
  }
}

const queryKey = ["counters"];

export const useCountersGetRecentStateQuery = () => {
  return useQuery({
    queryKey,
    queryFn: (scenario) =>
      queryFetch(CountersAPI.getCountersRecentState(scenario)),
  });
};

export default CountersAPI;
