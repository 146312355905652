import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

const useLocationChanged = () => {
  const history = useHistory();
  const [location, setLocation] = useState();

  useEffect(() => {
    return history.listen((loc) => setLocation(loc));
  }, [history]);

  return [location];
};

export { useLocationChanged };
