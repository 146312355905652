import { forwardRef } from "react";

import { Button, styled } from "@mui/material";

const basicButton = {
  borderRadius: "6px",
  width: "auto",
};

const StyledUiButton = styled(Button)(({ theme, col, variant, children }) => ({
  ...basicButton,
  ".MuiButton-startIcon": {
    marginRight: `${children ? "8px" : "0px"}`,
  },
  border:
    variant === "outlined"
      ? `1px solid ${theme.palette.button[col].contrastText}`
      : "none",

  backgroundColor:
    variant === "contained"
      ? theme.palette.button[col].main
      : variant === "outlined"
      ? "#FFFFFF"
      : "transparent",

  color: theme.palette.button[col].contrastText,

  "&:hover": {
    backgroundColor:
      variant === "text"
        ? theme.palette.button[col].hoverLight
        : theme.palette.button[col].hover,
  },
  "&:disabled": {
    backgroundColor: variant !== "text" && theme.palette.button[col].disabled,
    opacity: 0.6,
  },
}));

export const UiDangerButton = styled(Button)(
  ({ theme, variant = "contained" }) => {
    return {
      ...basicButton,
      border:
        variant === "outlined"
          ? `1px solid ${theme.palette.button.danger.main}`
          : `none`,

      backgroundColor:
        variant === "contained" ? theme.palette.button.danger.main : "#FFFFFF",
      color:
        variant === "contained"
          ? theme.palette.button.danger.contrastText
          : theme.palette.button.danger.main,

      "&:hover": {
        backgroundColor: theme.palette.button.danger.hover,
        color: theme.palette.button.danger.contrastText,
        border:
          variant === "outlined"
            ? `1px solid ${theme.palette.button.danger.hover}`
            : `none`,
      },
      "&:disabled": {
        backgroundColor:
          variant === "contained"
            ? theme.palette.button.danger.disabled
            : "#FFFFFF",
        color:
          variant === "contained"
            ? theme.palette.button.danger.contrastText
            : theme.palette.button.danger.main,
        border:
          variant === "outlined"
            ? `1px solid ${theme.palette.button.danger.main}`
            : `none`,
        opacity: 0.6,
      },
    };
  }
);

export const UiPrimaryButton = forwardRef(
  (
    {
      children,
      disabled = false,
      onClick,
      size = "medium",
      startIcon,
      variant = "contained",
      ...rest
    },
    ref
  ) => {
    return (
      <StyledUiButton
        col={"primary"}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        size={size}
        startIcon={startIcon}
        variant={variant}
        {...rest}
      >
        {children}
      </StyledUiButton>
    );
  }
);

export const UiSecondaryButton = forwardRef(
  (
    {
      children,
      disabled = false,
      onClick,
      size = "medium",
      startIcon,
      variant = "contained",
      ...rest
    },
    ref
  ) => {
    return (
      <StyledUiButton
        col={"secondary"}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        size={size}
        startIcon={startIcon}
        variant={variant}
        {...rest}
      >
        {children}
      </StyledUiButton>
    );
  }
);
