import React, { useEffect, useMemo, useRef } from "react";

import { Chart, registerables } from "chart.js";

import { parseSensitivityColor } from "utils/parseSensitivityColor";

import useLocalizedStrings from "hooks/useLocalizedStrings";

Chart.register(...registerables);

const ProfileActionsHistogramChart = (props) => {
  const strings = useLocalizedStrings();
  const { histogram: clusterhist, mode, translated, orderBy, slice } = props;
  const canvasRef = useRef();
  const chart = useRef();

  const total = useMemo(
    () => clusterhist.reduce((a, d) => a + d.c, 0),
    [clusterhist]
  );
  const processed = useMemo(
    () =>
      total
        ? clusterhist
            .map((d) => ({
              ...d,
              d: [d.d.replace(/(,)\s*/g, "\n").trim()],
              k: [d.k.replace(/(,|\t)\s*/g, "\n").trim()],
              nc: (d.c * 100) / total,
              s: Math.abs(Math.min(d.s, 0)),
            }))
            .sort((a, b) => (orderBy === "occurences" ? b.c - a.c : b.s - a.s))
            .filter(
              (item, idx, a) => (idx * 100) / a.length < slice && item.nc >= 0.1
            )
        : [],
    [clusterhist, total, orderBy, slice]
  );

  useEffect(() => {
    chart.current = new Chart(canvasRef.current, {
      type: "bar",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: {
          duration: 500,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            intersect: false,
            displayColors: true,
            callbacks: {
              label: function (context) {
                const value =
                  mode === "relative"
                    ? `${Number(context.formattedValue).toFixed(2)}%`
                    : context.raw;
                return `${context.dataset.label}: ${value}`;
              },
              title: function (context) {
                let title = context[0].label;
                return title;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: strings.profiledetails_tab_actions_chart_x,
              align: "center",
              font: {
                size: 14,
              },
            },
            ticks: {
              callback: () => "",
            },
          },
          y: {
            title: {
              display: true,
              text:
                mode === "absolute"
                  ? strings.profiledetails_tab_actions_chart_occurences
                  : strings.profiledetails_tab_actions_chart_popularity,
              align: "center",
              font: {
                size: 14,
              },
            },
            min: 0,
            // max: max,
            ticks: {
              callback: (val) => (mode === "absolute" ? val : `${val}%`),
            },
          },
        },
      },
      data: {
        labels: processed.map((d) => (translated && d.d[0] !== "" ? d.d : d.k)),
        datasets: [
          {
            label: mode === "absolute" ? "Occurences" : "Popularity",
            backgroundColor: (ctx) =>
              parseSensitivityColor(processed[ctx.dataIndex]?.s),
            data: processed.map((d) => (mode === "absolute" ? d.c : d.nc)),
          },
        ],
      },
    });
    return () => {
      chart.current && chart.current.destroy();
    };
  }, [processed, mode, translated, strings]);

  return (
    <div style={{ height: "50vh", minHeight: "500px" }}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default ProfileActionsHistogramChart;
