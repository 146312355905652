import React from "react";

import { styled } from "@mui/material";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledTable = styled("div")`
  position: relative;
  overflow: auto;
  scroll-behavior: smooth;
  scroll-padding-top: 3em;
  /* min-height: 180px; */

  & > table {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th,
    td {
      vertical-align: middle;
      text-align: left;
    }

    & > thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 0.5em 1em;
      text-transform: uppercase;
      text-align: left;
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
      font-weight: normal;
    }

    & td {
      transition: all 500ms ease;
      padding: 1em;
    }

    &.hover > tbody tr:hover > td {
      background-color: #f0f0f0;
      color: initial;
    }

    & tbody td {
      border-bottom: 1px solid #ddd;
    }

    &.dense :is(td, th) {
      padding: 5px 10px;
    }

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Table = React.forwardRef((props, ref) => {
  const strings = useLocalizedStrings();
  const {
    columns,
    items,
    noheader,
    nofooter,
    hover,
    emptyMessage,
    dense,
    style,
    ...rest
  } = props;
  return (
    <StyledTable ref={ref} className="table-wrapper" style={style}>
      <table className={`${hover && "hover"} ${dense && "dense"}`}>
        <colgroup>
          {columns &&
            columns.map((c) => (
              <col key={c.id} style={{ width: c.width || "unset" }} />
            ))}
        </colgroup>

        {noheader || (
          <thead>
            <tr>
              {columns && columns.map((c) => <th key={c.id}>{c.label}</th>)}
            </tr>
          </thead>
        )}

        <tbody>
          {items &&
            items.map((a) => (
              <tr key={a.id} id={a.id}>
                {columns &&
                  columns.map((c) => (
                    <td key={c.id} style={c.style}>
                      {c.render?.(a, { ...rest })}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>

        {nofooter || (
          <tfoot>
            <tr>
              <td colSpan={columns.length} style={{ textAlign: "center" }}>
                {items.length
                  ? strings.formatString(
                      strings.table_footer_items,
                      items.length
                    )
                  : emptyMessage || strings.table_footer_empty}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </StyledTable>
  );
});

export default Table;
