import { createContext, useContext, useMemo } from "react";

import { KeyboardArrowDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { TagsVisibilityContext } from "contexts/TagsVisibilityContext";

import { PageSubtitle } from "components/ui/PageSubtitle";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import SessionTokens, { longestLabelWidth } from "./SessionTokens";

const SessionSummaryFieldsContext = createContext();

export function SessionSummaryFieldsContextProvider({
  children,
  summaryFields,
}) {
  return (
    <SessionSummaryFieldsContext.Provider value={summaryFields}>
      {children}
    </SessionSummaryFieldsContext.Provider>
  );
}

export function useSessionSummaryFields() {
  return useContext(SessionSummaryFieldsContext);
}

function AnalyzedFields({
  histograms,
  longestWidth,
  totals,
  isPendingTagTotals,
}) {
  const strings = useLocalizedStrings();
  if (!histograms.length) {
    return null;
  }

  return (
    <>
      <PageSubtitle>{strings.session_fields_analyzed}</PageSubtitle>
      <SessionTokens
        histograms={histograms}
        totals={totals}
        isPendingTagTotals={isPendingTagTotals}
        longestWidth={longestWidth}
      />
    </>
  );
}

function InformativeFields({
  histograms,
  longestWidth,
  totals,
  isPendingTagTotals,
}) {
  const strings = useLocalizedStrings();
  const [expanded, setExpanded] = useCurrentUserSettings(
    "informative-fields-expanded",
    false
  );
  if (!histograms.length) {
    return null;
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      sx={{
        "&::before": {
          display: "none",
        },
        "&.MuiAccordion-root": {
          boxShadow: "none",
        },
        "&.Mui-expanded": {
          margin: "0",
        },
      }}
      square={true}
    >
      <AccordionSummary
        sx={{ flexDirection: "row-reverse", gap: "5px", p: 0 }}
        expandIcon={<KeyboardArrowDown />}
      >
        <PageSubtitle>{strings.session_fields_info}</PageSubtitle>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <SessionTokens
          histograms={histograms}
          totals={totals}
          isPendingTagTotals={isPendingTagTotals}
          longestWidth={longestWidth}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export function SummaryFields({
  tags,
  totals,
  isPendingTags,
  isPendingTagTotals,
}) {
  const { analyzedFields } = useContext(TagsVisibilityContext);
  const strings = useLocalizedStrings();

  const sorted = useMemo(() => {
    const r = {
      informative: [],
      analyzed: [],
    };
    if (!tags) {
      return r;
    }
    for (const t of tags) {
      if (analyzedFields.has(t.tag)) {
        r.analyzed.push(t);
      } else {
        r.informative.push(t);
      }
    }
    return r;
  }, [tags, analyzedFields]);

  const longestWidth = useMemo(() => longestLabelWidth(tags), [tags]);

  if (isPendingTags) {
    return <div className="tags-wrapper">{strings.text_loading2}</div>;
  }

  if (!tags?.length) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <AnalyzedFields
        histograms={sorted.analyzed}
        totals={totals}
        longestWidth={longestWidth}
        isPendingTagTotals={isPendingTagTotals}
      />
      <InformativeFields
        histograms={sorted.informative}
        totals={totals}
        longestWidth={longestWidth}
        isPendingTagTotals={isPendingTagTotals}
      />
    </div>
  );
}
