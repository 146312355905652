import { createContext, useCallback, useContext, useMemo } from "react";

import { DISPLAY_MODES } from "@tiq/shared/lib/constants";

import { useScenarioFieldSettingsQuery } from "api/scenarios";

import { ScenariosContext } from "./ScenariosContext";

export const TagsVisibilityContext = createContext({});

export function TagsVisibilityContextProvider({ children }) {
  const { selectedScenario } = useContext(ScenariosContext);
  const { data, isPending } = useScenarioFieldSettingsQuery(
    selectedScenario?.id
  );

  const { fields, analyzedFields, favoriteFields, configuredFields } =
    useMemo(() => {
      const res = {
        fields: new Map(),
        analyzedFields: new Set(),
        favoriteFields: [],
        configuredFields: [],
      };
      if (isPending) {
        return res;
      }
      for (const field of data) {
        res.fields.set(field.field, field);
        res.configuredFields.push(field.field);
        if (field?.favorite === DISPLAY_MODES.ALWAYS) {
          res.favoriteFields.push(field.field);
        }
        if (
          field.value_count_insights.toLowerCase() !== "no" ||
          field.value_frequency_insights.toLowerCase() !== "no"
        ) {
          res.analyzedFields.add(field.field);
        }
      }
      return res;
    }, [data, isPending]);

  const isFavoriteField = useCallback(
    (f) => {
      return fields.get(f)?.favorite === DISPLAY_MODES.ALWAYS;
    },
    [fields]
  );

  const isVisibleField = useCallback(
    (tag) => {
      const f = fields.get(tag);
      return f ? f.visible : true;
    },
    [fields]
  );

  return (
    <TagsVisibilityContext.Provider
      value={{
        isFavoriteField,
        isVisibleField,
        favoriteFields,
        configuredFields,
        analyzedFields,
      }}
    >
      {children}
    </TagsVisibilityContext.Provider>
  );
}
