import { useCallback, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { Checkbox, IconButton, Typography, styled } from "@mui/material";

import { useCollectorsFileCatalogInfiniteQuery } from "api/collectors";
import { useDataAnalysisJobMutation } from "api/scheduler";

import FiltersSection from "components/scenarios/FiltersSection";
import FreeSearchStrip from "components/scenarios/FreeSearchStrip";
import HtmlTooltip from "components/ui/HtmlTooltip";
import { UiPrimaryButton } from "components/ui/StyledButtons";
import { VirtuosoTable } from "components/ui/VirtuosoTable";

import FilterOperators from "utils/FilterOperators";
import { inUtc } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useStateDeep } from "hooks/deepcomp";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";
import useScenarioJobState from "hooks/useScenarioJobState";

import { DataAnalysisDialog } from "./DataAnalysisDialog";

const operators = FilterOperators;

const filtersSchema = (strings) =>
  Object.entries({
    batch: {
      label: strings.collectors_datatab_column_file_name,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    type: {
      label: strings.collectors_datatab_column_file_type,
      type: "text",
      operators: [operators["eq"], operators["in"], operators["notin"]],
    },
    created: {
      label: strings.collectors_datatab_column_created,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    num_records: {
      label: strings.collectors_datatab_column_num_records,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    oldest_event_time: {
      label: strings.collectors_datatab_column_oldest_event,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    most_recent_event_time: {
      label: strings.collectors_datatab_column_newest_event,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
  });

const dateFormat = (date) => {
  return !date ? "N/A" : inUtc(date).format("MMM D YYYY HH:mm:ss");
};

const fields = ({
  isRunning,
  onSelectAll,
  onSelectNone,
  onSelected,
  strings,
  selection,
}) => [
  {
    id: "checkbox",
    label: (
      <SelectionButtons
        isRunning={isRunning}
        onSelectAll={onSelectAll}
        onSelectNone={onSelectNone}
      />
    ),
    render: (v) => {
      const showFileSelector =
        Number(v.num_records) > 0 &&
        v.path &&
        v.status === "ok" &&
        v.type === "flat";

      return (
        showFileSelector && (
          <Checkbox
            checked={selection?.has(v.id)}
            color="primary"
            disabled={isRunning}
            name={v.id}
            onChange={(e) => onSelected(v.id, e.target.checked)}
          />
        )
      );
    },
    style: {
      width: "4em",
      textAlign: "center",
    },
    hidden: true,
  },
  {
    id: "name",
    label: strings.collectors_datatab_column_name,
    render: (v) => <Typography className="nameCell">{v.batch}</Typography>,
    style: {
      width: "10em",
      overflowWrap: "break-word",
    },
  },
  {
    id: "type",
    label: strings.collectors_datatab_column_file_type,
    render: (v) => <Typography>{v.type}</Typography>,
    style: {
      width: "4em",
    },
  },
  {
    id: "encryption",
    label: strings.collectors_dataExploration_tab_column_encyption,
    render: (v) => {
      return (
        <Typography>
          {v.is_encrypted
            ? strings.collectors_dataExploration_tab_column_encyption_applied
            : strings.collectors_dataExploration_tab_column_encyption_not_applied}
        </Typography>
      );
    },
    style: {
      width: "4em",
    },
  },
  {
    id: "created",
    label: strings.collectors_datatab_column_created,
    sort: "created",
    render: (v) => <Typography>{dateFormat(v.created)}</Typography>,
    style: {
      width: "6em",
    },
  },
  {
    id: "num_records",
    label: strings.collectors_datatab_column_num_records,
    sort: "num_records",
    render: (v) => <Typography variant="body1">{+v.num_records}</Typography>,
    style: {
      width: "5em",
    },
  },
  {
    id: "oldest_event_time",
    label: strings.collectors_datatab_column_oldest_event,
    sort: "oldest_event_time",
    render: (v) => <Typography>{dateFormat(v.oldest_event_time)}</Typography>,
    style: {
      width: "8em",
    },
  },
  {
    id: "most_recent_event_time",
    label: strings.collectors_datatab_column_newest_event,
    sort: "most_recent_event_time",
    render: (v) => (
      <Typography>{dateFormat(v.most_recent_event_time)}</Typography>
    ),
    style: {
      width: "8em",
    },
  },
  {
    id: "raw_file",
    label: strings.collectors_datatab_column_raw_file,
    sort: "raw_file",
    render: (v) => <Typography>{v.raw_file}</Typography>,
    style: {
      width: "7em",
      overflowWrap: "break-word",
    },
  },
];

const StyledTableArea = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "min-content 1fr",
  height: "calc(100vh - var(--appbar-height) - 100px)",
  position: "relative",
}));

const StyledExploreToolbar = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  height: "40px",
}));

const SelectionButtons = ({ isRunning, onSelectAll, onSelectNone }) => {
  const [checked, setChecked] = useState(false);
  const strings = useLocalizedStrings();

  const handleClick = () => {
    !checked ? onSelectAll?.() : onSelectNone?.();
    setChecked((checked) => !checked);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <HtmlTooltip title={strings.alertstable_selection_select_all_toggle}>
        <Checkbox
          color="primary"
          disabled={isRunning}
          checked={checked}
          onClick={handleClick}
        />
      </HtmlTooltip>
    </div>
  );
};

const DataFilesTable = ({ collector }) => {
  const FILE_SELECTION_LIMIT = 50;
  const pageLimit = 20;
  const strings = useLocalizedStrings();
  const [filters, setFilters] = useCurrentUserSettings(
    "dataColumns.filters",
    {}
  );
  const [orderBy, setOrderBy] = useCurrentUserSettings(
    "dataColumns.orderby",
    {}
  );
  const [openFilters, setOpenFilters] = useStateDeep(false);
  const { isRunning } = useScenarioJobState(collector?.id);
  const [selection, setSelection] = useState(new Set());
  const [explorationDialogOpen, setExplorationDialogOpen] = useState(false);
  const { pushMessage } = useMessages();

  const { mutate: runDataAnalysis } = useDataAnalysisJobMutation();
  const { data, isFetching, loadMoreItems, cancel } =
    useCollectorsFileCatalogInfiniteQuery({
      id: collector?.id,
      filters,
      orderBy,
      pageLimit,
    });

  const handleNextSortDirection = (id) => {
    cancel();
    setOrderBy((prev) => {
      let d = (prev?.[id] || 0) + 1;
      if (d > 1) {
        d = -1;
      }
      return d ? { [id]: d } : {};
    });
  };

  const handleResetFilters = () => {
    cancel();
    setFilters({});
  };

  const handleChangeFilters = (f) => {
    cancel();
    setFilters(f);
  };

  const handleExplore = (type, applyFilter) => {
    if (selection.size > FILE_SELECTION_LIMIT) {
      pushMessage(
        "error",
        strings.formatString(
          strings.collectors_dataExploration_tab_error_too_many_files,
          FILE_SELECTION_LIMIT
        )
      );
      return;
    }
    runDataAnalysis({
      collectorId: collector.id,
      fileIds: selection,
      explorationMode: type,
      filtering: applyFilter,
    });
  };

  const handleSelectAll = useCallback(() => {
    setSelection(
      data?.rows.reduce((acc, item) => {
        acc.add(item.id);
        return acc;
      }, new Set())
    );
  }, [data?.rows]);

  const handleSelectNone = useCallback(() => {
    setSelection(new Set());
  }, []);

  const handleSelected = useCallback(
    (id, selected) => {
      if (selected && !selection.has(id)) {
        const s = new Set(selection);
        s.add(id);
        setSelection(s);
      } else if (!selected && selection.has(id)) {
        const s = new Set(selection);
        s.delete(id);
        setSelection(s);
      }
    },
    [selection]
  );

  return (
    <StyledTableArea>
      <StyledExploreToolbar>
        <UiPrimaryButton
          disabled={isRunning || data?.rows.length === 0}
          onClick={() => setExplorationDialogOpen(true)}
          style={{ marginLeft: "5px", height: "30px" }}
        >
          {strings.collectors_files_tab_explore_button}
        </UiPrimaryButton>

        <FreeSearchStrip
          hideSearchInput
          filters={filters}
          schema={filtersSchema(strings)}
          setFilters={handleChangeFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          searchPattern={null}
          setSearchPattern={null}
        />
        <IconButton
          size="small"
          onClick={handleResetFilters}
          title="Reset filters"
        >
          <ClearIcon />
        </IconButton>
      </StyledExploreToolbar>

      <VirtuosoTable
        columns={fields({
          isRunning,
          onSelectAll: handleSelectAll,
          onSelectNone: handleSelectNone,
          onSelected: handleSelected,
          selection,
          strings,
        })}
        items={data?.rows}
        itemsCount={data?.count}
        perPageCount={true}
        footerDetails={`, ${selection?.size ?? 0} selected`}
        orderBy={orderBy}
        onOrderBy={handleNextSortDirection}
        loadMoreItems={loadMoreItems}
        isPending={isFetching}
      />

      <FiltersSection
        filters={filters}
        schema={filtersSchema(strings)}
        setFilters={setFilters}
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
      />
      <DataAnalysisDialog
        collector={collector}
        onClose={() => setExplorationDialogOpen(false)}
        onExplore={handleExplore}
        open={explorationDialogOpen}
        key={explorationDialogOpen}
        sampleExplorationLabel={
          strings.collectors_files_tab_explore_dialog_sample_exploration
        }
        fullExplorationLabel={
          strings.collectors_files_tab_explore_dialog_full_exploration
        }
        filesExplorationTitle={
          strings.collectors_files_tab_explore_dialog_title
        }
        fileSelection={selection.size}
      />
    </StyledTableArea>
  );
};

export default DataFilesTable;
