import React, { useContext, useMemo } from "react";

import { Checkbox, MenuItem, Select, styled } from "@mui/material";

import { BrowserNotificationsContext } from "contexts/BrowserNotificationsContext";
import { LocalizedStringsContext } from "contexts/LocalizedStringsContext";

import { UiSecondaryButton } from "components/ui/StyledButtons";

import { useHomepageConfig } from "hooks/useHomepageConfig";
import { useLocalStorage } from "hooks/useStorage";

function SettingsCheckbox(props) {
  return (
    <Checkbox
      {...props}
      style={{ backgroundColor: "transparent", marginRight: "-11px" }}
    />
  );
}
const StyledSettingsSelector = styled(Select)(() => ({
  cursor: "pointer",
  height: "40px",
  minWidth: "12em",
  padding: "0.5em 1em",
  textAlign: "left",
}));

function NotificationsCheckbox() {
  const { notificationsEnabled, enableNotifications } = useContext(
    BrowserNotificationsContext
  );
  return (
    <SettingsCheckbox
      checked={notificationsEnabled}
      onChange={(e) => enableNotifications(e.target.checked)}
    />
  );
}

function LanguageSelector() {
  const { language, setLanguage, availableLanguages } = useContext(
    LocalizedStringsContext
  );

  const langs = useMemo(
    () => new Intl.DisplayNames(availableLanguages, { type: "language" }),
    [availableLanguages]
  );

  if (!language) {
    return null;
  }

  return (
    <StyledSettingsSelector
      value={language}
      onChange={(e) => setLanguage(e.target.value)}
      variant="outlined"
    >
      {availableLanguages.map((d) => (
        <MenuItem key={d} value={d}>
          {langs.of(d)}
        </MenuItem>
      ))}
    </StyledSettingsSelector>
  );
}

const ClearLocalState = () => {
  const { strings } = useContext(LocalizedStringsContext);
  const [, , resetAll] = useLocalStorage();
  return (
    <UiSecondaryButton
      color="secondary"
      onClick={() => {
        resetAll();
      }}
    >
      {strings.button_clear}
    </UiSecondaryButton>
  );
};

const HomepageSelector = () => {
  const { homepageOptions, homepage, setHomepage } = useHomepageConfig();

  return (
    <StyledSettingsSelector
      value={homepage}
      onChange={(e) => setHomepage(e.target.value)}
      variant="outlined"
    >
      {homepageOptions.map((option) => (
        <MenuItem key={option.route} value={option.route}>
          {option.name}
        </MenuItem>
      ))}
    </StyledSettingsSelector>
  );
};

const settings = (strings) => [
  {
    id: "notifications",
    label: strings.account_settings_advanced_notifications,
    render: (settingsItem, props) => (
      <NotificationsCheckbox item={settingsItem} {...props} />
    ),
  },
  {
    id: "language",
    label: strings.account_settings_advanced_language,
    render: (settingsItem, props) => (
      <LanguageSelector item={settingsItem} {...props} />
    ),
  },
  {
    id: "clear-local-state",
    label: strings.account_settings_advanced_clearlocalstate,
    render: (settingsItem, props) => (
      <ClearLocalState item={settingsItem} {...props} />
    ),
  },
  {
    id: "homepage",
    label: "Homepage",
    render: (settingsItem, props) => (
      <HomepageSelector item={settingsItem} {...props} />
    ),
  },
];

const StyledAdvancedSettingsContainer = styled("div")(() => ({
  padding: "3em 9em",
  table: {
    lineHeight: "3em",
    border: "none",
    borderCollapse: "collapse",
    "& tr": {
      "& th": {
        minWidth: "19em",
        textAlign: "left",
        fontWeight: "100",
        border: "none",
      },
      "& td": {
        width: "auto",
        textAlign: "right",
        padding: "0.3em 2em",

        "& > :not(button)": {
          backgroundColor: "#fff",
        },
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.03)",
        "& th": {
          fontWeight: "800",
        },
      },
    },
  },
}));

export default function AccountAdvanced() {
  const { strings } = useContext(LocalizedStringsContext);

  return (
    <StyledAdvancedSettingsContainer>
      <table>
        <tbody>
          {settings(strings)
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((d) => (
              <tr key={d.id}>
                <th>{d.label}</th>
                <td>{d.render(d)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </StyledAdvancedSettingsContainer>
  );
}
