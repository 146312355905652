import { useEffect } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useBackendEvents from "hooks/useBackendEvents";

import API from "./apibase";
import { queryFetch } from "./query";

export default class DiagnosticsAPI extends API {
  static getMessagesForUrl(scenario, count) {
    return {
      url: `/api/acm/v1/user_messages/key/${scenario}?limit=${count || 1000}`,
    };
  }

  static deleteMessagesFor(scenario, ids = []) {
    return {
      url: `/api/acm/v1/user_messages/key/${scenario}`,
      options: {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ ids }),
      },
    };
  }
}

const baseKey = ["diagnostics"];
const diagnosticKeys = {
  messages: ({ scenarioId, count }) => [baseKey, scenarioId, "messages", count],
};

const messagesBackendEvents = ["user_messages"];
export const useDiagnosticsGetMessagesForUrlQuery = (
  { scenarioId, count },
  opts
) => {
  const [backendEvents] = useBackendEvents(messagesBackendEvents, [scenarioId]);
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: diagnosticKeys.messages({ scenarioId, count }),
    });
  }, [backendEvents, count, queryClient, scenarioId]);

  return useQuery({
    queryKey: diagnosticKeys.messages({ scenarioId, count }),
    queryFn: () =>
      queryFetch(DiagnosticsAPI.getMessagesForUrl(scenarioId, count)),
    enabled: !!scenarioId && !!count,
    ...opts,
  });
};

export const useDiagnosticsDeleteMessagesForQuery = ({ scenarioId }) =>
  useMutation({
    mutationFn: (ids) =>
      queryFetch(DiagnosticsAPI.deleteMessagesFor(scenarioId, ids)),
  });
