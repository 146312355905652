import { useCallback, useContext, useMemo, useState } from "react";
import { useLayoutEffect } from "react";

import { Box, Typography } from "@mui/material";
import {
  DataGridPro,
  GridFooterContainer,
  GridPagination,
  GridToolbarDensitySelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useLocation } from "react-router-dom";

import { ScenariosContext } from "contexts/ScenariosContext";
import { TagsVisibilityContext } from "contexts/TagsVisibilityContext";

import HtmlTooltip from "components/ui/HtmlTooltip";
import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";
import RarityIndicator from "components/ui/RarityIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";

import { isIp } from "utils/isIp";
import { timeFormatter } from "utils/time-fmt";

import { useOverflowState } from "hooks/useOverflowState";

import IpAddressLink from "./IpAddressLink";

const staticCols = [
  {
    field: "time",
    headerName: "Time",
    width: 160,
    hideable: false,
    display: "flex",
    renderCell: (cell) => <CellValue>{timeFormatter(cell.value)}</CellValue>,
  },
  {
    field: "sensitivity",
    headerName: "Sensitivity",
    width: 80,
    hideable: false,
    display: "flex",
    renderCell: (cell) => {
      if (!cell.row.match_info) {
        return <SensitivityIndicator value={cell.value} />;
      }
      return (
        <HtmlTooltip
          title={
            <div>
              <h4>Matched by:</h4>
              <pre style={{ fontFamily: "monospace" }}>
                {cell.row.match_info}
              </pre>
            </div>
          }
        >
          <SensitivityIndicator value={cell.value} />
        </HtmlTooltip>
      );
    },
  },
  {
    field: "rarity_score",
    headerName: "Rarity",
    width: 80,
    hideable: false,
    display: "flex",
    renderCell: (cell) => <RarityIndicator value={cell.value} />,
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    hideable: true,
    renderCell: (cell) => <CellValue>{cell.value}</CellValue>,
  },
];

const useTableState = () => {
  const apiRef = useGridApiRef();
  const { selectedScenario } = useContext(ScenariosContext);
  const [storedColumns, setStoredColumns] = useState();
  const storeKey = `timeline-table-${selectedScenario?.id}`;

  const saveState = useCallback(() => {
    if (!apiRef.current.exportState) {
      return;
    }
    const data = JSON.stringify(apiRef.current.exportState());
    if (data === "{}") {
      return;
    }
    localStorage.setItem(storeKey, data);
  }, [apiRef, storeKey]);

  useLayoutEffect(() => {
    const dataFromStorage = localStorage.getItem(storeKey);
    setStoredColumns(dataFromStorage ? JSON.parse(dataFromStorage) : {});
    window.addEventListener("beforeunload", saveState());
    return () => {
      window.removeEventListener("beforeunload", saveState());
      saveState();
    };
  }, [saveState, storeKey]);

  return { storedColumns, apiRef };
};

const CustomFooter = () => {
  return (
    <GridFooterContainer>
      <GridToolbarDensitySelector />
      <GridPagination />
    </GridFooterContainer>
  );
};

const CellValue = ({ children }) => {
  const { ref, overflow } = useOverflowState();
  return (
    <HtmlTooltip title={children} disableHoverListener={!overflow}>
      <Typography
        variant="body2"
        ref={ref}
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {children}
      </Typography>
    </HtmlTooltip>
  );
};

export const TimelineDataGrid = ({
  items,
  presentFields,
  actionKeys,
  isPending,
}) => {
  const { configuredFields, isFavoriteField } = useContext(
    TagsVisibilityContext
  );
  const { storedColumns, apiRef } = useTableState();
  const location = useLocation();
  const showOnlyFavoriteFields =
    !!location?.state?.action || !!location?.state?.summaryFields;
  const propertyFields = location?.state?.propertyFields;
  const summaryFields = location?.state?.summaryFields;

  const dynamicCols = useMemo(
    () =>
      configuredFields.map((field) => {
        return {
          field,
          headerName: field,
          headerClassName: actionKeys?.has(field) ? "action" : "annotation",
          renderCell: (cell) => {
            const ip = isIp(cell?.value);
            return (
              <CellValue>
                {ip ? (
                  <IpAddressLink ip={ip} value={cell?.value} />
                ) : (
                  cell.value
                )}
              </CellValue>
            );
          },
        };
      }),
    [actionKeys, configuredFields]
  );

  const columns = useMemo(() => {
    if (!storedColumns) {
      return;
    }
    const allCols = staticCols.concat(dynamicCols).map((col) => ({
      ...(storedColumns?.columns?.dimensions?.[col.field] ?? {}),
      ...col,
    }));
    const order = storedColumns?.columns?.orderedFields;
    if (!order) {
      return allCols;
    }
    return allCols.sort(
      (a, b) => order.indexOf(a.field) - order.indexOf(b.field)
    );
  }, [dynamicCols, storedColumns]);

  const initialColumns = useMemo(() => {
    let columnVisibilityModel = {};
    if (showOnlyFavoriteFields) {
      for (const { field } of dynamicCols) {
        const hasFav = isFavoriteField(field);
        const hasProp = !!propertyFields?.includes(field);
        const onSummary = !!summaryFields?.includes(field);
        columnVisibilityModel[field] = hasFav || hasProp || onSummary;
      }
    } else {
      for (const { field } of dynamicCols) {
        columnVisibilityModel[field] = presentFields.has(field);
      }
    }
    for (const { field } of staticCols) {
      columnVisibilityModel[field] = true;
    }

    return {
      columnVisibilityModel,
    };
  }, [
    presentFields,
    showOnlyFavoriteFields,
    dynamicCols,
    isFavoriteField,
    propertyFields,
    summaryFields,
  ]);

  const rows = useMemo(() => {
    return items.map((i) => {
      const row = i.tokens_filtered.reduce((acc, r) => {
        acc[r.key] = r.value[0];
        return acc;
      }, {});
      row.id = i.id;
      row.sensitivity = i.sensitivity;
      row.rarity_score = i.rarity_score;
      row.time = i.time;
      row.description = i.description.replace(/\.$|\t/g, "");
      row.match_info = i.match_info.match;
      return row;
    });
  }, [items]);

  if (!storedColumns || !columns) {
    return <PageLoadingSpinner />;
  }

  return (
    <Box sx={{ position: "relative", width: "100%", overflowX: "scroll" }}>
      <DataGridPro
        key={isPending}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "700",
            textTransform: "capitalize",
          },
          "& .action": {
            color: "steelblue",
          },
          "& .MuiDataGrid-cell": {
            alignItems: "center",
            display: "flex",
            padding: "10px 10px",
          },
        }}
        slotProps={{
          panel: {
            sx: {
              "& .MuiDataGrid-panelWrapper": {
                maxHeight: "30vh",
              },
            },
          },
        }}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        getRowHeight={({ densityFactor }) => {
          if (densityFactor === 1.3) {
            return "auto";
          }
          return densityFactor * 60;
        }}
        initialState={{
          ...storedColumns,
          columns: {
            ...storedColumns?.columns,
            columnVisibilityModel: {
              ...initialColumns,
              ...storedColumns?.columns?.columnVisibilityModel,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        loading={isPending}
        pageSizeOptions={[100]}
        disableColumnFilter
        localeText={{
          toolbarDensityComfortable: "Wrap", // Custom label for comfortable
        }}
        pagination
        slots={{
          footer: CustomFooter,
        }}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
