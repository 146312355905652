import { useContext, useEffect, useMemo, useState } from "react";

import { ROLES } from "@tiq/shared/lib/constants";

import { LoginStatusContext } from "contexts/LoginStatusContext";

import { useCurrentUserSettings } from "./currentUserSettings";
import { useRoles } from "./useRoles";

const homepageOptions = [
  { route: "analytics", name: "Analytics" },
  { route: "scenarios", name: "Scenarios" },
  { route: "settings", name: "Collectors" },
];

const defaultHomepage = homepageOptions[0].route;
const accountAdminHomepage = "settings";

export const useHomepageConfig = () => {
  const { loading } = useContext(LoginStatusContext);

  const [homepage, setHomepage] = useCurrentUserSettings(
    "homepage",
    defaultHomepage
  );
  const [homepageLoading, setHomepageLoading] = useState(true);
  const { isRole } = useRoles();

  const isAccountAdmin = isRole(ROLES.ACCOUNT_ADMIN);
  const isAccountViewer = isRole(ROLES.VIEWER);
  const isAccountAnalyst = isRole(ROLES.ANALYST);

  const resolvedHomepage = useMemo(() => {
    if (isAccountAdmin) {
      return accountAdminHomepage;
    }

    if (isAccountViewer || isAccountAnalyst) {
      if (homepage === "settings") {
        return defaultHomepage;
      }
    }

    return homepage;
  }, [homepage, isAccountAdmin, isAccountViewer, isAccountAnalyst]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setHomepage((homepage) => {
      const homepageValid = homepageOptions.find(
        (item) => item.route === homepage
      );

      if (!homepageValid) {
        return defaultHomepage;
      }

      return homepage;
    });
    setHomepageLoading(false);
  }, [loading, setHomepage]);

  return {
    homepageOptions,
    homepage: resolvedHomepage,
    setHomepage,
    homepageLoading,
  };
};
