import { useEffect, useMemo, useRef } from "react";

import { styled } from "@mui/material";
import { Chart, registerables } from "chart.js";

import { diagonalPattern, steelBlue } from "utils/colorPatterns";
import { sessionsHistogramParser } from "utils/sessionsHistogramParser";

Chart.register(...registerables);

const StyledChartWrapper = styled("div")(
  () => `
  height: 100%;
  overflow: hidden;
`
);

const ProfilePictureChart = ({
  histogram,
  onBarClicked,
  selectedSession,
  sessionType,
}) => {
  const canvasRef = useRef();
  const chart = useRef();

  const sessionsCut = useMemo(
    () => sessionsHistogramParser(histogram, selectedSession),
    [histogram, selectedSession]
  );

  useEffect(() => {
    const barColor = sessionType === "nearest" ? diagonalPattern() : steelBlue;
    chart.current = new Chart(canvasRef.current, {
      type: "polarArea",
      options: {
        scales: {
          r: {
            min: -0.2,
            suggestedMax: 0.7,
            ticks: {
              callback: (val) => (val < 0 ? "" : val),
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: {
          duration: 500,
          animateRotate: false,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            events: ["click", "mousemove"],
          },
        },
        onClick: (e) => {
          const points = chart.current.getElementsAtEventForMode(
            e,
            "nearest",
            { intersect: true },
            true
          );
          if (!points || points.length === 0) {
            return;
          }
          const sid = sessionsCut[points[0].index]?.x;
          if (!sid) {
            return;
          }
          setTimeout(() => onBarClicked?.(sid), 10);
        },
        onHover: (e, element) => {
          e.native.target.style.cursor = element[0] ? "pointer" : "default";
        },
      },
      data: {
        labels: sessionsCut.map((d) => d.x),
        datasets: [
          {
            label: "Distance",
            backgroundColor: selectedSession
              ? sessionsCut.map((e) =>
                  e.x === selectedSession ? "orange" : barColor
                )
              : [barColor],
            data: sessionsCut.map((d) => d.y),
          },
        ],
      },
    });
    return () => {
      chart.current && chart.current.destroy();
    };
  }, [sessionType, sessionsCut, onBarClicked, selectedSession]);

  return (
    <StyledChartWrapper>
      <canvas ref={canvasRef} />
    </StyledChartWrapper>
  );
};

export default ProfilePictureChart;
