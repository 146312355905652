import { useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useStore } from "store";

import { useJobsQuery } from "api/scheduler";

import { LoginStatusContext } from "contexts/LoginStatusContext";

import { useCapabilities } from "./useCapabilities";
import { useDataExplorationState } from "./useScenarioJobState";

export const useJobPolling = () => {
  const { user } = useContext(LoginStatusContext);
  const { isDataExplorationJobActive } = useDataExplorationState();
  const setJobs = useStore((state) => state.setJobs);
  const history = useHistory();
  const caps = useCapabilities();

  const jobPollingAllowed =
    caps({ "scheduler.jobs": { read: true } }) &&
    !!user?.id &&
    !user?.super_user;

  const { data: jobs } = useJobsQuery({
    enabled: jobPollingAllowed,
    refetchInterval: isDataExplorationJobActive ? 5_000 : 30_000,
  });

  useEffect(() => {
    if (jobs?.auth_url) {
      history.push("/?expired=1");
    }
  }, [history, jobs?.auth_url]);

  useEffect(() => {
    setJobs(jobs);
  }, [jobs, setJobs]);
};
