import { useMemo, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";

import DataLink from "components/ui/DataLink";
import HtmlTooltip from "components/ui/HtmlTooltip";
import RiskIndicator from "components/ui/RiskIndicator";
import { StdSwitch } from "components/ui/StdSwitch";
import TableCellText from "components/ui/TableCellText";

import { timeFormatter } from "utils/time-fmt";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { useSessionDetails } from "./SessionDetailsContext";

const StyledRiskHistoryContent = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "3em",
  gridTemplateRows: "2em min-content 1fr",
  height: "calc(100vh - 270px)",
  overflow: "hidden",
  padding: "2em",
  width: "100%",
  "& .toggleContainerWrapper": {
    position: "relative",
    width: "100%",
    "& .toggleContainer": {
      position: "relative",
      "& .toggle": {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        right: theme.spacing(13),
        "& > *": {
          padding: theme.spacing(0.5),
        },
      },
    },
  },
  "& .tableWrapper": {
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 357px)",

    "& .infoTimeContainer": {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      textAlign: "left",
    },
    "& .infoHidden": {
      display: "none",
      width: "10%",
    },
    "& .infoVisible": {
      display: "flex",
      width: "10%",
    },
    "& .time": {
      display: "flex",
      width: "90%",
    },

    "& table": {
      borderCollapse: "collapse",
      left: "0",
      position: "absolute",
      tableLayout: "fixed",
      top: "0",
      width: "100%",
      "td,th": {
        border: "none",
        padding: "10px 10px",
        textAlign: "left",
      },
      "& thead": {
        tr: {
          th: {
            backgroundColor: `${theme.palette.sidebar.light}`,
            color: `${theme.palette.purple.dark}`,
            position: "sticky",
            textTransform: "capitalize",
            top: "0",
            zIndex: "10",
          },
        },
      },
      "& tbody": {
        tr: {
          td: {
            borderBottom: "1px solid #ddd",
          },
        },
      },
    },
  },
}));

const RiskHistory = ({ scenario }) => {
  const strings = useLocalizedStrings();
  const [debugModeEnabled, setDebug] = useState(false);
  const { data: sessionInfo } = useSessionDetails();

  const riskHistory = useMemo(() => {
    const sortedRiskHistory = sessionInfo?.risk_history?.toSorted(
      (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()
    );
    return debugModeEnabled
      ? sortedRiskHistory
      : sortedRiskHistory?.filter(
          (risk_history_element, index, risk_history) => {
            const index_of_first = risk_history.findIndex(
              (obj) => obj.run_id === risk_history_element.run_id
            );
            return !risk_history_element.run_id || index_of_first === index;
          }
        );
  }, [sessionInfo, debugModeEnabled]);

  if (!sessionInfo) {
    return null;
  }

  const handleDebug = (_e, debugState) => {
    setDebug(!debugState);
  };

  return (
    <StyledRiskHistoryContent>
      <div className="toggleContainerWrapper">
        <h2>{strings.sessiondetails_risk_history_title}</h2>
        <div className="toggleContainer">
          <div className="toggle">
            <StdSwitch
              beforeLabel="ON"
              afterLabel="OFF"
              checked={debugModeEnabled}
              onChange={(e) => handleDebug(e, debugModeEnabled)}
              disabled={false}
              label={
                strings.sessiondetails_risk_history_debug_mode_toggle_label
              }
              labelPlacement="start"
            />
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableCellText variant="body1">
                    {strings.sessiondetails_risk_history_column_time}
                  </TableCellText>
                </TableCell>
                <TableCell>
                  <TableCellText variant="body1">
                    {strings.sessiondetails_risk_history_column_risk_score}
                  </TableCellText>
                </TableCell>
                <TableCell>
                  <TableCellText variant="body1">
                    {strings.profile}
                  </TableCellText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {riskHistory?.map((param) => (
                <TableRow key={param.time}>
                  <TableCell>
                    <div className="infoTimeContainer">
                      <div
                        className={
                          debugModeEnabled ? "infoVisible" : "infoHidden"
                        }
                      >
                        <HtmlTooltip
                          title={
                            <div>
                              <p>
                                {strings.formatString(
                                  strings.sessiondetails_risk_history_debug_mode_tooltip_run_id_info,
                                  param.run_id ?? "N/A"
                                )}
                              </p>
                              <p>
                                {strings.formatString(
                                  strings.sessiondetails_risk_history_debug_mode_tooltip_job_info,
                                  param.job ?? "N/A"
                                )}
                              </p>
                              <p>
                                {strings.formatString(
                                  strings.sessiondetails_risk_history_debug_mode_tooltip_reevaluated_info,
                                  param.reevaluated ?? "N/A"
                                )}
                              </p>
                            </div>
                          }
                        >
                          <InfoIcon></InfoIcon>
                        </HtmlTooltip>
                      </div>
                      <div className="time">
                        <span>{timeFormatter(new Date(param.time))}</span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <RiskIndicator value={param.risk * 100} />
                  </TableCell>
                  <TableCell>
                    {param.cluster > 0 ? (
                      <DataLink
                        to={`/scenarios/${scenario}/profiles?id=${sessionInfo.cluster}`}
                      >
                        #{param.cluster}
                      </DataLink>
                    ) : (
                      <span>{"Unprofiled"}</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </StyledRiskHistoryContent>
  );
};

export default RiskHistory;
