import { useCallback, useState } from "react";
import { cloneElement } from "react";

import { CircularProgress } from "@mui/material";

import { baseUrl } from "utils/baseUrl";

import { HTTPError } from "hooks/http";
import { useMessages } from "hooks/useMessage";

const RemoteDownloader = ({ url, filename, options, disabled, children }) => {
  const { pushMessage } = useMessages();

  const [loadingState, setLoadingState] = useState(false);

  const downloadDisabled = disabled || loadingState;

  const handleClick = useCallback(() => {
    setLoadingState(true);
    let mime;
    let objUrl;

    const opt = {
      method: "GET",
      credentials: "include",
      ...options,
    };

    fetch(baseUrl() + url, opt)
      .then(async (result) => {
        if (!result.ok) {
          throw new HTTPError(result, await result.json());
        }
        mime = result.headers.get("content-type");
        return result.blob();
      })
      .then((blob) => {
        objUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.id = url;
        a.href = objUrl;
        a.download = filename;
        a.type = mime;
        a.click();
        setTimeout(() => a.remove(), 1000);
      })
      .catch((error) => {
        pushMessage("error", error.message);
      })
      .finally(() => {
        if (objUrl) {
          URL.revokeObjectURL(objUrl);
        }
        setLoadingState(false);
      });
  }, [options, url, filename, pushMessage]);

  return (
    <div onClick={handleClick} disabled={downloadDisabled}>
      {cloneElement(children, {
        disabled: downloadDisabled,
        children: (
          <>
            {children?.props?.children}
            {loadingState && (
              <CircularProgress
                size={24}
                sx={{ color: "purple.main", position: "absolute" }}
              />
            )}
          </>
        ),
      })}
    </div>
  );
};

export default RemoteDownloader;
