import { Checkbox, styled } from "@mui/material";

const BrandedCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
}));

export default BrandedCheckbox;
