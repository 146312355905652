import React, { useMemo } from "react";

import PublishIcon from "@mui/icons-material/Publish";
import { styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TableFooterLoadingMessage from "./TableFooterLoadingMessage";
import { SortHeader } from "./tables/SortHeader";

const DefaultDataRow = React.forwardRef((props, ref) => {
  const { columns, item, ...rest } = props;
  return (
    <TableRow id={item.id} hover={true} ref={ref}>
      {columns
        .filter((c) => c.visible !== false)
        .map((c) => (
          <TableCell
            key={`${item.id}_${c.id}`}
            id={`${item.id}_${c.id}`}
            style={c.style}
          >
            {c.render(item, rest)}
          </TableCell>
        ))}
    </TableRow>
  );
});

const StyledDataViewContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",

  "& .tableContainer": {
    position: "relative",
    flexGrow: "1",
    flexBasis: "min-content",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.paper,
    overflowY: "auto",
    borderTop: "1px solid #ddd",
    "& table": {
      position: "absolute",
      tableLayout: "fixed",
      "&.debug-sizes .MuiTableCell-root": {
        border: "1px solid LightPink",
      },
      "& .MuiTableHead-root .MuiTableCell-root": {
        backgroundColor: theme.palette.primary.contastText,
        color: theme.palette.purple.dark,
        padding: `${theme.spacing(0.3)} ${theme.spacing(1)}`,
        "& *": {
          fontWeight: "bold",
          textTransform: "capitalize",
          userSelect: "none",
        },
        "& .MuiTableSortLabel-icon": {
          fontSize: "2rem",
          fill: "tomato",
        },
      },
      "& .MuiTableBody-root td": {
        color: theme.palette.table.main,
        padding: "8px",
        overflow: "hidden",
      },
    },
    "& .MuiTableSortLabel-root": {
      backgroundColor: "inherit",
      color: "inherit",
    },
    "& .MuiTableSortLabel-icon": {
      margin: 0,
    },
  },

  "& .footer": {
    position: "relative",
    display: "flex",
    flexBasis: "min-content",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5em 0 0.5em 0.5em",
    fontFamily: "inherit",
    fontSize: "inherit",
    userSelect: "none",
    "& .MuiButtonBase-root": {
      backgroundColor: "transparent",
      opacity: 0.6,
      transition: "opacity 200ms ease",
      "& .MuiIconButton-label": {
        padding: "4px",
      },
      "&::hover": {
        opacity: 1,
      },
    },
  },
}));

const DataTableINF = React.forwardRef((props, ref) => {
  const {
    columns,
    items = [],
    itemsCount,
    orderBy = {},
    onOrderBy = () => {},
    RowComponent = DefaultDataRow,
    isPending,
    noheader,
    noCount,
    perPageCount,
    showTotal,
    footerDetails,
    ...rest
  } = props;

  const handleScrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  };

  const count = useMemo(() => {
    const f = new Intl.NumberFormat("en-US");
    const page = f.format(items?.length ?? 0);
    const total = f.format(itemsCount ?? 0);
    if (noCount) {
      return "";
    }
    if (perPageCount) {
      return `${page}/${total} items`;
    }
    if (showTotal) {
      return `Total: ${total} results`;
    }
    return `Displaying ${page} results`;
  }, [noCount, showTotal, perPageCount, items?.length, itemsCount]);

  return (
    <StyledDataViewContainer>
      <TableContainer className="tableContainer" ref={ref}>
        <Table stickyHeader aria-label="sticky table">
          <colgroup>
            {columns
              .filter((c) => c.visible !== false)
              .map((c) => (
                <col
                  key={c.id}
                  style={{
                    ...c.style,
                    width: c.style?.width || "unset",
                  }}
                />
              ))}
          </colgroup>

          {noheader ? null : (
            <TableHead>
              <TableRow>
                {columns
                  .filter((c) => c.visible !== false)
                  .map((c) => (
                    <SortHeader
                      key={c.id}
                      header={c}
                      orderBy={orderBy}
                      onOrderBy={onOrderBy}
                    />
                  ))}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {items?.map((d) => (
              <RowComponent
                columns={columns}
                key={d.id}
                data-id={d.id}
                item={d}
                {...rest}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="footer">
        <div>
          {count}
          {footerDetails}
        </div>
        <IconButton
          size="small"
          onClick={handleScrollToTop}
          title="Go To Top"
          color="primary"
        >
          <PublishIcon />
        </IconButton>
      </div>

      {isPending && <TableFooterLoadingMessage />}
    </StyledDataViewContainer>
  );
});

export default DataTableINF;
