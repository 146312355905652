import { memo, useCallback, useRef, useState } from "react";

import { Paper, TextField, styled } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useFormContext } from "react-hook-form";

import { useDataAnalysisColumns } from "hooks/useDataAnalysisColumns";
import { useInView } from "hooks/useInView";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useOutputFields } from "hooks/useOutputFields";

const StyledColumnSelector = styled(Autocomplete)(() => ({
  marginBottom: "10px !important",
  "& fieldset": {
    marginBottom: "0",
    height: "100%",
  },
  "& input": {
    height: "38px",
  },
  "& .MuiFormHelperText-contained": {
    marginTop: "0px",
  },
  "& .MuiInputBase-root": {
    width: "20em",
  },
}));

const ColumnSelectorDropdown = memo((props) => (
  <Paper style={{ width: "30em", marginBottom: "10px" }} {...props} />
));

export const ColumnSelector = ({
  name,
  defaultValue = "",
  outputField = null,
  disabled,
}) => {
  const strings = useLocalizedStrings();

  const { columns } = useDataAnalysisColumns();
  const [filter, setFilter] = useState(defaultValue);
  const prevVal = useRef("");
  const { setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const { inView, target } = useInView({
    toggleOnHidden: true,
    rootMargin: "40px 100% 20px",
  });
  const { setOutputFields } = useOutputFields(name);

  const updateOutput = useCallback(
    (value) => {
      if (!outputField) {
        return;
      }
      setOutputFields(outputField, value ?? "");
      setValue(outputField, value ?? "", { shouldValidate: true });
    },
    [outputField, setOutputFields, setValue]
  );

  const handleFocus = useCallback((e) => {
    prevVal.current = e.target.value;
  }, []);

  const handleBlur = useCallback(
    (e) => {
      if (e.target.value === prevVal.current) {
        return;
      }
      updateOutput(e.target.value);
    },
    [updateOutput]
  );

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      rules={{
        pattern: /^[a-zA-Z0-9_. ]([a-zA-Z0-9_. ])*$/,
        required: true,
      }}
      render={({ field, fieldState }) => (
        <StyledColumnSelector
          {...field}
          freeSolo
          ListboxProps={{ style: { maxHeight: "22em" } }}
          open={inView && open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          PaperComponent={ColumnSelectorDropdown}
          disabled={disabled}
          options={columns}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(_, v) => {
            field.onChange(v);
            setFilter(v);
            updateOutput(v);
          }}
          filterOptions={(opts) => {
            return filter
              ? opts.filter((op) =>
                  op.toLowerCase().includes(filter.toLowerCase())
                )
              : opts;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              ref={target}
              onChange={(e) => {
                const val = e.target.value;
                field.onChange(val);
                setFilter(val);
              }}
              error={!!fieldState.error}
              helperText={!!fieldState.error ? strings.invalid_input : ""}
              label={
                strings.sourcedialog_mapping_editor_mapping_tab_label_input_field
              }
              variant="outlined"
            />
          )}
        />
      )}
    />
  );
};
