import React from "react";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, styled } from "@mui/material";
import { DebounceInput } from "react-debounce-input";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledTextSearchInput = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "#fff",
  border: "1px solid #ddd",
  display: "flex",
  flexGrow: "1",
  flexShrink: "1",
  gap: "5px",
  outline: "none",
  overflow: "hidden",
  padding: "0 5px",
  transition: "all 150ms ease-in-out",
  "&:disabled, &[disabled]": {
    opacity: 0.4,
  },
  "& > .search-input": {
    backgroundColor: "transparent",
    border: 0,
    color: theme.palette.purple.dark,
    flexGrow: "1",
    flexShrink: "1",
    fontFamily: "inherit",
    fontSize: "1rem",
    minWidth: 0,
    outline: "none",
    padding: ".55em 0",
    width: "100%",
    "&::placeholder": {
      color: theme.palette.purple.dark,
      opacity: 0.5,
    },
  },
  "& .MuiButtonBase-root": {
    backgroundColor: "transparent",
    color: theme.palette.purple.dark,
    flexShrink: "0",
  },
  "& .search-icon": {
    flexShrink: "0",
    opacity: 0.5,
  },
}));

function TextSearchInput(props) {
  const strings = useLocalizedStrings();
  const {
    searchPattern,
    setSearchPattern,
    placeholder,
    timeout = 300,
    disabled,
    className = "",
    style,
  } = props;

  return (
    <StyledTextSearchInput
      className={`text-search ${className}`}
      style={style}
      disabled={disabled}
    >
      <SearchIcon className="search-icon" />
      <DebounceInput
        className="search-input"
        value={searchPattern || ""}
        onChange={(e) => setSearchPattern(e.target.value)}
        debounceTimeout={timeout || 300}
        placeholder={
          placeholder || `${strings.textsearch_default_placeholder}...`
        }
        spellCheck="false"
      />
      <IconButton
        className="clear-button"
        size="small"
        onClick={() => setSearchPattern("")}
        title={strings.textsearch_clear_tooltip}
      >
        <ClearIcon />
      </IconButton>
    </StyledTextSearchInput>
  );
}

export default TextSearchInput;
