import { createTheme } from "@mui/material";

//eslint-disable-next-line
import "../index.css";

export const orangePrimary = "rgba(255,163,90,1)";
export const orangeSecondary = "rgba(255, 186, 6, 1)";
export const greenLight = "#71C572";
export const redPrimary = "#D64545";
export const greyLight = "#F0EFF0";
export const greyDark = "#777182";

const purpleDark = "#1A0C2B";

const reveal = {
  primary: {
    purple: "#342C83",
    purpleBright: "#5D50EC",
    charcoal: "#222",
  },
  secondary: {
    main: "#CD5C5C",
    contrastText: "#fff",
    purple: "#5859A8",
    purple50: "rgba(88, 89, 168, 0.5)",
    purple25: "rgba(88, 89, 168, 0.25)",
    purple10: "rgba(88, 89, 168, 0.1)",
    gray: "#9C9AAA",
    white: "#EBEBF3",
  },
  accent: {
    blue: "#4BBEEF",
    orange: "#EF724B",
    orangeLight: "#f9c7b7",
  },
};

//TODO
//Check usage of all colors

const theme = createTheme({
  palette: {
    primary: {
      main: "#5e6572", //used in mapping editor buttons
      purple: reveal.primary.purple,
      bright: reveal.primary.purpleBright,
      dark: reveal.primary.charcoal,
      charcoal: reveal.primary.charcoal,
      contrastText: "#fff",
    },
    secondary: reveal.secondary,
    accent: {
      orange: reveal.accent.orange,
      blue: reveal.accent.blue,
    },
    button: {
      primary: {
        main: reveal.accent.orange,
        hover: "#ea4815",
        disabled: reveal.accent.orangeLight,
        contrastText: "#fff",
      },
      secondary: {
        main: "#D2D0D4",
        hover: "#A39FAC",
        hoverLight: "#D2D0D4",
        disabled: "#D2D0D4",
        contrastText: "#1A0C2B",
      },
      danger: {
        main: redPrimary,
        hover: "#9B2020",
        disabled: "#d645459c",
        contrastText: "#FFFFFF",
      },
    },
    purple: {
      main: "#342C83",
      light: "#BEBCDE",
      light2: "#B0B0FF",
      dark: "#1A0C2B",
      lighterDark: "#361E7E",
      contrastText: "#fff",
      gradient: "linear-gradient(105deg, #774BFF 32.32%, #1A0C2B 124.11%)",
    },
    green: {
      light: greenLight,
      main: "#0E8A32",
    },
    grey: {
      dark: greyDark,
      light: greyLight,
      main: "#A39FAC",
    },
    error: {
      main: "#d32f2f",
    },
    scenarioThumbnail: {
      main: "#6f6f6f",
    },
    table: {
      main: "#555",
    },
    sidebar: {
      main: "#D5D4EA",
      light: "#EBEAF5",
    },
    warning: {
      main: "#ffaa00",
    },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiButtonGroup: {
      disableElevation: true,
      disableRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiTooltip: {
      enterDelay: 500,
      enterNextDelay: 500,
    },
  },
  typography: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          cursor: "default",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&:not(.Mui-disabled)": {
            color: purpleDark,
            "&.Mui-checked": {
              color: purpleDark,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.26,
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          paddingBottom: "1px",
          paddingTop: "1px",
          paddingLeft: "1px",
          paddingRight: "1px",
          margin: 1,
          color: purpleDark,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none !important",
          minWidth: "unset",
        },
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            padding: "6px 16px",
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: "8px 24px",
          },
        },
        {
          props: { size: "large" },
          style: {
            padding: "12px 28px",
          },
        },
      ],
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          minWidth: "150px",
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
  },
});

export default theme;
