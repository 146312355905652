import { useEffect } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useBackendEvents from "hooks/useBackendEvents";

import { queryFetch } from "./query";

const baseUrl = "/api/acm/v1/api_keys";
const baseKey = ["api_keys"];
const apiKeyKeys = {
  list: () => [baseKey, "list"],
};

const getApiKeys = () => queryFetch(baseUrl);
export const useApiKeysQuery = (opts) => {
  const queryClient = useQueryClient();
  const [event] = useBackendEvents(baseKey);

  useEffect(() => {
    event && queryClient.invalidateQueries({ queryKey: apiKeyKeys.list() });
  }, [event, queryClient]);

  return useQuery({
    queryKey: apiKeyKeys.list(),
    queryFn: getApiKeys,
    ...opts,
  });
};

const createApiKey = ({ name, expires }) =>
  queryFetch({
    url: baseUrl,
    options: {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        name,
        expires,
      }),
    },
  });
export const useApiKeysCreateMutation = () => {
  return useMutation({
    mutationFn: createApiKey,
  });
};

const deleteApiKey = ({ id }) => {
  const opts = {
    url: `${baseUrl}/key/${id}`,
    options: {
      method: "DELETE",
    },
  };
  return queryFetch(opts);
};
export const useApiKeysDeleteMutation = () => {
  return useMutation({
    mutationFn: deleteApiKey,
  });
};
