import { DeleteButton } from "components/ui/DeleteButton";
import { HourSelector } from "components/ui/form/HourSelector";
import { TextFieldInput } from "components/ui/form/TextFieldInput";
import { WeekdaySelector } from "components/ui/form/WeekdaySelector";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const ConditionInput = ({ watchedTypeValue, namePrefix, strings, message }) => {
  if (watchedTypeValue === "time_day") {
    return (
      <WeekdaySelector
        name={`${namePrefix}.condition`}
        label={
          strings.sourcedialog_mapping_editor_bucketing_tab_label_condition
        }
        required={true}
        style={{ width: "25em", height: "56px" }}
      />
    );
  }

  if (watchedTypeValue === "time_hours") {
    return <HourSelector name={`${namePrefix}.condition`} />;
  }

  return (
    <TextFieldInput
      name={`${namePrefix}.condition`}
      rules={{
        pattern: {
          value:
            watchedTypeValue === "numeric"
              ? /^-?(0|[1-9]\d*)(\.\d+)?$/
              : /(?!\s*$).+/, // if watchedTypeValue is string, regex doesn't allow value to be empty or whitespace values ('  ')
          message,
        },
        validate: (val) => !!val?.length || message, //without this, rhf uses previous fields validate function
      }}
      required={true}
      style={{ width: "25em" }}
      label={strings.sourcedialog_mapping_editor_bucketing_tab_label_condition}
    />
  );
};

export const LabelRules = ({
  handleDelete,
  item,
  namePrefix,
  watchedTypeValue,
}) => {
  const strings = useLocalizedStrings();
  const bucketingTypesConditionLabels = {
    string:
      strings.sourcedialog_mapping_editor_bucketing_tab_label_condition_type_string,
    time_day:
      strings.sourcedialog_mapping_editor_bucketing_tab_label_condition_type_time_day,
    time_hours:
      strings.sourcedialog_mapping_editor_bucketing_tab_label_condition_type_time_hours,
    string_contains:
      strings.sourcedialog_mapping_editor_bucketing_tab_label_condition_type_string_contains,
    numeric:
      strings.sourcedialog_mapping_editor_bucketing_tab_label_condition_type_numeric,
  };
  const message = strings.invalid_input;
  const conditionLabel = bucketingTypesConditionLabels[watchedTypeValue];

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        gap: "1em",
        paddingBottom: "1em",
      }}
      key={item.id}
    >
      <TextFieldInput
        name={`${namePrefix}.label`}
        required={true}
        style={{ width: "25em" }}
        label={strings.sourcedialog_mapping_editor_bucketing_tab_label_label}
      />

      <span style={{ paddingBottom: "20px" }}>{conditionLabel}</span>

      <ConditionInput
        namePrefix={namePrefix}
        watchedTypeValue={watchedTypeValue}
        strings={strings}
        message={message}
      />

      <DeleteButton style={{ paddingBottom: "20px" }} onClick={handleDelete} />
    </div>
  );
};
