import { useCallback, useEffect, useMemo, useState } from "react";

export const useExpanded = (fieldsLength) => {
  const [expandedState, setExpandedState] = useState(false);
  const isExpandingDisabled = useMemo(
    () => (fieldsLength > 1 ? false : true),
    [fieldsLength]
  );

  const handleExpand = useCallback(() => {
    setExpandedState((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isExpandingDisabled) {
      setExpandedState(false);
    }
  }, [isExpandingDisabled]);

  return {
    expandedState,
    isExpandingDisabled,
    handleExpand,
  };
};
