module.exports.DISPLAY_MODES = {
  INSIGHTS_ONLY: "insights_only",
  ALWAYS: "always",
  NEVER: "never",
};

module.exports.ROLES = {
  ACCOUNT_ADMIN: "ACCOUNT_ADMIN",
  ADMIN: "ADMIN",
  ANALYST: "ANALYST",
  VIEWER: "VIEWER",
};
