import { createRef, useEffect, useMemo, useRef, useState } from "react";

import { Badge } from "@mui/material";
import { styled } from "@mui/material";

const StyledMappingEditorTab = styled("div")(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
  justifyContent: "center",
  letterSpacing: 2,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  textDecoration: "none",
  textTransform: "uppercase",
  transition: "all 200ms ease",
  userSelect: "none",
  "&:hover, &:active, &.active": {
    color: theme.palette.text.primary,
  },
  "&.activeTab": {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: "5px",
    color: "white",
    textShadow: "0px 0px 1px white",
  },
}));

const MappingEditorTabs = ({ defaultIndex, tabsConfig, isActive }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex ?? 0);
  const [, refresh] = useState(0);
  const numberOfErrorFields = useRef([0, 0, 0, 0]);

  const tabRefs = useMemo(() => {
    const refs = {};
    tabsConfig.forEach((_, index) => {
      refs[index] = createRef(null);
    });
    return refs;
  }, [tabsConfig]);

  useEffect(() => {
    Object.values(tabRefs).forEach((ref, index) => {
      const errorFieldsNumber = ref.current?.querySelectorAll(
        ".Mui-error.MuiInputBase-formControl,.customError"
      )?.length;

      if (errorFieldsNumber !== numberOfErrorFields.current[index]) {
        numberOfErrorFields.current[index] = errorFieldsNumber;
        refresh((prev) => prev + 1);
      }
    });
  });

  const handleClick = (index) => setSelectedIndex(index);

  return (
    <div
      style={{
        display: isActive ? "block" : "none",
      }}
    >
      <div style={{ display: "flex", gap: "0.5em" }}>
        {tabsConfig.map((item, index) => {
          return (
            <div
              style={{
                position: "relative",
              }}
              key={index}
            >
              <StyledMappingEditorTab
                className={selectedIndex === index ? "activeTab" : ""}
                onClick={() => handleClick(index)}
              >
                {item.label}
              </StyledMappingEditorTab>

              <div
                style={{ position: "absolute", top: "-10px", right: "15px" }}
              >
                {numberOfErrorFields.current[index] !== 0 && (
                  <Badge
                    badgeContent={numberOfErrorFields.current[index]}
                    color="error"
                    overlap="circular"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          height: "100%",
          marginTop: "20px",
          overflow: "auto",
          paddingRight: "5px",
        }}
      >
        {tabsConfig.map((tab, index) => (
          <div
            style={{ width: "100%", height: "auto" }}
            hidden={selectedIndex !== index}
            key={`tabpanel-${index}`}
            ref={tabRefs[index]}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MappingEditorTabs;
