import { styled } from "@mui/material";

const StyledFootnotes = styled("div")`
  font-family: inherit;
  counter-reset: notes;
  .footnote {
    font-style: italic;
    display: flex;
    align-items: flex-start;
    gap: 0.3em;
    opacity: 0.65;
    &:before {
      content: counter(notes) ")";
      counter-increment: notes;
      font-size: 0.5rem;
    }
  }
`;

const Footnotes = (props) => {
  const { notes, ...rest } = props;
  return (
    <StyledFootnotes className="footnotes" {...rest}>
      {notes
        ?.filter((d) => !!d)
        .map((d, i) => (
          <div className="footnote" key={i}>
            <span className="footnote-text">{d}</span>
          </div>
        ))}
    </StyledFootnotes>
  );
};

export default Footnotes;
