import React from "react";

import { styled } from "@mui/material";

import { useSessionDetails } from "components/sessions/SessionDetailsContext";

import Anomaly from "./Anomaly";
import AnomalyInfo from "./AnomalyInfo";
import ProfileContext from "./ProfileContext";

const StyledAnomalyPage = styled("div")(({ hasProfile }) => ({
  display: `${hasProfile ? "grid" : ""}`,
  gap: "8px",
  gridTemplateColumns: "4fr 2fr",
  gridTemplateRows: "1fr 1fr",
  height: "100%",
  padding: "16px",
  "& > *": {
    margin: "0",
  },
  "& .MuiPaper-root": {
    height: "calc(100vh - var(--appbar-height) - 148px - 100px)",
    maxHeight: "40em",
    width: "unset",
  },
}));

export default function AnomalyPage({ scenario, session }) {
  const { data: sessionObject } = useSessionDetails();

  const hasProfile = sessionObject?.cluster > 0;

  return (
    <StyledAnomalyPage hasProfile={hasProfile}>
      <Anomaly
        scenario={scenario}
        sessionId={session}
        session={sessionObject}
      />
      {hasProfile && (
        <ProfileContext scenario={scenario} session={sessionObject} />
      )}
      {hasProfile && <AnomalyInfo session={sessionObject} />}
    </StyledAnomalyPage>
  );
}
