import { styled } from "@mui/material";

const StyledSectionErrorMessage = styled("div")(({ theme }) => ({
  color: theme.palette.error.main,
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

export const SectionErrorMessage = ({ children, ...props }) => {
  return (
    <StyledSectionErrorMessage {...props}>{children}</StyledSectionErrorMessage>
  );
};
