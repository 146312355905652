import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import { Link } from "react-router-dom";

import HtmlTooltip from "components/ui/HtmlTooltip";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

export const AlertStatusIcon = ({ scenario }) => {
  const strings = useLocalizedStrings();
  const caps = useCapabilities();

  return (
    <HtmlTooltip title={strings.sidebar_tooltip_alerts_muted}>
      {caps({ "acm.sources": { write: true } }) ? (
        <Link
          to={{
            pathname: `/scenarios/${scenario}/settings`,
            state: { alertersOpen: true },
          }}
        >
          <NotificationsOffOutlinedIcon
            size="small"
            style={{ fontSize: "1.1rem" }}
          />
        </Link>
      ) : (
        <NotificationsOffOutlinedIcon
          size="small"
          style={{ fontSize: "1.1rem" }}
        />
      )}
    </HtmlTooltip>
  );
};
