import { useContext, useEffect } from "react";

import { Box, TextField, styled } from "@mui/material";
import { Redirect } from "react-router-dom";

import { useVersionQuery } from "api/metadata";

import { LoginStatusContext } from "contexts/LoginStatusContext";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useHomepageConfig } from "hooks/useHomepageConfig";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import Messages from "./ui/Messages";
import { PageLoadingSpinner } from "./ui/PageLoadingSpinner";
import { UiPrimaryButton } from "./ui/StyledButtons";

const StyledLanding = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.primary.bright,
  backgroundImage: `url(${
    import.meta.env.BASE_URL
  }/large-cropped-favicon-purple.svg)`,
  backgroundSize: "150vh",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 0px bottom 90%",
  backgroundAttachment: "fixed",
  fontSize: "2em",
  height: "100%",
  justifyContent: "center",
  "& .dots": {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "60%",
  },
  "& .welcome-frame": {
    alignItems: "center",
    boxShadow: "-11px 12px 49px 1px rgba(0,0,0,0.4)",
    borderRadius: "26px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px",
    position: "relative",
    textTransform: "uppercase",
  },
  "& .welcome-bar": {
    alignItems: "center",
    background: theme.palette.secondary.white,
    borderRadius: "26px 26px 0px 0px ",
    display: "flex",
    height: "80px",
    justifyContent: "center",
    width: "100%",
  },
  "& .welcome-content": {
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "0px 0px 26px 26px ",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    minHeight: "300px",
    padding: "20px 32px",
    "& fieldset legend": {
      width: "0px",
    },
  },
  "& .landingLogo": {
    left: "50px",
    top: "50px",
    position: "absolute",
  },
}));

function Landing({ location }) {
  const { pushMessage } = useMessages();
  const { setUser, user, login, loading } = useContext(LoginStatusContext);
  const { data: version } = useVersionQuery();
  const strings = useLocalizedStrings();
  const [accountName, setAccountName] = useCurrentUserSettings(
    "account.name",
    ""
  );
  const { homepage, homepageLoading } = useHomepageConfig();

  useEffect(() => {
    if (!loading) {
      const query = new URLSearchParams(location.search);
      if (query.has("user_not_found")) {
        pushMessage("error", strings.landing_unregistered, true);
      }

      if (query.has("expired")) {
        pushMessage("warning", strings.landing_expired, true);
        setUser(null);
      }
    }
  }, [location, pushMessage, setUser, strings, loading]);

  if (loading || homepageLoading) {
    return <PageLoadingSpinner />;
  }

  if (user?.id) {
    return <Redirect to={`/${homepage}`} />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    login(accountName, location?.state?.u);
  };

  return (
    <StyledLanding>
      <img
        className="landingLogo"
        src={`${import.meta.env.BASE_URL}/reveal-logo-white.svg`}
        alt="RevealSecurity"
        height={100}
      />
      <Box className="welcome-frame">
        <div className="welcome-bar">
          <img
            className="welcome-icon"
            src={`${import.meta.env.BASE_URL}/favicon-dark-BG.svg`}
            alt="RevealSecurity"
            height={60}
          />
        </div>
        <form className="welcome-content" onSubmit={handleSubmit}>
          <div className="welcome">
            {strings.formatString(
              strings.landing_welcome,
              <strong>TrackerIQ</strong>,
              <strong>{version?.version || "..."}</strong>
            )}
          </div>
          <TextField
            autoComplete="off"
            name="accountName"
            onChange={(e) => setAccountName(e.target.value)}
            placeholder={strings.landing_input_placeholder}
            sx={{
              width: "400px",
              fontSize: "20px",
              "& ::placeholder": {
                textAlign: "center",
              },
            }}
            value={accountName}
          />
          <UiPrimaryButton
            style={{
              marginTop: "0em",
              fontSize: ".8em",
              fontWeight: "bold",
            }}
            type="submit"
          >
            {strings.landing_login}
          </UiPrimaryButton>
        </form>
      </Box>
      <Messages />
    </StyledLanding>
  );
}

export default Landing;
