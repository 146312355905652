import { useController } from "react-hook-form";

export const HiddenInput = ({ control, name, value, options }) => {
  const { field } = useController({
    control,
    name,
    defaultValue: value,
    ...options,
  });
  return <input type="hidden" value={value} {...field} />;
};
