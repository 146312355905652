import { forwardRef } from "react";

import { styled } from "@mui/material";

import { parseSensitivityColor } from "utils/parseSensitivityColor";

const StyledSensitivityIndicator = styled("div")(({ bkgColor }) => ({
  alignItems: "center",
  background: "transparent",
  border: `2px solid ${bkgColor}`,
  borderRadius: "50%",
  color: "black",
  display: "inline-flex",
  fontSize: "1em",
  height: "25px",
  justifyContent: "center",
  transition: "all 100ms ease-in-out",
  width: "25px",
  cursor: "default",
}));

const SensitivityIndicator = forwardRef((props, ref) => {
  const { value = 0, ...rest } = props;
  return (
    <StyledSensitivityIndicator
      ref={ref}
      bkgColor={parseSensitivityColor(value)}
      {...rest}
    >
      {value}
    </StyledSensitivityIndicator>
  );
});

export default SensitivityIndicator;
