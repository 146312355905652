import { useMutation, useQuery } from "@tanstack/react-query";

import API from "./apibase";
import { downloadResponse, queryFetch } from "./query";

export default class ReportsAPI extends API {
  static getReports() {
    return { url: `/api/acm/v1/reports` };
  }

  static getScenarioReportById(sid, rid) {
    return { url: `/api/analysis-model/v1/${sid}/reports/generic/${rid}` };
  }
}
const baseKey = ["reports"];
const reportKeys = {
  list: () => [baseKey, "list"],
};

export const useReportsGetQuery = () => {
  return useQuery({
    queryKey: reportKeys.list(),
    queryFn: () => queryFetch(ReportsAPI.getReports()),
  });
};

const fetchReport = ({ sid, rid, params }) => {
  const queryParams = new URLSearchParams(params).toString();
  const url = `${
    ReportsAPI.getScenarioReportById(sid, rid).url
  }?${queryParams}`;
  return queryFetch(url);
};

export const useDownloadReportQuery = () => {
  return useMutation({
    mutationFn: fetchReport,
    onSuccess: (data, report) => {
      downloadResponse({ data, type: "text/csv", fileName: report.name });
    },
  });
};
