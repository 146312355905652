import { useEffect, useMemo, useRef, useState } from "react";

import { ButtonGroup, Checkbox, FormControlLabel, styled } from "@mui/material";
import { Collapse } from "@mui/material";
import { Chart } from "chart.js";
import "chartjs-adapter-dayjs-4";
import { range } from "lodash-es";

import AuditAPI from "api/audit";

import useBillingInfo from "components/reports/billing/useBillingInfo";
import RemoteDownloader from "components/ui/RemoteDownloader";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import postfixedNumberFormatter from "utils/postfixedNumberFormatter";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledBillingPage = styled("div")`
  --max-content-width: 100em;
  --min-content-width: 50em;
  height: calc(100vh - 119px);
  display: grid;
  grid-template-rows: min-content 1fr;
  .report-Report-parameters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em 0.5em 0.5em;
    border-bottom: 1px solid #ddd;
    select {
      padding: 0.6rem 2rem;
      border: 1px solid #ddd;
      outline: none;
      margin: 0;
      cursor: pointer;
      &.year-selector {
        font-size: 1rem;
      }
    }
  }
  .current-status-wrapper {
    position: relative;
    /* overflow-y: auto;
		overflow-x: hidden; */
    overflow: auto;
    width: 100%;
    background-color: #fff;
    .current-status-body {
      max-width: 1600px;
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 1em;
      left: 0;
      top: 0;
      right: 0;
      bottom: unset;
      height: auto;
      padding: 2rem;
      .chart-options {
        display: flex;
        align-items: center;
        gap: 2em;
      }
      .scenarios-list {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .scenario-report {
        display: flex;
        flex-direction: column;
        &.left-marker {
          border-left: 3px solid #aaa;
          padding-left: 1em;
        }
        .scenario-report-title {
          &.clickable {
            cursor: pointer;
            .collapse-indicator {
              margin-left: -0.3em;
              color: #aaa;
              transition: transform 150ms ease;
              &:not(.collapsed) {
                transform: rotate(90deg);
              }
            }
          }
          .scenario-title-total {
            margin-left: auto;
          }
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          user-select: none;
          & > * {
            display: inline;
          }
          & > span:nth-of-type(2):not(:empty) {
            &:before {
              content: "(";
              margin-left: 1em;
            }
            &:after {
              content: ")";
            }
          }
          span.deleted {
            text-decoration-line: line-through;
            text-decoration-color: rgba(0, 0, 0, 0.4);
            text-decoration-thickness: 1px;
            opacity: 0.7;
          }
        }
        &:hover {
          .scenario-report-title {
            background-color: rgba(0, 0, 0, 0.03);
            /* font-weight: bold; */
          }
          &.left-marker {
            border-color: orangered;
          }
        }
        .scenario-report-body {
          margin-left: auto;
          display: flex;
          width: 50%;
          flex-direction: column;
        }
      }
      .table-wrapper {
        position: relative;
        overflow-y: auto;
        overflow-x: auto;
        min-height: 30em;
        border: 1px solid #ddd;
        border-radius: 3px;
        background-color: Ivory;
        margin-top: 5px;
        & > table {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      table {
        min-width: 100%;
        border: 0;
        border-collapse: collapse;
        td,
        th {
          text-align: left;
          padding: 0.3em 1em;
        }
        th {
          text-transform: uppercase;
          font-size: 0.9em;
          position: sticky;
          top: 0;
          background-color: #555;
          color: white;
        }
        .day {
          min-width: 10em;
        }
        .events-consumed {
          text-align: right;
        }
        tbody.hover > tr:hover > * {
          background-color: rgba(0, 0, 0, 0.03);
        }
      }
    }
    .chart-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 22em 1fr;
      .chart-relative-canvas-wrapper {
        position: relative;
        min-height: 17em;
        overflow: hidden;
        .chart-canvas-wrapper {
          position: absolute;
          inset: 0;
        }
      }
      .chart-legend-wrapper {
        position: relative;
        overflow-y: scroll;
        overflow-x: auto;
        ul {
          position: absolute;
          list-style: none;
          font-size: smaller;
          min-width: 100%;
          li {
            margin-bottom: 5px;
          }
        }
      }
    }
    .number {
      font-family: "Courier New", Courier, monospace, monospace;
    }
    .total-report {
      .number {
        background-color: #444;
        color: white;
        border-radius: 100vw;
        padding: 4px 1em;
      }
    }
    .scenarios-list-divider {
      height: 0.5em;
    }
    h1.headline {
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-weight: 100;
      font-size: 1.3rem;
    }
  }
`;

function ScenarioReport({ report }) {
  const strings = useLocalizedStrings();
  const [openTable, setOpenTable] = useState(false);
  return (
    <div className="scenario-report left-marker">
      <div
        className="scenario-report-title clickable"
        onClick={() => setOpenTable((prev) => !prev)}
      >
        {/* <ChevronRightIcon className={`collapse-indicator ${openTable ? '' : 'collapsed'}`}/> */}
        <span className={`${report.deleted ? "deleted" : ""}`}>
          {report.name?.toUpperCase()}
        </span>
        <span>{report?.description}</span>
        <span className="scenario-title-total number">
          {report?.total.toLocaleString()}
        </span>
      </div>
      <div className="scenario-report-body">
        <Collapse in={openTable}>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th className="day">
                    {strings.account_settings_billing_scenario_day}
                  </th>
                  <th className="events-consumed">
                    {strings.account_settings_billing_scenario_eventsconsumed}
                  </th>
                </tr>
              </thead>
              <tbody className="hover">
                {report.events.map((d) => (
                  <tr key={d.date}>
                    <td className="day">{d.date.split("T")[0]}</td>
                    <td className="events-consumed number">
                      {Number(d.num_events).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Collapse>
      </div>
    </div>
  );
}

const getOrCreateLegendList = (_chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, _args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement("li");
      li.style.alignItems = "center";
      li.style.cursor = "pointer";
      li.style.display = "flex";
      li.style.flexDirection = "row";

      li.onclick = () => {
        chart.setDatasetVisibility(
          item.datasetIndex,
          !chart.isDatasetVisible(item.datasetIndex)
        );
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + "px";
      boxSpan.style.display = "inline-block";
      boxSpan.style.height = "20px";
      boxSpan.style.marginRight = "10px";
      boxSpan.style.width = "20px";

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

function DailyTotalsChart({ events, includeDeleted, stacked }) {
  const canvasRef = useRef();
  const chart = useRef();

  const processed = useMemo(() => {
    if (!events) {
      return [];
    }
    return {
      datasets: [
        ...Object.entries(events)
          .filter(([_, v]) => !v.deleted)
          .sort((a, b) => a[1].name.localeCompare(b[1].name)),
        ...Object.entries(events)
          .filter(([_, v]) => v.deleted)
          .sort((a, b) => a[1].name.localeCompare(b[1].name)),
      ]
        .map(([_k, v], idx, a) => ({
          label: v.name,
          data: v.events.map((e) => ({ x: e.date, y: +e.num_events })),
          backgroundColor: `hsl(${30 + (idx * 330) / a.length}, 100%, 65%)`,
          deleted: v.deleted,
        }))
        .filter((d) => !d.deleted || includeDeleted),
    };
  }, [events, includeDeleted]);

  useEffect(() => {
    if (!processed || processed.length === 0 || !canvasRef.current) {
      return;
    }
    chart.current = new Chart(canvasRef.current, {
      type: "bar",
      plugins: [htmlLegendPlugin],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: {
          duration: 100,
        },
        plugins: {
          legend: {
            display: false,
            position: "bottom",
            align: "center",
            onHover: (e) => (e.native.target.style.cursor = "pointer"),
            onLeave: (e) => (e.native.target.style.cursor = "default"),
          },
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: "chart-legend-wrapper",
          },
        },
        scales: {
          x: {
            type: "time",
            display: true,
            stacked: stacked,
            title: {
              display: true,
              // text: strings.dashboard_daily_events_x_title,
              align: "center",
              font: {
                size: 14,
              },
            },
            ticks: {
              font: {
                size: 10,
              },
            },
            time: {
              unit: "day",
              displayFormats: {
                day: "MMM DD, YYYY",
              },
            },
          },
          y: {
            stacked: stacked,
            type: stacked ? "linear" : "logarithmic",
            title: {
              display: true,
              // text: strings.dashboard_daily_events_y_title,
              align: "center",
              font: {
                size: 14,
              },
            },
            min: 0,
            ticks: {
              callback: (val) => postfixedNumberFormatter(val, 1),
            },
          },
        },
      },
      data: {
        labels: processed.labels,
        datasets: processed.datasets,
      },
    });
    return () => {
      chart.current?.destroy();
    };
  }, [processed, stacked]);

  return (
    <div className="chart-wrapper">
      <div id="chart-legend-wrapper" className="chart-legend-wrapper"></div>
      <div className="chart-relative-canvas-wrapper">
        <div className="chart-canvas-wrapper">
          <canvas ref={canvasRef} />
        </div>
      </div>
    </div>
  );
}

export default function AccountUsage() {
  const strings = useLocalizedStrings();
  const currYear = useMemo(() => new Date().getFullYear(), []);
  const [year, setYear] = useState(currYear);
  const fromDate = useMemo(() => new Date(Date.UTC(year, 0, 1)), [year]);
  const toDate = useMemo(() => new Date(Date.UTC(year + 1, 0, 1)), [year]);
  const events = useBillingInfo(fromDate, toDate);
  const [includedDeleted, setIncludeDeleted] = useCurrentUserSettings(
    "usage-show-deleted",
    false
  );

  const dateDay = (d) => d.toISOString().replace(/T.*/, "");

  const total = useMemo(() => {
    if (!events) {
      return 0;
    }
    return Object.keys(events).reduce((a, k) => a + events[k].total, 0);
  }, [events]);

  return (
    <StyledBillingPage className="billing-wrapper">
      <div className="report-Report-parameters">
        <select
          className="year-selector"
          value={year}
          onChange={(e) => setYear(+e.target.value)}
        >
          {range(currYear, currYear - 10).map((y) => (
            <option key={y} value={y}>
              {y}
            </option>
          ))}
        </select>
        <ButtonGroup>
          <RemoteDownloader
            url={AuditAPI.getUsage(fromDate, toDate, "csv").url}
            filename={`usage.${dateDay(fromDate)}.${dateDay(toDate)}.csv`}
          >
            <UiPrimaryButton>{strings.button_export}</UiPrimaryButton>
          </RemoteDownloader>
        </ButtonGroup>
      </div>

      <div className="current-status-wrapper">
        <div className="current-status-body">
          <h1 className="headline">
            {strings.account_settings_billing_headline}
          </h1>
          <h2>{strings.account_settings_billing_section_timeline}</h2>
          <DailyTotalsChart
            events={events}
            includeDeleted={includedDeleted}
            stacked={true}
          />
          <div className="chart-options">
            <FormControlLabel
              control={
                <Checkbox
                  checked={includedDeleted}
                  onChange={(e) => setIncludeDeleted(e.target.checked)}
                />
              }
              label={strings.account_settings_billing_checkbox_include_deleted}
            />
          </div>
          <hr />
          <h2>{strings.account_settings_billing_section_details}</h2>
          <div className="scenarios-list">
            {Object.keys(events)
              .filter((k) => !events[k].deleted)
              .sort((a, b) => a.localeCompare(b))
              .map((scenario) => (
                <ScenarioReport key={scenario} report={events[scenario]} />
              ))}
            <div className="scenarios-list-divider" />
            {Object.keys(events)
              .filter((k) => events[k].deleted)
              .sort((a, b) => a.localeCompare(b))
              .map((scenario) => (
                <ScenarioReport key={scenario} report={events[scenario]} />
              ))}
          </div>
          <hr />
          <div className="scenario-report total-report">
            <div className="scenario-report-title">
              <span>
                <b>
                  {strings.account_settings_billing_scenario_totalall_title}
                </b>
              </span>
              <span></span>
              <span className="scenario-title-total number">
                <b>{Number(total).toLocaleString()}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </StyledBillingPage>
  );
}
