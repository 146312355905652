import { useMemo } from "react";

import { Typography, styled } from "@mui/material";
import { useHistory } from "react-router-dom";

import { greyLight } from "themes/MainTheme";

import ScenarioAlertsButton from "components/scenarios/ScenarioAlertsButton";
import ApplicationIcon from "components/ui/ApplicationIcon";

import useApplications from "hooks/useApplications";

const StyledScenario = styled("div")(() => ({
  background: "#fff",
  border: `1px solid ${greyLight}`,
  borderRadius: "5px",
  boxShadow: `3px 4px 3px -2px ${greyLight}`,
  cursor: "pointer",
  display: "flex",
  flexWrap: "wrap",
  gap: "5px",
  justifyContent: "space-between",
  marginBottom: "5px",
  marginLeft: "5px",
  marginRight: "5px",
  padding: "5px",
  width: "95%",
  minHeight: "50px",
  alignItems: "flex-start",
}));

const ScenarioNameContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-start",
  gap: 10,
  flex: "1",
}));

const SourcesContainer = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "90px",
  justifyContent: "right",
  alignItems: "end",
  gap: 10,
  padding: "5px",
}));

export const Scenario = ({ scenario }) => {
  const [apps] = useApplications();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/scenarios/${scenario.id}/dashboard`);
  };

  const sourceApps = useMemo(() => {
    if (!apps) {
      return null;
    }

    const uniqueSources = scenario.sources.reduce((acc, curr) => {
      const app = apps.find((a) => a.connector === curr.connector);
      if (!acc.find((obj) => obj.connector === curr.connector)) {
        acc.push({
          ...curr,
          icon_data: app?.icon_data,
          icon_content_type: app?.icon_content_type,
        });
      }
      return acc;
    }, []);

    return uniqueSources.map((s) => (
      <ApplicationIcon
        app={s}
        key={s.id}
        paused={!s.active}
        size="small"
        tooltip
      />
    ));
  }, [apps, scenario?.sources]);

  return (
    <StyledScenario onClick={(e) => handleClick(e)}>
      <ScenarioNameContainer>
        <ScenarioAlertsButton scenario={scenario} />
        <Typography sx={{ display: "flex", wordBreak: "break-word" }}>
          {scenario.name}
        </Typography>
      </ScenarioNameContainer>
      <SourcesContainer>{sourceApps}</SourcesContainer>
    </StyledScenario>
  );
};
