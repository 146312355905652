import { useMemo } from "react";

import { styled } from "@mui/material";

import SessionTokens, {
  longestLabelWidth,
} from "components/sessions/SessionTokens";
import Flexbox from "components/ui/Flexbox";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useTags } from "hooks/useTags";

const StyledSessionTokensPopularityChart = styled(Flexbox)(() => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: "1em",
}));

function SessionTokensPopularityChart({ scenario, session }) {
  const strings = useLocalizedStrings();

  const { tags, isPending, isPendingTagTotals, totals } = useTags({
    scenario,
    session,
  });

  const longestWidth = useMemo(() => longestLabelWidth(tags), [tags]);

  if (isPending) {
    return strings.text_loading2;
  }

  return (
    <>
      <StyledSessionTokensPopularityChart>
        <p>{strings.sessiondetails_tags_explanation1}</p>
        <p>{strings.sessiondetails_tags_explanation2}</p>
        <SessionTokens
          histograms={tags}
          totals={totals}
          isPendingTagTotals={isPendingTagTotals}
          longestWidth={longestWidth}
        />
      </StyledSessionTokensPopularityChart>
    </>
  );
}

export default SessionTokensPopularityChart;
