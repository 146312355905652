import { useContext } from "react";

import { Typography } from "@mui/material";

import { ScenariosContext } from "contexts/ScenariosContext";

import FrameContent from "components/ui/FrameContent";
import { PaperFrame } from "components/ui/PaperFrame";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const areValuesValid = (values) =>
  values.every(
    (value) => value !== null && value !== undefined && !isNaN(value)
  );

const formattedValue = (value) => {
  if (value?.toFixed(2) === "0.00") {
    return 0;
  }
  return value?.toFixed(2);
};

export default function AnomalyInfo({ session }) {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);

  const sessionLength = selectedScenario?.session_length_anomaly_enabled;
  const sessionSensitivity =
    selectedScenario?.session_sensitivity_anomaly_enabled;

  const absoluteNumSensitiveEvents = Math.abs(session?.num_sensitive_events);
  const absoluteSensitivityAverage = Math.abs(session?.sensitivity_average);
  const absoluteNum_events = Math.abs(session?.num_events);
  const absoluteLengthAverage = Math.abs(session?.length_average);

  if (!session || (!sessionLength && !sessionSensitivity)) {
    return null;
  }

  const sessionsSensitivityDistance =
    absoluteNumSensitiveEvents - absoluteSensitivityAverage;

  const sessionsLengthDistance =
    Math.log2(absoluteNum_events) - absoluteLengthAverage;

  const lengthValid = areValuesValid([
    session?.length_average,
    session?.num_events,
  ]);

  const sensitivityValid = areValuesValid([
    session?.sensitivity_average,
    session?.num_sensitive_events,
  ]);

  return (
    <PaperFrame style={{ gridColumn: "1/3", height: "80px" }}>
      <FrameContent style={{ height: "100%" }}>
        <div>
          {sessionSensitivity && (
            <div
              className="anomalyInfoWrapper"
              style={{
                alignItems: "center",
                display: "flex",
                gap: 5,
                height: "22px",
              }}
            >
              <Typography variant="inherit">
                <strong>
                  {
                    strings.sessiondetails_anomaly_anomalyInfo_sessions_sensitivity
                  }
                </strong>
                {
                  strings.sessiondetails_anomaly_anomalyInfo_sessions_profiles_average
                }
                {sensitivityValid ? (
                  <span>
                    {strings.formatString(
                      strings.sessiondetails_anomaly_anomalyInfo_sessions_sensitivity_and_lengthText,
                      formattedValue(absoluteSensitivityAverage),
                      formattedValue(sessionsSensitivityDistance)
                    )}
                  </span>
                ) : (
                  <span>
                    {
                      strings.sessiondetails_anomaly_anomalyInfo_sessions_invalid_text
                    }
                  </span>
                )}
              </Typography>
            </div>
          )}
          {sessionLength && (
            <div
              className="anomalyInfoWrapper"
              style={{
                alignItems: "center",
                display: "flex",
                gap: 5,
                height: "22px",
              }}
            >
              <Typography variant="inherit">
                <strong>
                  {strings.sessiondetails_anomaly_anomalyInfo_sessions_length}
                  {` (Log2) `}
                </strong>
                {
                  strings.sessiondetails_anomaly_anomalyInfo_sessions_profiles_average
                }
                {lengthValid ? (
                  <span>
                    {strings.formatString(
                      strings.sessiondetails_anomaly_anomalyInfo_sessions_sensitivity_and_lengthText,
                      formattedValue(absoluteLengthAverage),
                      formattedValue(sessionsLengthDistance)
                    )}
                  </span>
                ) : (
                  <span>
                    {
                      strings.sessiondetails_anomaly_anomalyInfo_sessions_invalid_text
                    }
                  </span>
                )}
              </Typography>
            </div>
          )}
        </div>
      </FrameContent>
    </PaperFrame>
  );
}
