import React, { useContext } from "react";

import { styled } from "@mui/material";

import { ScenariosContext } from "contexts/ScenariosContext";

import Settings from "components/scenarios/settings/SettingsPage";
import SessionDetailsLayout from "components/sessions/SessionDetailsLayout";
import SubNavigator from "components/ui/SubNavigator";
import TableFooterLoadingMessage from "components/ui/TableFooterLoadingMessage";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import AlertsTable from "./AlertsTable";
import Diagnostics from "./Diagnostics";
import SessionsTable from "./SessionsTable";
import Sources from "./Sources";
import Users from "./Users";
import { ScenarioDashboard } from "./dashboard/ScenarioDashboard";
import ProfileDetailsMenu from "./profiles/ProfileDetailsMenu";
import ProfilesTablePage from "./profiles/ProfilesTablePage";

const StyledScenariosContent = styled("div")(({ theme }) => ({
  minHeight: "100%",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "min-content min-content auto",
  alignItems: "start",
  "& .scenario-header": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    "& .scenario-header-item": {
      flexGrow: 1,
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
      padding: theme.spacing(0.7),
    },
  },
  "& .MuiAppBar-positionRelative": {
    backgroundColor: "white",
    color: "black",
    borderBottom: "1px solid #ddd",
  },
  "& .scenarios-tabs": {
    position: "absolute",
    width: "100%",
    height: "100%",
    "& .scenarios-tab-content": {
      flexGrow: 1,
      width: "100%",
    },
  },
}));

const tabs = (strings) => [
  {
    id: "dashboard",
    label: strings.scenario_navbar_tab_dashboard,
    route: "dashboard",
    component: ScenarioDashboard,
  },
  {
    id: "alerts",
    label: strings.scenario_navbar_tab_alerts,
    route: "alerts",
    component: AlertsTable,
  },
  {
    id: "sessions",
    label: strings.scenario_navbar_tab_sessions,
    route: "sessions",
    component: SessionsTable,
  },
  {
    id: "users",
    label: strings.scenario_navbar_tab_users,
    route: "users",
    component: Users,
  },
  {
    id: "profiles-analytics",
    label: strings.scenario_navbar_tab_profiles,
    route: "profiles",
    component: ProfilesTablePage,
    caps: { "analysis-model.clusters": { read: true } },
  },
  {
    id: "sources",
    label: strings.scenario_navbar_tab_sources,
    route: "sources",
    component: Sources,
    tabStyle: { marginLeft: "auto" },
    caps: { "acm.sources": { write: true } },
  },
  {
    id: "settings",
    label: strings.scenario_navbar_tab_settings,
    route: "settings",
    component: Settings,
    caps: { "acm.scenarios": { write: true } },
  },
  {
    id: "run",
    label: strings.scenario_navbar_tab_diagnostics,
    route: "run",
    component: Diagnostics,
    caps: { "acm.scenarios": { write: true } },
  },
];

const routes = (strings) => [
  {
    id: "session-details",
    route: "sessions/:session",
    component: SessionDetailsLayout,
  },
  {
    id: "profile-details",
    route: "profiles/:profile",
    component: ProfileDetailsMenu,
  },
  ...tabs(strings).filter((t) => t.route),
];

function ScenarioTabs(props) {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);
  return (
    <SubNavigator
      label={strings.scenario_navbar_title}
      tabsSpec={tabs(strings)}
      routes={routes(strings)}
      scenario={selectedScenario.id}
      {...props}
    />
  );
}

function ScenarioContentLayout(props) {
  const { selectedScenario } = useContext(ScenariosContext);

  if (!selectedScenario) {
    return null;
  }

  return (
    <StyledScenariosContent>
      {!selectedScenario.initialized ? (
        <TableFooterLoadingMessage />
      ) : (
        <ScenarioTabs {...props} style={{ flexGrow: 1, flexShrink: 1 }} />
      )}
    </StyledScenariosContent>
  );
}

export default ScenarioContentLayout;
