import { useEffect, useMemo, useRef, useState } from "react";

import { Divider, MenuItem, styled } from "@mui/material";
import { isEqual } from "lodash-es";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Virtuoso } from "react-virtuoso";

import { AddButton } from "components/scenarios/MappingEditor/components/AddButton";
import { ColumnSelector } from "components/scenarios/MappingEditor/components/ColumnSelector";
import { Expander } from "components/scenarios/MappingEditor/components/Expander";
import { OutputFieldInput } from "components/scenarios/MappingEditor/components/OutputFieldInput";
import { SectionErrorMessage } from "components/scenarios/MappingEditor/components/SectionErrorMessage";
import { SectionWrap } from "components/scenarios/MappingEditor/components/SectionWrap";
import { DeleteButton } from "components/ui/DeleteButton";
import FileUpload from "components/ui/FileUpload";
import Flexbox from "components/ui/Flexbox";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import { SelectorInput } from "components/ui/form/SelectorInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { useExpanded } from "hooks/useExpanded";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import { LabelRules } from "./LabelRules";
import { useBucketingCsvUpload } from "./useBucketingCsvUpload";

const StyledItemWrapper = styled("div")(() => ({
  height: "auto",
  padding: "10px 0px 10px 25px",
  "& .MuiFormControl-root": {
    width: "unset !important",
  },
  width: "95%",
  transition: "max-height 500ms ease",

  "& .labelRulesWrapper": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-evenly",
    marginBottom: "10px",
    width: "100%",
  },
  "& .virtuoso": {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    minHeight: "110px",
    overflow: "auto",
    height: "230px !important",
    transition: "height 600ms ease",
    paddingBottom: "10px",
    width: "100%",
    "&.expanded": {
      height: "500px !important",
    },
    "&.emptyVirtuoso": {
      height: "80px !important",
    },
  },
}));

const bucketingTypes = [
  {
    label: "String - Equals",
    value: "string",
  },
  {
    label: "String - Contains",
    value: "string_contains",
  },
  {
    label: "Numeric",
    value: "numeric",
  },
  {
    label: "Time - Day",
    value: "time_day",
  },
  {
    label: "Time - Hour",
    value: "time_hours",
  },
];
// all columns are added below
// deps added on the output_column field are just the ones that are required on the mapping tab
// should those required fields change, deps should be updated here and on transformations tab
// validation is triggered when deleting/adding the entry in component above.
// there's an example at TransformationsTabItemComponent and it's parent component.

const bucketingColumns = [
  {
    label: "Annotation",
    value: "annotation",
  },
  {
    label: "Appuser",
    value: "appuser",
  },
  {
    label: "Device",
    value: "device",
  },
  {
    label: "Location",
    value: "location",
  },
];

const BucketingTabItemComponent = ({ data, dataKey, onDelete }) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const strings = useLocalizedStrings();
  const virtuosoRef = useRef(null);
  const bucketingType = useRef(data.type);

  const {
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const namePrefix = `options.internal_config.bucketing.entries.${dataKey}`;
  const fieldArrayName = `${namePrefix}.label_rules`;

  const customValidate = (value) => {
    if (value.length === 0) {
      setError(namePrefix, {
        customRule: {
          type: "custom",
          message: "There must be at least one label rule!",
        },
      });
      return false;
    }
    if (
      !!errors?.options?.internal_config?.bucketing?.entries?.[dataKey]?.[
        "customRule"
      ]
    ) {
      clearErrors([namePrefix]);
    }
    return true;
  };

  const { fields, append, remove, replace } = useFieldArray({
    name: fieldArrayName,
    rules: {
      validate: (value) => customValidate(value),
    },
  });
  const handleUploadCsv = useBucketingCsvUpload({
    onUpload: (labelRules) => {
      replace(labelRules);
      setRefreshTrigger((prev) => prev + 1);
    },
  });
  const { expandedState, isExpandingDisabled, handleExpand } = useExpanded(
    fields?.length
  );

  const watchedTypeValue = watch(`${namePrefix}.type`);
  const timeSelected = watchedTypeValue?.startsWith("time_");

  const defaultAppendValue = useMemo(() => {
    if (!timeSelected) {
      return {
        condition: "",
        label: "",
      };
    }
    return {
      condition: watchedTypeValue === "time_hours" ? [0, 24] : [],
      label: "",
    };
  }, [timeSelected, watchedTypeValue]);

  const handleDelete = (index) => {
    const values = getValues(`${namePrefix}.label_rules.${index}`);

    if (isEqual(values, defaultAppendValue)) {
      remove(index);
      return;
    }

    setConfirmDeleteOpen(true);
    setDeleteIndex(index);
  };

  const handleTypeChange = (e, field) => {
    const val = e.target.value;
    remove();
    if (val?.startsWith("time_")) {
      setValue(`${namePrefix}.input_field`, "tq_datetime");
    } else {
      setValue(`${namePrefix}.input_field`, "");
    }
    setValue(`${namePrefix}.no_value_label`, "");
    field.onChange(val);
  };

  useEffect(() => {
    if (bucketingType.current !== watchedTypeValue) {
      append(defaultAppendValue);
    }
    bucketingType.current = watchedTypeValue;
  }, [append, defaultAppendValue, watchedTypeValue]);

  const handleConfirmationAction = () => {
    remove(deleteIndex);
  };

  const handleAddNewItem = () => {
    append(defaultAppendValue);
    virtuosoRef.current.scrollToIndex({
      align: "start",
      index: fields?.length - 1,
    });
  };

  const checkForErrorClasses = () => {
    if (
      !!errors?.options?.internal_config?.bucketing?.["entries"]?.[dataKey]
        ?.label_rules?.root
    ) {
      return " customError";
    } else if (
      !!errors?.options?.internal_config?.bucketing?.["entries"]?.[dataKey]
    ) {
      return " errorSection";
    } else {
      return "";
    }
  };

  return (
    <div
      key={dataKey}
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "20px",
      }}
    >
      <DeleteButton
        style={{ height: "50px" }}
        onClick={() => onDelete(dataKey)}
      />
      <SectionWrap className={checkForErrorClasses()}>
        <StyledItemWrapper>
          <div
            style={{
              display: "flex",
              gap: "2em",
            }}
          >
            <SelectorInput
              name={`${namePrefix}.type`}
              label={
                strings.sourcedialog_mapping_editor_bucketing_tab_label_type
              }
              rules={{
                required: true,
                deps: [`${namePrefix}.label_rules`],
              }}
              onChange={handleTypeChange}
              style={{
                height: "51px",
                width: "15em",
              }}
            >
              {bucketingTypes.map((type) => {
                return (
                  <MenuItem key={type.value} value={type.value}>
                    <span>{type.label}</span>
                  </MenuItem>
                );
              })}
            </SelectorInput>
            <ColumnSelector
              name={`${namePrefix}.input_field`}
              defaultValue={data.input_field || ""}
              disabled={timeSelected}
            />
            <OutputFieldInput name={`${namePrefix}.output_field`} />
            <SelectorInput
              name={`${namePrefix}.output_column`}
              rules={{
                required: true,
                deps: [
                  "options.internal_config.mapping.appuser",
                  "options.internal_config.mapping.location",
                ],
              }}
              label={
                strings.sourcedialog_mapping_editor_bucketing_tab_label_mapped_column
              }
              style={{
                height: "51px",
                width: "12em",
              }}
            >
              {bucketingColumns.map((type) => {
                return (
                  <MenuItem
                    key={type.value}
                    value={type.value || bucketingColumns}
                  >
                    <span>{type.label}</span>
                  </MenuItem>
                );
              })}
            </SelectorInput>
          </div>
          <div style={{ display: "flex", gap: "2em" }}>
            <TextFieldInput
              name={`${namePrefix}.no_value_label`}
              disabled={timeSelected}
              label={
                strings.sourcedialog_mapping_editor_bucketing_tab_label_no_value_label
              }
              style={{ height: "54px", width: "25em" }}
            />
            <TextFieldInput
              name={`${namePrefix}.default_label`}
              label={
                strings.sourcedialog_mapping_editor_bucketing_tab_label_default_label
              }
              style={{ height: "54px", width: "25em" }}
            />
            {timeSelected && (
              <TextFieldInput
                name={`${namePrefix}.timezone_offset`}
                label={"Timezone offset"}
                style={{ height: "54px", width: "11em" }}
                autoComplete="off"
                options={{
                  shouldUnregister: true,
                  defaultValue: "0",
                }}
                rules={{
                  max: {
                    value: 12,
                    message:
                      strings.sourcedialog_mapping_editor_bucketing_tab_invalid_offset,
                  },
                  min: {
                    value: -12,
                    message:
                      strings.sourcedialog_mapping_editor_bucketing_tab_invalid_offset,
                  },
                  pattern: {
                    value: /^[+-]?\d+$/,
                    message: strings.invalid_input,
                  },
                }}
              />
            )}
          </div>
          <Divider
            flexItem
            orientation="horizontal"
            variant="fullWidth"
            style={{
              background: "black",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="labelRulesWrapper">
              <Virtuoso
                components={{
                  Header: () => <div style={{ marginTop: "10px" }} />,
                }}
                className={
                  "virtuoso" +
                  (expandedState ? " expanded" : "") +
                  (fields?.length <= 1 ? " emptyVirtuoso" : "")
                }
                data={fields}
                itemContent={(index, item) => (
                  <LabelRules
                    handleDelete={() => handleDelete(index)}
                    item={item}
                    namePrefix={`${namePrefix}.label_rules.${index}`}
                    watchedTypeValue={watchedTypeValue}
                  />
                )}
                ref={virtuosoRef}
              />

              {!!errors?.options?.internal_config?.bucketing?.entries?.[dataKey]
                ?.customRule && (
                <SectionErrorMessage>
                  {
                    errors?.options?.internal_config?.bucketing?.entries?.[
                      dataKey
                    ]?.customRule?.message
                  }
                </SectionErrorMessage>
              )}
            </div>
            <Flexbox gap="20px">
              <AddButton
                style={{ alignSelf: "center" }}
                onClick={handleAddNewItem}
              />
              {watchedTypeValue === "string" && (
                <FileUpload
                  accept="text/csv"
                  onUpload={handleUploadCsv}
                  placeholder={`${strings.sourcedialog_mapping_editor_bucketing_tab_csv_file_upload_placeholder}...`}
                  refresh={refreshTrigger}
                  style={{ flexGrow: 0, width: "380px" }}
                />
              )}
            </Flexbox>
          </div>
        </StyledItemWrapper>

        <Expander
          isExpandingDisabled={isExpandingDisabled}
          expandedState={expandedState}
          handleExpand={handleExpand}
        />
      </SectionWrap>

      <MultipleOptionsDialog
        onConfirm={handleConfirmationAction}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        text={
          strings.sourcedialog_mapping_editor_bucketing_tab_delete_item_question
        }
        title={
          strings.sourcedialog_mapping_editor_bucketing_tab_delete_item_title
        }
      />
    </div>
  );
};

export default BucketingTabItemComponent;
