import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(duration);

const inUtc = dayjs.utc;

function timeFormatter(t, options = { includeTime: true }) {
  let fmtString = "MMM DD, YYYY";
  if (options.includeTime) {
    fmtString += " HH:mm:ss";
  }
  return inUtc(t).format(fmtString).toString();
}

function msToTime(s) {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const sec = s % 60;
  s = (s - sec) / 60;
  const min = s % 60;
  s = (s - min) / 60;
  let hrs = s;
  let days;

  if (hrs >= 24) {
    hrs = hrs % 24;
    s = (s - hrs) / 24;
    days = s;
  }

  const pad = (v, w = 2) => v.toString().padStart(w, "0");
  return `${days ? `${pad(days)}:` : ""}${pad(hrs)}:${pad(min)}:${pad(
    sec + (ms ? 1 : 0)
  )}`;
}

function formatInterval(d) {
  return msToTime(d);
}

function intervalObjectAsMilliseconds(tio) {
  let v = 0;
  if (tio.milliseconds) {
    v += tio.milliseconds;
  }
  if (tio.seconds) {
    v += tio.seconds * 1000;
  }
  if (tio.minutes) {
    v += tio.minutes * 60 * 1000;
  }
  if (tio.hours) {
    v += tio.hours * 60 * 60 * 1000;
  }
  if (tio.days) {
    v += tio.days * 24 * 60 * 60 * 1000;
  }
  return v;
}

function formatIntervalObject(tio) {
  return msToTime(intervalObjectAsMilliseconds(tio));
}

function timeInterval(t1, t2) {
  const t1Int = t1 instanceof Date ? t1.getTime() : new Date(t1).getTime();
  const t2Int = t2 instanceof Date ? t2.getTime() : new Date(t2).getTime();
  return t2Int - t1Int;
}

function diffDays(from_date, to_date) {
  if (!from_date || !to_date) {
    return "0";
  }
  const from = dayjs(from_date);
  const to = dayjs(to_date);
  const diffDays = to.diff(from, "day") + 1;
  return String(diffDays);
}

export {
  inUtc,
  formatInterval,
  formatIntervalObject,
  timeFormatter,
  timeInterval,
  diffDays,
};
