import { styled } from "@mui/material";

const D3Chart = styled("div")`
  position: relative;
  outline: none !important;

  svg {
    width: 100%;
    user-select: none;
    font-family: inherit;
    font-weight: normal;
    outline: none !important;
    background-color: #3e475f;
    color: #fff;
  }
  .chart-title {
    position: absolute;
    top: 5px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
    opacity: 0.6;
  }
  .chart-toolbar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 6px 4px;
    display: flex;
    justify-content: flex-start;
    gap: 5px;

    & > :is(button, input, select) {
      flex-basis: 10em;
      font-size: 0.7em;
      border: none;
      border-radius: 10px;
      padding: 5px 10px;
      margin: 1px;
      outline: none !important;
      text-transform: uppercase;
      transition: all 200ms ease;

      &:disabled,
      &[disabled] {
        background-color: rgba(255, 255, 255, 0.6);
      }

      &:not(:is(:disabled, [disabled])) {
        cursor: pointer;
      }
    }
  }

  &:is(:disabled, [disabled]) * {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export default D3Chart;
