import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import CallMadeIcon from "@mui/icons-material/CallMade";
import { Chip, TextField, styled, useTheme } from "@mui/material";
import { isNull } from "lodash-es";
import { capitalize } from "lodash-es";

import { useAdminsQuery } from "api/admins";
import { useClusterUpdateMutation } from "api/clusters";
import {
  useSessionReferencedCountQuery,
  useSessionsEvaluationReviewNotificationMutation,
  useSessionsUpdateMutation,
} from "api/sessions";

import { LoginStatusContext } from "contexts/LoginStatusContext";
import { ScenariosContext } from "contexts/ScenariosContext";

import AffiliationSelector from "components/ui/AffiliationSelector";
import DataLink from "components/ui/DataLink";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import NotAllowedMessage from "components/ui/NotAllowedMessage";
import { PageSubtitle } from "components/ui/PageSubtitle";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";

import { timeFormatter } from "utils/time-fmt";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import { useSessionDetails } from "./SessionDetailsContext";

const StyledEvaluateSession = styled("div")(({ profileAffiliation }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.1rem",

  "& .evaluationStatusesContainer": {
    borderRadius: "var(--border-radius)",
    "& > *": {
      height: "3em",
    },
  },
  "& .alertInitiationStatus": {
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,.03)",
    color: "rgba(0,0,0,.6)",
    display: "flex",
    gap: ".3em",
    marginBottom: "0px",
    padding: "10px 1em",
  },
  "& .evaluationStatus": {
    alignItems: "center",
    display: "flex",
    gap: ".3em",
    padding: "10px 1em",
  },
  "& .ticketOpen": {
    backgroundColor: "rgba(255,0,0,.1)",
    color: "rgba(128,0,0,1)",
  },
  "& .ticketClosed": {
    backgroundColor: "rgba(0,255,0,.2)",
    color: "rgba(0,128,0,1)",
  },
  "& .ticketNa": {
    backgroundColor: "rgba(0,0,255,.06)",
    color: "rgba(0,0,128,.6)",
  },
  "& .ticketStatusHighlight": {
    backgroundColor: "rgba(255,255,255,.6)",
    borderRadius: "100vw",
    padding: "4px .6em",
  },
  "& .evaluationFrame": {
    backgroundColor: "#eee",
    borderRadius: "var(--border-radius)",
    padding: "1rem",
    position: "relative",
  },
  "& .evaluationFrameTitle": {
    alignItems: "center",
    display: "flex",
    gap: ".5em",
    left: ".7em",
    letterSpacing: "1px",
    opacity: ".8",
    position: "absolute",
    textTransform: "uppercase",
    top: "-.7em",
    whiteSpace: "normal",
    width: "min-content",
  },
  "& .evaluationFrameBody": {
    display: "grid",
    gap: ".5em",
    gridTemplateRows: "1fr min-content",
    height: "100%",
    width: "100%",
    "& .MuiTextField-root": {
      backgroundColor: "#fff",
      border: "none",
    },
  },
  "& .evaluationClassification": {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .evaluationActions": {
    alignItems: "center",
    display: "flex",
    gap: "10px",
    justifyContent: "space-between",
    "& > *": {
      height: "4em",
      padding: "5px 20px",
    },
  },
  "& .reviewerSelection": {
    alignItems: "center",
    border: `1px solid #ddd`,
    borderRadius: "6px",
    display: "flex",
    gap: "10px",
    height: "3.7em",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    "&:disabled, &[disabled]": {
      opacity: ".5",
    },
    "& select": {
      backgroundColor: "rgba(255,255,255,.6)",
      border: "none",
      borderRadius: "4px",
      minWidth: "10em",
      outline: "none",
      padding: "8px 5px",
      textTransform: "capitalize",
      "&:not(:disabled), &:not([disabled])": {
        cursor: "pointer",
      },
    },
  },
  "& a": {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& .profileEvaluation": {
    backgroundColor:
      profileAffiliation === "unclassified" ? "green" : "indianRed",
    display: profileAffiliation ? "flex" : "none",
    color: "white",
    fontSize: "1em",
  },
  "& .profileEvaluationComment": {
    background: "white",
    border: "1px solid #cbcbcb",
    padding: "10px",
  },
  "& .profileEvaluationNoComment": {
    fontWeight: "bold",
    marginTop: "10px",
  },
}));

const ClassificationToolbar = (props) => {
  const { values, value, setValue, disabled } = props;

  return (
    <div className="evaluationClassification">
      <AffiliationSelector
        {...props}
        values={values}
        value={value || "unclassified"}
        setValue={setValue}
        disabled={disabled}
      />
    </div>
  );
};

const CommentEditor = (props) => {
  return (
    <TextField
      placeholder="Enter comment here..."
      multiline={true}
      minRows={4}
      maxRows={4}
      style={{
        resize: "none",
      }}
      {...props}
    />
  );
};

const AlertInitiationStatus = (props) => {
  const { alertTime, transport } = props;
  const strings = useLocalizedStrings();

  if (!alertTime) {
    return (
      <div className="alertInitiationStatus">
        <span>{strings.sessiondetails_evaluate_no_alert}</span>
      </div>
    );
  }

  return (
    <div className="alertInitiationStatus">
      <span>{strings.sessiondetails_evaluate_alert_sent}</span>
      <span className="ticketStatusHighlight">
        {timeFormatter(new Date(alertTime))}
      </span>
      <span>{strings.sessiondetails_evaluate_alert_sent_using}</span>
      <span className="ticketStatusHighlight">{transport?.join(",")}</span>
    </div>
  );
};

const EvaluationStatus = (props) => {
  const strings = useLocalizedStrings();
  const { on, by, email } = props;

  if (on) {
    return (
      <div className="evaluationStatus ticketClosed">
        <span>{strings.sessiondetails_evaluate_status_closed}</span>
        <span>{strings.sessiondetails_evaluate_status_closed_on}</span>
        <span className="ticketStatusHighlight">
          {timeFormatter(new Date(on))}
        </span>

        <span>{strings.sessiondetails_evaluate_status_closed_by}</span>
        {by ? (
          <a className="ticketStatusHighlight" href={`mailto:${email}`}>
            {by}
          </a>
        ) : (
          <span>
            {strings.sessiondetails_evaluate_status_closed_by_unknown}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="evaluationStatus ticketOpen">
      <span>{strings.sessiondetails_evaluate_status_open}</span>
    </div>
  );
};

const ReviewersSelector = (props) => {
  const { reviewer, setReviewer } = props;
  const { user: me } = useContext(LoginStatusContext);
  const { data: users } = useAdminsQuery();
  const theme = useTheme();
  const reviewers = useMemo(
    () =>
      users
        ? users.filter((d) => d.id !== me.id && !d.internal && d.email)
        : [],
    [users, me.id]
  );

  const handleChange = (e) => {
    const { value } = e.target;
    const { name } = users.find((user) => user.id === value);
    const v = value?.trim() || "";
    v?.length ? setReviewer({ id: v, name }) : setReviewer(null);
  };

  return (
    <select
      value={reviewer?.id || ""}
      onChange={handleChange}
      disabled={props.disabled}
      style={{
        background: theme.palette.grey.light,
        color: theme.palette.purple.dark,
      }}
    >
      <option value=""></option>
      {reviewers.map((d) => (
        <option key={d.id} value={d.id}>
          {d.name}
        </option>
      ))}
    </select>
  );
};

export default function EvaluateSession({ scenario, session }) {
  const caps = useCapabilities();
  const writeAllowed = caps({ "analysis-model.sessions": { write: true } });
  const [sessionAffiliation, setSessionAffiliation] = useState();
  const [sessionComment, setSessionComment] = useState("");
  const [reviewer, setReviewer] = useState(null);
  const strings = useLocalizedStrings();
  const { pushMessage } = useMessages();

  const { selectedScenario } = useContext(ScenariosContext);
  const [confirmCloseOpen, setConfirmCloseOpen] = useState(false);
  const { data: users } = useAdminsQuery();
  const { data: sessionInfo } = useSessionDetails();
  const { data: referringSessionsCount } = useSessionReferencedCountQuery({
    session,
    scenario,
  });

  const { mutate: updateProfile } = useClusterUpdateMutation();
  const { mutate: updateSession } = useSessionsUpdateMutation();
  const { mutate: updateReviewer } =
    useSessionsEvaluationReviewNotificationMutation();

  const closedBy = useMemo(() => {
    return sessionInfo?.ticket_closed_by
      ? users?.find((d) => d.id === sessionInfo.ticket_closed_by)
      : null;
  }, [sessionInfo?.ticket_closed_by, users]);

  const profileAffiliation =
    +sessionInfo?.cluster > 0
      ? sessionInfo?.cluster_malicious
        ? "malicious"
        : "unclassified"
      : null;

  const profileComment = sessionInfo?.cluster_annotation || "";

  const sendReviewNotification = useCallback(() => {
    if (!reviewer) {
      return;
    }

    updateReviewer(
      {
        analystId: reviewer?.id,
        notes: "",
        scenario,
        session,
      },
      {
        onSuccess: () => {
          pushMessage(
            "success",
            strings.formatString(
              strings.sessiondetails_evaluate_review_sent,
              reviewer?.name
            )
          );
        },
      }
    );
  }, [updateReviewer, reviewer, scenario, session, pushMessage, strings]);

  useEffect(() => {
    if (!sessionInfo) {
      return;
    }
    setSessionAffiliation(
      sessionInfo.benign
        ? "benign"
        : sessionInfo.malicious
        ? "malicious"
        : "unclassified"
    );
    setSessionComment(sessionInfo.annotation || "");
  }, [sessionInfo]);

  const dialogText = useMemo(() => {
    const perUser = selectedScenario.per_user ? "of the user" : "of all users";

    if (sessionAffiliation === "malicious") {
      return strings.formatString(
        strings.sessiondetails_evaluate_malicious_confirmation_text,
        <span style={{ fontStyle: "italic" }}>
          <b>{capitalize(sessionAffiliation)}</b>
        </span>,
        <span style={{ textDecoration: "underline" }}>pattern</span>,
        perUser
      );
    }

    if (sessionAffiliation === "benign") {
      return strings.formatString(
        strings.sessiondetails_evaluate_benign_confirmation_text,
        <span style={{ fontStyle: "italic" }}>
          <b>{capitalize(sessionAffiliation)}</b>
        </span>,
        <span style={{ textDecoration: "underline" }}>pattern</span>,
        perUser
      );
    }

    let benignText = !isNull(sessionInfo?.score_ref)
      ? strings.sessiondetails_evaluate_unclassified_referring_confirmation_text
      : referringSessionsCount
      ? strings.sessiondetails_evaluate_unclassified_confirmation_text
      : strings.sessiondetails_evaluate_unclassified_no_referring_confirmation_text;

    return strings.formatString(
      benignText,
      <span style={{ fontStyle: "italic" }}>
        <b>{capitalize(sessionAffiliation)}</b>
      </span>,
      referringSessionsCount || ""
    );
  }, [
    referringSessionsCount,
    selectedScenario.per_user,
    sessionAffiliation,
    sessionInfo,
    strings,
  ]);

  const savedSessionAffiliation = sessionInfo?.benign
    ? "benign"
    : sessionInfo?.malicious
    ? "malicious"
    : "unclassified";

  const transports = useMemo(() => {
    if (!sessionInfo) {
      return [];
    }

    const t = sessionInfo.alert_info
      ?.filter((d) => d.status !== "fail" && d.transport)
      .map((d) => d.transport.toUpperCase())
      .reduce((a, d) => {
        a.add(d);
        return a;
      }, new Set());
    return Array.from(t);
  }, [sessionInfo]);

  const handleSubmit = () => {
    if (!sessionInfo) {
      return;
    }

    if (+sessionInfo.cluster > 0) {
      updateProfile({
        scenarioId: scenario,
        cluster: sessionInfo.cluster,
        malicious: profileAffiliation === "malicious",
        annotation: profileComment,
      });
    }

    updateSession(
      {
        annotation: sessionComment,
        benign: sessionAffiliation === "benign",
        close: true,
        malicious: sessionAffiliation === "malicious",
        scenario,
        session,
      },
      {
        onSuccess: () => {
          pushMessage(
            "success",
            strings.sessiondetails_evaluate_submit_success
          );
        },
      }
    );
  };

  const handleEvaluate = () => {
    if (savedSessionAffiliation !== sessionAffiliation) {
      setConfirmCloseOpen(true);
      return;
    }
    handleSubmit();
  };

  const handleReOpenTicket = () => {
    updateSession({
      annotation: undefined,
      benign: undefined,
      close: false,
      malicious: undefined,
      scenario,
      session,
    });
  };

  const allowSendingNotifications = caps({
    "acm.users.notify": { write: true },
  });

  if (!caps({ "analysis-model.sessions": { read: true } })) {
    return <NotAllowedMessage />;
  }

  if (!sessionInfo) {
    return null;
  }

  return (
    <StyledEvaluateSession profileAffiliation={profileAffiliation}>
      <PageSubtitle>{strings.sessiondetails_evaluate_title}</PageSubtitle>
      <div className="evaluationStatusesContainer">
        <AlertInitiationStatus
          alertTime={sessionInfo.time_alerted}
          transport={transports}
        />
        <EvaluationStatus
          alerted={sessionInfo.alerted}
          on={sessionInfo.ticket_closed_time}
          by={closedBy?.name}
          email={closedBy?.email}
        />
      </div>

      <div className="evaluationFrame evaluateSession">
        <div className="evaluationFrameTitle">
          {strings.sessiondetails_evaluate_session_title}
        </div>
        <div className="evaluationFrameBody">
          <div
            className="similarSessionHolder"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="similarSession" style={{ display: "flex" }}>
              {!isNull(sessionInfo.score_ref) ? (
                <>
                  <CallMadeIcon
                    fontSize="small"
                    style={{
                      transform: "rotate(265deg)",
                      color: "#4582b4",
                    }}
                  />
                  <DataLink
                    to={`/scenarios/${scenario}/sessions/${encodeURIComponent(
                      sessionInfo.score_ref
                    )}/session-summary`}
                  >
                    {strings.sessiondetails_evaluate_similar_session}
                  </DataLink>
                </>
              ) : referringSessionsCount ? (
                <>
                  <CallMadeIcon
                    fontSize="small"
                    style={{
                      transform: "rotate(265deg)",
                      color: "#4582b4",
                    }}
                  />
                  <DataLink
                    to={`/scenarios/${scenario}/sessions?score_ref=${session}`}
                  >
                    {strings.sessiondetails_evaluate_referring_session}
                  </DataLink>
                </>
              ) : null}
            </div>
            <ClassificationToolbar
              disabled={!writeAllowed}
              setValue={setSessionAffiliation}
              value={sessionAffiliation}
              values={["benign", "unclassified", "malicious"]}
            />
          </div>
          <CommentEditor
            disabled={!writeAllowed}
            onChange={(e) => setSessionComment(e.target.value)}
            value={sessionComment}
          />
        </div>
      </div>

      <div className="evaluationFrame evaluateProfile">
        <div className="evaluationFrameTitle">
          {strings.profile}
          {+sessionInfo.cluster > 0 && (
            <DataLink
              to={`/scenarios/${scenario}/profiles/${sessionInfo.cluster}/diffusion`}
            >
              (#{sessionInfo.cluster})
            </DataLink>
          )}
          <Chip
            className="profileEvaluation"
            label={profileAffiliation}
            size="small"
          />
        </div>
        <div className="evaluationFrameBody">
          {profileAffiliation ? (
            profileComment ? (
              <>
                <div>{strings.sessiondetails_evaluate_profile_comment}</div>
                <div className="profileEvaluationComment">{profileComment}</div>
              </>
            ) : (
              <div className="profileEvaluationNoComment">
                {strings.sessiondetails_evaluate_profile_no_comment}
              </div>
            )
          ) : (
            <div className="profileEvaluationNoComment">
              {strings.sessiondetails_evaluate_no_profile}
            </div>
          )}
        </div>
      </div>

      <div className="evaluationActions">
        <div className="reviewerSelection">
          {strings.sessiondetails_evaluate_review} <br />
          <ReviewersSelector
            disabled={!allowSendingNotifications}
            reviewer={reviewer}
            setReviewer={setReviewer}
          />
          <UiSecondaryButton
            disabled={!reviewer}
            onClick={sendReviewNotification}
          >
            {strings.sessiondetails_evaluate_send}
          </UiSecondaryButton>
        </div>
        <div style={{ alignItems: "center", display: "flex", gap: "15px" }}>
          <UiSecondaryButton
            disabled={!writeAllowed || !sessionInfo.ticket_closed_time}
            onClick={handleReOpenTicket}
          >
            {strings.sessiondetails_evaluate_reopen}
          </UiSecondaryButton>
          <UiPrimaryButton disabled={!writeAllowed} onClick={handleEvaluate}>
            {strings.sessiondetails_evaluate_submit}
          </UiPrimaryButton>
        </div>
      </div>
      <MultipleOptionsDialog
        onConfirm={handleSubmit}
        open={confirmCloseOpen}
        setOpen={setConfirmCloseOpen}
        text={dialogText}
        title={strings.text_confirmation}
      />
    </StyledEvaluateSession>
  );
}
