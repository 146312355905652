import { useCallback, useContext, useRef, useState } from "react";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { queryFetch } from "api/query";

import { ScenariosContext } from "contexts/ScenariosContext";

import FileUpload from "components/ui/FileUpload";
import Flexbox from "components/ui/Flexbox";
import FrameContent from "components/ui/FrameContent";
import RemoteDownloader from "components/ui/RemoteDownloader";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import SettingsOption from "./SettingsOption";

const CsvSettings = ({
  title,
  filePrefix,
  uploadDisabled,
  getUploadUrl,
  getCountUrl,
  getDownloadUrl,
  invalidateKey,
  popover = null,
}) => {
  const strings = useLocalizedStrings();
  const { pushMessage } = useMessages();
  const { selectedScenario } = useContext(ScenariosContext);
  const queryClient = useQueryClient();
  const [expanded, setExpanded] = useState(false);
  const opened = useRef(false);
  const caps = useCapabilities();

  const queryKey = [
    "scenario",
    selectedScenario?.id,
    "settings",
    "parameters",
    filePrefix,
  ];

  const { data: count, refetch } = useQuery({
    queryKey,
    queryFn: () => queryFetch(getCountUrl(selectedScenario?.id)),
    select: (data) => data[0].count,
    enabled: !!getCountUrl && !!selectedScenario?.id && opened.current,
  });

  const { mutate } = useMutation({
    mutationFn: (file) => queryFetch(getUploadUrl(selectedScenario?.id, file)),
    onSuccess: (_data, file) => {
      pushMessage(
        "success",
        strings.formatString(strings.scenariosettings_upload_success, file.name)
      );
      refetch();
      if (invalidateKey) {
        queryClient.invalidateQueries({ queryKey: invalidateKey });
      }
    },
  });

  const handleExpand = useCallback(() => {
    setExpanded((prev) => {
      if (!prev && !opened.current) {
        opened.current = true;
      }
      return !prev;
    });
  }, []);

  return (
    <SettingsOption title={title} open={expanded} onChange={handleExpand}>
      <FrameContent>
        <Typography variant="body1" style={{ marginBottom: "16px" }}>
          {strings.formatString(
            strings.scenariosettings_currently_configured_counter,
            count ?? 0
          )}
        </Typography>
        <Flexbox justifyContent="center" width={1} style={{ gap: "10px" }}>
          {popover}
          <FileUpload
            placeholder={`${strings.scenariosettings_upload_placeholder}...`}
            accept="text/csv"
            onUpload={mutate}
            style={{ flexGrow: 1 }}
            disabled={
              uploadDisabled !== undefined
                ? uploadDisabled
                : !caps({ "acm.scenarios": { write: true } })
            }
          />
          <RemoteDownloader
            url={getDownloadUrl(selectedScenario.id).url}
            filename={`${filePrefix}.${selectedScenario.id}.csv`}
            style={{ flexGrow: 0 }}
          >
            <UiPrimaryButton
              startIcon={<CloudDownloadIcon />}
              style={{
                height: "3.2em",
                marginLeft: "10px",
                marginRight: "8px",
              }}
            >
              {strings.button_download}
            </UiPrimaryButton>
          </RemoteDownloader>
        </Flexbox>
      </FrameContent>
    </SettingsOption>
  );
};

export default CsvSettings;
