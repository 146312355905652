import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  styled,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import BundlesAPI from "api/bundles";

import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import { SelectorInput } from "components/ui/form/SelectorInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { useReportedFetch } from "hooks/http";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledScenarioForm = styled(Dialog)(() => ({
  "& .form-grid": {
    gap: "0.5em",
    paddingTop: "10px",
  },
  "& .form-grid .MuiGrid-item > *": {
    width: "100%",
  },
}));

const ScenarioForm = ({
  open,
  sourceScenario,
  formMode,
  onCancel,
  onAccept,
}) => {
  const strings = useLocalizedStrings();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      use_case: "",
      name:
        formMode === "create"
          ? ""
          : `${sourceScenario?.name} (${strings.text_copy})`,
      description:
        formMode === "create"
          ? ""
          : `${sourceScenario?.description} (${strings.text_copy})`,
    },
  });

  const isValid = methods.formState.isValid;
  const submitting = methods.formState.isSubmitting;

  const [bundleKeys] = useReportedFetch(BundlesAPI.getKeys().url, []);

  const dialogTitle =
    formMode === "clone"
      ? strings.scenarioform_clone_title
      : strings.scenarioform_new_title;

  const acceptLabel =
    formMode === "clone" ? strings.button_clone : strings.button_create;

  return (
    <StyledScenarioForm open={open} fullWidth>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onAccept)}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <Grid container className="form-grid">
              <Grid item xs={12}>
                {formMode === "clone" ? (
                  <h4 style={{ marginBottom: "2em" }}>
                    Cloning scenario {sourceScenario?.id}
                  </h4>
                ) : (
                  <SelectorInput
                    name="use_case"
                    label={strings.scenarioform_bundle}
                    required={true}
                  >
                    {bundleKeys.map((b) => (
                      <MenuItem
                        className="bundle-option"
                        key={b.use_case}
                        value={b.use_case}
                      >
                        {b.use_case
                          .split(/[ ]/g)
                          .map((t) => t.charAt(0).toUpperCase() + t.slice(1))
                          .join(" ")}
                      </MenuItem>
                    ))}
                  </SelectorInput>
                )}
              </Grid>

              <Grid item xs={12}>
                <TextFieldInput
                  name={"name"}
                  label={strings.scenarioform_name}
                  required
                  rules={{
                    minLength: {
                      value: 3,
                      message: strings.formatString(
                        strings.invalid_min_length,
                        3
                      ),
                    },
                    maxLength: {
                      value: 50,
                      message: strings.formatString(
                        strings.invalid_max_length,
                        50
                      ),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldInput
                  name="description"
                  label={strings.scenarioform_description}
                  required
                  rules={{
                    minLength: {
                      value: 3,
                      message: strings.formatString(
                        strings.invalid_min_length,
                        3
                      ),
                    },
                    maxLength: {
                      value: 100,
                      message: strings.formatString(
                        strings.invalid_max_length,
                        100
                      ),
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <UiSecondaryButton onClick={onCancel}>
              {strings.button_cancel}
            </UiSecondaryButton>
            <UiPrimaryButton disabled={!isValid || submitting} type="submit">
              {acceptLabel}
            </UiPrimaryButton>
          </DialogActions>
        </form>
      </FormProvider>
    </StyledScenarioForm>
  );
};

export default ScenarioForm;
