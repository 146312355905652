import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useController } from "react-hook-form";

import { inUtc } from "utils/time-fmt";

const dateForQuery = (date) => {
  return inUtc(date).startOf("d").toISOString();
};

export function DatePickerInput({
  name,
  rules,
  defaultValue,
  control,
  testId,
  ...props
}) {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  return (
    <DesktopDatePicker
      views={["year", "month", "day"]}
      label="Select a date"
      timezone="UTC"
      value={field.value ? inUtc(field.value) : null}
      onChange={(date) => {
        field.onChange(dateForQuery(date));
      }}
      slotProps={{
        popper: {
          placement: "top-start",
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
          ],
        },
        textField: {
          helperText: fieldState.error?.message,
          readOnly: true,
        },
        openPickerIcon: {
          "data-testid": testId,
        },
      }}
      inputRef={field.ref}
      format="MMM DD, YYYY"
      {...props}
    />
  );
}
