import React, { useState } from "react";

import { FormControlLabel, RadioGroup } from "@mui/material";

import BrandedRadio from "components/ui/BrandedRadio";
import NotAllowedMessage from "components/ui/NotAllowedMessage";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import ProfileTabContainer from "./ProfileTabContainer";
import { ProfileTimeHistogramChart } from "./ProfileTimeHistogramChart";
import StyledChartWrapper from "./StyledChartWrapper";

export default function ProfileTimeHistogram({ profile, isPending }) {
  const strings = useLocalizedStrings();
  const [mode, setMode] = useState("relative");
  const caps = useCapabilities();

  if (!caps({ "analysis-model.clusters": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <ProfileTabContainer
      loading={isPending}
      explanation={strings.profiledetails_tab_time_gap_explanation}
    >
      <StyledChartWrapper>
        <div className="toolbar">
          <RadioGroup
            label={strings.profiledetails_tab_time_gap_quantities}
            row
            className="chart-modes"
            name="chart-mode"
            value={mode}
            onChange={(e) => setMode(e.target.value)}
          >
            <FormControlLabel
              value="relative"
              control={<BrandedRadio />}
              label={strings.profiledetails_tab_time_gap_quantities_relative}
              labelPlacement="end"
            />
            <FormControlLabel
              value="absolute"
              control={<BrandedRadio />}
              label={strings.profiledetails_tab_time_gap_quantities_absolute}
              labelPlacement="end"
            />
          </RadioGroup>
        </div>

        <ProfileTimeHistogramChart
          histogram={profile?.histogram || []}
          mode={mode}
          orderBy="occurences"
          slice={100}
        />
      </StyledChartWrapper>
    </ProfileTabContainer>
  );
}
