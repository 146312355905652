import React from "react";

export const useStateDeep = (init) => {
  const [state, setState] = React.useState(init);
  const setStateCompare = (newState) => {
    let changed = false;
    try {
      changed = JSON.stringify(newState) !== JSON.stringify(state);
    } catch (err) {
      changed = newState !== state;
    }
    if (changed) {
      setState(newState);
    }
  };
  return [state, setStateCompare];
};
