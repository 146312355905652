import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { ColumnSelector } from "components/scenarios/MappingEditor/components/ColumnSelector";
import { OutputFieldInput } from "components/scenarios/MappingEditor/components/OutputFieldInput";
import { SectionWrap } from "components/scenarios/MappingEditor/components/SectionWrap";
import { DeleteButton } from "components/ui/DeleteButton";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledItemWrapper = styled("div")(() => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  height: "auto",
  marginLeft: "10px",
  maxHeight: "240px",
  minHeight: "50px",
  overflow: "auto",
  paddingTop: "10px",
  width: "100%",
  transition: "max-height 500ms ease",
  "& .singleItemWrapper": {
    display: "flex",
    flexDirection: "column",
    "&div": {
      height: "56px",
    },
  },
  "& .singleItemWrapperTextField": {
    marginBottom: "10px !Important",
    "& fieldset": {
      height: "100%",
      marginBottom: "0",
    },
    "& input": {
      height: "56px",
    },
  },
  "& .singleItemWrapperSelectField": {
    height: "56px",
    "& select": {
      height: "56px",
    },
    "& fieldset": {
      height: "100%",
      marginBottom: "5px",
    },
    "& .MuiInputBase-root": {
      width: "100%",
    },
  },
}));

const TransformationsTabItemComponent = ({ data, dataKey, onDelete }) => {
  const strings = useLocalizedStrings();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const namePrefix = "options.internal_config.transformations.entries";

  const TransformationsTypes = [
    {
      label: "Regex Capture",
      value: "regex_capture",
    },
  ];

  const TransformationsColumns = [
    {
      label: "Appuser",
      value: "appuser",
    },
    {
      label: "Device",
      value: "device",
    },
    {
      label: "Location",
      value: "location",
    },
  ];

  const checkForErrorClasses = () => {
    if (
      !!errors?.options?.internal_config?.transformations?.["entries"]?.[
        dataKey
      ]?.root
    ) {
      return " customError";
    } else if (
      !!errors?.options?.internal_config?.transformations?.["entries"]?.[
        dataKey
      ]
    ) {
      return " errorSection";
    } else {
      return "";
    }
  };

  return (
    <div
      key={dataKey}
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "20px",
      }}
    >
      <DeleteButton onClick={() => onDelete(dataKey)} />
      <SectionWrap className={checkForErrorClasses()} key={dataKey}>
        <StyledItemWrapper key={dataKey}>
          <div key={dataKey} style={{ width: "95%" }}>
            <>
              <div key={dataKey} style={{ marginLeft: "20px" }}>
                <div className="singleItemWrapper" key={dataKey}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "150px" }}>
                      <Controller
                        control={control}
                        defaultValue={data.type || ""}
                        name={`${namePrefix}.${dataKey}.type`}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl
                            className="singleItemWrapperSelectField"
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <InputLabel id="selector">
                              {
                                strings.sourcedialog_mapping_editor_transformations_tab_label_type
                              }
                            </InputLabel>
                            <Select
                              error={!!error}
                              label={
                                strings.sourcedialog_mapping_editor_transformations_tab_label_type
                              }
                              labelId="selector"
                              onChange={(value) => setValue(field.name, value)}
                              value={data.type || ""}
                              variant="outlined"
                              {...field}
                            >
                              {TransformationsTypes.map((type) => {
                                return (
                                  <MenuItem key={type.value} value={type.value}>
                                    <span>{type.label}</span>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {!!error && (
                              <FormHelperText error>
                                {strings.invalid_input}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </div>
                    <ColumnSelector
                      defaultValue={data.input_field || ""}
                      name={`${namePrefix}.${dataKey}.input_field`}
                    />
                    <OutputFieldInput
                      name={`${namePrefix}.${dataKey}.output_field`}
                    />

                    <div style={{ width: "150px" }}>
                      <Controller
                        control={control}
                        defaultValue={data.output_column || ""}
                        name={`${namePrefix}.${dataKey}.output_column`}
                        rules={{
                          required: true,
                          deps: [
                            "options.internal_config.mapping.appuser",
                            "options.internal_config.mapping.location",
                          ],
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl
                            className="singleItemWrapperSelectField"
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <InputLabel id="selector">
                              {
                                strings.sourcedialog_mapping_editor_transformations_tab_label_mapped_column
                              }
                            </InputLabel>
                            <Select
                              error={!!error}
                              label={
                                strings.sourcedialog_mapping_editor_transformations_tab_label_mapped_column
                              }
                              labelId="selector"
                              onChange={(value) => setValue(field.name, value)}
                              value={data.output_column || ""}
                              variant="outlined"
                              {...field}
                            >
                              {TransformationsColumns.map((type) => {
                                return (
                                  <MenuItem key={type.value} value={type.value}>
                                    <span>{type.label}</span>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {!!error && (
                              <FormHelperText error>
                                {strings.invalid_input}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </div>
                  </div>
                  <Divider
                    flexItem
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{
                      background: "black",
                      marginBottom: "20px",
                    }}
                  />

                  <div className="defaulLabel">
                    <Controller
                      control={control}
                      defaultValue={data.formula || ""}
                      name={`${namePrefix}.${dataKey}.formula`}
                      rules={{ required: true, pattern: /(?!\s*$).+/ }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          autoFocus={true}
                          className="singleItemWrapperTextField"
                          error={!!error}
                          helperText={!!error ? strings.invalid_input : ""}
                          label={
                            strings.sourcedialog_mapping_editor_transformations_tab_label_regex
                          }
                          onChange={(value) => setValue(field.name, value)}
                          style={{ width: "200px" }}
                          variant="outlined"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        </StyledItemWrapper>
      </SectionWrap>
    </div>
  );
};

export default TransformationsTabItemComponent;
