import { Badge } from "@mui/material";
import { styled } from "@mui/material";

//TODO: Reuse the styling from the mapping editor, use an actual button for the tabs
//create another component for the Navlinks to use in the Subnavigator
const StyledEditorTab = styled("div")(({ theme }) => ({
  cursor: "pointer",
  position: "relative",
  display: "inline-flex",
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  justifyContent: "center",
  textDecoration: "none",
  textTransform: "uppercase",
  letterSpacing: 2,
  color: theme.palette.text.secondary,
  transition: "all 200ms ease",
  fontSize: "12px",
  userSelect: "none",
  "&:hover, &:active, &.active": {
    color: theme.palette.text.primary,
  },
  "&.activeTab": {
    textShadow: "0px 0px 1px white",
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    borderRadius: "5px",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "-3px",
      left: "50%",
      width: "1em",
      height: "1em",
      transform: "translate(-50%) rotate(-135deg)",
      backgroundColor: `${theme.palette.primary.dark}`,
      zIndex: -1,
    },
  },
}));

const ErrorIndicator = styled(Badge)(() => ({
  position: "absolute",
  top: "-3px",
  right: "15px",
  "& .MuiBadge-anchorOriginTopRightCircular": {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const EditorTab = ({ selected, hasError, children, ...props }) => {
  return (
    <StyledEditorTab className={selected ? "activeTab" : ""} {...props}>
      {hasError && (
        <ErrorIndicator badgeContent="!" color="error" overlap="circular" />
      )}
      {children}
    </StyledEditorTab>
  );
};
