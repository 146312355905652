import React from "react";

import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import BrandedRadio from "./BrandedRadio";
import Flexbox from "./Flexbox";

const affiliationXlat = (strings) => ({
  unclassified: strings.evaluationframe_affiliation_unclassified,
  benign: strings.evaluationframe_affiliation_benign,
  malicious: strings.evaluationframe_affiliation_malicious,
});

export default function AffiliationSelector({
  values,
  value,
  setValue,
  disabled,
}) {
  const strings = useLocalizedStrings();
  return (
    <RadioGroup
      name="affiliation"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      disabled={disabled}
    >
      <Flexbox style={{ gap: "2em" }}>
        {values.map((v) => (
          <FormControlLabel
            key={v}
            value={v}
            control={<BrandedRadio affiliation={v} disabled={disabled} />}
            label={affiliationXlat(strings)[v]}
            style={{ marginRight: 0 }}
          />
        ))}
      </Flexbox>
    </RadioGroup>
  );
}
