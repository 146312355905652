import React from "react";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import BigMessage from "./BigMessage";

export default function NotAllowedMessage() {
  const strings = useLocalizedStrings();
  return <BigMessage>{strings.text_not_permitted}</BigMessage>;
}
