import { useState } from "react";

import { useCollectorsResetMutation } from "api/collectors";
import { useAbortJobMutation, useRunJobMutation } from "api/scheduler";

import Flexbox from "components/ui/Flexbox";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";
import useScenarioJobState from "hooks/useScenarioJobState";

function ActionButton({ onClick, disabled, label, primary }) {
  if (primary) {
    return (
      <UiPrimaryButton
        onClick={onClick}
        disabled={disabled}
        style={{ width: "13em" }}
      >
        {label}
      </UiPrimaryButton>
    );
  }

  return (
    <UiSecondaryButton
      onClick={onClick}
      disabled={disabled}
      style={{ width: "13em" }}
    >
      {label}
    </UiSecondaryButton>
  );
}

function ConfirmableAction({
  onAction,
  disabled,
  label,
  actionLabel,
  dialogText,
  dialogTitle,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ActionButton
        onClick={() => setOpen(true)}
        disabled={disabled}
        label={label}
      />

      <MultipleOptionsDialog
        confirmText={actionLabel}
        onConfirm={onAction}
        open={open}
        setOpen={setOpen}
        title={dialogTitle}
        text={dialogText}
      />
    </>
  );
}

function JobActions({
  scenario,
  scenarioName,
  onProgressStarted,
  collectorCapabilities,
}) {
  const strings = useLocalizedStrings();
  const caps = useCapabilities();
  const { isRunning } = useScenarioJobState(scenario);
  const { pushMessage } = useMessages();

  const { mutate: runJob } = useRunJobMutation();
  const { mutate: abortJob } = useAbortJobMutation();
  const { mutate: resetCollector } = useCollectorsResetMutation();

  const { delete_all_flat = false, reprocess_flat = false } =
    collectorCapabilities;

  const jobDisabled = isRunning || !caps({ "scheduler.jobs": { write: true } });
  const abortDisabled =
    !isRunning || !caps({ "scheduler.jobs": { write: true } });

  const handleRun = () =>
    runJob({ scenarioId: scenario }, { onSuccess: () => onProgressStarted() });

  const handleAbort = () => {
    abortJob(scenario, {
      onSuccess: () => {
        pushMessage(
          "success",
          strings.scenariodiagnostics_jobprogress_abortrequested
        );
      },
    });
  };

  const scenarioReset = (jobType, toastMessage) => {
    resetCollector(
      {
        scenarioId: scenario,
        jobType,
      },
      {
        onSuccess: () => {
          onProgressStarted();
          pushMessage(
            "success",
            strings.formatString(toastMessage, scenarioName || "")
          );
        },
      }
    );
  };

  const handleReprocess = () => {
    scenarioReset(
      reprocess_flat ? "reprocess_flat" : "clean_flat",
      strings.collectors_diagnostics_actions_reprocess_toast_message
    );
  };

  const handleDeleteFlat = () => {
    scenarioReset(
      "delete_flat",
      strings.collectors_diagnostics_actions_delete_flat_toast_message
    );
  };

  const handleDeleteAll = () => {
    scenarioReset(
      "delete_all",
      strings.collectors_diagnostics_actions_delete_all_toast_message
    );
  };

  return (
    <Flexbox
      style={{
        gap: "15px",
        flexBasis: 1,
        flexDirection: "column",
        maring: "auto",
      }}
    >
      <ActionButton
        primary
        onClick={handleRun}
        disabled={jobDisabled}
        label={strings.scenariodiagnostics_jobprogress_run}
      />
      <ActionButton
        onClick={handleAbort}
        disabled={abortDisabled}
        label={strings.scenariodiagnostics_jobprogress_abort}
      />
      <ConfirmableAction
        onAction={handleReprocess}
        disabled={jobDisabled}
        label={strings.button_reprocess}
        actionLabel={strings.button_reprocess}
        dialogTitle={
          strings.collectors_diagnostics_actions_reprocess_dialog_title
        }
        dialogText={
          strings.collectors_diagnostics_actions_reprocess_dialog_text
        }
      />
      {delete_all_flat && (
        <>
          <ConfirmableAction
            onAction={handleDeleteFlat}
            disabled={jobDisabled}
            label={strings.button_delete_flat}
            actionLabel={strings.button_delete}
            dialogTitle={
              strings.collectors_diagnostics_actions_delete_flat_dialog_title
            }
            dialogText={strings.formatString(
              strings.collectors_diagnostics_actions_delete_flat_dialog_text,
              <em>{scenarioName}</em>
            )}
          />
          <ConfirmableAction
            onAction={handleDeleteAll}
            disabled={jobDisabled}
            label={strings.button_delete_all}
            actionLabel={strings.button_delete}
            dialogTitle={
              strings.collectors_diagnostics_actions_delete_all_dialog_title
            }
            dialogText={strings.formatString(
              strings.collectors_diagnostics_actions_delete_all_dialog_text,
              <em>{scenarioName}</em>
            )}
          />
        </>
      )}
    </Flexbox>
  );
}

export default JobActions;
