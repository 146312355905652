import { useEffect, useMemo, useState } from "react";

import { TextField } from "@mui/material";

import { useClusterUpdateMutation } from "api/clusters";

import AffiliationSelector from "components/ui/AffiliationSelector";
import Flexbox from "components/ui/Flexbox";
import Frame from "components/ui/Frame";
import FrameActions from "components/ui/FrameActions";
import FrameContent from "components/ui/FrameContent";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import NotAllowedMessage from "components/ui/NotAllowedMessage";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import ProfileTabContainer from "./ProfileTabContainer";

export default function EvaluateProfile({
  profile,
  scenario: scenarioId,
  profileId: cluster,
}) {
  const strings = useLocalizedStrings();
  const [open, setOpen] = useState(false);
  const [affiliation, setAffiliation] = useState();
  const [annotation, setAnnotation] = useState();
  const { pushMessage } = useMessages();
  const caps = useCapabilities();
  const disabled = !caps({ "analysis-model.clusters": { write: true } });

  const { mutate: updateProfile } = useClusterUpdateMutation();

  const savedProfileAffiliation = profile?.malicious
    ? "malicious"
    : "unclassified";

  const malicious = affiliation === "malicious";
  const confirmText = useMemo(() => {
    if (malicious) {
      return strings.formatString(
        strings.profiledetails_tab_malicious_confirmation_text,
        <b style={{ fontStyle: "italic" }}>Malicious</b>,
        <span style={{ textDecoration: "underline" }}>pattern</span>
      );
    }
    return strings.formatString(
      strings.profiledetails_tab_unclassified_confirmation_text,
      <b style={{ fontStyle: "italic" }}>Unclassified</b>,
      <span style={{ textDecoration: "underline" }}>pattern</span>
    );
  }, [malicious, strings]);

  useEffect(() => {
    if (!profile) {
      return;
    }
    setAnnotation(profile.annotation);
    setAffiliation(profile.malicious ? "malicious" : "unclassified");
  }, [profile]);

  const handleSubmit = () => {
    updateProfile(
      {
        scenarioId,
        cluster,
        malicious: affiliation === "malicious",
        annotation,
      },
      {
        onSuccess: () => {
          pushMessage(
            "success",
            strings.profiledetails_tab_evaluation_submit_success
          );
        },
      }
    );
  };

  if (!caps({ "analysis-model.clusters": { read: true } })) {
    return <NotAllowedMessage />;
  }

  const handleEvaluate = () => {
    if (savedProfileAffiliation !== affiliation) {
      setOpen(true);
      return;
    }
    handleSubmit();
  };

  return (
    <ProfileTabContainer>
      <Frame
        style={{
          margin: 0,
          maxHeight: "24em",
          width: "50%",
          maxWidth: "600px",
          border: "none",
        }}
      >
        <FrameContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Flexbox
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <AffiliationSelector
              values={["unclassified", "malicious"]}
              value={affiliation || "unclassified"}
              setValue={setAffiliation}
              disabled={disabled}
            />

            <TextField
              variant="outlined"
              label={strings.evaluationframe_comment_label}
              placeholder={`${strings.evaluationframe_comment_placeholder}...`}
              value={annotation || ""}
              onChange={(e) => setAnnotation(e.target.value)}
              multiline
              minRows={5}
              maxRows={5}
              style={{ width: "100%", marginTop: "1em" }}
              disabled={disabled}
            />
          </Flexbox>
        </FrameContent>

        <FrameActions>
          <UiPrimaryButton onClick={handleEvaluate} disabled={disabled}>
            {strings.button_save}
          </UiPrimaryButton>
        </FrameActions>
      </Frame>

      <MultipleOptionsDialog
        onConfirm={handleSubmit}
        open={open}
        setOpen={setOpen}
        title={"Confirmation"}
        text={confirmText}
      />
    </ProfileTabContainer>
  );
}
