import { useEffect, useMemo, useRef } from "react";

import { Chart, registerables } from "chart.js";

import { useAlertsDailyTrendsQuery } from "api/sessions";

import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";

import { getUniqueColor } from "utils/getUniqueColor";
import { inUtc } from "utils/time-fmt";

const useGetAlertDailyTrends = ({ scenarios, numDays }) => {
  const results = useAlertsDailyTrendsQuery({
    scenarios,
    minRisk: 0,
    maxRisk: 100,
    numDays,
  });
  const isPending = results.some((r) => r.isFetching);

  const data = useMemo(() => {
    const daysArray = Object.fromEntries(
      Array.from({ length: numDays }, (_, i) => {
        const date = inUtc().subtract(i, "days").format("YYYY-MM-DD");
        return [date, { x: date, y: 0 }];
      })
    );

    return results.map((res, idx) => {
      const label = scenarios[idx].name;

      const newBackgroundColor = getUniqueColor({
        index: idx,
      });

      const alertsToDates = { ...daysArray };
      res.data?.forEach((d) => {
        const date = d.date.replace(/T.+/, "");
        const item = {
          x: date,
          y: +d.num_alerts,
        };
        alertsToDates[date] = item;
      });
      const data = Object.values(alertsToDates).sort(
        (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()
      );

      return {
        label,
        data,
        backgroundColor: newBackgroundColor,
        fill: false,
        borderColor: newBackgroundColor,
        borderWidth: 2,
      };
    });
  }, [numDays, results, scenarios]);

  return {
    data,
    isPending,
  };
};

Chart.register(...registerables);

export const AlertsDailyTrendsChart = ({ scenarios, numDays, isBarChart }) => {
  const { data, isPending } = useGetAlertDailyTrends({ scenarios, numDays });
  const chartRef = useRef(null);
  const chartCanvas = useRef(null);

  useEffect(() => {
    if (!data || isPending) {
      return;
    }

    chartRef.current = new Chart(chartCanvas.current, {
      type: isBarChart ? "bar" : "line",
      options: {
        plugins: {
          legend: {
            labels: {
              position: "top",
              align: "start",
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          x: {
            display: true,
            stacked: isBarChart,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 12,
              },
            },
          },
          y: {
            display: true,
            stacked: isBarChart,
            title: {
              display: true,
              text: "# Alerts",
              font: {
                size: 20,
              },
            },
            grid: {
              display: false,
            },
            suggestedMax: 5,
            ticks: {
              stepSize: 10,
            },
            min: 0,
          },
        },
      },
      data: {
        datasets: data,
      },
    });

    return () => {
      chartRef?.current?.destroy();
    };
  }, [data, isBarChart, isPending]);

  return (
    <div className="chart-wrapper">
      {isPending ? <PageLoadingSpinner /> : <canvas ref={chartCanvas} />}
    </div>
  );
};
