import { Checkbox, FormControlLabel } from "@mui/material";
import { useController } from "react-hook-form";

export const CheckboxInput = ({ name, options, ...props }) => {
  const { field } = useController({ name, ...options });
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          checked={!!field.value}
          color="primary"
          disabled={props?.disabled}
        />
      }
      {...props}
    />
  );
};
